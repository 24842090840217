import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Usecard = React.memo(function () {
  return (
    <section className="use-card-travel-easy compadding lightgrey">
      <div className="container">
        <div className="card-travel-wrapper flex-box">
          <div className="card-travel-content headingbox letter-normal full-width-925 mb-zero">
            <h2>
              <span className="themecolor">Forex Card Your Key To</span> An Easy
              Travel
            </h2>

            <p>
              A Forex card is a simple prepaid card that comes with a
              complimentary insurance cover, and protection against any sudden
              currency rate change. It has multiple other benefits as well.
            </p>

            <p>
              If you are a student planning to pursue your education in either
              Europe or Australia, we can assist you with procuring a FOREX card
              through a partner firm. The charges for the same are nominal and
              you can keep it for your entire stay.
            </p>

            <h5>
              A FOREX card comes with multiple benefits. The major ones are
              listed below:
            </h5>

            <ul className="card-travel-details-list">
              <li className="card-travel-details-list-item">
                It works as an accepted identification car globally
              </li>

              <li className="card-travel-details-list-item">
                You can get exclusive discounts on food, clothing, accommodation
                etc
              </li>

              <li className="card-travel-details-list-item">
                Protection against currency fluctuations
              </li>

              <li className="card-travel-details-list-item">
                There is an extensive insurance cover
              </li>

              <li className="card-travel-details-list-item">
                Protection against card theft
              </li>

              <li className="card-travel-details-list-item">
                Air accident insurance cover
              </li>

              <li className="card-travel-details-list-item">
                Loss of personal documents cover- in some cases, the card issuer
                will assist you with getting the new documents as well It
                becomes probably the only card that you will need for your home
                country and the country of current stay
              </li>
            </ul>
          </div>

          <div className="card-travel-imagebox full-width-925">
            <span className="forex-image orange-card">
              <LazyLoadingImages src="/img/orange-card.png" alt="" />
            </span>

            <div
              className="travel-image bg-style"
              style={{ backgroundImage: `url('/img/forex-location.jpg')` }}
            ></div>

            <span className="forex-image forex-card">
              <LazyLoadingImages src="/img/forex-credit-card.png" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Usecard;
