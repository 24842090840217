import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInterestedInUser } from "../../../actions/userActions";
import { useNavigate } from "react-router-dom";

export default function InterestedIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, userInterest } = useSelector(
    (state) => state.userInterestIn
  );

  useEffect(() => {
    if (!userInterest) {
      dispatch(getInterestedInUser());
    }
  }, [dispatch, userInterest]);
  return (
    <div className="profile-others-details-wrapper">
      <div className="profile-others-details-heading flex-box align-center">
        <h2>Interested In</h2>
        {/* <span className="edit-profile-details" >
          <img src="/img/edit.png" alt="student" onClick={()=>navigate("/panel/myprofile_edit")} />
        </span> */}
      </div>
      <div className="profile-courses-details">
        <div className="profile-courses-info">
          <h5>Course Category</h5>
          <h3>{userInterest ? userInterest.Category_Of_Study : ""}</h3>
        </div>
        <div className="profile-courses-info">
          <h5>Country</h5>
          <h3>
            {userInterest ? userInterest.Icnt1 : ""}{" "}
            {userInterest ? "," + userInterest.Icnt2 : ""}
          </h3>
        </div>
        <div className="profile-courses-info">
          <h5>Degree</h5>
          <h3>{userInterest ? userInterest.Interested_Level_Of_Study : ""}</h3>
        </div>
      </div>
    </div>
  );
}
