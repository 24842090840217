import React, { useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import BannerForm from "../Common/BannerForm";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import Meta from "../../components/Meta";

const Index = React.memo(function Index() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const metaData = {
    title: "Study in Canada | Free Counselling – Search Education",
    mtitle: "Study in Canada | Free Counselling – Search Education",
    mdescription:
      "Experience academic excellence in Canada | Explore Education Opportunities with our Expert Guidance. Your path to global education begins here!",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Study in Canada | Free Counselling – Search Education",
    ogdescription:
      "Experience academic excellence in Canada | Explore Education Opportunities with our Expert Guidance. Your path to global education begins here!",
    ogimage: "https://www.searcheducation.com/img/img_24.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle: "Study in Canada | Free Counselling – Search Education",
    twitterDescription:
      "Experience academic excellence in Canada | Explore Education Opportunities with our Expert Guidance. Your path to global education begins here!",
    twitterImage: "https://www.searcheducation.com/img/img_24.jpg",
  };
  return (
    <>
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <Meta metaData={metaData} />
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/canada-banner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Canada</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>

              <div className="canada-university-info-list flex-box">
                <div className="canada-university-info-list-item">
                  <h4>807,750+</h4>
                  <p>International Students</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>10,000 CAD$</h4>
                  <p>Avg Living costs/pa</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>96+</h4>
                  <p>Universities</p>
                </div>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>

      <section className="about-study-canada compadding">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center">
            <h2>
              <span className="themecolor">Study</span> In Canada
            </h2>
            <p>
              Canada has a diverse choice of undergraduate and graduate
              programs, making it one of the greatest international study
              locations. Its universities place a high emphasis on research,
              which is critical to the advancement of the country.
            </p>
          </div>
          <div className="flex-box why-study-canada-wrapper align-center just-center">
            <div className="why-study-canada-content full-width-925">
              <div className="headingbox letter-normal mb-0">
                <h2>
                  <span className="themecolor">Why Study In</span> Canada?
                </h2>
                <p>
                  Apart from the fact that Canada boasts world-class
                  universities, overseas students come to Canada for a variety
                  of reasons:
                </p>
              </div>
              <ul className="study-country-details-list">
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    In Canada, international students are cordially welcomed.
                    Immigration-friendly regulations and multi-cultural cities
                    make it easier for students to live abroad.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    The rate of approval of US F1 visas has declined, allowing
                    Canada to overtake the United States as the most popular
                    study destination.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Reduced education and living costs have contributed
                    significantly to the increased desire for study in Canada,
                    particularly among applicants from underdeveloped countries.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Canadian colleges will certainly witness a large influx of
                    overseas students in the future.
                  </p>
                </li>
              </ul>
            </div>
            <div className="why-study-canada-image-group flex-box full-width-925">
              <div className="why-study-canada-image-leftbox">
                <div
                  className="why-study-canada-image-first bg-style"
                  style={{
                    backgroundImage: `url('/img/woman-with-medical-mask.jpg')`,
                  }}
                ></div>
                <div
                  className="why-study-canada-image-second bg-style"
                  style={{ backgroundImage: `url('/img/red-aeroplane.png')` }}
                ></div>
              </div>
              <div className="why-study-canada-image-rightbox">
                <div
                  className="why-study-canada-image-rightfirst bg-style"
                  style={{ backgroundImage: `url('/img/canada-flag.png')` }}
                ></div>
                <div
                  className="why-study-canada-image-rightsecond bg-style"
                  style={{ backgroundImage: `url('/img/chelsey-faucher.jpg')` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="compadding lightgrey">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/paper-work.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Students Progress</h2>
              <p>
                Many designated institutions that solely provide short-term
                courses for a specific level of education, such as diploma and
                certificate programs, may be found in Canada, which has a high
                number of short-term courses available to international
                students. Students must be able to communicate in one of
                Canada's two official languages, English or French. Many
                Canadian universities offer language classes as well.
              </p>
              <p>
                The most popular subjects chosen by overseas students in Canada
                are Business and Finance, Engineering, Computer Science and IT,
                and so more.. The following are some of the most popular courses
                to study in Canada:
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper row-reverse just-center">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/canada-flag.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Best Courses</span> To Study
                <br /> In Canada
              </h2>
              <p>
                Canada's education is both inexpensive and well-regarded abroad.
                The adaptability and range of programs offered by Canada's
                educational system are the key factors for the roughly 90
                percent growth in foreign students studying in the country since
                2008. The educational system in Canada is separated into three
                levels: primary, secondary, and post-secondary.
              </p>
              <ul className="canada-study-courses-list">
                <li className="canada-study-courses-list-item flex-box align-center">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>Education At The Primary Level</span>
                </li>
                <li className="canada-study-courses-list-item flex-box align-center">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>Secondary education</span>
                </li>
                <li className="canada-study-courses-list-item flex-box align-center">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>Education beyond high school</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="study-courses-tags-list-wrapper headingbox letter-normal mb-0">
            <ul className="flex-box study-courses-tags-list">
              <li className="study-courses-tags-list-item">BTech</li>
              <li className="study-courses-tags-list-item">
                MS in Business Analytics
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Computer Science
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Civil Engineering
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Engineering Management
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Architecture
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Artificial Intelligence
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Social Work
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Statistics
              </li>
              <li className="study-courses-tags-list-item">B.Arch</li>
              <li className="study-courses-tags-list-item">
                Masters in Health Informatics
              </li>
              <li className="study-courses-tags-list-item">
                Masters in Cyber Security
              </li>
              <li className="study-courses-tags-list-item">
                MS in Data Science
              </li>
              <li className="study-courses-tags-list-item">MBA</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="services compadding top-places-canada">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center mb-zero">
            <h2>
              <span className="themecolor">Top Places</span> To Study In Canada
            </h2>
            <p>
              Apart from wanting to attend your favourite university, you might
              be interested in learning about Canada's best student cities.
              Montreal, Toronto, Quebec, Vancouver, and Edmonton are among the
              most famous student cities.
            </p>
          </div>

          <div className="services-list-wrapper flex-box">
            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/toronto.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Toronto</h3>

                <p>
                  The Institution of Toronto, located in the province of
                  Ontario, is Canada's most prestigious university.
                </p>

                <p className="moretext">
                  It is also the largest city in Canada and one of the world's
                  most multicultural cities. The city is known as Canada's
                  financial centre, as it is home to five of the country's top
                  banks.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/vancouver.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Vancouver</h3>

                <p>
                  Vancouver is the country's third-largest metropolitan, despite
                  its youth. It is one of the most livable cities in the planet.
                </p>

                <p className="moretext">
                  It's perfect for students who want to live in the city yet
                  still have easy access to nature. Universities such as the
                  University of British Columbia, Simon Fraser University, and
                  the University of Victoria are all located there.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/montreal.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Montreal</h3>

                <p>
                  Montreal, Canada's second-largest region, has the highest
                  number of post-secondary students.
                </p>

                <p className="moretext">
                  It is also one of the Best Student Cities, according to QS.
                  Here you'll find McGill University, the University of
                  Montreal, and Concordia University.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/beautiful-view-peace-bridge.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Edmonton</h3>

                <p>
                  Edmonton, Alberta's capital, is sometimes known as "Festival
                  City."
                </p>

                <p className="moretext">
                  It is home to the University of Alberta and offers students
                  comfortable living conditions in Canada.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="application-process compadding lightgrey">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/career-counseling.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/best-college.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/offer-letter.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/approverd-visa.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="visa-of-country compadding">
        <div className="container">
          <div className="flex-box visa-of-country-wrapper just-center">
            <div className="visa-of-country-image full-width-925">
              <LazyLoadingImages src="/img/visa-of-canada.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <h2>
                <span className="themecolor">Visa</span> for Canada
              </h2>
              <p>
                International students who wish to study in Canada for longer
                than six months or continue their degree must first get a study
                permit. A research permit is the equivalent of a student visa in
                Canada. Students who wish to study in Canada should apply for a
                study permit four to six months before their studies begin.
              </p>
              <br />
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    To apply for permission, you'll need a few documents. You
                    must obtain a letter of permission from the chosen learning
                    institution in order to apply for a student visa (DLI).
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    It is necessary to have a valid passport or travel document.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The identification verification must be approved by the
                    resident's government.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    A financial statement is also required, demonstrating the
                    candidate's ability to pay living expenses and fees and a
                    letter expressing your desire to study in Canada and how you
                    may contribute to the pool of resources available.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>Health-related information</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="process-immigrating-canada compadding lightgrey">
        <div className="container">
          <div className="flex-box process-immigrating-wrapper just-center">
            <div className="process-immigrating-content headingbox mb-0 letter-normal full-width-925">
              <h2>
                <span className="themecolor">Process</span> Of Immigrating
                <br /> To Canada
              </h2>
              <p>
                After earning a degree from a Canadian university, relocating to
                Canada is easy. The Canadian government offers a variety of
                services to help foreign students become permanent residents.
              </p>
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Relocating to Canada after receiving a degree from a
                    Canadian university is simple. The Post-Graduate Job Permit
                    (PGWP) is a programme that permits recent graduates to gain
                    work experience in the Canadian workforce.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The Canadian Experience Class is open to students who have
                    completed two years of schooling and have one year of work
                    experience.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Experiencing Quebec Students must have completed a CEGEP
                    programme from a Quebec-based institution and be fluent in
                    French.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Qualified candidates apply through a paper-based method or
                    through express entrance after being nominated by provincial
                    officials.
                  </span>
                </li>
              </ul>
            </div>
            <div className="process-immigrating-image full-width-925">
              <LazyLoadingImages src="/img/passport-cover.jpg" />
            </div>
          </div>
        </div>
      </section>

      <section
        className="scholarship-in-canada flex-box align-center compadding align-center bg-style"
        style={{
          backgroundImage: `url('/img/canada-college.png')`,
          backgroundColor: "#191915",
        }}
      >
        <div className="container">
          <div className="flex-box scholarship-canada-wrapper just-center">
            <div className="scholarship-canada-description headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Scholarship to</span> study in
                Canada
              </h2>
              <p>
                Canadian universities and colleges provide a variety of
                scholarships to help deserving international students afford
                their education. Apart from that, the Canadian government, as
                well as public and private institutions, offer a variety of
                scholarships, grants, and bursaries
              </p>
              <p>
                The majority of scholarships require a high academic score, but
                it also relies on your intended destination, topic, and level.
                With some scholarships, you can use extracurricular sports and
                philanthropic activity to your advantage. Some of the most
                well-known government-funded scholarships for Indian students
                studying in Canada are as follows:
              </p>
            </div>
            <ul className="scholarship-details full-width-925">
              <li className="scholarship-details-info">
                Shastri Indo-Canadian Institute
              </li>
              <li className="scholarship-details-info">
                Canadian Commonwealth Scholarship and Fellowship Plan
              </li>
              <li className="scholarship-details-info">
                Ontario Graduate Scholarship Program
              </li>
              <li className="scholarship-details-info">
                National Research Council of Canada (NRCC)
              </li>
              <li className="scholarship-details-info">
                Quebec Provincial Government Scholarship
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="trendingcoursesec compadding lightgrey">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Explore Our</span>
              <span className="themecolor">Top</span> Courses
            </h2>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                Can students work along with studies in Canada?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  During the academic year, Indian students are permitted to
                  work for 20 hours a week and full-time during vacations.
                  Depending on the length of your degree, you could be eligible
                  for a three-year extension after graduation.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                {" "}
                Do institutions in Canada accept the 10+2+3 education system of
                India?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, the 10+2+3 scheme is approved for postgraduate diploma
                  programmes; however, almost all universities require 10+2+4
                  for master's degrees. On a case-by-case basis, some
                  universities can consider 10+2+3 for master's degrees in
                  specific programmes.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                How much does it cost to study in Canada?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Studying in Canada can be less expensive than looking in the
                  United States, the United Kingdom, or Australia because Canada
                  has lower tuition rates for international students than the
                  United States, the United Kingdom, and Australia. The
                  estimated annual cost of education, including tuition and
                  living expenses, is CAD 25 000.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                {" "}
                Are there any internships available in Canada?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  Job experience is part of the curriculum of several Canadian
                  programmes.
                </p>

                <ul>
                  <li>
                    If you have a valid study permit and working is a
                    significant part of your study programme in Canada, you can
                    apply for a co-op or intern work permit.
                  </li>
                  <li>
                    A letter from your school states that all students in your
                    programme are required to complete work placements to
                    receive their degree or certificate.
                  </li>
                  <li>
                    Your co-op or internship should account for no more than
                    half of your total programme of study.
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("05")}>
                Can international students apply for permanent residence?
                <span
                  className={active === "05" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "05" ? "panel blk" : "panel"}>
                <p>
                  The permanent resident visa is granted based on a point
                  system. The PGWPP will help you obtain valuable job experience
                  that will help you apply for permanent residency in Canada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
});

export default Index;
