import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Aboutvisa = React.memo(function () {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/student-visa-banner.png')`,
          backgroundColor: "#07070B",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Study in Abroad</h4>

              <h1>Apply for Student Visa</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper align-center">
            <div className="about-visa-image full-width-925 hide-mobile">
              <LazyLoadingImages src="/img/student-study.jpg" alt="student" />
            </div>

            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">Student</span> Visa
              </h2>

              <p>
                A student visa as the name suggests is a government
                signed/stamped document that gives the person holding it the
                official right to enter into the country for educational
                purposes. Acclaimed for its world-class teaching facility and
                wide range of courses, Australia observes millions of students
                taking admissions in its various universities every year.
              </p>

              <p>
                The Australian education system for international students is
                unparalleled. A student can enroll into the Australian
                universities to work on their grip on the English language or to
                get a full-time University degree or enhance their expertise on
                any of the practical vocational courses as offered by the
                country. Australia provides several options for students of
                foreign nationals to study for a few months or a few years as
                their need may be on an Australian Student Visa.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutvisa;
