import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listGender } from "../actions/genderActions";

export default function Gender() {
  const dispatch = useDispatch();
  
  const { loading, error, genders } = useSelector((s) => s.genderList);

  const getGender = () => {
    dispatch(listGender());
  };
  
  useEffect(() => {
    if (!genders) {
      getGender();
    }
  }, [dispatch]);

  return genders ? (
    genders.map((gnd, index) => (
      <option key={index+1} value={gnd.Id}>
        {gnd.Gender}
      </option>
    ))
  ) : (
    <option>{error}</option>
  );
}
