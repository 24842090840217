import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Aboutemployers from "./AboutEmployers";
import Applyingstuvisasec from "./ApplyingStuVisaSec";
import Eligibilityvisa from "./EligibilityVisa";

const Index = React.memo(function index() {
  return (
    <>
      <Header />
      <Aboutemployers />
      <Eligibilityvisa />
      <Applyingstuvisasec />
      <Footer />
    </>
  );
});

export default Index;
