import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";
const Othersericesec = React.memo(function Othersericesec() {
  return (
    <section className="othersericesec compadding journey-servicesec">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">How To Apply For</span> Forex Cards
          </h2>

          <p>
            Now that you know how Forex Card works and its benefits, you might
            now want to know how you can get one.
          </p>
        </div>

        <div className="sec-row">
          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/address-proof.png"
                  alt="Getting Visa Guidence"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Address Proof</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/travel-documnets.png"
                  alt="Help in Loan"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Travel Documents & Approved Visa</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/aadhaar.png"
                  alt="International Bank Account"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Aadhaar Details</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/passport-cerficate.png"
                  alt="Forex"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Passport Certificate Details</p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-exploremore text-center">
          <a href="/consult_a_counselor" className="btn">
            Guide Me
          </a>
        </div>
      </div>
    </section>
  );
});

export default Othersericesec;
