import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCountry, listallCountry } from "../actions/countryActions";
import { useEffect } from "react";

export default function InterestedCountry() {
  const dispatch = useDispatch();
  const { loading, error, countryData } = useSelector(
    (s) => s.countryList
  );
  const getCountryDatas = () => {
    dispatch(listCountry());
  };
  useEffect(()=>{
    if(!countryData){
        getCountryDatas();
    }
  },[dispatch,countryData])
  return countryData
    ? countryData.length > 0
      ? countryData.map((alcd, index) => (
          <option value={alcd.Id} key={alcd.Id}>
            {alcd.Country}
          </option>
        ))
      : ""
    : "";
}
