import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Aboutvisa = React.memo(function Aboutvisa() {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/sponsored-banner.png')`,
          backgroundColor: `#07070B`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec about-family-sponsored-visa compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper align-center">
            <div className="about-visa-image about-sponsored-visa-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/sponsored-visa.jpg"
                alt="student-image"
              />
            </div>

            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  Family-Sponsored Visa
                  <br />
                </span>{" "}
                Subclass 492
              </h2>

              <p>
                Out of all the family-sponsored visas available for Australia,
                subclass 491 is the most widely applied form of visa. The visa
                is issued for a full fledged 5-year term to work in Australia
                and can be later on converted into Permanent Residency.
              </p>

              <p>
                However, since these are issued in a very low number every year
                by the authorities, they are not so easily obtained.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutvisa;
