import React from "react";
import { Helmet } from "react-helmet";

export default function Meta({ metaData }) {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{metaData.title}</title>
      <meta name="title" content={metaData.mtitle} />
      <meta name="description" content={metaData.mdescription} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content={metaData.ogtype} />
      <meta property="og:url" content={metaData.ogurl} />
      <meta property="og:title" content={metaData.ogtitle} />
      <meta property="og:description" content={metaData.ogdescription} />
      <meta property="og:image" content={metaData.ogimage} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content={metaData.twitterCard} />
      <meta property="twitter:url" content={metaData.twitterUrl} />
      <meta property="twitter:title" content={metaData.twitterTitle} />
      <meta
        property="twitter:description"
        content={metaData.twitterDescription}
      />
      <meta property="twitter:image" content={metaData.twitterImage} />
    </Helmet>
  );
}
