import React, { useEffect, useState } from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { createSubscribe } from "../../actions/otherActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";
import { contactJson } from "../../models/userModel";

const Bookappointment = React.memo(function Bookappointment() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };

  const dispatch = useDispatch();

  const [contactData, setContactData] = useState(contactJson);

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, success, error } = contactCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const handleData = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
      Link: window.location.href,
      Comments: `Country - ${country?.country_name}, Link: ${window.location.href}`,
    });
  };

  const handleSubmit = () => {
    var errorm = "";
    if (
      contactData.Email_Address === "" ||
      contactData.Email_Address === undefined
    )
      errorm += "Incorrect Email Address!";
    if (contactData.Phone_No === "" || contactData.Phone_No === undefined)
      errorm += "\nIncorrect Phone Number!";
    if (contactData.full_name === "" || contactData.full_name === undefined)
      errorm += "\nIncorrect Name!";
    if (contactData.VisaType === "" || contactData.VisaType === undefined)
      errorm += "\nSelect Type of Visa!";

    if (errorm === "") {
      dispatch(createContact(contactData));
    } else {
      alert(errorm);
    }
  };

  useEffect(() => {
    if (success) {
      setContactData({ ...contactJson, VisaType: "" });

      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 7000);
    }
  }, [dispatch, success]);

  return (
    <>
      <section className="book-appointment">
        <div className="container">
          <div className="headingbox com-headingbox text-center letter-normal">
            <h2>
              <span className="themecolor">Book an</span> appointment
            </h2>

            {/* <p>
              The Customs Law of the country prevents an individual from
              bringing in drugs, steroids, firearms, and weapons into the
              country.
            </p> */}
          </div>

          <div className="appointment-form">
            <form className="flex-box">
              <div className="form-group">
                <label for="name">Name*</label>

                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Name"
                  name="full_name"
                  value={contactData.full_name}
                  onChange={(e) => handleData(e)}
                />
              </div>

              <div className="form-group">
                <label for="email">Email*</label>

                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="Email"
                  name="Email_Address"
                  value={contactData.Email_Address}
                  onChange={(e) => handleData(e)}
                />
              </div>

              <div className="form-group">
                <label for="number">Number*</label>

                <input
                  type="text"
                  id="number"
                  className="form-control"
                  placeholder="Number"
                  name="Phone_No"
                  value={contactData.Phone_No}
                  onChange={(e) => handleData(e)}
                />
              </div>

              <div className="form-group appoint-select">
                <label for="number">Location or University</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Location or University"
                  name="VisaType"
                  value={contactData.VisaType}
                  onChange={(e) => handleData(e)}
                />
              </div>

              <div className="appoint-submit text-center">
                {loading ? (
                  <button type="button" className="btn">
                    Loading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
            <div>
              <span className="bg-success">{success}</span>
              <span className="bg-danger">{error}</span>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                Can I leave and re-enter Australia using my student Visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                Can I work in Australia using my Student Visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Can my family come to visit me if I have an Australian Student
                Visa?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Bookappointment;
