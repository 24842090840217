import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
import Slider from "react-slick";
import Meta from "../../components/Meta";

const Index = React.memo(function () {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  const metaData = {
    title: "Study in UK | Free Counselling – Search Education",
    mtitle: "Study in UK | Free Counselling – Search Education",
    mdescription:
      "Explore World-Class Education Opportunities in New Zealand. Find your ideal study courses at Search Education - Your Path to Quality Global Education ",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Study in UK | Free Counselling  – Search Education",
    ogdescription:
      "Explore World-Class Education Opportunities in New Zealand. Find your ideal study program at Search Education - Your Path to Quality Global Education",
    ogimage: "https://www.searcheducation.com/img/img_7.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle: "Study in UK | Free Counselling  – Search Education",
    twitterDescription:
      "Explore World-Class Education Opportunities in New Zealand. Find your ideal study program at Search Education - Your Path to Quality Global Education",
    twitterImage: "https://www.searcheducation.com/img/img_7.jpg",
  };
  return (
    <>
      <Header />
      {/* <!-- Start Inner Page banner --> */}
      <Meta metaData={metaData} />
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/view-london-city-sunset.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>United Kingdom</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>

              <div className="canada-university-info-list flex-box">
                <div className="canada-university-info-list-item">
                  <h4>559,825+</h4>
                  <p>International Students</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>GBP 12,006</h4>
                  <p>Avg Living costs/pa</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>160+</h4>
                  <p>Universities</p>
                </div>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/uk-flaq-with-girl.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Study In</span> The United Kingdom
              </h2>
              <p>
                Foreign Education’ always means one from the UK also known as
                United Kingdom of Great Britain and Northern Ireland. A general
                norm that was followed decades ago when anyone planned to study
                abroad. Our world-driving notorieties as well as our freedom
                fighters right from Mahatma Gandhi to Dr. Ambedkar were the
                pioneers in creating examples for students with or without
                financial standing to study at international destinations then.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/smily-gilr-with-leptop.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <p>
                Today almost every country is inviting students to study in
                their land. Yet the UK has always remained successful and
                unbeatable in being the best and top destination for going
                abroad for education. This is because a total 90 universities in
                the UK are recognised by the QS World University Rankings 2021.
                Four from these come under the top 10 universities of the world.
                The UK was, is and will be an all time ideal and favourite
                destination with best scholastic educators. Don't be surprised
                to see the significant number of best scholars of the world as
                educators in this country with four independent. Apart from
                refining one’s English Language skills, this country offers an
                opportunity for a student to blend academic and vocational
                educaton. Additionally one can develop as many skills, thanks to
                the diverse environment that thrives one towards progressing
                ahead. Cost of tuition fees and living expenses are low as the
                Bachelors and Masters Degree Programs are of lesser duration
                compared to other countries. Also those with 12th standard plus
                three years of education are eligible to study here and they
                dont need to go for additional gap filling bridges to take up
                higher education.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="visa-of-country compadding education-in-country lightgrey">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">Why Study in </span>UK
            </h2>
            <p>
              Our world-famous personalities and liberation warriors, from
              Mahatma Gandhi to Dr. Ambedkar, were pioneers in setting an
              example for students with or without financial means to study in
              overseas destinations at the time. Foreign education always refers
              to education received outside of the United Kingdom, also known as
              the United Kingdom of Great Britain and Northern Ireland. When
              anyone wanted to study abroad decades ago, there was a common rule
              that was followed.
            </p>
            <p>
              Almost every country nowadays invites students to study in their
              country. Despite this, the United Kingdom has always been the
              greatest and most popular destination for students looking to
              study abroad. This is because the QS World University Rankings
              2021 recognizes a total of 90 universities in the United Kingdom.
              Four of them are among the world's top ten universities.
            </p>
            <p>
              The United Kingdom was, is, and will continue to be an outstanding
              and preferred location for the top academic educators. Don't be
              shocked to find a considerable number of the world's greatest
              researchers as educators in this country, which has four
              independent universities.
            </p>
          </div>
          <div className="flex-box visa-of-country-wrapper just-center">
            <div className="visa-of-country-image full-width-925">
              <LazyLoadingImages src="/img/describe-map.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <h2>Education In UK</h2>
              <p>
                The school system in the United Kingdom is as adaptable as it
                gets. There is also plenty of room to customize the course and
                curriculum to your preferences. These are only two of the
                numerous advantages of studying in the United Kingdom. Let's
                have a look at a few more:
              </p>

              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The United Kingdom alone is responsible for 5% of all global
                    scientific research and produces more than 15% of all
                    referenced papers each year.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    UK-based colleges provide you with the unique opportunity to
                    combine academic and vocational courses.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The UK curriculum is very practical, thus research and lab
                    work, as well as industry relationships, receive a lot of
                    attention.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The United Kingdom has some of the top faculties in the
                    world and one of the best universities in the world.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    As the birthplace of the English language, the country
                    provides a wonderful opportunity for students to improve
                    their language skills.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        className="the-united-kingdom flex-box align-center compadding align-center bg-style"
        style={{
          backgroundImage: `url('/img/edinburgh-city-view-panorama-night-uk.png')`,
          backgroundColor: "#191915",
        }}
      >
        <div className="container">
          <div className="the-united-wrapper just-center">
            <div className="the-united-description headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">The United Kingdom</span>
              </h2>
              <p>
                This is because a total 90 universities in the UK are recognised
                by the QS World University Rankings 2021.
              </p>
            </div>
            <div className="the-united-details-wrapper headingbox mb-0 flex-box">
              <div className="the-united-details-list">
                <div className="the-united-detail-icon">
                  <LazyLoadingImages src="/img/capital-city.png" />
                </div>
                <div className="the-united-details-list-item">
                  <h6>Capital City</h6>
                  <h3>London</h3>
                </div>
              </div>
              <div className="the-united-details-list">
                <div className="the-united-detail-icon">
                  <LazyLoadingImages src="/img/sterlink.png" />
                </div>
                <div className="the-united-details-list-item">
                  <h6>Currency</h6>
                  <h3>Pound Sterling</h3>
                </div>
              </div>
              <div className="the-united-details-list">
                <div className="the-united-detail-icon">
                  <LazyLoadingImages src="/img/university.png" />
                </div>
                <div className="the-united-details-list-item">
                  <h6>Universities</h6>
                  <h3>160+</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="searchcountrysec compadding top-universities">
        <div className="container">
          <div className="headingbox text-center com-headingbox letter-normal">
            <h2>
              <span className="themecolor">Top</span> Universities
            </h2>

            <p>
              Engineering, art and design, architecture, science, business and
              management, law, finance, and accounting all have universities in
              the United Kingdom. The educational curriculum in the United
              Kingdom is often a mix of core academic disciplines and vocational
              and practical training. The following are some of the UK's major
              universities:
            </p>
          </div>

          <Slider
            className="searchcountrysliderpro visa-countries-wrapper top-university-slider"
            {...options}
          >
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-oxford.jpg')`,
                  }}
                ></div>
                <p>
                  University Of <strong>Oxford</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-cambridge.jpg')`,
                  }}
                ></div>
                <p>
                  University Of <strong>Cambridge</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london.jpg')`,
                  }}
                ></div>
                <p>
                  Imperial College <strong>London</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london-ucl.jpg')`,
                  }}
                ></div>
                <p>
                  University College <strong>London (UCL)</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  London School Of <strong>Econo & Pol-Sci</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london-ucl.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Edinburgh</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  King's College <strong>London</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london-ucl.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Manchester</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/universityecono.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Warwick</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/universityecono.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Bristol</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/universityecono.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Glasgow</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Birmingham</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  Queen Mary University of <strong>London</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Sheffield</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  University of <strong>Southampton</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london-ucl.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>York</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london.jpg')`,
                  }}
                ></div>
                <p>
                  Lancaster <strong>University</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  Durham <strong>University</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('/img/university-london-ucl.jpg')`,
                  }}
                ></div>
                <p>
                  University of <strong>Nottingham</strong>
                </p>
              </div>
            </div>
            <div className="searchcountryslide visa-countries-box">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/universityecono.jpg')` }}
                ></div>
                <p>
                  University of <strong>Leeds</strong>
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <section className="visa-of-study compadding lightgrey">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">Visa for Studying in</span> UK
            </h2>
            <p>
              You will need a Visa to study in the UK if you are an overseas
              student. About the website's official URL, you may find more
              extensive information on the subject:
            </p>
          </div>
          <div className="flex-box visa-for-study-wrapper just-center">
            <div className="visa-for-study-content full-width-925">
              <div className="visa-for-study-details flex-box">
                <div className="visa-for-study-icon">
                  <LazyLoadingImages src="/img/general-stu-visa.png" />
                </div>
                <div className="visa-for-study-details-info headingbox letter-normal mb-0">
                  <h5>Tier 4 or General Student Visa</h5>
                  <p>
                    Individuals aged 16 and up who wish to study in the United
                    Kingdom should apply for a Tier 4 or General Visa. To apply
                    for this visa, you must offer a place at one of the
                    universities in the United Kingdom. Your university will
                    also give you a document called a "Confirmation of
                    Acceptance of Studies," or CAS, once you receive the offer
                    letter.
                  </p>
                </div>
              </div>
              <div className="visa-for-study-details flex-box">
                <div className="visa-for-study-icon">
                  <LazyLoadingImages src="/img/general-stu-visa.png" />
                </div>
                <div className="visa-for-study-details-info headingbox letter-normal mb-0">
                  <h5>Short Term Study Visa</h5>
                  <p>
                    These visas are offered to students who plan to enroll in
                    six-month or less-than-one-year courses. Visit this link to
                    learn more about Short-Term Visas.
                  </p>
                </div>
              </div>
            </div>
            <div className="visa-for-study-image full-width-925">
              <LazyLoadingImages src="/img/visa-image.jpg" />
            </div>
          </div>
        </div>
      </section>
      <section className="trendingcoursesec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Explore Our</span>
              <span className="themecolor">Top</span> Courses
            </h2>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="services compadding top-places-canada lightgrey">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center mb-zero">
            <h2>
              <span className="themecolor">Top</span> Cities
            </h2>
            <p>
              Our world-driving notorieties as well as our freedom fighters
              right from Mahatma Gandhi to Dr. Ambedkar were the pioneers in
              creating examples for students with or without financial standing
              to study at international destinations then.
            </p>
          </div>

          <div className="services-list-wrapper flex-box">
            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Manchester-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Manchester</h3>

                <p>
                  Manchester is always known for industrial advancement. Apart
                  from being the second most famous city of England after
                  London, this city has maintained its reputation in many ways.
                </p>

                <p className="moretext">
                  {" "}
                  This ‘European City of Science’ has got its value due to its
                  immense contribution to Science and innovation. This city
                  earned its name in sports, culture and industry. This
                  diversity gives Manchester a beautiful edge over other cities
                  of the UK after London. Learners and scholars get the best of
                  the exposure in education and work.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/London-520-x-600.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>London</h3>

                <p>
                  London has always remained as a ‘Hub of Education’ from ages,
                  apart from the presence of the Royal Kingdom,and being
                  financially, culturally and commercially advanced.
                </p>

                <p className="moretext">
                  {" "}
                  With a significant number of international students opting to
                  study in the heart of England, London has its own charm and
                  beauty that’s incomparable to any other cities of the world.
                  This capital of England offers ample opportunities for full
                  time and part time jobs This makes it the most lucrative city
                  in entire UK compared to other cities
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Birmingham--520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Birmingham</h3>

                <p>
                  Birmingham comes at third level in terms of population after
                  London and Manchester. Within a distance of less than two
                  hours from London,
                </p>

                <p className="moretext">
                  {" "}
                  apart from this proximity, this city is famous for being
                  diverse, culturally advanced and picturesque English
                  countryside around. Known to be the greenest city of the UK,
                  Birmingham has been very serious about quality of life and
                  health. The city has dedicated 8000 acres just for parks and
                  open spaces. More than 10000 students from 150 countries study
                  here making it the world’s largest international student
                  communities in the UK.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/Glasgow-520-x-600.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Glasgow</h3>

                <p>
                  Glasgow is the first city that has the first international
                  football match and invention of the Television.
                </p>

                <p className="moretext">
                  {" "}
                  ‘Huge Hub of Music and Arts’ this city has maintained its
                  position very well from the international perspective. Best
                  concerts and events happen here. Those with keen interests to
                  study on culture, music and arts, this city is not less than a
                  heaven in Scotland. Opportunities to work after study is ample
                  in this highly developed city.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Edinburgh-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Edinburgh</h3>

                <p>
                  Edinburg, this capital of Scotland is also known for being the
                  second most populous city.
                </p>

                <p className="moretext">
                  {" "}
                  Students coming from two-third of the world’s countries study
                  here. Edinburg is ranked in the world's top 20 most
                  international universities making it the most sought after
                  destination to study. Education in different spaces like
                  e-science, engineering, life and medical sciences, and arts
                  and culture makes Edinburgh, a prominent place to study
                  compared to other destinations. Places like The city's
                  backdrop of Arthur's Seat, the Pentland Hills and Edinburgh's
                  Waterfront makes it unique in many ways.
                </p>

                {/* <a href="javascript:void(0)" className="btn moreless-button">
                  Explore
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="application-process compadding">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/career-counseling.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages
                  src="/img/best-college.png"
                  alt="Best College"
                />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" alt="Prepare" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages
                  src="/img/offer-letter.png"
                  alt="Offer Letter"
                />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages
                  src="/img/approverd-visa.png"
                  alt="Approved Visa"
                />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" alt="take off" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                How much does it cost to study in the UK?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  The average tuition fee for an undergraduate and graduate
                  degree ranged from $17000 to $25000. For the living expenses,
                  the costs come around $1000 to $1600 per month. However, most
                  students pool in together and stay at more affordable
                  locations, which can reduce these costs by at least 20-30%
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                {" "}
                Can international students get PR in the UK?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  For a PR, you will need the “indefinite leave to remain (ILR)
                  Visa”. This can only be applied after completing a graduate
                  job for five years at a stretch. The process for ILR takes six
                  months on average.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                How much can a student earn in the UK?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  The average part-time weekly wage across the UK is GBP 112.20.
                  Some students can make up to GBP 200 as well.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                {" "}
                What are the latest Immigration rules being followed in the UK?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  The latest update given by the UK Government for immigration
                  is as under:
                </p>

                <ul>
                  <li>
                    The Graduate route will come active for new applications
                    from 1st July 2021. The UK government has stood on its
                    stance on implementing the points-based immigration system.
                  </li>
                  <li>
                    The UK Graduate route (PSW) will now be charged 700 for the
                    visa fee and 624 per year for a total immigration health
                    surcharge.
                  </li>
                  <li>
                    Students aspiring to work in the UK post studies can do so
                    for two years (3 years for Doctoral courses).
                  </li>
                  <li>
                    The earlier deadline of 6th April for final year students to
                    return to the UK has been pushed off to 21st June.
                  </li>
                  <li>
                    Students who will begin their courses after 31st December
                    2020 will be required to be back in the UK latest by 27th
                    September to be eligible.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
