import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Studentvisa = React.memo(function () {
  return (
    <section className="student-visa-charges-sec">
      <div className="container">
        <div className="flex-box align-center student-visa-charges-wrapper">
          <div className="about-visa-charges headingbox mb-zero full-width-925">
            <h6>Study in Australia</h6>

            <h2>
              <span className="themecolor">
                Australian Student
                <br />
              </span>{" "}
              Visa Charges
            </h2>

            <p>
              The overall approximate charge of an Australian Student Visa
              depends largely on the age of the applicant.
            </p>

            <a href="/get_visa" className="btn visa-enquire hide-925">
              Enquire Now!
            </a>
          </div>

          <div className="student-age-wrapper full-width-925">
            <div className="student-age text-center">
              <div className="student-visa-image">
                <LazyLoadingImages src="/img/student-visa.jpg" alt="student" />
              </div>

              <div className="student-visa-content headingbox mb-zero">
                <h3>
                  <span className="themecolor">AUD 620 + AUD 150</span>
                </h3>

                <p>Below the age of 18</p>
              </div>
            </div>

            <div className="student-age text-center">
              <div className="student-visa-image">
                <LazyLoadingImages src="/img/visa2.jpg" alt="student" />
              </div>

              <div className="student-visa-content headingbox mb-zero">
                <h3>
                  <span className="themecolor">AUD 620 + AUD 150</span>
                </h3>

                <p>Above the age of 18</p>
              </div>
            </div>
          </div>

          <a href="/get_visa" className="btn visa-enquire show-925">
            Enquire Now!
          </a>
        </div>
      </div>
    </section>
  );
});

export default Studentvisa;
