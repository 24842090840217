import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useState, useEffect } from "react";
import { contactJson } from "../../models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";

const EventForm = React.memo(function EventForm({ formClass, cpid }) {
  const dispatch = useDispatch();
  const [contactArray, setContactArray] = useState(contactJson);

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, error, success: successC } = contactCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const handleChange = (e) => {
    setContactArray({
      ...contactArray,
      Link: window.location.href,
      Comments:
        "Contact Filled the form using the Website Register Yourself Form on Website. Country: " +
        country?.country_name,
      Activity: "Website Event Registration Form Submitted.",
      Activity_Des:
        "Event Registration Yourself Form submitted using the Website. Country: " +
        country?.country_name,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(createContact(contactArray));
  };

  useEffect(() => {
    if (successC) {
      setContactArray(contactJson);
      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 3500);
    }
  }, [dispatch, successC]);

  return (
    <form className={formClass}>
      <div className="form-group input-form">
        <span className="form-icon">
          <LazyLoadingImages src="/img/user-icon.png" alt="student-image" />
        </span>

        <input
          type="text"
          placeholder="Full Name"
          className="form-control"
          name="full_name"
          value={contactArray.full_name}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group input-form">
        <span className="form-icon">
          <LazyLoadingImages src="/img/form-email.png" alt="student-image" />
        </span>

        <input
          type="text"
          placeholder="Email Address"
          className="form-control"
          name="Email_Address"
          value={contactArray.Email_Address}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group input-form">
        <span className="form-icon">
          <LazyLoadingImages src="/img/email-icon.png" alt="student-image" />
        </span>

        <input
          type="text"
          placeholder="Mobile Number"
          className="form-control"
          name="Phone_No"
          value={contactArray.Phone_No}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group submit-form-btn">
        {loading ? (
          <button type="button" className="btn text-center">
            Loading...
          </button>
        ) : (
          <button
            type="button"
            className="btn text-center"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        )}
      </div>
      <p>
        <span className="bg-success">{successC}</span>
        <span className="bg-danger">{error}</span>
      </p>
    </form>
  );
});

export default EventForm;
