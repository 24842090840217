import {
  FINANCIAL_SOURCE_LIST_REQUEST,
  FINANCIAL_SOURCE_LIST_SUCCESS,
  FINANCIAL_SOURCE_LIST_FAIL,
} from "../constants/financialSourceConstants";

export const financialSourceListReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case FINANCIAL_SOURCE_LIST_REQUEST:
      return { loading: true };
    case FINANCIAL_SOURCE_LIST_SUCCESS:
      return { loading: false, financialSourcesL: payload };
    case FINANCIAL_SOURCE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
