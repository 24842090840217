export const courseSearchJson = {
  Country: "",
  University: "",
  StudyLevel: "",
  Intake: "",
  Duration: "",
  Fees: "",
  FeesFrom:"",
  FeesTill:"",
  IELTSScore: "",
  PageSize: 10,
  PageNo: 1,
  searchtxt: "",
  Popular_Courses: "",
  Global_Courses: "",
  Au_NZ_Popular_Courses: "",
  UK_IR_Popular_Courses: "",
};

export const courseCompareJsone = {
  Course_Id: 0,
  Course_Name: "",
  University_Name: "",
  logo74x74: "",
};
