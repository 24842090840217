import React from "react";

const Faqs = React.memo(function Faqs() {
  return (
    <section className="faqs">
      <div className="container">
        <div className="text-box text-center faq-heading headingbox">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="faq-wrapper">
          <div className="accordion-group accordion-ques">
            <button className="accordion">
              Can I leave and re-enter Australia using my student Visa?
              <span className="faq-icon"></span>
            </button>

            <div className="panel">
              <p>
                Yes, you are free to leave and re-enter Australia as many times
                as you want throughout the validity period of your visa.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion">
              Can I work in Australia using my Student Visa?
              <span className="faq-icon"></span>
            </button>

            <div className="panel">
              <p>
                Yes, you are free to leave and re-enter Australia as many times
                as you want throughout the validity period of your visa.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion">
              Can my family come to visit me if I have an Australian Student
              Visa?
              <span className="faq-icon"></span>
            </button>

            <div className="panel">
              <p>
                Yes, you are free to leave and re-enter Australia as many times
                as you want throughout the validity period of your visa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Faqs;
