import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAIL,
  USER_REGISTER_VERIFY_REQUEST,
  USER_REGISTER_VERIFY_FAIL,
  USER_REGISTER_VERIFY_SUCCESS,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_COUNSELLOR_REQUEST,
  USER_COUNSELLOR_FAIL,
  USER_COUNSELLOR_SUCCESS,
  USER_DASHBOARD_FAIL,
  USER_DASHBOARD_REQUEST,
  USER_DASHBOARD_SUCCESS,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD2_REQUEST,
  USER_FORGOT_PASSWORD2_FAIL,
  USER_FORGOT_PASSWORD2_SUCCESS,
  USER_INTERESTED_IN_REQUEST,
  USER_INTERESTED_IN_FAIL,
  USER_INTERESTED_IN_SUCCESS,
} from "../constants/userConstants";
import api2 from "../core/api2";

export const loginUser = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const { data } = await api2.post(`/student/details/login`, userJson);

    const response = data.Result;

    if (response.includes("Error")) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: "Logged In SuccessFully!",
      });
      localStorage.setItem("accinf", response);
      // The time is in milliseconds, so to multiple and get the date one day ahead we will multiple 1000 milliseconds with 60 seconds and 60 minutes and 24 hours.
      const dt = new Date().getTime() + 24 * 60 * 60 * 1000;
      localStorage.setItem("acdt", dt);
      localStorage.setItem("usinf", JSON.stringify(data.details[0]));
      document.location = "/panel/dashboard";
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const logoutUser = () => async () => {
  localStorage.clear();
  document.location = "/";
};

export const registerUser = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api2.post(
      `/student/details/register`,
      userJson,
      config
    );
    const response = data.Result;

    if (response.includes("Error")) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const registerVerifyUser = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_VERIFY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api2.post(
      `/student/details/register2`,
      userJson,
      config
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_REGISTER_VERIFY_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_REGISTER_VERIFY_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_VERIFY_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const detailUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAIL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.get(`/student/details`, config);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: USER_DETAIL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateUser = (userJson) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.put(`/student/details`, userJson, config);

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getCounsellorUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_COUNSELLOR_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.get(
      `/student/details/getCounsellorDetails`,
      config
    );

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: USER_COUNSELLOR_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_COUNSELLOR_SUCCESS,
        payload: response[0],
      });
    }
  } catch (error) {
    dispatch({
      type: USER_COUNSELLOR_FAIL,
      payload:
        error.response.data && error.response.data
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getInterestedInUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INTERESTED_IN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.get(
      `/student/details/getInterestedRecords`,
      config
    );

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: USER_INTERESTED_IN_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_INTERESTED_IN_SUCCESS,
        payload: response[0],
      });
    }
  } catch (error) {
    dispatch({
      type: USER_INTERESTED_IN_FAIL,
      payload:
        error.response.data && error.response.data
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getDashboardTotalsUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DASHBOARD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.get(
      `/student/details/getDashboardTotals`,
      config
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_DASHBOARD_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_DASHBOARD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_DASHBOARD_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const forgotPasswordUser = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api2.post(
      `/student/details/ForgotPassword`,
      userJson,
      config
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_FORGOT_PASSWORD_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_FORGOT_PASSWORD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const forgotPasswordVerifyUser = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD2_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api2.post(
      `/student/details/ForgotPassword2`,
      userJson,
      config
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_FORGOT_PASSWORD2_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_FORGOT_PASSWORD2_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD2_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const changePasswordUser = (userJson) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CHANGE_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    const { data } = await api2.post(
      `/student/details/ChangePassword`,
      userJson,
      config
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: USER_CHANGE_PASSWORD_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: USER_CHANGE_PASSWORD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_CHANGE_PASSWORD_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
