import {
  SUBSCRIBE_CREATE_REQUEST,
  SUBSCRIBE_CREATE_SUCCESS,
  SUBSCRIBE_CREATE_FAIL,
} from "../constants/otherConstants";
import api2 from "../core/api2";

export const createSubscribe = (email) => async (dispatch) => {
  try {
    dispatch({
      type: SUBSCRIBE_CREATE_REQUEST,
    });
    const emailJson = {
      Email_Address: email,
    };

    const { data } = await api2.post(`/subscribe`, emailJson);

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: SUBSCRIBE_CREATE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: SUBSCRIBE_CREATE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_CREATE_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
