import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const OtherServiceSec = React.memo(function () {
  return (
    <>
      <section className="othersericesec">
        <div className="container compadding">
          <div className="headingbox text-center">
            <h2>
              <span className="smh">Let’s Check our</span>
              <span className="themecolor">Search</span> Services
            </h2>
            <div className="headingseparator">
              <LazyLoadingImages
                src="/img/headingseparator.png"
                alt="separator"
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="sec-md-col-25">
              <div className="otherservicebox">
                <div className="otherserviceicon">
                  <LazyLoadingImages
                    src="/img/visaguidence-icon.png"
                    alt="Getting Visa Guidence"
                  />
                </div>
                <div className="otherservicecntnt">
                  <p>
                    Getting Visa Guidence
                    <a href="/get_visa" className="btn">
                      <LazyLoadingImages
                        src="/img/arrows-right.png"
                        alt="Arrow"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="otherservicebox">
                <div className="otherserviceicon">
                  <LazyLoadingImages
                    src="/img/helploan-icon.png"
                    alt="Help in Loan"
                  />
                </div>
                <div className="otherservicecntnt">
                  <p>
                    Help in Loan
                    <a href="/hdfc_credila_edu_loan" className="btn">
                      <LazyLoadingImages
                        src="/img/arrows-right.png"
                        alt="Arrow"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="otherservicebox">
                <div className="otherserviceicon">
                  <LazyLoadingImages
                    src="/img/internationalbank-icon.png"
                    alt="International Bank Account"
                  />
                </div>
                <div className="otherservicecntnt">
                  <p>
                    International Bank Account
                    <a href="/contactus" className="btn">
                      <LazyLoadingImages
                        src="/img/arrows-right.png"
                        alt="Arrow"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="otherservicebox">
                <div className="otherserviceicon">
                  <LazyLoadingImages src="/img/forex-icon.png" alt="Forex" />
                </div>
                <div className="otherservicecntnt">
                  <p>
                    Forex
                    <a href="/forex" className="btn">
                      <LazyLoadingImages
                        src="/img/arrows-right.png"
                        alt="Arrow"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="moreservice">
            <ul>
              <li>
                <div className="li-innr">
                  <div className="li-cntnt">
                    <h6>
                      <LazyLoadingImages src="/img/uservectorone.png" alt="" />
                      IELTS Coaching
                      <a href="/ielts_training" className="btn">
                        <LazyLoadingImages
                          src="/img/arrows-right.png"
                          alt="Arrow"
                        />
                      </a>
                    </h6>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-innr">
                  <div className="li-cntnt">
                    <h6>
                      <LazyLoadingImages src="/img/uservectortwo.png" alt="" />
                      PTE Coaching
                      <a href="/pte_training" className="btn">
                        <LazyLoadingImages
                          src="/img/arrows-right.png"
                          alt="Arrow"
                        />
                      </a>
                    </h6>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-innr">
                  <div className="li-cntnt">
                    <h6>
                      <LazyLoadingImages
                        src="/img/uservectorthree.png"
                        alt=""
                      />
                      Accomodation
                      <a href="/housing_partner" className="btn">
                        <LazyLoadingImages
                          src="/img/arrows-right.png"
                          alt="Arrow"
                        />
                      </a>
                    </h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
});

export default OtherServiceSec;
