import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import InnrBanner from "./innrBanner";
import Termsection from "./term-section";

export default function index() {
  return (
    <>
      <Header />
      <InnrBanner />
      <Termsection />
      <Footer />
    </>
  );
}
