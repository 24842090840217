import React from "react";
import { Link } from "react-router-dom";

const Bechelorad = React.memo(function Bechelorad() {
  return (
    <section className="bacheloradsec-slider aboutscholarshipsec">
      <div
        className="bacheloradsec "
        style={{ backgroundImage: `url('/img/aboutscholarshipsec.jpg')` }}
      >
        <div className="container newcontainer">
          <div className="bacheloradcntnt">
            <div className="headingbox">
              <h4>Know More</h4>
              <h2>
                About Scholarship &<br /> Visa Services
              </h2>
              <a href="/get_visa" className="btn">
                Explore More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Bechelorad;
