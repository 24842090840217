import React, { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInternalMessage,
  getMessageListInternal,
} from "../../../actions/messageActions";
import { useState } from "react";
import moment from "moment";
import { MESSAGE_ADD_RESET } from "../../../constants/messageConstants";
import { useParams } from "react-router-dom";
import LeftPanel from "../Common/leftPanel";

export default function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [messagetxt, setMessagetxt] = useState("");
  const bottomRef = useRef();

  const [newMessageId, setNewMessageId] = useState(0);

  const {
    loading: loadingmli,
    error: errormli,
    messageinternallists,
  } = useSelector((state) => state.messageInternalList);

  const getmessageInternallist = (id) => {
    dispatch(getMessageListInternal(id));
  };

  const {
    loading: loadingInsert,
    error: errorinsert,
    success,
  } = useSelector((state) => state.messageInsert);

  const insertMessage = (e) => {
    e.preventDefault();
    if (messagetxt) {
      dispatch(
        addInternalMessage({
          Message_Id: id,
          Message_body: messagetxt,
        })
      );
    } else {
      alert("Message should not be empty!");
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    if (!messageinternallists) {
      getmessageInternallist(id);
    }
    if (success) {
      getmessageInternallist(id);
      setMessagetxt("");
      dispatch({
        type: MESSAGE_ADD_RESET,
      });
    }
  }, [dispatch, success, messageinternallists]);

  return (
    <>
      <LeftPanel>
        <div className="sec-lg-col-75 dashboard-rightbox dashboard-message common-dashboard-search-box sec-col-83">
          <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
            <div className="coursesearch mb-tzero">
              <form action="" className="searchofcourses">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for Courses...."
                />
                <button type="submit" name="coursesarchsubmit" className="btn">
                  <img src="/img/searchicon.png" alt="Search Icon" />
                </button>
              </form>
            </div>
            <div className="notification-bellbox">
              <div
                className="notification-bell"
                style={{
                  backgroundImage: `url('/img/bell.png')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >
                <span className="notification">
                  <img src="/img/notification-circle.png" alt="message" />
                </span>
              </div>
            </div>
          </div>
          <div className="featuredbg-style my-messages">
            <div className="my-messages-topbox flex-box align-center">
              <div className="dash-common-heading-style my-messages-topleft-content flex-box align-center">
                <h1>
                  {messageinternallists
                    ? messageinternallists[0].Message_Sub
                    : ""}
                </h1>
                {/* <div className="write-message write-modal-toggle">
                      <img src="/img/add-courses-icon.png" alt="message" />
                    </div> */}
              </div>
              <div className="myOngoing-topic">
                <div className="myOngoing-topic-list flex-box">
                  {/* <h6>My Ongoing Topic :-</h6> */}
                  {/* {!loading ? (
                        messagelists ? (
                          messagelists.map((mslt) => (
                            <span className="myOngoing-topic-name">
                              <a onClick={() => setMessageId(mslt.Id)}>
                                {mslt.Message_Sub}
                              </a>
                            </span>
                          ))
                        ) : (
                          <>{error}</>
                        )
                      ) : (
                        ""
                      )} */}
                </div>
              </div>
            </div>
            <div className="messages-user flex-box align-center">
              <div className="messages-user-heading flex-box align-center">
                <div className="messages-user-image">
                  <img src="/img/student-icon.jpg" alt="message" />
                </div>
                <div className="messages-user-name">
                  {/* <h4>{userDt.Contact_Name}</h4> */}
                  <h4>Search Education Team</h4>
                  {/* <p>Online - Last seen, 2.02pm</p> */}
                  {/* <p>{messagelists ? messagelists.Message_Sub}</p> */}
                </div>
              </div>
              {/* <div className="messages-chat-option">
                    <button className="messages-call-option audio-call">
                      <img src="/img/phone-outline.png" alt="message" />
                    </button>
                    <button className="messages-call-option video-call">
                      <img src="/img/fluent-video.png" alt="message" />
                    </button>
                    <div className="message-more-info-dropdown-box">
                      <button className="messages-call-option message-more-info">
                        <img src="/img/three-dots-vertical.png" alt="message" />
                      </button>
                      <div className="message-more-info-dropdown">
                        <ul className="message-more-info-list">
                          <li>
                            <a href="/">Contact info</a>
                          </li>
                          <li>
                            <a href="/">Select messages</a>
                          </li>
                          <li>
                            <a href="/">Close chat</a>
                          </li>
                          <li>
                            <a href="/">Mute notifications</a>
                          </li>
                          <li>
                            <a href="/">Clear messages</a>
                          </li>
                          <li>
                            <a href="/">Delete chat</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
            </div>
            <div
              className="message-chat-box-wrapper scroll mCustomScrollbar _mCS_3"
              id="mCSB_3"
            >
              <div
                className="messages-chat-box flex-box mCSB_container"
                id="mCSB_3_container"
              >
                {messageinternallists
                  ? messageinternallists.map((mslli, index) => (
                      <>
                        <div
                          className={
                            mslli.Internal_or_student === "1"
                              ? "messages-chat chat-you"
                              : "messages-chat chat-me"
                          }
                        >
                          <p>{mslli.Message_body}</p>
                        </div>
                        <div
                          className={
                            mslli.Internal_or_student === "1"
                              ? "conversation-status conversation-status-you"
                              : "conversation-status conversation-status-me"
                          }
                        >
                          <p>
                            {moment(mslli.Created_Date).format(
                              "DD-MM-YYYY hh:mm"
                            )}
                          </p>
                        </div>
                      </>
                    ))
                  : ""}
                <div ref={bottomRef} />
              </div>
            </div>
            <div style={{ padding: 12 }}>&nbsp;</div>
            <div className="message-chat-type-text-box flex-box align-center">
              <div className="message-chat-type-text">
                <form onSubmit={(e) => insertMessage(e)}>
                  <div className="form-group message-chat-inner flex-box">
                    {/* <div className="attach-file">
                    <span className="attach-file-image">
                      <img src="/img/attach-file.png" alt="message" />
                    </span>
                    <input
                      type="file"
                      className="file-upload"
                      id="myfile"
                      name="myfile"
                    />
                  </div> */}
                    <div className="message-type">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type your message here..."
                        value={messagetxt}
                        onChange={(e) => setMessagetxt(e.target.value)}
                        // onKeyDown={(e)=>{if(e.key==="Enter") insertMessage(e)}}
                      />
                    </div>
                    {/* <div className="message-chat-type-right">
                    <span className="message-chat-other-option message-react-emoji">
                      <img src="/img/emoji.png" alt="message" />
                    </span>
                    <span className="message-chat-other-option message-chat-camera">
                      <img src="/img/camera.png" alt="message" />
                    </span>
                  </div> */}
                  </div>
                </form>
              </div>
              <div className="message-send-box">
                <span
                  className="message-send audio-message"
                  onClick={insertMessage}
                >
                  <img src="/img/sendicon.png" alt="message" />
                </span>
                {/* <span
                className="message-send send-message"
                style={{ display: "none" }}
              >
                <img src="/img/send-message.png" alt="message" />
              </span> */}
              </div>
            </div>
          </div>
        </div>
      </LeftPanel>
      <div className="write-modal">
        <div className="modal-overlay write-modal-toggle"></div>
        <div className="write-message-modal modal-transition">
          <div className="write-message-modal-inner">
            <div className="write-modal-inner">
              <h2>Write Messages</h2>
              <span className="close-modal write-modal-toggle">
                <img src="/img/close-black-icon.png" alt="message" />
              </span>
              <form className="write-modal-form">
                <div className="form-group write-modal-form-group">
                  <label for="yourtopic">Topic</label>
                  <input
                    type="text"
                    className="form-control"
                    id="yourtopic"
                    name="yourtopic"
                    placeholder="Type Your Topic Here ..."
                  />
                </div>
                <div className="form-group write-modal-form-group">
                  <label for="YourMessage">Your Message</label>
                  <textarea
                    id="YourMessage"
                    name="Your Message"
                    rows="3"
                    className="form-control"
                    placeholder="Write Message here"
                  ></textarea>
                </div>
                <div className="form-group write-modal-form-group text-center">
                  <button type="submit" className="btn btn-submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
