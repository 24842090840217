import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/darwin-nannies.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Darwin</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding study-in-newzeland">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/portrait-young-stylish.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Darwin</h2>
              <p>
                Darwin lies in the northern territory of Australia. And like
                other coastal areas it has backwaters, waterfronts, reefs and
                coastlines throughout. Being the closest port of call to old
                mariners it has a distinct south Asian flavor to its history.
                The Darwin festival which hosts culinary fares, crocodile jumps,
                sea and river cruises and fine living are a major attractions.
                Also unique to Darwin is the street art which is all over the
                walls especially in the suburbs. Unlike the bigger cities,
                Darwin city is cheaper to live, visit or work in.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/darwin-wharf-precinct.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Tourist and Educational Hub</h2>
              <p>
                It is the gateway to the vast outback of the place and therefore
                promotes adventure, cycling, backpacking and nature trails. The
                raw terrain, rough weather and sparse vegetation give one taste
                of the rough life in the outback. At the same time the city has
                differently available cuisine which is cost effective to both
                the customer and the roadside eateries. Being prominent in the
                northern territory, Darwin bore the brunt of the Japanese
                invasion and has wound marks of those times still part of its
                cultural heritage. The state and war museums exhibit the
                remnants of the war. The underground tunnels which were used
                during the war are now a tourist attraction. And it is
                interesting to see the weapons and other war equipment lying
                around as they were left behind after the hostilities ended.
                Eating in Darwin town’s streets has its own flavor and is easy
                on a visitor’s budget. For international students it would be a
                good idea to do part time work for their long stays This will
                add cushion to their wallet and make the entire experience
                enjoyable.
              </p>

              <p>
                Darwin being a smaller city should be explored first before
                venturing into the wilderness. This will help the visitor to
                first acquaint, acclimatize and thereafter venture out which is
                a good thing to do. Education in Darwin is as world class as
                that in Melbourne, Brisbane and other Australian universities.
                So is Darwin another good educational hub. All in all Darwin
                remains high on a visitors list and is a backpackers paradise.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
