import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Forexcard = React.memo(function Forexcard() {
  return (
    <section className="advantages-forex-card">
      <div className="container">
        <div className="flex-box advantages-forex-details-wrapper">
          <div className="advantages-forex-details headingbox letter-normal mb-zero full-width-730">
            <h2>
              <span className="themecolor">Advantages Of A</span> Forex Card For
              Students
            </h2>

            <p>
              You can load your Travel Card as per the applicable Forex Card
              rates of the bank when you are still in India. The card can be
              loaded with most of the popular currencies.
            </p>

            <div className="advantages-student-imagebox">
              <div className="advantages-student">
                <LazyLoadingImages
                  src="/img/advantages-student.jpg"
                  alt="student-image"
                />
              </div>

              <span className="advantages-student-visa">
                <LazyLoadingImages
                  src="/img/stu-visa.png"
                  alt="student-image"
                />
              </span>
            </div>
          </div>

          <div className="advantages-forex-list-wrapper full-width-730">
            <ul className="advantages-forex-list">
              <li className="advantages-forex-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="circle-image"
                />

                <h5>
                  Better exchange rates than
                  <br /> currency
                </h5>
              </li>

              <li className="advantages-forex-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="circle-image"
                />

                <h5>
                  Safe and secure transactions as card comes with chip and pin
                  technology
                </h5>
              </li>

              <li className="advantages-forex-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="circle-image"
                />

                <h5>Can carry multiple foreign currencies</h5>
              </li>

              <li className="advantages-forex-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="circle-image"
                />

                <h5>Universally accepted as a mode of payment</h5>
              </li>

              <li className="advantages-forex-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="cirle-image"
                />

                <h5>Cheaper than using credit and debit cards abroad</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Forexcard;
