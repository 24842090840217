import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

import Banner from "./Banner";
import Bookappointment from "./BookAppointment";
import Exchangeassistance from "./ExchangeAssistance";
import Exchangecompadding from "./ExchangeAssitsCompadding";
import Faqs from "./Faqs";
import Forexcard from "./Forexcard";
import Othersericesec from "./Othersericesec";
import Supportedcur from "./Supportedcur";
import Usecard from "./Usecard";

const Index = React.memo(function index() {
  return (
    <>
      <Header />
      <Banner />
      <Exchangecompadding />
      <Usecard />
      <Exchangeassistance />
      <Supportedcur />
      <Othersericesec />
      <Forexcard />
      <Bookappointment />
      <Faqs />
      <Footer />
    </>
  );
});

export default Index;
