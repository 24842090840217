import React from "react";

const InnrBanner = React.memo(function () {
  return (
    <section
      className="innrbanner about-banner"
      style={{
        backgroundImage: `url('/img/t&cimage.png')`,
        backgroundColor: "#07070B",
      }}
    >
      <div className="container">
        <div className="innrbannercntnt">
          <div className="headingbox">
            <h1>Search Education Privacy Policy</h1>
          </div>
        </div>
      </div>
    </section>
  );
});

export default InnrBanner;
