import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Hdfcloanfeatures = React.memo(function () {
  return (
    <section className="hdfc-loan-features lightgrey compadding">
      <div className="container">
        <div className="flex-box hdfc-loan-features-wrapper">
          <div className="headingbox letter-normal hdfc-loan-features-images mb-0 full-width-925">
            <h2>
              <span className="themecolor">HDFC Credila’s</span> Education Loan
              Features
            </h2>
            <p>HDFC Credila Student Loans offer several benefits, including:</p>
            <LazyLoadingImages
              src="/img/education-celibrate.jpg"
              alt="student-image"
            />
          </div>
          <div className="hdfc-loan-features-list full-width-925">
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages src="/img/tax.png" alt="student-image" />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>Tax Benefit Under Sec 80 E</h5>
                <p>
                  HDFC Credila loan borrowers are entitled to avail tax benefits
                  on interest paid on education loan under Sec 80-E of the
                  Income Tax Act.
                </p>
              </div>
            </div>
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages
                  src="/img/dollar-price.png"
                  alt="student-image"
                />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>Up to 100% Finance</h5>
                <p>
                  HDFC Credila funds the entire cost of your education
                  signifying zero-margin money. The loan along with college fees
                  covers living expenses, traveling expenses & all other
                  expenses attached to the course.
                </p>
              </div>
            </div>
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages
                  src="/img/approved-loan.png"
                  alt="student-image"
                />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>Pre-approved Loan</h5>
                <p>
                  HDFC Credila sanctions loan even before the student has
                  received an admission offer from university or college.{" "}
                </p>
              </div>
            </div>
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages
                  src="/img/loan-amount.png"
                  alt="student-image"
                />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>No Upper Limit On Loan Amount</h5>
                <p>
                  HDFC Credila does not have an upper limit on the loan. The
                  company processes loan exceeding INR 25,00,000
                </p>
              </div>
            </div>
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages
                  src="/img/easy-emi.png"
                  alt="student-image"
                />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>Easy EMIs</h5>
                <p>
                  The flexibility to choose repayment tenure between 12 to 180
                  months.
                </p>
              </div>
            </div>
            <div className="hdfc-loan-features-list-item flex-box align-center letter-normal">
              <div className="hdfc-loan-features-list-icon">
                <LazyLoadingImages
                  src="/img/trustworthy.png"
                  alt="student-image"
                />
              </div>
              <div className="hdfc-loan-features-list-item-content">
                <h5>Trustworthy</h5>
                <p>
                  The product is trustworthy. HDFC Credila is a subsidiary of
                  HDFC Ltd. company, one of the most trusted financial service
                  providers in India{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Hdfcloanfeatures;
