import React, { useState } from "react";

const Faqs = React.memo(function ({ city }) {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  return (
    <section className="faqs">
      <div className="container">
        <div className="text-box text-center faq-heading headingbox">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="faq-wrapper">
          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("01")}>
              What is the cost of an Australian student visa?
              <span
                className={active === "01" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "01" ? "panel blk" : "panel"}>
              <p>
                The application fee for a student visa is 620 Australian dollars
                (around 35,000 INR)
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("02")}>
              Is it possible to work while studying in {city}?
              <span
                className={active === "02" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "02" ? "panel blk" : "panel"}>
              <p>
                Yes, it is feasible for an overseas student to work while being
                an international student in {city}. During the academic year,
                international students in Australia are permitted to work up to
                40 hours per fortnight (two weeks), and full-time during
                specified breaks. It is crucial to remember that there are
                limitations on the kinds of jobs that overseas students can
                hold, and that they must adhere to visa regulations and
                Australian labour laws.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("03")}>
              Is it true that Australian degrees are recognised worldwide?
              <span
                className={active === "03" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "03" ? "panel blk" : "panel"}>
              <p>
                Yes, Australian degrees are respected internationally due to the
                country's excellent educational standards and the presence of
                numerous prestigious universities. Australian university
                graduates are well-equipped to pursue a variety of academic and
                professional opportunities around the world.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("04")}>
              What scholarships are available to study in Australia?
              <span
                className={active === "04" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "04" ? "panel blk" : "panel"}>
              <p>
                There are several scholarships available for international
                students to study in Australia, including:
              </p>
              <ul>
                <li>
                  Australia Awards Scholarships: For students from
                  underdeveloped nations, the scholarships cover their entire
                  tuition, as well as their travel and living costs.
                </li>
                <li>
                  Private scholarships: International students studying in
                  Australia may also be eligible for financial aid from private
                  foundations, corporations, and organisations.
                </li>
                <li>
                  Research Scholarships: Are accessible to those pursuing
                  research-based degrees, such PhDs.
                </li>
                <li>
                  Endeavour Postgraduate Scholarship Awards: With the help of
                  this scholarship, international students can pursue
                  postgraduate study or research in Australia.
                </li>
                <li>
                  University-specific scholarships: Many Australian institutions
                  provide financial aid to foreign students based on their
                  academic performance, potential for leadership, or other
                  factors.
                </li>
              </ul>
              <p>
                Before submitting an application for any scholarship, it's
                crucial to properly investigate the requirements and check that
                you meet them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Faqs;
