import React from 'react'
import LeftPanel from '../Common/leftPanel'

export default function index() {
  return (
    <div>
        <LeftPanel/>
        <section className="dashboard">
        <div className="container">
            <div className="sec-row">
              <div className="toggle-sidebar-btn-wrapper">
                <div className="sidebar-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
              </div>
              
                <div className="sec-lg-col-75 dashboard-rightbox common-dashboard-search-box sec-col-83">
                    <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
                        <div className="coursesearch mb-tzero">
                            <form action="" className="searchofcourses">
                                <input type="text" className="form-control" placeholder="Search for Courses...."/>
                                <button type="submit" name="coursesarchsubmit" className="btn">
                                <img src="/img/searchicon.png" alt="Search Icon"/>
                                </button>
                            </form>
                        </div>
                        <div className="notification-bellbox">
                            <div className="notification-bell" style={{backgroundImage: `url('/img/bell.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}}>
                                <span className="notification"><img src="/img/notification-circle.png"alt="dashboard"/></span>
                              </div>
                        </div>
                    </div>
                    <div className="dashboard-compare mb-900-zero">
                        <div className="course-compare-main-heading flex-box">
                            <div className="headingbox course-compare-heading">
                                <h2>Course Compare</h2>
                            </div>
                            <div className="add-course">
                                <a href="/" className="btn">Add Course</a>
                            </div>
                        </div>
                        <div className="course-compare-details-wrapper">
                            <div className="course-compare-details flex-box">
                                <div className="course-compare-detail-box text-center">
                                <span className="close close-compare-details-box">
                                    <img src="/img/close.png"alt="dashboard"/>
                                </span>
                                <div className="compare-course-image">
                                    <img src="/img/courses-image.jpg"alt="dashboard"/>
                                </div>
                                <h3>Marine and Lacustrine Science & Management - MSc</h3>
                                <span className="compare-course-country-image">
                                    <img src="/img/country-image.jpg"alt="dashboard"/>
                                </span>
                                <h4>Bachelor of Hospitality Management</h4>
                                <a href="/" className="btn view-course-detail">View Detail</a>
                                <div className="compare-course-details-info">
                                    <h6>Duration</h6>
                                    <p>NA years</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Fees</h6>
                                    <p>$2,122</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>College Ranking</h6>
                                    <p>45/2309</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Course Rating</h6>
                                    <p>Stars | 3.5/5</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Teaching Mode</h6>
                                    <p>Online, Hybrid</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Admission</h6>
                                    <p>Jan, Oct, Jun</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>IELTS Entry Score</h6>
                                    <p>7.0</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>PTE Entry</h6>
                                    <p>64-72</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Scholarship Status</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Accommodation</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Career Outcomes</h6>
                                    <p>Manager; Data Anylysts</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Starting Salary</h6>
                                    <p>$ 800 per hour</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Additional</h6>
                                    <p>Lorem ipsum</p>
                                </div>
                                </div>
                                <div className="course-compare-detail-box text-center">
                                <span className="close close-compare-details-box">
                                    <img src="/img/close.png"alt="dashboard"/>
                                </span>
                                <div className="compare-course-image">
                                    <img src="/img/courses-image.jpg"alt="dashboard"/>
                                </div>
                                <h3>Marine and Lacustrine Science & Management - MSc</h3>
                                <span className="compare-course-country-image">
                                    <img src="/img/country-image.jpg"alt="dashboard"/>
                                </span>
                                <h4>Bachelor of Hospitality Management</h4>
                                <a href="/" className="btn view-course-detail">View Detail</a>
                                <div className="compare-course-details-info">
                                    <h6>Duration</h6>
                                    <p>NA years</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Fees</h6>
                                    <p>$2,122</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>College Ranking</h6>
                                    <p>45/2309</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Course Rating</h6>
                                    <p>Stars | 3.5/5</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Teaching Mode</h6>
                                    <p>Online, Hybrid</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Admission</h6>
                                    <p>Jan, Oct, Jun</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>IELTS Entry Score</h6>
                                    <p>7.0</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>PTE Entry</h6>
                                    <p>64-72</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Scholarship Status</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Accommodation</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Career Outcomes</h6>
                                    <p>Manager; Data Anylysts</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Starting Salary</h6>
                                    <p>$ 800 per hour</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Additional</h6>
                                    <p>Lorem ipsum</p>
                                </div>
                                </div>
                                <div className="course-compare-detail-box text-center">
                                <span className="close close-compare-details-box">
                                    <img src="/img/close.png"alt="dashboard"/>
                                </span>
                                <div className="compare-course-image">
                                    <img src="/img/courses-image.jpg"alt="dashboard"/>
                                </div>
                                <h3>Marine and Lacustrine Science & Management - MSc</h3>
                                <span className="compare-course-country-image">
                                    <img src="/img/country-image.jpg"alt="dashboard"/>
                                </span>
                                <h4>Bachelor of Hospitality Management</h4>
                                <a href="/" className="btn view-course-detail">View Detail</a>
                                <div className="compare-course-details-info">
                                    <h6>Duration</h6>
                                    <p>NA years</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Fees</h6>
                                    <p>$2,122</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>College Ranking</h6>
                                    <p>45/2309</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Course Rating</h6>
                                    <p>Stars | 3.5/5</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Teaching Mode</h6>
                                    <p>Online, Hybrid</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Admission</h6>
                                    <p>Jan, Oct, Jun</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>IELTS Entry Score</h6>
                                    <p>7.0</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>PTE Entry</h6>
                                    <p>64-72</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Scholarship Status</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Accommodation</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Career Outcomes</h6>
                                    <p>Manager; Data Anylysts</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Starting Salary</h6>
                                    <p>$ 800 per hour</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Additional</h6>
                                    <p>Lorem ipsum</p>
                                </div>
                                </div>
                                <div className="course-compare-detail-box text-center">
                                <span className="close close-compare-details-box">
                                    <img src="/img/close.png"alt="dashboard"/>
                                </span>
                                <div className="compare-course-image">
                                    <img src="/img/courses-image.jpg"alt="dashboard"/>
                                </div>
                                <h3>Marine and Lacustrine Science & Management - MSc</h3>
                                <span className="compare-course-country-image">
                                    <img src="/img/country-image.jpg"alt="dashboard"/>
                                </span>
                                <h4>Bachelor of Hospitality Management</h4>
                                <a href="/" className="btn view-course-detail">View Detail</a>
                                <div className="compare-course-details-info">
                                    <h6>Duration</h6>
                                    <p>NA years</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Fees</h6>
                                    <p>$2,122</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>College Ranking</h6>
                                    <p>45/2309</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Course Rating</h6>
                                    <p>Stars | 3.5/5</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Teaching Mode</h6>
                                    <p>Online, Hybrid</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Admission</h6>
                                    <p>Jan, Oct, Jun</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>IELTS Entry Score</h6>
                                    <p>7.0</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>PTE Entry</h6>
                                    <p>64-72</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Scholarship Status</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Accommodation</h6>
                                    <p>Yes</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Career Outcomes</h6>
                                    <p>Manager; Data Anylysts</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Starting Salary</h6>
                                    <p>$ 800 per hour</p>
                                </div>
                                <div className="compare-course-details-info">
                                    <h6>Additional</h6>
                                    <p>Lorem ipsum</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
