import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Adcomparecourselisting from "./AdCompareCourselisting";
import Courselisting from "./CourseListing";

const Index = React.memo(function Index() {
  return (
    <>
      <Header />
      <Courselisting />
      <Adcomparecourselisting />
      <Footer />
    </>
  );
});

export default Index;
