import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Whyjoinus = React.memo(function Whyjoinus() {
  return (
    <section className="why-join-us lightgrey compadding">
      <div className="container">
        <div className="why-join-us-content-wrapper flex-box">
          <div className="why-join-image-box full-width-925">
            <div
              className="why-join-image bg-style"
              style={{ backgroundImage: `url('/img/why-join-img1.jpg')` }}
            ></div>
            <div
              className="why-join-image why-join-image-second bg-style"
              style={{ backgroundImage: `url('/img/office-room.jpg')` }}
            ></div>
          </div>
          <div className="why-join-content-box headingbox full-width-925">
            <h2>
              <span className="themecolor">Why Join </span>Us
            </h2>
            <p>
              Positive work culture: An environment where team members feel
              valued and supported, and where they are encouraged to learn from
              mistakes and grow professionally.
            </p>
            <p>
              Celebrate successes: We recognize and celebrate team successes to
              boost morale and reinforce the importance of teamwork.
            </p>
            <p>
              Encourage open communication: We encourage team members to share
              their thoughts and ideas openly and respectfully.
            </p>
            <div className="join-us-info-wrapper flex-box">
              <div className="join-us-info-box">
                <div className="join-us-info-image">
                  <LazyLoadingImages
                    src="/img/handshake.png"
                    alt="handshek-image"
                  />
                </div>
                <div className="join-us-info-content">
                  <h3>Integrity</h3>
                </div>
              </div>
              <div className="join-us-info-box">
                <div className="join-us-info-image">
                  <LazyLoadingImages
                    src="/img/personalize.png"
                    alt="personamlize-image"
                  />
                </div>
                <div className="join-us-info-content">
                  <h3>Personalised Service</h3>
                </div>
              </div>
              <div className="join-us-info-box">
                <div className="join-us-info-image">
                  <LazyLoadingImages
                    src="/img/approaches.png"
                    alt="approaches-image"
                  />
                </div>
                <div className="join-us-info-content">
                  <h3>Innovative Approaches</h3>
                </div>
              </div>
              <div className="join-us-info-box">
                <div className="join-us-info-image">
                  <LazyLoadingImages
                    src="/img/communication.png"
                    alt="communication-image"
                  />
                </div>
                <div className="join-us-info-content">
                  <h3>Transparent Communication</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Whyjoinus;
