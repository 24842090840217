import React from "react";
import BannerForm from "../Common/BannerForm";

const Educationloan = React.memo(function () {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{ backgroundImage: `url('/img/hdfc-banner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Everything You Need To Know</h4>

              <h1>HDFC Credila Education Loan</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="education-loan-value compadding">
        <div className="container">
          <div className="flex-box edu-loan-value-wrapper">
            <div className="headingbox letter-normal edu-loan-value-content full-width-925">
              <h2>
              Education<span className="themecolor"> Loans</span>
              </h2>
              <p>
              Education serves as the building block of life, and a strong foundation of quality education is imperative to ensure a bright future. To obtain an outstanding education, individuals worldwide are constantly seeking the finest academic institutions, both domestically and internationally. While a plethora of universities offer world-class education to aspirants, tuition fees are skyrocketing more each day. Financial impediments should not hinder educational pursuits, hence, the most viable solution is to explore education loans to secure the necessary funding for educational excellence.
              </p>
            </div>
            <div className="edu-loan-image-group flex-box full-width-925">
              <div
                className="edu-loan-image-first bg-style"
                style={{ backgroundImage: `url('/img/education-degree.jpg')` }}
              ></div>
              <div
                className="edu-loan-image-second bg-style"
                style={{
                  backgroundImage: `url('/img/education-degree-cap.jpg')`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
})

export default Educationloan
