import React from "react";
import EnquiryForm from "./EnquiryForm";

const Takeptesec = React.memo(function () {
  return (
    <>
      <section
        className="efficient-courses-banner innrbanner innr-student-banner-sec"
        style={{
          backgroundImage: `url('/img/female-speaker-giving-presentation.png')`,
          backgroundColor: "#09091E",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox letter-normal text-white">
              <h1>IELTS Training & Coaching</h1>

              <p>Best IELTS Preparation Platform Designed So Far.</p>
            </div>

            <div className="efficient-courses-forms forex-applynow-form">
              <EnquiryForm />
            </div>
          </div>
        </div>
      </section>
      <section className="take-pte-sec compadding lightgrey">
        <div className="container">
          <div className="comm-content-wrapper-width">
            <div className="headingbox letter-normal">
              <h2>
                <span className="themecolor">Why</span> Take IELTS?
              </h2>
              <p>
                The IELTS Preparation courses in this specialization are
                intended for those seeking to boost their scores on the IELTS
                exam. Whether you have taken the test before, these courses will
                help you to become more familiar with the various types of
                questions on the test.
              </p>
            </div>
            <div className="take-pte-list">
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    IELTS is recognised by more than 11,000 education and
                    training providers worldwide.
                  </h5>
                </div>
              </div>
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    Take IELTS to migrate to Australia, Canada, New Zealand and
                    the UK.
                  </h5>
                </div>
              </div>
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    Organisations around the world rely on IELTS to help them
                    select the right people.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Takeptesec;
