import React, { useEffect, useState } from "react";

export default function YearCompletion() {
  const [years, setYears] = useState([]);
  const getYears = () => {
    var currentYear = new Date().getFullYear(),
      min1 = new Date().getFullYear() - 15;

    var dt1 = "[";
    for (var i = min1; i <= currentYear; i++) {
      // dt1.push(i);
      dt1 += `{"year":"${i}"},`;
    }
    dt1 = dt1.replace(/,\s*$/, "");
    dt1 += "]";
    setYears(JSON.parse(dt1));
  };
  useEffect(() => {
    if (!years || years.length === 0) {
      getYears();
    }
  }, [years]);
  return years ? (
    years.length > 0 ? (
      years.map((yrs, index) => (
        <option key={index + 1} value={yrs.year}>
          {yrs.year}
        </option>
      ))
    ) : (
      <option>No Record Exist</option>
    )
  ) : (
    <option>Something Occured!</option>
  );
}
