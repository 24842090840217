import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCareer } from "../../actions/careerActions";
import { careerJson } from "../../models/careerModel";
import { CAREER_CREATE_RESET } from "../../constants/careerConstants";

const CareerForm = React.memo(function CareerForm() {
  const [file, setFile] = useState();
  const [ierror, setIerror] = useState("");

  const dispatch = useDispatch();
  const [careerData, setCareerData] = useState(careerJson);

  const careerCreate = useSelector((state) => state.careerCreate);
  const { loading, success, error } = careerCreate;

  const handleChange = (e) => {
    setCareerData({ ...careerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    let iherror = "";
    if (!careerData.fname) {
      iherror += "All Fields are cumpulsory!";
    }
    if (iherror === "") {
      var bodyFormData = new FormData();
      bodyFormData.append("files", file);
      bodyFormData.append("fname", careerData.fname);
      bodyFormData.append("lname", careerData.lname);
      bodyFormData.append("Namee", careerData.fname + " " + careerData.lname);
      bodyFormData.append("EmailAddress", careerData.EmailAddress);
      bodyFormData.append("PhoneNumber", careerData.PhoneNumber);
      bodyFormData.append("Position", careerData.Position);
      bodyFormData.append("Descriptionn", careerData.Descriptionn);
      dispatch(createCareer(bodyFormData));
    } else {
      setIerror(iherror);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (success) {
      setCareerData({ ...careerJson });
      setFile("");
      document.getElementById("uploadFile").value = "";
      setTimeout(() => {
        dispatch({
          type: CAREER_CREATE_RESET,
        });
      }, 3500);
    }
  }, [dispatch, success]);

  return (
    <form className="contact-form flex-box" onSubmit={handleSubmit}>
      <div className="form-group">
        <label for="firstname">First Name*</label>
        <input
          type="text"
          className="form-control"
          id="firstname"
          placeholder="First Name"
          name="fname"
          value={careerData.fname}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group">
        <label for="lastname">Last Name*</label>
        <input
          type="text"
          className="form-control"
          id="lastname"
          placeholder="Last Name"
          name="lname"
          value={careerData.lname}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group">
        <label for="email">Email*</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Email"
          name="EmailAddress"
          value={careerData.EmailAddress}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group">
        <label for="Phonenumber">Number*</label>
        <input
          type="text"
          className="form-control"
          id="Phonenumber"
          placeholder="Number"
          name="PhoneNumber"
          value={careerData.PhoneNumber}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group">
        <label for="Phonenumber">Number*</label>
        <input
          type="file"
          id="uploadFile"
          className="form-control"
          onChange={(e) => handleFileChange(e)}
        />
      </div>
      {/* <div className="form-group">
        <label for="select-country">Select Country*</label>
        <span className="select-arrow">
          <LazyLoadingImages src="/img/arrow-down.png" alt="arrow" />
        </span>
        <select
          id="select-country"
          className="form-control"
          name="fname"
          value={careerData.count}
          onChange={(e)=>handleChange(e)}
        >
          <option value="">Select Country</option>
          <CountrySelect />
        </select>
      </div>
      <div className="form-group">
        <label for="select-state">State*</label>
        <input
          type="text"
          className="form-control"
          id="State"
          placeholder="State"
        />
      </div> */}
      <div className="form-group">
        <label for="select-state">Position*</label>
        <input
          type="text"
          className="form-control"
          id="State"
          placeholder="Position"
          name="Position"
          value={careerData.Position}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group col-full">
        <label for="yourmessage">Your Message*</label>
        <textarea
          rows="3"
          className="form-control"
          id="yourmessage"
          placeholder="Your Message"
          name="Descriptionn"
          value={careerData.Descriptionn}
          onChange={(e) => handleChange(e)}
        ></textarea>
      </div>
      <div className="form-group col-full">
        {loading ? (
          <button type="button" className="btn">
            Loading...
          </button>
        ) : (
          <button type="button" className="btn" onClick={handleSubmit}>
            Send Details
          </button>
        )}
      </div>
      <div>
        <p>
          <span className="bg-success">{success}</span>
          <span className="bg-danger">{error}</span>
        </p>
      </div>
    </form>
  );
});

export default CareerForm;
