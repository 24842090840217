import React from "react";
import AdSection1 from "./AdSection1";
import CounterDataSec from "./CounterDataSec";
import EducationPartner from "./EducationPartner";
import EnrollSearchTest from "./EnrollSearchTest";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import HowItWorks from "./HowItWorks";
import MainBanner from "./MainBanner";
import MostPopularCourses from "./MostPopularCourses";
import OtherServiceSec from "./OtherServiceSec";
import OurPartnerSec from "./OurPartnerSec";
import PopularUniversitySec from "./PopularUniversitySec";
import SearchCountrySec from "./SearchCountrySec";
import SearchDreamCourse from "./SearchDreamCourse";
import TestimonialSec from "./TestimonialSec";
import TrendingCourseSec from "./TrendingCourseSec";
import Meta from "../../components/Meta";

export default function Index() {
  const metaData = {
    title: "Search Education – Study Abroad Consultant | Free Counselling",
    mtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    mdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    ogdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogimage: "https://www.searcheducation.com/img/img_3.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle:
      "Search Education – Study Abroad Consultant | Free Counselling",
    twitterDescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    twitterImage: "https://www.searcheducation.com/img/img_3.jpg",
  };
  return (
    <>
      <Header />

      <Meta metaData={metaData} />

      <MainBanner />

      <SearchDreamCourse />

      <PopularUniversitySec secName="Search" />

      <HowItWorks />

      <MostPopularCourses />

      <EnrollSearchTest />

      <AdSection1 />

      <OtherServiceSec />

      <TrendingCourseSec />

      <SearchCountrySec />

      <TestimonialSec />

      <EducationPartner />

      <CounterDataSec />

      <OurPartnerSec />

      <Footer />
    </>
  );
}
