import React from 'react'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Currentopening from './CurrentOpening'

import Ourculture from './OurCulture'
import Ourmisson from './OurMisson'
import Whyjoinus from './WhyJoinUs'

export default function index() {
  return (
    <>
      <Header />
      <Ourmisson />
      <Whyjoinus />
      <Ourculture />
      <Currentopening />
      <Footer />
    </>
  )
}
