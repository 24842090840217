import React from "react";
import LeftPanel from "./leftPanel";
import { useState } from "react";
import SearchSection from "./SearchSection";

export default function Header(props) {
  return (
    <LeftPanel>
      <div
        className={
          "sec-lg-col-75 dashboard-rightbox dashboard-message common-dashboard-search-box sec-col-83"
        }
      >
        <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
          <SearchSection />
          {/* <div className="notification-bellbox">
            <div
              className="notification-bell"
              style={{
                backgroundImage: `url('/img/bell.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <span className="notification">
                <img src="/img/notification-circle.png" alt="dashboard" />
              </span>
            </div>
          </div> */}
        </div>
        {props.children}
      </div>
    </LeftPanel>
  );
}
