import {
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
  DOCUMENT_LIST_FAIL,
  DOCUMENT_ADD_REQUEST,
  DOCUMENT_ADD_SUCCESS,
  DOCUMENT_ADD_FAIL,
  DOCUMENT_REMOVE_REQUEST,
  DOCUMENT_REMOVE_SUCCESS,
  DOCUMENT_REMOVE_FAIL,
} from "../constants/documentConstants";
import api2 from "../core/api2";

export const getDocumentList = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_LIST_REQUEST,
    });

    const { data } = await api2.get(`/student/documents`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: DOCUMENT_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: DOCUMENT_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: DOCUMENT_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const addDocuments = (documentJson) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_ADD_REQUEST,
    });

    const { data } = await api2.post(`/student/documents`, documentJson);

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: DOCUMENT_ADD_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: DOCUMENT_ADD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: DOCUMENT_ADD_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const removeDocument = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_REMOVE_REQUEST,
    });

    const { data } = await api2.delete(`/student/documents`);

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: DOCUMENT_REMOVE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: DOCUMENT_REMOVE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: DOCUMENT_REMOVE_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
