import React from "react";
import TestimonialSec from "../Counselling/TestimonialSec";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Takeptesec from "./TakePteSec";
import Typepteexam from "./TypePteExam";
import Ptetrainingeducation from "./PteTrainingEducation";
import Ptecounselling from "./PteCounselling";
import SearchCountrySec from "../Home/SearchCountrySec";
import Meta from "../../components/Meta";

export default function index() {
  const metaData={
    title:"IELTS Coaching Classes – Search Education",
    mtitle:"IELTS Coaching Classes – Search Education",
    mdescription:"Achieve a High IELTS Score with our Expert Coaching – Avail access to exclusive mock tests, course materials and more! Enroll Today!",
    ogtype:"website",
    ogurl:"",
    ogtitle:"IELTS Coaching Classes – Search Education",
    ogdescription:"Achieve a High IELTS Score with our Expert Coaching – Avail access to exclusive mock tests, course materials and more! Enroll Today!",
    ogimage:"https://www.searcheducation.com/img/img_2.jpg",
    twitterCard:"summary_large_image",
    twitterUrl:"",
    twitterTitle:"IELTS Coaching Classes – Search Education",
    twitterDescription:"Achieve a High IELTS Score with our Expert Coaching – Avail access to exclusive mock tests, course materials and more! Enroll Today!",
    twitterImage:"https://www.searcheducation.com/img/img_2.jpg",
  }
  return (
    <>
      <div className="template-pte-training">
        <Header />
        <Meta metaData={metaData} />
        <Takeptesec />
        <Typepteexam />
        <Ptetrainingeducation />
        <TestimonialSec />
        <Ptecounselling />
        <SearchCountrySec />
        <Footer />
      </div>
    </>
  );
}
