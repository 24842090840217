import {
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAIL,
  All_COUNTRY_LIST_REQUEST,
  All_COUNTRY_LIST_FAIL,
  All_COUNTRY_LIST_SUCCESS,
} from "../constants/countryConstants";
import api2 from "../core/api2";

export const listCountry = () => async (dispatch) => {
  try {
    dispatch({
      type: COUNTRY_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api2.get(`/country/getCountries`, config);

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: COUNTRY_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COUNTRY_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COUNTRY_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listallCountry = () => async (dispatch) => {
  try {
    dispatch({
      type: All_COUNTRY_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api2.get(`/country`, config);

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: All_COUNTRY_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: All_COUNTRY_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: All_COUNTRY_LIST_FAIL,
      payload:
        error.response.data && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
