import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Abouthdfccredila = React.memo(function Abouthdfccredila() {
  return (
    <section className="about-hdfc-credila compadding pd-tzero">
      <div className="container">
        <div className="flex-box about-hdfc-credila-wrapper">
          <div className="about-hdfc-credila-image full-width-925">
            <LazyLoadingImages
              src="/img/hdfc-credila.png"
              alt="student-image"
            />
          </div>
          <div className="headingbox letter-normal about-hdfc-credila-content full-width-925">
            <h2>
              <span className="themecolor">About</span> HDFC Credila
            </h2>
            <p>
              HDFC Credila, an HDFC Ltd. Company, is India's First Dedicated
              Education Loan Company who pioneered the concept of specialist
              Education Loan lending. It offers speedy, tailor made education
              loans to students who want to pursue higher education in India or
              abroad. Till date, HDFC Credila has funded thousands of India
              students to over 2100+ unique educational institutes across 35+
              countries through a hassle-free loan application process.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Abouthdfccredila;
