import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Validitystuvisa = React.memo(function () {
  return (
    <section className="validity-stu-visa-sec">
      <div className="container">
        <div className="headingbox validity-stu-visa-heading common-stu-visa-heading text-center">
          <h2>
            <span className="themecolor">Validity of a</span> Student Visa
          </h2>

          <p>
            The validity of the Australian Student Visa depends on the course
            duration of the student. In general:
          </p>
        </div>

        <div
          className="flex-box validity-stu-visa-wrapper"
          id="validity-stu-visa-slider"
        >
          <div className="validity-stu-visa-detailbox">
            <div className="validity-stu-visa-image">
              <LazyLoadingImages
                src="/img/less-duration-courses.jpg"
                alt="student"
              />
            </div>

            <div className="validity-stu-visa-content">
              <h4>
                If the duration of the course is{" "}
                <strong>less than 10 months</strong>
              </h4>

              <p>
                The visa will hold its validity for one month more than the
                entire duration of the course opted by the student.
              </p>
            </div>
          </div>

          <div className="validity-stu-visa-detailbox">
            <div className="validity-stu-visa-image">
              <LazyLoadingImages
                src="/img/long-duration-courses.jpg"
                alt="student"
              />
            </div>

            <div className="validity-stu-visa-content">
              <h4>
                If the duration of the course is{" "}
                <strong>less than 10 months</strong>
              </h4>

              <p>
                But it will be finished sometime between January and October-
                The visa will hold its validity for two months more. For
                instance, say if the course is to end in Feb 2021, in this case
                the student visa will be issued till April 2021.
              </p>
            </div>
          </div>

          <div className="validity-stu-visa-detailbox">
            <div className="validity-stu-visa-image">
              <LazyLoadingImages
                src="/img/duration-courses.jpg"
                alt="student"
              />
            </div>

            <div className="validity-stu-visa-content">
              <h4>
                If the duration of the course is{" "}
                <strong>less than 10 months</strong>
              </h4>

              <p>
                But it will be finished sometime in November or December- Here
                the visa will be applicable till March 15 of the upcoming next
                year. For instance, say your course is to end in December 2020,
                then the visa will be valid till March 15, 2021.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Validitystuvisa;
