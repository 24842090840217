import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getCourse, listCompareCourse } from "../../actions/courseActions";
import {
  COURSE_COMPARE_DETAIL_RESET,
  COURSE_COMPARE_LIST,
} from "../../constants/courseConstants";

const Coursecomparedetails = React.memo(function Coursecomparedetails() {
  const dispatch = useDispatch();
  const [courses, setcourses] = useState([]);

  //getCourse
  const { loading, error, compareCourseRec } = useSelector(
    (state) => state.compareCourseDetail
  );

  const getcourses = () => {
    const val = localStorage.getItem("ccids")
      ? localStorage.getItem("ccids")
      : "";
    if (val != "") {
      dispatch(listCompareCourse(val));
    }
  };

  const handleRemove = (e, id) => {
    // remove the item from localstorage of ccids
    let recids = localStorage.getItem("ccids").split(",");
    const index = recids.indexOf(id.toString());
    recids.splice(index, 1);
    localStorage.setItem("ccids", recids.toString());

    // remove the item from localstorage of cc
    let rec = localStorage.getItem("cc");
    let recObj = rec ? JSON.parse(rec) : [];
    recObj.splice(
      recObj.findIndex((item) => item.Course_Id === id),
      1
    );
    localStorage.setItem("cc", JSON.stringify(recObj));
    dispatch({
      type: COURSE_COMPARE_LIST,
      payload: JSON.stringify(recObj),
    });
    dispatch({
      type: COURSE_COMPARE_DETAIL_RESET,
    });
  };

  useEffect(() => {
    if (compareCourseRec === undefined) {
      getcourses();
    }
  }, [dispatch, compareCourseRec]);

  return (
    <div className="header-white">
      <section className="course-compare-sec course-compare-details-sec">
        <div className="container">
          <div className="course-compare-main-heading flex-box">
            <div className="headingbox course-compare-heading">
              <h2>
                <span className="themecolor">Course </span>Compare
              </h2>

              <p>
                Compare up to 4 colleges based on your preferred course and get
                comparison on their ranks, fees, placements, seats,
                infrastructure and various other details
              </p>
            </div>

            <div className="add-course">
              <a href="/courses" className="btn">
                Add Course
              </a>
            </div>
          </div>

          <div className="course-compare-details-wrapper">
            <div className="course-compare-details flex-box">
              {compareCourseRec
                ? compareCourseRec.length > 0
                  ? compareCourseRec.map((rec) => (
                      <div className="course-compare-detail-box text-center">
                        <span className="close close-compare-details-box">
                          <a onClick={(e) => handleRemove(e, rec.Course_Id)}>
                            <LazyLoadingImages
                              src="/img/close.png"
                              alt="close-img"
                            />
                          </a>
                        </span>

                        <div className="compare-course-image">
                          <LazyLoadingImages
                            src={`${process.env.REACT_APP_SED_DOC_URL}/university/Logo134x64/${rec.Logo134x64}`}
                            alt="courses"
                          />
                        </div>

                        <h3>{rec.Course_Name}</h3>

                        <span className="compare-course-country-image">
                          <LazyLoadingImages
                            src={`${process.env.REACT_APP_SED_DOC_URL}/flags/${rec.ImageS}`}
                            alt={rec.Country}
                          />
                        </span>

                        <h4>{rec.University_Name}</h4>

                        <a
                          href={`/course/${rec.Course_Id}`}
                          className="btn view-course-detail"
                          target="_blank"
                        >
                          View Detail
                        </a>

                        <div className="compare-course-details-info">
                          <h6>Duration</h6>

                          <p>{rec.Duration}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Fees</h6>

                          <p>
                            {rec.Country_Symbol}
                            {rec.Yearly_Tuition_Fees}
                          </p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>College Ranking</h6>

                          <p>{rec.Country_Ranking}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Course Rating</h6>

                          <p>Stars | {rec.CourseRating}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Teaching Mode</h6>

                          <p>{rec.TeachingMode}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Admission</h6>

                          <p>{rec.Intakes}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>IELTS Entry Score</h6>

                          <p>{rec.IELTS_Required}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>PTE Entry</h6>

                          <p>{rec.PTE_Required}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Scholarship Status</h6>

                          <p>{rec.ScholarshipStatus}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Accommodation</h6>

                          <p>{rec.Accomodation}</p>
                        </div>

                        <div className="compare-course-details-info">
                          <h6>Career Outcomes</h6>

                          <p>{rec.Study_Level}</p>
                        </div>
                      </div>
                    ))
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Coursecomparedetails;
