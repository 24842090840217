import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSubscribe } from "../../actions/otherActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";

const Footer = React.memo(function Footer() {
  const dispatch = useDispatch();
  const [email, setemail] = useState("");

  const [footerSec, setFooterSec] = useState("");

  const handlesubmit = () => {
    dispatch(createSubscribe(email));
  };

  const subscribeCreate = useSelector((state) => state.subscribeCreate);
  const { loading, success, error } = subscribeCreate;

  useEffect(() => {
    if (success === undefined || success === "") {
    } else {
      setemail("");
    }
  }, [dispatch, success]);

  return (
    <>
      <footer className="sitefooter">
        <div className="container">
          <div className="subscribenewsletter">
            <div className="newletterheading">
              <h3>Subscribe Our Newsletter</h3>
              <p>
                to receive course information as well as the latest news and
                contests in your mailbox.
              </p>
            </div>
            <div className="newlettersubscribe">
              <div className="newlettersubscribeform">
                <span className="mailicon">
                  <LazyLoadingImages src="/img/mailicon.png" alt="Mail" />
                </span>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  id="subscribe"
                  name="subscribe"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <button type="submit" onClick={handlesubmit}>
                  <LazyLoadingImages src="/img/sendicon.png" alt="send" />
                </button>

                <p>
                  <span className="text-danger">{error}</span>
                  <span className="text-success">{success}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="footerrowwidget">
            <div className="sec-row">
              <div className="seccol sec-md-col-30">
                <div className="footercompanydescription">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <a href="/">
                      <div className="logo">
                        <LazyLoadingImages src="/img/logo.png" alt="Logo" />
                      </div>
                    </a>

                    <div className="accredted_logo">
                      <a
                        href="https://www.icef.com/ias-id-code/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src="/img/ICEF_ACCREDITED.png"
                          alt="ICEF_ACCREDITED"
                        />
                      </a>

                      <a href="https://aaeri.org.in/" target="_blank">
                        <img src="/img/Aaeri.png" alt="Aaeri" />
                      </a>

                      {/* <a
  href="https://www.icef.com/ias-id-code/"
  rel="noreferrer"
  target="_blank"
>
  <img src="/img/ICEF_ACCREDITED.png" alt="ICEF_ACCREDITED" />
</a> */}
                    </div>
                  </div>
                  <p>
                    Facilitating students acquire perfect-fit education
                    placements that ultimately changes their lives!
                  </p>
                  <ul className="flex-box social-list">
                    <li>
                      <a
                        href="https://www.instagram.com/searcheduindia/?hl=en"
                        target="_blank"
                      >
                        <LazyLoadingImages
                          src="/img/instagram.png"
                          alt="instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/searcheduindia"
                        target="_blank"
                      >
                        <LazyLoadingImages
                          src="/img/facebook.png"
                          alt="facebook"
                        />
                      </a>
                    </li>
                    {/* <li>
                      <a href="/">
                        <LazyLoadingImages
                          src="/img/twitter.png"
                          alt="twitter"
                        />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://wa.me/917982467300" target="_blank">
                        <LazyLoadingImages
                          src="/img/whatsapp.png"
                          alt="whatsaap"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://in.linkedin.com/company/search-education"
                        target="_blank"
                      >
                        <LazyLoadingImages
                          src="/img/linkedin.png"
                          alt="linkedin"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="seccol sec-md-col-25"
                onClick={(e) => setFooterSec("01")}
              >
                <div className="footerwidget">
                  <div
                    className={
                      footerSec === "01"
                        ? "footerheading active"
                        : "footerheading"
                    }
                  >
                    <h6>Company</h6>
                  </div>
                  <div
                    className={
                      footerSec === "01" ? "footermenu active" : "footermenu"
                    }
                  >
                    <ul>
                      <li>
                        <a href="/courses">Find A Course</a>
                      </li>
                      <li>
                        <a href="/consult_a_counselor">Consult A Counselor</a>
                      </li>
                      <li>
                        <a href="/career">Career</a>
                      </li>
                      <li>
                        <a href="/services">Our Services</a>
                      </li>
                      <li>
                        <a href="/about_us">About Us</a>
                      </li>
                      <li>
                        <a href="/event_listing">Event</a>
                      </li>
                      <li>
                        <a href="/contactus">Contact Us</a>
                      </li>
                      {/* <li><a href="/">Apply Now</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="seccol sec-md-col-25"
                onClick={(e) => setFooterSec("02")}
              >
                <div className="footerwidget">
                  <div
                    className={
                      footerSec === "02"
                        ? "footerheading active"
                        : "footerheading"
                    }
                  >
                    <h6>Services</h6>
                  </div>
                  <div
                    className={
                      footerSec === "02" ? "footermenu active" : "footermenu"
                    }
                  >
                    <ul>
                      <li>
                        <a href="/student_visa">Student Visa</a>
                      </li>
                      <li>
                        <a href="/employer_visa_482">Employer Visa 482</a>
                      </li>
                      <li>
                        <a href="/family_sponsored_visa">
                          Family Sponsored Visa
                        </a>
                      </li>
                      <li>
                        <a href="/forex">Forex</a>
                      </li>
                      <li>
                        <a href="/get_visa">Get Visa</a>
                      </li>
                      <li>
                        <a href="/housing_partner">Housing Partner</a>
                      </li>
                      <li>
                        <a href="/hdfc_credila_edu_loan">Loans</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="seccol sec-md-col-20"
                onClick={(e) => setFooterSec("03")}
              >
                <div className="footerwidget">
                  <div
                    className={
                      footerSec === "03"
                        ? "footerheading active"
                        : "footerheading"
                    }
                  >
                    <h6>Support</h6>
                  </div>
                  <div
                    className={
                      footerSec === "03" ? "footermenu active" : "footermenu"
                    }
                  >
                    <ul>
                      {/* <li><a href="/">Faq</a></li> */}
                      <li>
                        <a href="/terms_condition">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/sitemap">Site Map</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyrightsite">
            <div className="copy_right">
              <p>
                © {new Date().getFullYear()} SearchEducation.com All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
});

export default Footer;
