import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Aboutvisa = React.memo(function () {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          SbackgroundImage: `url('/img/campaign-creators.png')`,
          backgroundColor: "#0B0B28",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec skilled-work-regional compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper just-center">
            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  Skilled Work Regional Visa
                  <br />
                </span>{" "}
                Subclass 491
              </h2>

              <p>
                The Skilled Work Regional Visa – Subclass 491 is a culmination
                of two visa types, namely
              </p>

              <ul className="skilled-independent-visa-info-list full-width-925">
                <li className="skilled-independent-visa-info-list-item flex-box align-center">
                  <LazyLoadingImages
                    src="/img/check-circle-image.png"
                    alt="student-image"
                  />
                  <span>The Skilled Independent Subclass 189 visa</span>
                </li>
                <li className="skilled-independent-visa-info-list-item flex-box align-center">
                  <LazyLoadingImages
                    src="/img/check-circle-image.png"
                    alt="student-image"
                  />
                  <span>The Skilled or Subclass State Nominated 190 visa</span>
                </li>
              </ul>

              <p>
                This is a temporary visa type that may be converted later on
                into Permanent Residency. It does not require the applicant to
                be sponsored by the employer, however, it does necessitate the
                visa aspirant to agree to live and work in a regional area of
                the country.
              </p>
            </div>

            <div className="about-visa-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/info-leptop.jpg"
                alt="student-image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutvisa;
