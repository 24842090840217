import React from 'react'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Aboutvisa from './about-visa'
import Applyingstuvisa from './applying-stu-visa'
import Docurequired from './docu-required-stu-visa'
import Eligibilitystuvisa from './eligibility-stu-visa'
import Studentvisa from './student-visa'
import Validitystuvisa from './validity-stu-visa'

export default function index() {
    return (
        <>
            <Header />
            <Aboutvisa />
            <Studentvisa />
            <Validitystuvisa />
            <Applyingstuvisa />
            <Docurequired />
            <Eligibilitystuvisa />
            <Footer />
        </>
    )
}
