import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { listPopularUniversity } from "../../actions/universityActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";

const PopularUniversitySec = React.memo(function ({ secName, cntry }) {
  const dispatch = useDispatch();

  // const { country } = useSelector((state) => state.getLocation);

  const univeristyPopularList = useSelector(
    (state) => state.univeristyPopularList
  );
  const { loading, error, universityPData } = univeristyPopularList;

  const getPopularData = () => {
    if (cntry && cntry == "nepal") {
      dispatch(listPopularUniversity("Nepal"));
    } else {
      dispatch(listPopularUniversity("India"));
    }
  };

  useEffect(() => {
    if (!universityPData) {
      getPopularData();
    }
  }, [dispatch, universityPData]);

  var options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="popularuniversitysec compadding pd-bzero">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Our Popular</span>
              <span className="themecolor">{secName} </span>Universities
            </h2>
          </div>
          <div className="popularuniversitysliderpro">
            {universityPData ? (
              <Slider className="popularuniversityslider" {...options}>
                {universityPData.map((rec, index) => (
                  <div className="popularuniversityslide" key={index + 1}>
                    <div className="popularuniversityinnr equal-height">
                      <div className="popularuniversityimg">
                        <LazyLoadingImages
                          src={`${process.env.REACT_APP_SED_DOC_URL}/university/Banner402x300/${rec.Banner402x300}`}
                          alt="University"
                        />
                      </div>
                      <div className="popularuniversitycntnt">
                        <div className="popularuniversitylogo">
                          <LazyLoadingImages
                            src={`${process.env.REACT_APP_SED_DOC_URL}/university/Logo74x74/${rec.Logo74x74}`}
                            alt="University Logo"
                          />
                        </div>
                        <h5>
                          {/* <a
                            href={`/college_detail/${rec.University_Id}`}
                            target="_blank"
                          > */}
                          {rec.University_Name}
                          {/* </a> */}
                        </h5>
                        <p>
                          <LazyLoadingImages
                            src="/img/locationicon.png"
                            alt="Map"
                          />
                          {rec.Country}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div>{error}</div>
            )}
          </div>
        </div>
      </section>
    </>
  );
});

export default PopularUniversitySec;
