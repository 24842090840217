import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { listTrendingCourse } from "../../../actions/courseActions";
import { useEffect } from "react";

export default function TrendingCourse() {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  var dispatch = useDispatch();

  const trendingCourseList = useSelector((state) => state.trendingCourseList);
  const { loading, error, trendingcourses } = trendingCourseList;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const getTrendingCourse = () => {
    if (country) {
      const cntname = country?.country_name;
      dispatch(listTrendingCourse(cntname));
    } else {
      dispatch(listTrendingCourse("India"));
    }
  };

  useEffect(() => {
    if (!trendingcourses) {
      getTrendingCourse();
    }
  }, [dispatch, trendingcourses]);

  return (
    <>
      {trendingcourses ? (
        <Slider
          className="shortlistedcoursesslider trending-coursesslider"
          {...options}
        >
          {trendingcourses.map((trendval, val) => (
            <div className="shortlistedcoursesslide">
              <div className="shortlistedcoursesinner trending-coursesslider equal-height">
                <div className="shortlistedcoursescntnt trending-coursesslider">
                  <div className="degree-rating align-center flex-box">
                    {/* <h5 className="star-rating">
                      <span className="fa fa-star"></span> 4.5
                    </h5> */}
                    <h4>{trendval.Duration} months</h4>
                  </div>
                  <h3>{trendval.Course_Name}</h3>
                  <div className="degree-duration">
                    <h5>{trendval.Study_Level}</h5>
                  </div>
                  <a
                    href={`/course/${trendval.Course_Id}`}
                    className="view-details"
                  >
                    <img src="/img/arrow-white.png" alt="Arrow White" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        ""
      )}
    </>
  );
}
