import React from "react";
import Slider from "react-slick";

const Counsellingsearchcountry = React.memo(
  function Counsellingsearchcountry() {
    const options = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      variableWidth: true,
    };
    return (
      <section className="counselling-searchcountry searchcountrysec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              Search <span className="themecolor">Country</span>
            </h2>
          </div>

          <div className="searchcountrysliderpro">
            <Slider className="searchcountryslider" {...options}>
              <div className="searchcountryslide">
                <a href="/study_in_australia">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country.jpg')` }}
                    ></div>

                    <p>
                      Study in <strong>Australia</strong>
                    </p>
                  </div>
                </a>
              </div>

              <div className="searchcountryslide">
                <a href="/study_in_canada">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country2.jpg')` }}
                    ></div>

                    <p>
                      Study in <strong>Canada</strong>
                    </p>
                  </div>
                </a>
              </div>

              <div className="searchcountryslide">
                <a href="/study_in_uk">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country3.jpg')` }}
                    ></div>

                    <p>
                      Study in <strong>United Kingdom</strong>
                    </p>
                  </div>
                </a>
              </div>

              <div className="searchcountryslide">
                <a href="/study_in_ireland">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country4.jpg')` }}
                    ></div>

                    <p>
                      Study in <strong>Ireland</strong>
                    </p>
                  </div>
                </a>
              </div>

              <div className="searchcountryslide">
                <a href="/study_in_newzealand">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country5.jpg')` }}
                    ></div>

                    <p>
                      Study in <strong>New Zealand</strong>
                    </p>
                  </div>
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
);

export default Counsellingsearchcountry;
