import {
  CONTACT_CREATE_FAIL,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
} from "../constants/contactConstants";
import api2 from "../core/api2";

export const createContact = (contactJson) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_CREATE_REQUEST,
    });

    const { data } = await api2.post(`/student/Details`, contactJson);

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: CONTACT_CREATE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: CONTACT_CREATE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: CONTACT_CREATE_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
