import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

import Applicationprocess from "./application-process";

import Studyincountry from "./study-in-country";
import TrendingCourseSec from "./trendingCourseSec";
import Faqs from "./Faqs";
import SearchCity from "../Home/SearchCity";

export default function index() {
  return (
    <>
      <Header />
      <Studyincountry />
      <TrendingCourseSec />
      <Applicationprocess />
      <section className="searchcountrysec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Most Popular</span>
              <span className="themecolor">Search</span> City
            </h2>
          </div>
          <div className="searchcountrysliderpro">
            <SearchCity removeCity="Adelaide" />
          </div>
        </div>
      </section>
      <Faqs city={"Adelaide"} />
      <Footer />
    </>
  );
}
