import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Eligibilityvisa = React.memo(function Eligibilityvisa() {
  return (
    <section className="eligibility-visa lightgrey family-sponsored-eligibility-491 compadding">
      <div className="container">
        <div className="eligibility-type-heading headingbox">
          <h2>
            <span className="themecolor">Eligibility for</span> 491
          </h2>
        </div>
        <div className="flex-box eligibility-type-wrapper just-center">
          <div className="eligibility-type-list-wrapper eligibility-for-applicant full-width-925">
            <div className="headingbox letter-normal">
              <h2>For the Applicant</h2>
              <p>To get hold of this visa type, the applicant must</p>
            </div>
            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have the required skills to work in an occupation mentioned in
                  the MLTSSL.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>Be between the age of 18 to 45.</h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>Be well versed in English dialect.</h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Be of sound health and possess their character certificates.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have secured the passing marks in the skilled migration points
                  test which is currently 90-95.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have a sponsoring relative living in the regional area of the
                  country. If the relative of the applicant resides in any of
                  the major cities, they cannot become a sponsor.
                </h5>
              </div>
            </div>
          </div>

          <div className="eligibility-type-list-wrapper eligibility-for-sponsor full-width-925">
            <div className="headingbox letter-normal">
              <h2>For the Sponsor</h2>
              <p>
                Any relative of the applicant such as parents, siblings,
                uncle/aunt, etc are eligible to become a sponsor, provided they
                fulfill the following terms
              </p>
            </div>
            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>The relative must be of 18 years or older.</h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  They should be residing in a designated regional area of
                  Australia and not in any of the major cities of the country.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  They must be an Australian citizen, or an Australian permanent
                  resident, or an eligible citizen of New Zealand.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Must be the applicant’s or their partner’s eligible relative.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Eligibilityvisa;
