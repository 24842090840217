import React, { useEffect } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getEventDetail } from "../../actions/eventActions";
import EnquireSec from "./EnquireSec";
import Meta from "../Common/Meta";
import Moment from "moment";
import RegisterInEvent from "./RegisterInEvent";
import RegisterYourself from "./RegisterYourself";
import RelatedEvents from "./RelatedEvents";

const Index = React.memo(function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const cpid = searchParams.get("campaignid")
    ? searchParams.get("campaignid")
    : "0";

  const detailEvent = useSelector((state) => state.detailEvent);
  const { error, loading, eventDet } = detailEvent;

  const getEventDetaili = () => {
    dispatch(getEventDetail(id));
  };

  useEffect(() => {
    if (eventDet === undefined) {
      getEventDetaili();
    }
  }, [dispatch, eventDet]);

  return eventDet ? (
    <>
      {/* <Meta title="" keywords="" description="" /> */}
      <Meta
        title={eventDet[0].EventName}
        keywords="event, events, searcheducation event, search education event, search education events"
        description={eventDet[0].EventDescription}
      />

      <div className="header-white">
        <Header />
      </div>

      <EnquireSec imgSrc={eventDet[0].ImageOfEvent} cpid={cpid} />

      <section className="event-information">
        <div className="container">
          <div className="event-detail-main-heading flex-box align-center">
            <div className="event-detail-main-heading-left">
              <div className="orgnizer">
                <span>Organizer</span>&nbsp;&nbsp;
                <h4>Search Education, {eventDet[0].Branch}</h4>
              </div>
              <h1>
                <strong>{eventDet[0].EventName}</strong>
              </h1>
            </div>

            {/* <div className="event-join-cta hide-601">
              <a href="/" className="btn">
                Register for Event
              </a>
            </div> */}
          </div>

          <div className="event-info-wrapper flex-box">
            <div className="event-info-box flex-box">
              <div className="event-icon">
                <LazyLoadingImages
                  src="/img/event-calander.png"
                  alt="student-image"
                />
              </div>

              <div className="event-content">
                <h6>Event Date &amp; Time</h6>
                <h3>
                  {Moment(eventDet[0].StartDate).format("MMM DD, YYYY")}{" "}
                  {eventDet[0].StartTime}
                </h3>
              </div>
            </div>

            <div className="event-info-box flex-box">
              <div className="event-icon">
                <LazyLoadingImages
                  src="/img/event-video.png"
                  alt="student-image"
                />
              </div>

              <div className="event-content">
                <h6>Event Mode</h6>
                <h3>
                  {eventDet[0].ModeEvent === "Online"
                    ? eventDet[0].ModeEvent
                    : "Offline"}
                </h3>
              </div>
            </div>

            <div className="event-info-box flex-box">
              <div className="event-icon">
                <LazyLoadingImages
                  src="/img/event-location.png"
                  alt="student-image"
                />
              </div>

              <div className="event-content">
                <h6>Event Location</h6>
                <h3>
                  {eventDet[0].ModeEvent === "Online"
                    ? eventDet[0].MediumName
                    : eventDet[0].Branch_Address}
                </h3>
              </div>
            </div>
          </div>

          <div className="event-join-cta show-601">
            <a href="#registerYourSelfSection" className="btn">
              Register Now
            </a>
          </div>
        </div>
      </section>

      <section className="listing-details-sec event-detail-sec pd-bzero">
        <div className="container">
          <div className="flex-box listing-details-wrapper">
            <div className="listing-details-left-box event-listing">
              <ul className="listing-details-leftbar event-details-leftbar sticky">
                <li id="about-event-link" data-link="about-event">
                  <a>About Event</a>
                </li>

                {/* <li id="key-speakers-link" data-link="key-speakers">
                  <a>Key Speakers</a>
                </li>

                <li id="agenda-link" data-link="agenda">
                  <a>Agenda</a>
                </li>

                <li id="register-now-link" data-link="register-now">
                  <a>Register Now</a>
                </li> */}
              </ul>
            </div>

            <div className="listing-details-info event-detail-info">
              <div className="listing-details-info-box" id="about-event">
                <div className="list-details-heading">
                  <h2>
                    <strong>Event</strong> Overview
                  </h2>
                </div>

                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDet[0].EventDescription,
                    }}
                  ></div>
                </p>

                {/* <p>
                  Digital marketing skills are critical not only for digital
                  marketing professionals but for anyone working in a business
                  with an online presence. This includes entrepreneurs,
                  marketing managers, marketing specialists, and small business
                  owners. The course is hugely interactive with projects and
                  checklists.
                </p>

                <div className="detail-info-list-wrapper event-info-list">
                  <h3>Our Agenda</h3>

                  <ul className="detail-info-list">
                    <li>
                      Grow a business online from scratch & Make money as an
                      affiliate marketer
                    </li>

                    <li>Land a high paying job in digital marketing</li>

                    <li>Work from home as a freelance marketer</li>
                  </ul>
                </div>

                <div className="detail-info-list-wrapper event-info-list">
                  <h3>You will learn:</h3>

                  <ul className="detail-info-list">
                    <li>
                      Market Research, WordPress Setup, SEO, SEO Writing,
                      YouTube Marketing
                    </li>

                    <li>
                      Google Ads, LinkedIn Marketing, App Marketing, Email
                      Marketing
                    </li>

                    <li>
                      Google Analytics, Social Media Marketing, Facebook Ads
                    </li>
                  </ul>
                </div> */}
              </div>

              {/* <div className="listing-details-info-box" id="key-speakers">
                <div className="list-details-heading">
                  <h2>
                    <strong>Key</strong> Speakers
                  </h2>
                </div>

                <p>
                  This includes entrepreneurs, marketing managers, marketing
                  specialists, and small business owners. The course is hugely
                  interactive with projects and checklists.
                </p>

                <div className="key-speakers-wrapper flex-box">
                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/mali-baum.jpg"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Mali Baum</h3>

                      <h5>CEO, WLOUNGE</h5>
                    </div>
                  </div>

                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/saalim-chowdhury.jpg"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Saalim Chowdhury</h3>

                      <h5>Managing Director, London</h5>
                    </div>
                  </div>

                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/gaelle-walrave.png"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Gaelle Walrave</h3>

                      <h5>Executive in Residence, Berlin</h5>
                    </div>
                  </div>

                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/martin-schilling.png"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Martin Schilling</h3>

                      <h5>Managing Director, Berlin</h5>
                    </div>
                  </div>

                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/joao-nunes.png"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Joao Nunes</h3>

                      <h5>Techstars Startup Scout EMEA</h5>
                    </div>
                  </div>

                  <div className="key-speakers-box flex-box align-center">
                    <div className="speakers-image">
                      <LazyLoadingImages
                        src="/img/sebastien-louit.png"
                        alt="student-image"
                      />
                    </div>

                    <div className="speakers-info">
                      <h3>Sebastien Louit</h3>

                      <h5>CEO, Mondaycar (Techstars 19')</h5>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="listing-details-info-box universityofthemonth bg-style flex-box align-center"
                id="agenda"
                style={{
                  backgroundImage: `url('/img/universityofthemonth.png')`,
                  backgroundColor: `#25253F`,
                  borderRadius: `10px`,
                }}
              >
                <div className="universityofthemonth-content-left-box">
                  <h4>
                    University Of The Month: <strong>Cornell New York</strong>
                  </h4>
                </div>

                <div className="universityofthemonth-cta">
                  <a href="/" className="btn">
                    Know more
                  </a>
                </div>
              </div> */}
            </div>

            <RegisterInEvent cpid={cpid} />
          </div>
        </div>
      </section>

      <RegisterYourself cpid={cpid} />

      <section className="address-details">
        <div className="container">
          <div className="address-details-wrapper border-style flex-box width-81">
            <div className="map-box">
              {/* <LazyLoadingImages src="/img/ma-bg.jpg" alt="student-image" /> */}
              <iframe
                src={eventDet[0].GoogleMapLink}
                width="670"
                height="336"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="address-details-info-main-box event-info-wrapper">
              <div className="event-info-box flex-box">
                <div className="event-icon">
                  <LazyLoadingImages
                    src="/img/event-calander.png"
                    alt="student-image"
                  />
                </div>

                <div className="event-content">
                  <h6>Event Date &amp; Time</h6>

                  <h3>
                    {Moment(eventDet[0].StartDate).format("MMM DD, YYYY")}{" "}
                    {eventDet[0].StartTime}
                  </h3>
                </div>
              </div>

              <div className="event-info-box flex-box">
                <div className="event-icon">
                  <LazyLoadingImages
                    src="/img/event-video.png"
                    alt="student-image"
                  />
                </div>

                <div className="event-content">
                  <h6>Event Mode</h6>
                  <h3>
                    {eventDet[0].ModeEvent === "Online"
                      ? eventDet[0].ModeEvent
                      : "Offline"}
                  </h3>
                </div>
              </div>

              <div className="event-info-box flex-box">
                <div className="event-icon">
                  <LazyLoadingImages
                    src="/img/event-location.png"
                    alt="student-image"
                  />
                </div>

                <div className="event-content">
                  <h6>Event Location</h6>

                  <h3>
                    {eventDet[0].ModeEvent === "Online"
                      ? eventDet[0].MediumName
                      : eventDet[0].Branch_Address}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <RelatedEvents /> */}
      <Footer />
    </>
  ) : (
    ""
  );
});

export default Index;
