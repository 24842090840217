import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";
const Ptecounsellingsec = React.memo(function () {
  return (
    <section
      className="pte-counselling-sec efficient-signup bg-style"
      style={{
        backgroundImage: `url('/img/making-notes.png')`,
        backgroundColor: "#25253F",
      }}
    >
      <div className="container">
        <div className="efficient-signup-wrapper">
          <div className="headingbox text-white">
            <h5>
              <span className="themecolor">Counselling</span>
            </h5>
            <h2>
              One To One
              <br /> Counselling Sessions
            </h2>
          </div>
          <div className="pte-counselling-sessions efficient-signup-featured flex-box space-between">
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="icon"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>
                  Comprehensive
                  <br /> <strong>analytics tool</strong>
                </h5>
              </div>
            </div>
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="icon"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>
                  Increase your
                  <br /> <strong>PTE band score</strong>
                </h5>
              </div>
            </div>
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="icon"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>
                  Take recent
                  <br /> actual <strong>PTE Tests</strong>
                </h5>
              </div>
            </div>
          </div>
          <div className="efficient-signup-featured-button">
            <a href="/consult_a_counselor" className="btn">
              Free Counselling
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Ptecounsellingsec;
