import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser, registerVerifyUser } from "../../actions/userActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import {
  USER_REGISTER_RESET,
  USER_REGISTER_VERIFY_RESET,
} from "../../constants/userConstants";
import { contactRegisterJson } from "../../models/userModel";

const SignupPopup = React.memo(function SignupPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);

  const [countryName, setcountryName] = useState("");

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const [registerJson, setRegisterJson] = useState({
    ...contactRegisterJson,
    Links: window.location.href,
    campaignId: "",
    medium: "wb",
  });

  const [email, setemail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [otp, setOTP] = useState("");

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, success, error } = userRegister;

  const userRegisterVerify = useSelector((state) => state.userRegisterVerify);
  const {
    loading: loadingVerify,
    success: successVerify,
    error: errorVerify,
  } = userRegisterVerify;

  const handleSignUp2 = (e) => {
    e.preventDefault();

    const otpJson = {
      Email: email,
      OTP: otp,
    };
    dispatch(registerVerifyUser(otpJson));
  };

  const handleonNumChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length < 7) {
        setOTP(e.target.value);
      }
    }
  };

  const closeAllPopup = (e) => {
    e.preventDefault();
    setshow1(false);
    setshow2(false);
    props.handleClose();
  };

  // const openLogin = (e) => {
  //   e.preventDefault();
  //   setshow2(false);
  //   props.handleSignIn();
  // };

  const handleData = (e) => {
    e.preventDefault();
    setRegisterJson({
      ...registerJson,
      [e.target.name]: e.target.value,
      Comments:
        "Student Registered using Website. From Location - " +
        country?.country_name,
    });
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    var verified = true;
    if (registerJson.Email == "") verified = false;
    if (registerJson.Phone_No == "") verified = false;
    if (registerJson.full_name == "") verified = false;
    if (registerJson.Password == "") verified = false;

    // If all the fields are inserted.
    if (verified) {
      // Validating the Password and confirm password.
      if (registerJson.Password === confirmPassword) {
        dispatch(registerUser(registerJson));
      } else {
        alert("Password and Confirm Password should be same!");
      }
    } else {
      alert("All Fields are Cumpulsory!");
    }
  };

  useEffect(() => {
    if (success == undefined || success == "") {
    } else {
      setemail(registerJson.Email);
      setRegisterJson({ ...contactRegisterJson });
      setshow1(true);
      setshow2(false);
      props.handleClose();
      setTimeout(() => {
        dispatch({
          type: USER_REGISTER_RESET,
        });
      }, 1000);
    }
    if (successVerify == undefined || successVerify == "") {
    } else {
      setOTP("");
      setshow1(false);
      setshow2(true);
      setTimeout(() => {
        dispatch({
          type: USER_REGISTER_VERIFY_RESET,
        });
      }, 1000);
    }
  }, [dispatch, success, successVerify]);

  return (
    <>
      <div
        className={
          props.show
            ? "signnup-process-modal is-visible"
            : "signnup-process-modal"
        }
        id="process-one"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner flex-box">
            <div className="process-modal-image-box-main full-width-925 hide-925">
              <div className="process-modal-image-box flex-box space-between align-center">
                <div className="process-modal-image-content">
                  <LazyLoadingImages src="/img/small-logo.png" alt="student" />
                  <h3>It's Time To Search your Dream Course</h3>
                  <a className="modal-signnup" onClick={props.handleSignIn}>
                    Sign In
                  </a>
                </div>
                <div className="process-modal-image">
                  <LazyLoadingImages
                    src="/img/laughing-girl.png"
                    alt="student"
                  />
                </div>
              </div>
              <div className="university-info-list flex-box space-between">
                <div className="university-info-list-item">
                  <h4>19,000 +</h4>
                  <p>
                    <strong>Success</strong> Stories
                  </p>
                </div>
                <div className="university-info-list-item">
                  <h4>2,000 +</h4>
                  <p>
                    <strong>Helped</strong> Students
                  </p>
                </div>
                <div className="university-info-list-item">
                  <h4>8,000 +</h4>
                  <p>
                    <strong>Educated</strong> Teacher
                  </p>
                </div>
              </div>
            </div>
            <div className="process-modal-inner-info full-width-925">
              <span className="close-modal" onClick={props.handleClose}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <h4>Create Account</h4>
              <form className="create-acco-form">
                <div className="create-acco-form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="full_name"
                    onChange={(e) => handleData(e)}
                    placeholder="Enter Your Name"
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="Email"
                    onChange={(e) => handleData(e)}
                    placeholder="Email"
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="PhoneNumber"
                    name="Phone_No"
                    onChange={(e) => handleData(e)}
                    placeholder="Phone Number"
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="Password"
                    name="Password"
                    onChange={(e) => handleData(e)}
                    placeholder="Password"
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="ConfirmPassword"
                    name="ConfirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="create-acco-form-group">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-submit verification-popup"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-submit verification-popup"
                      onClick={handleSignUp}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div>
                  <span className="sedtext-danger">{error && error}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          show1 ? "signnup-process-modal is-visible" : "signnup-process-modal"
        }
        id="process-two"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner flex-box">
            <div className="process-modal-image-box-main full-width-925 hide-925">
              <div className="process-modal-image-box flex-box space-between align-center">
                <div className="process-modal-image-content">
                  <LazyLoadingImages src="/img/small-logo.png" alt="student" />
                  <h3>It's Time To Search your Dream Course</h3>
                  <a className="modal-signnup" onClick={props.handleSignIn}>
                    Sign In
                  </a>
                </div>
                <div className="process-modal-image">
                  <LazyLoadingImages
                    src="/img/smily-boy-with-book.png"
                    alt="student"
                  />
                </div>
              </div>
              <div className="university-info-list flex-box space-between">
                <div className="university-info-list-item">
                  <h4>19,000 +</h4>
                  <p>
                    <strong>Success</strong> Stories
                  </p>
                </div>
                <div className="university-info-list-item">
                  <h4>2,000 +</h4>
                  <p>
                    <strong>Helped</strong> Students
                  </p>
                </div>
                <div className="university-info-list-item">
                  <h4>8,000 +</h4>
                  <p>
                    <strong>Educated</strong> Teacher
                  </p>
                </div>
              </div>
            </div>
            <div className="process-modal-inner-info full-width-925">
              <span className="close-modal " onClick={closeAllPopup}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <div className="verification text-box">
                <h4>
                  Verification OTP has been sent to your Email
                  <span>Note: OTP is valid for 15 Minutes only!</span>
                </h4>
              </div>
              <h4>Verify Your Account</h4>
              <form className="create-acco-form">
                <div className="create-acco-form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="verifyemail"
                    name="verifyemail"
                    value={email}
                    disabled
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={otp}
                    onChange={(e) => handleonNumChange(e)}
                    placeholder="Enter OTP"
                  />
                </div>
                <div className="create-acco-form-group">
                  {loadingVerify ? (
                    <button
                      type="button"
                      className="btn btn-submit Success-popup"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-submit Success-popup"
                      onClick={handleSignUp2}
                    >
                      Verify
                    </button>
                  )}
                </div>
                <div>
                  <span className="sedtext-danger">
                    {errorVerify && errorVerify}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          show2 ? "signnup-process-modal is-visible" : "signnup-process-modal"
        }
        id="process-three"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner">
            <div className="process-successfully text-center flex-box">
              <span className="close-modal" onClick={closeAllPopup}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <div className="process-successfully-content text-box">
                <LazyLoadingImages src="/img/tick.png" alt="student" />
                <h3>
                  Congratulations, Your Account Has Been Successfully Created.
                  Please
                  <span style={{ color: "black", textDecoration: "underline" }}>
                    Sign in
                  </span>
                  to complete your profile.
                </h3>
                <span className="modal-btn-group">
                  <a className="btn" onClick={closeAllPopup}>
                    Close
                  </a>
                  <a href="/courses" className="btn">
                    Browse Courses
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SignupPopup;
