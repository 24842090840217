import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import BannerForm from "../Common/BannerForm";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import { useState } from "react";
import Meta from "../../components/Meta";

const Index = React.memo(function () {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const metaData = {
    title: "Study in Australia | Free Counselling – Search Education",
    mtitle: "Study in Australia | Free Counselling – Search Education",
    mdescription:
      "Exploring Study Options in Australia? We have got you covered. Find Your Ideal Study Program Today and Elevate Your Career!",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Study in Australia | Free Counselling – Search Education",
    ogdescription:
      "Exploring Study Options in Australia? We have got you covered. Find Your Ideal Study Program Today and Elevate Your Career! ",
    ogimage: "https://www.searcheducation.com/img/img_25.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle: "Study in Australia | Free Counselling – Search Education",
    twitterDescription:
      "Exploring Study Options in Australia? We have got you covered. Find Your Ideal Study Program Today and Elevate Your Career!",
    twitterImage: "https://www.searcheducation.com/img/img_25.jpg",
  };
  return (
    <>
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <Meta metaData={metaData} />
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/aust-banner.jpg')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Australia</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>

              <div className="canada-university-info-list flex-box">
                <div className="canada-university-info-list-item">
                  <h4>448,274+</h4>
                  <p>International Students</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>21,041 AUD</h4>
                  <p>Avg Living costs/pa</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>43+</h4>
                  <p>Universities</p>
                </div>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>

      <section className="about-study-canada compadding">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center">
            <h2>
              <span className="themecolor">Prime and Prominent facts</span> of
              Australia
            </h2>
            <p>
              Australia is renowned for offering the best education with a focus
              on employable skills and research-based learning. The nation is
              home to a number of highly regarded universities and institutions
              that provide a wide range of courses and programs.
            </p>
          </div>
          <div className="flex-box why-study-canada-wrapper align-center just-center">
            <div className="why-study-canada-content full-width-925">
              <div className="headingbox letter-normal mb-0">
                <h2>
                  <span className="themecolor">Why Study In</span> Australia?
                </h2>
                <p>
                  Apart from Australia having some of the world's greatest
                  universities and educational institutions, which provides
                  high-quality education with a strong emphasis on research and
                  practical skill development. International students choose to
                  study here for a variety of reasons.
                </p>
              </div>
              <ul className="study-country-details-list">
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Canberra, not Sydney, is Australia's capital, and it has
                    English as its primary language. This country appeals to
                    international students because of its cultural diversity,
                    friendliness, and good quality education.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    The government is committed to education and invests
                    millions of dollars in scholarships, grants, and bursaries
                    for deserving students with lists of names of the
                    institutions where foreign and local students can study.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    There are a total of 1100 institutions in the country, with
                    43 universities. In the previous four years, the number of
                    Indian students studying abroad has more than doubled, from
                    15800 in 2015 to 35400 in 2019.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Information Technology, Business Management, Engineering,
                    Architecture, Hospitality and Tourism, and Accounting and
                    Finance are some of the most well-known UG and PG courses.
                    Ideally, a wide range of STEM and non-STEM programs, as well
                    as vocational courses in a variety of fields, are available
                    here.
                  </p>
                </li>
              </ul>
            </div>
            <div className="why-study-canada-image-group flex-box full-width-925">
              <div className="why-study-canada-image-leftbox">
                <div
                  className="why-study-canada-image-first bg-style"
                  style={{
                    backgroundImage: `url('/img/study-australia-1.jpg')`,
                  }}
                ></div>
              </div>
              <div className="why-study-canada-image-rightbox">
                <div
                  className="why-study-canada-image-rightfirst bg-style"
                  style={{ backgroundImage: `url('/img/australia-flag.png')` }}
                ></div>
                <div
                  className="why-study-canada-image-rightsecond bg-style"
                  style={{
                    backgroundImage: `url('/img/study-australia-2.jpg')`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="visa-of-country compadding lightgrey immigrat-study-in-australia">
        <div className="container">
          <div className="flex-box visa-of-country-wrapper just-center">
            <div className="visa-of-country-image full-width-925">
              <LazyLoadingImages src="/img/why-study-of-immigratig-australia.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <h2>
                <span className="themecolor">Why study in</span> Of Immigrating
                Australia?
              </h2>
              <p>
                When applying for education at an international university, the
                most crucial factor to consider is the institution's post-study
                career potential. Australia satisfies this basic requirement,
                and there are many more compelling reasons to visit this
                stunning country. With its well-organized and well-regarded
                educational system, nice people, proximity to nature, and
                diversity, it has always been a desirable destination for many.
                <span className="following-info">
                  Here are some common benefits one gets in Australia:
                </span>
              </p>
              <ul className="study-country-details-list">
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Recognised as the top rated education system in the world
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Highly organised, controlled and protected education and
                    political environment by Government
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    People friendly environment, especially conducive for
                    students
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>Proximity to nature</p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Advanced technology environment in all sectors leading to
                    ultimate development of the country
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Australian Dollar is the fifth most traded currency in the
                    forex market due to its excellent stability and
                    diversification benefits
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>Culturally diverse and adaptable population</p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>Decent cost of education, accommodation and travel</p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    Internationally acknowledged programs that allows a student
                    to move globally for work opportunities
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    As per the QS world rankings, Australia stands with 7 out of
                    43 universities as the top 100 universities in the world.
                  </p>
                </li>
                <li className="study-country-details-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <p>
                    English is the first language i.e. national language, that
                    allows students studying in this language at other
                    destinations to travel, study and stay without much
                    adjustments
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="process-immigrating-canada compadding">
        <div className="container">
          <div className="flex-box process-immigrating-wrapper just-center">
            <div className="process-immigrating-content headingbox mb-0 letter-normal full-width-925">
              <h2>
                <span className="themecolor">Popular courses to</span>
                <br />
                study in Australia
              </h2>
              <p>
                The Australian education system offers higher education,
                vocational education and training, schools, English language
                programs, and non-awarding courses. Let's see how they differ
                from one another:
              </p>
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Undergraduate and postgraduate degree/certificate programs
                    make up higher education.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    VET refers to government-industry collaboration programs
                    that allow students to work and learn alongside industry
                    experts at TAFE and private schools.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    English language programs are available to students who wish
                    to begin their studies in Australia.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Pre-university courses that help you prepare for university
                    are known as foundation programs.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Lower-level study programs are available in Australian
                    schools.
                  </span>
                </li>
              </ul>
            </div>
            <div className="process-immigrating-image full-width-925">
              <LazyLoadingImages src="/img/Popular-courses-to-study-in-Australia2.jpg" />
            </div>
          </div>
          <div className="study-courses-tags-list-wrapper headingbox letter-normal mb-0">
            <p>
              The following are some of the most common streams among
              international students studying in Australia:
            </p>
            <ul className="flex-box study-courses-tags-list">
              <li className="study-courses-tags-list-item">
                Information and Communication Technology
              </li>
              <li className="study-courses-tags-list-item">Health</li>
              <li className="study-courses-tags-list-item">Engineering</li>
              <li className="study-courses-tags-list-item">
                Culture and Society
              </li>
              <li className="study-courses-tags-list-item">
                Physical and natural sciences
              </li>
              <li className="study-courses-tags-list-item">
                Business, Management, and Commerce
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="services compadding top-places-canada lightgrey">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center mb-zero">
            <h2>
              <span className="themecolor">Top Places</span> To Study In
              Australia
            </h2>
            <p>
              Aside from wishing to attend your preferred university, you may be
              interested in knowing about Australia's best student cities. The
              most well-known cities for students are Melbourne, Sydney,
              Brisbane, and Perth.
            </p>
          </div>

          <div className="services-list-wrapper flex-box">
            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Melbourne-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Melbourne</h3>

                <p>
                  Melbourne is well-known for its thriving arts and culture
                  scene, varied population, and world-class universities. It is
                  also routinely regarded as one of the world's most livable
                  cities.
                </p>

                {/* <!-- <p className="moretext"> Hence, we assist students building their profiles based on their capabilities and interests. And we find an university and a course corresponding to the profile. The entire process begins with reviewing the student’s academic performance. This helps us navigate the possible career types that offers the best chances of admission. We give special emphasis on interacting with the students to understand their interests. This insight is then used to narrow down the choices that may be suitable for her / him. In case the student has some predetermined choices, we will include at the initial profiling stage. Yet, we will share the final decision for the most suitable career option only after careful evaluation and consideration. This would be done over the call or in-person meetings.</p> --> */}

                <a href="/study_in_melbourne" className="btn moreless-button">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{ backgroundImage: `url('/img/Sydney-520-x-600.jpg')` }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Sydney</h3>

                <p>
                  Sydney is the largest and most cosmopolitan city in Australia.
                  It is home to some of Australia's best universities, as well
                  as gorgeous beaches and renowned monuments such as the Sydney
                  Opera House and Harbour Bridge.
                </p>

                <a href="/study_in_sydney" className="btn">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Brisbane-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Brisbane</h3>

                <p>
                  Brisbane is famous for its mild weather, friendly locals, and
                  good universities. It's also an excellent starting point for
                  seeing neighbouring natural wonders like the excellent Barrier
                  Reef and the Gold Coast.
                </p>

                <a href="/study_in_brisbane" className="btn">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Perth-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Perth</h3>

                <p>
                  Perth is the capital city of Western Australia and is
                  recognised for its relaxed lifestyle, beautiful beaches, and
                  natural beauty. It is home to several prestigious colleges and
                  is an excellent choice for those interested in marine biology
                  or environmental science.
                </p>

                <a href="/study_in_perth" className="btn">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/Hobart-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Hobart</h3>

                <p>
                  Hobart is Australia’s second oldest capital city after Sydney
                  and is in the heart of Tasmania state. It has a mild and
                  temperate climate though which is a welcome relief. It’s tough
                  life conditions made it a perfect place as a penal colony.
                </p>

                <a href="/study_in_hobart" className="btn">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/darwin-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Darwin</h3>

                <p>
                  Darwin lies in the northern territory of Australia. And like
                  other coastal areas it has backwaters, waterfronts, reefs and
                  coastlines throughout. Also unique to Darwin is the street art
                  which is all over the walls especially in the suburbs. Unlike
                  the bigger cities, Darwin city is cheaper to live, visit or
                  work in.
                </p>

                <a href="/study_in_darwin" className="btn">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/adelaide-520-x-600.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Adelaide</h3>

                <p>
                  Adelaide is an excellent city to live in and it has the lowest
                  cost of living among the major Australian cities. With a
                  population of only 1.5 million, Adelaide is a very clean city
                  and a safe place to stay.
                </p>

                <a href="/study_in_adelaide" className="btn">
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="compadding">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/visa-for-studying-in-australia.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Visa for studying in</span>{" "}
                Australia
              </h2>
              <p>
                Before applying for a student visa in Australia, there are a few
                requirements that must be met. Proof of finances is required, as
                is proof of health insurance in the form of an Overseas health
                insurance receipt.
              </p>
              <p>
                Acceptance Letter from the institution with a proposed study
                plan, Confirmation of Enrolment Form (COE) from the institution
                after tuition fees have been received, Application fee payment
                receipt, Nonimmigrant Visa Application, Letter from the last
                studied institution, and 2-4 passport-sized photos are required
                to be submitted with this valid passport.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925">
              <LazyLoadingImages src="/img/admission-intake.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Admission</span> Intake
              </h2>
              <p>
                In most cases, there are two intakes in February and July, with
                students having until December and May, respectively, to submit
                their applications. In September and November, however, several
                universities have triple and double intakes.
              </p>
              <p>
                To apply to these universities, a student should start planning
                at least 6 months before the deadline. In this country, a
                student with a well-constructed profile can easily obtain an
                offer letter. The application process takes a total of 3-6
                months. For vocational courses, the same duration and
                application process as for general education apply.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="application-process compadding lightgrey">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/career-counseling.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/best-college.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/offer-letter.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/approverd-visa.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="scholarship-in-canada flex-box align-center compadding align-center bg-style"
        style={{
          backgroundImage: `url('/img/why-study-in-australia.jpg')`,
          backgroundColor: "#191915",
        }}
      >
        <div className="container">
          <div className="flex-box scholarship-canada-wrapper just-center">
            <div className="scholarship-canada-description headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Why Study In</span> Australia?
              </h2>
              <p>
                The country is home to various prestigious universities and
                institutes that provide a diverse range of courses and programs.
                Furthermore, Australian degrees are recognised and respected
                around the world, making graduates in great demand by
                businesses. Australia is also a multicultural country with a
                diversified population, providing international students with
                the opportunity to learn and experience different cultures. With
                stunning cities and natural wonders abound in Australia, offers
                students with a one-of-a-kind and interesting study abroad
                experience. Furthermore, studying in Australia is inexpensive,
                and students can take advantage of a variety of scholarships and
                financial aid programs. Some of the most well-known
                government-funded scholarships for Indian students studying in
                Australia are as follows:
              </p>
            </div>
            <ul className="scholarship-details full-width-925">
              <li className="scholarship-details-info">
                Endeavour Postgraduate Scholarship Award
              </li>
              <li className="scholarship-details-info">
                Research Training Program (RTP) Scholarship
              </li>
              <li className="scholarship-details-info">
                International Postgraduate Research Scholarship (IPRS)
              </li>
              <li className="scholarship-details-info">
                Australia-India Strategic Research Fund (AISRF)
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="trendingcoursesec compadding lightgrey">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Explore Our</span>
              <span className="themecolor">Top</span> Courses
            </h2>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                What is the cost of an Australian student visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  The application fee for a student visa is 620 Australian
                  dollars (around 35,000 INR)
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                What are the documents required for Australian visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  The following documents must be submitted by international
                  applicants:
                </p>
                <ul>
                  <li>Identity Verification</li>
                  <li>CoE, acceptance letter, and financial support</li>
                  <li>
                    62,222 AUD as proof of getting enough money to stay (for
                    singles)
                  </li>
                  <li>
                    Demonstrate that you have legitimate access to the funds.
                  </li>
                  <li>
                    If applicable, proof that you are excluded from paying the
                    visa application fee.
                  </li>
                  <li>OSHC-related proof</li>
                  <li>Documents from the GTE</li>
                  <li>
                    Language documents- Scores of exams required to study in
                    Australia
                  </li>
                  <li>Partner documents, if valid.</li>
                  <li>Documents for dependents and children, if applicable.</li>
                </ul>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                What jobs are available after studying in Australia?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  You qualify as an attractive employee to employers all over
                  the world after completing your studies in Australia. You will
                  be eligible for an Australian work visa subclass 485, if you
                  have completed your bachelor's or master's degree in Australia
                  and expect to stay and work there. Before beginning their
                  careers in Australia, foreign students must obtain a work
                  visa. About 7500 AUD is the average monthly salary in
                  Australia. Jobs for MBA graduates in Australia rank first
                  among the highest-paying industries, with an average annual
                  salary of 82,000 AUD.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                What is the cost of studying in Australia?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  International students who wish to study in countries such as
                  Australia can expect to spend a significant amount of money on
                  tuition, applications, housing, living in Australia, and other
                  expenses. The cost of obtaining a particular degree can vary
                  depending on the university, level of study, and programme of
                  choice. The cost for pursuing some of the popular courses in
                  Australia include-
                </p>
                <ul>
                  <li>MBA in Australia: 54,000 AUD to 86,000 AUD</li>
                  <li>
                    MS in Business Analytics in Australia: 40,000 to 77,000 AUD
                  </li>
                  <li>MS in Australia: 20,000 AUD to 50,000 AUD</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
