import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCountry } from "../../actions/countryActions";

const CountrySelect = React.memo(function CountrySelect(props) {
  const dispatch = useDispatch();

  const countryList = useSelector((state) => state.countryList);
  const { loading, error, countryData } = countryList;

  const getCountryList = () => {
    dispatch(listCountry());
  };

  useEffect(() => {
    if (!countryData) {
      getCountryList();
    }
  }, [dispatch, countryData]);

  return (
    <>
      {countryData ? (
        countryData.map((lis) => (
          <option key={lis.Id} value={lis.Id} class="tx-uppercase">
            {lis.Country}
          </option>
        ))
      ) : (
        <option>Loading...</option>
      )}
    </>
  );
});

export default CountrySelect;
