import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCounsellorUser } from "../../../actions/userActions";
import { USER_COUNSELLOR_RESET } from "../../../constants/userConstants";

export default function CounsellorSection() {
  const dispatch = useDispatch();

  const userCounsellor = useSelector((state) => state.userCounsellor);
  const { loading, error, counsellor } = userCounsellor;

  const getCounsellorDetails = () => {
    dispatch(getCounsellorUser());
  };

  useEffect(() => {
    if (!counsellor) {
      getCounsellorDetails();
    }
  }, [dispatch, counsellor, loading, error]);

  return (
    <>
      {counsellor ? (
        <div className="assign-mentor">
          <h3>Assigned Counsellor</h3>
          <div className="assign-mentor-box flex-box">
            <div
              className="assign-mentor-image"
              style={{ marginRight: "-45px" }}
            >
              <img src="/img/counsellor-icon.jpg" alt="Empty" />
            </div>
            <div className="assign-content">
              <h5>{counsellor.Cousellor_Name}</h5>
              <a href={counsellor.Counsellor_No} className="assign-contentinfo">
                {counsellor.Counsellor_No}
              </a>
              <a href="mailto:" className="assign-contentinfo">
                {counsellor.Email_Address}
              </a>
              <a
                href="mailto:ment@searcheducation.com"
                className="assign-mentor-arrow"
              >
                <img src="/img/arrow-small-orange.png" alt="arrow small" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
