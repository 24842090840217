import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listEnglishTestAttempted } from "../actions/englishTestAttemptedActions";
export default function EnglishTestAttempted() {
  const dispatch = useDispatch();
  const { loading, error, englishtests } = useSelector(
    (s) => s.englishTestAttemptedList
  );
  const getenglishTests = () => {
    dispatch(listEnglishTestAttempted());
  };
  useEffect(() => {
    if (!englishtests) {
      getenglishTests();
    }
  }, [dispatch, englishtests]);
  return englishtests
    ? englishtests.length > 0
      ? englishtests.map((engl, index) => (
          <option key={index + 1} value={engl.Id}>
            {engl.English_Test_Attempted}
          </option>
        ))
      : "No Record Exist"
    : error;
}
