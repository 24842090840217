import React from 'react'

export default function Adcomparesticky() {
  return (
    <div>
        <section className="ad-compare-sticky-sec sticky">
        <div className="container">
          <div className="ad-compare-sticky-sec-inner">
            <div className="ad-compare-wrapper flex-box align-center">
              <div className="ad-compare-box-wrapper">
                <div className="ad-compare-box text-center">
                  <div className="ad-compare-detail">
                    <span className="close close-ad-compare-detail">
                      <img src="/img/close.png" alt="dashboard" />
                    </span>
                    <div className="compare-logo">
                      <img src="/img/universitylogo2.png" alt="dashboard" />
                    </div>
                    <div className="ad-compare-detail-info">
                      <p className="location">
                        <img src="/img/locationicon.png" alt="dashboard" /> Vrije Universiteit Brussel, Belgium
                      </p>
                      <h4>Business Administration & Management - BA</h4>
                    </div>
                  </div>
                </div>
                <div className="ad-compare-box text-center">
                  <div className="ad-compare-detail">
                    <span className="close close-ad-compare-detail">
                      <img src="/img/close.png" alt="dashboard" />
                    </span>
                    <div className="compare-logo">
                      <img src="/img/universitylogo3.png" alt="dashboard" />
                    </div>
                    <div className="ad-compare-detail-info">
                      <p className="location">
                        <img src="/img/locationicon.png" alt="dashboard" /> Vrije Universiteit Brussel, Belgium
                      </p>
                      <h4>Business Administration & Management - BA</h4>
                    </div>
                  </div>
                </div>
                <div className="ad-compare-box text-center">
                  <div className="add-compare-course">
                    <img src="/img/add-courses-icon.png" alt="dashboard" />
                  </div>
                </div>
                <div className="ad-compare-box text-center">
                  <div className="add-compare-course">
                    <img src="/img/add-courses-icon.png" alt="dashboard" />
                  </div>
                </div>
              </div>
              <div className="compare-btn-group">
                <a href="/" className="btn">Compare</a>
                <a href="/" className="btn whitebtn compare-clear-cta">Clear</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
