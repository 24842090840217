import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppliedCourseTotal } from "../../../actions/courseActions";

export default function OngoingApplications() {
  const dispatch = useDispatch();

  const { loading, error, courseapplystotal } = useSelector(
    (state) => state.courseAppliedTotal
  );

  useEffect(()=>{
    if(!courseapplystotal){
        dispatch(getAppliedCourseTotal());
    }
  },[dispatch, courseapplystotal])

  return (
    <div className="profile-ongoing-applications">
      <div className="profile-applications-heading">
        <h2>Ongoing Applications</h2>
      </div>
      <div className="applications-status-wrapper">
        <div className="applications-status applications-applied-course flex-box align-center">
          <h4>Applied Course</h4>
          <span className="ongoing-applications-count">{courseapplystotal ? courseapplystotal[0].AppliedCourses : '0'}</span>
        </div>
        <div className="applications-status applications-status-approved flex-box align-center">
          <h4>Approved</h4>
          <span className="ongoing-applications-count">{courseapplystotal ? courseapplystotal[0].Approved : '0'}</span>
        </div>
        <div className="applications-status applications-status-under-eeview flex-box align-center">
          <h4>Under Review</h4>
          <span className="ongoing-applications-count">{courseapplystotal ? courseapplystotal[0].UnderReview : "0"}</span>
        </div>
        <div className="applications-status applications-status-rejected flex-box align-center">
          <h4>Rejected</h4>
          <span className="ongoing-applications-count">{courseapplystotal ? courseapplystotal[0].Rejected : '0'}</span>
        </div>
      </div>
    </div>
  );
}
