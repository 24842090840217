import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import TrendingCourseSec from "../Home/TrendingCourseSec";
import Bechelorad from "./BachelorAd";
import Nextcourse from "./NextCourse";
import RequirementsInfo from "./RequirementsInfo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { detailUniversity } from "../../actions/universityActions";

const Index = React.memo(function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loading, error, universityDet } = useSelector(
    (s) => s.universityDetail
  );

  const getUniversityDetail = () => {
    dispatch(detailUniversity(id));
  };

  useEffect(() => {
    if (!universityDet) {
      getUniversityDetail();
    }
  }, [dispatch, universityDet]);

  return (
    <>
      <Header />
      <RequirementsInfo det={universityDet} />
      <Bechelorad />
      <TrendingCourseSec />
      <Nextcourse det={universityDet} />
      <Footer />
    </>
  );
});

export default Index;
