import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <div>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/harry-cunningham.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Perth</h1>
                <p>
                  Home to the 'English' language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding">
        <div className="container">
          <div className="headingbox letter-normal text-center">
            <h2>
              The <span className="themecolor">“Education City” </span> of
              Australia
            </h2>
            <p>
              A thriving hub of education, culture, innovation and breath-taking
              sunsets. As an international student, you’ll find a range of
              world-class institutions providing quality education, as well as
              ample opportunities to develop your skills and explore the
              city.The fast developing industrial activities in Western
              Australia and a thriving economy make Perth a favoured
              destination.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/perfect-young-woman-using-smart-phone.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Education</h2>
              <p>
                Perth boasts a range of renowned universities, colleges, and
                institutions that attract students from all over the world. Some
                of the notable institutions include the University of Western
                Australia, Curtin University, Murdoch University, and Edith
                Cowan University. These universities provide a variety of
                undergraduate, postgraduate, and research programs, catering to
                students across diverse fields of study, including business,
                engineering, science, arts, and the humanities.
              </p>
              <p>
                Additionally, Perth also houses several TAFE colleges,
                vocational institutions, and language schools, providing
                students with ample opportunities to develop their skills and
                enhance their career prospects. With top-notch facilities and
                faculty, international students can expect a high-quality
                education that prepares them for success in their chosen field.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/african-student-female-posed-with-backpack.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Financial help and scholarships</h2>
              <p>
                International students may have access to several financial aid
                options and scholarships, to help offset their education costs.
                For instance, some universities offer International Student
                Scholarships, which may cover tuition fees, living expenses, or
                travel costs. Students can also consider applying for external
                scholarships, such as those offered by the Australian Government
                or other organizations.{" "}
              </p>
              <p>
                Besides scholarships, international students can explore
                part-time work opportunities to supplement their income. With a
                student visa, international students can work up to 40 hours per
                fortnight during their course and full-time during breaks.
                Students can also find work through university job portals,
                recruitment agencies, or seek job help from their institution.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding lightgrey">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/african-american-people-greeting-job-interview.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Work Opportunities</h2>
              <p>
                Perth offers a range of work opportunities that cater to the
                needs of international students. Apart from casual and part-time
                work during study, students can explore internship programs,
                work placements, or volunteer work, to gain valuable work
                experience and improve their employability.
              </p>
              <p>
                Perth provides a range of industries and sectors, including
                mining, engineering, healthcare, technology, agriculture, and
                tourism, offering a diverse range of opportunities.
                Additionally, Perth is home to several innovative startups and
                entrepreneurial hubs, providing exciting opportunities for
                students looking to pursue their passion in entrepreneurship and
                innovation.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/urlaubstracke.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Weather Conditions and Attractions</h2>
              <p>
                Apart from its vibrant education and work culture, Perth also
                boasts stunning natural landscapes, exciting attractions, and a
                warm Mediterranean climate. With over 3000 hours of sunshine and
                mild winters, Perth is an ideal destination for beach lovers and
                outdoor enthusiasts.
              </p>
              <p>
                Perth is home to several tourist attractions, such as the iconic
                Kings Park and Botanic Garden, the Fremantle Prison, Rottnest
                Island, and the Swan River. Additionally, Perth is located in
                close proximity to several national parks, such as Yanchep
                National Park, Pinnacles Desert, and Margaret River, providing
                ample opportunities for weekend getaways or day trips.
              </p>
              <p>
                <b>In Conclusion</b>
              </p>
              <p>
                Perth provides international students with a conducive
                environment to learn, explore, and develop. With a range of
                world-class institutions, financial help, work opportunities,
                and exciting attractions, students are sure to have a fulfilling
                experience in Perth.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Studyincountry;
