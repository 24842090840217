import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/denise-jans.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Melbourne</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding study-in-newzeland">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/state-library-melbourne.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Melbourne</h2>
              <p>
                Melbourne lies on the coastline as the financial and business
                capital of Victoria state of Australia. For seven consecutive
                years Melbourne is voted as the best city to live in the world.
                Contributions of many dynamic factors make it as a preferred
                world destination to visit, work, explore or settle down. These
                are the cultural heritage, healthy climate, economic and
                financial base, and an organic infrastructure. The coast as well
                as the Yarra River way make for enthralling water sport
                activity. The financial center and businesses help in
                establishing robust economic models. Along with this the
                educational system influenced by the British makes it one of the
                best in the world. Museum, Art, botanical gardens, and operas
                add to the city’s cultural exhibits. These factors keeps the
                residents as well as the tourists enthralled for a lifetime.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/natasha-kasim.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Multiethnic Multi-cultural Flavor</h2>
              <p>
                Melbourne pumps adrenaline, gives us joy and benefits anyone who
                wishes to set foot here. Melbourne has a history of being a
                aboriginal settlement 40000 years ago and had a violent
                termination by the European settlers. These early remnants have
                been well preserved thereby adding to the essence of the city.
                The seaport and the airport are one of the busiest thereby
                making connectivity to any city all over the world a single hop
                away. The Chinese and European settlers who came during the
                Victoria gold rush expanded Melbourne.
              </p>

              <p>
                As of a day Melbourne has a multiethnic multi-cultural flavor in
                all it does. For residents and visitors, Melbourne is the live
                music capital of the world. This one can experience through
                concerts and groups playing or performing round the year,
                thereby making it a very happy place.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
