import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

import Aboutgetvisa from "./AboutGetVisa";
import Bookappointment from "./BookAppointment";
import Internationalvisa from "./InternationalVisa";
import Journeyservice from "./JourneyService";
import Searchcountrysec from "./SearchCountrySec";
import Visaapplingprocess from "./VisaApplingProcess";

const Index = React.memo(function Index() {
  return (
    <>
      <Header />
      <Aboutgetvisa />
      <Internationalvisa />
      <Visaapplingprocess />
      <Journeyservice />
      <Searchcountrysec />
      <Bookappointment />
      <Footer />
    </>
  );
});

export default Index;
