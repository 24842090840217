import React, { useState } from "react";

const Faqs = React.memo(function Faqs() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  return (
    <section className="faqs">
      <div className="container">
        <div className="text-box text-center faq-heading headingbox">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="faq-wrapper">
          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("01")}>
              Does a person must have an invitation while applying for the
              subclass 491 Visa?
              <span
                className={active === "01" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "01" ? "panel blk" : "panel"}>
              <p>
                Yes, the applicant must be nominated by any of their relatives
                residing in a regional area of the country. In absence of due
                nomination, the visa will not be granted.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("02")}>
              If the applicant is older than 45 years will they be eligible to
              apply for the 491 Visa?
              <span
                className={active === "02" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "02" ? "panel blk" : "panel"}>
              <p>
                Yes, you are free to leave and re-enter Australia as many times
                as you want throughout the validity period of your visa.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("03")}>
              How many points are required to avail the 491 Family Sponsored
              Visa?
              <span
                className={active === "03" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "03" ? "panel blk" : "panel"}>
              <p>
                Yes, you are free to leave and re-enter Australia as many times
                as you want throughout the validity period of your visa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Faqs;
