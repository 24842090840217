import React from "react";
import Slider from "react-slick";

const AdSection1 = React.memo(function () {
  const recJson = [
    {
      imgUrl: "/img/mini_banner_3.jpg",
      text: ``,
      imglnk: `ielts_training`,
    },
    {
      imgUrl: "/img/img_28n.jpg",
      text: ``,
      imglnk: `pte_training`,
    },
  ];
  const options = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <>
      <Slider className="bacheloradsec-ttr" {...options}>
        {recJson.map((rec) => (
          <a href={"/" + rec.imglnk} className="no-decoration">
            <section className="bacheloradsec-slider">
              <div
                className="bacheloradsec"
                style={{
                  backgroundImage: `url('${rec.imgUrl}')`,
                }}
              >
                <div className="container">
                  <div className="bacheloradcntnt">
                    <div
                      className="headingbox"
                      dangerouslySetInnerHTML={{ __html: rec.text }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </a>
        ))}
      </Slider>
    </>
  );
});

export default AdSection1;
