import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { createSubscribe } from "../../actions/otherActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";
import { contactJson } from "../../models/userModel";

const BannerForm = React.memo(function BannerForm({ clw }) {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState(contactJson);
  const [subscribe, setSubscribe] = useState(false);

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, success, error } = contactCreate;

  const subscribeCreate = useSelector((state) => state.subscribeCreate);
  const {
    loading: loadingsubscribe,
    success: successsubscribe,
    error: errorsubscribe,
  } = subscribeCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const checkHandler = () => {
    setSubscribe(!subscribe);
  };

  const handleData = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
      Link: window.location.href,
      Comments: `Country - ${country?.country_name}, Link: ${window.location.href}`,
    });
  };

  const handleSubmit = () => {
    var errorm = "";
    if (
      contactData.Email_Address === "" ||
      contactData.Email_Address === undefined
    )
      errorm += "Incorrect Email Address!";
    if (contactData.Phone_No === "" || contactData.Phone_No === undefined)
      errorm += "\nIncorrect Phone Number!";
    if (contactData.full_name === "" || contactData.full_name === undefined)
      errorm += "\nIncorrect Name!";

    if (errorm === "") {
      if (subscribe) {
        dispatch(createContact(contactData));
        dispatch(createSubscribe(contactData.Email_Address));
      } else {
        dispatch(createContact(contactData));
      }
    } else {
      alert(errorm);
    }
  };

  useEffect(() => {
    if (success) {
      setContactData(contactJson);
      setSubscribe(false);
      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 7000);
    }
  }, [dispatch, success]);

  return (
    <div className="forex-applynow-form">
      <form className="flex-box align-center" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className={clw == "Yes" ? "form-control cl-white" : "form-control"}
            id="fname"
            placeholder="Enter Name"
            name="full_name"
            value={contactData.full_name}
            onChange={(e) => handleData(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className={clw == "Yes" ? "form-control cl-white" : "form-control"}
            id="phnumber"
            placeholder="Enter Number"
            name="Phone_No"
            value={contactData.Phone_No}
            onChange={(e) => handleData(e)}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            className={clw == "Yes" ? "form-control cl-white" : "form-control"}
            id="email"
            placeholder="Enter Email"
            name="Email_Address"
            value={contactData.Email_Address}
            onChange={(e) => handleData(e)}
          />
        </div>

        <div className="form-group checkbox-box">
          <input
            type="checkbox"
            id="education-news"
            className="single-checkbox"
            name="education-news"
            checked={subscribe}
            onChange={checkHandler}
          />

          <label for="education-news">
            <span>I want to receive overseas education news & updates</span>
          </label>
        </div>

        <div className="form-group apply-now">
          {!loading ? (
            <button type="button" className="btn" onClick={handleSubmit}>
              SUBMIT
            </button>
          ) : (
            <button type="button" className="btn">
              Loading...
            </button>
          )}
        </div>
        <div>
          <span className="bg-success">{success}</span>
          <span className="bg-danger">{error}</span>
        </div>
      </form>
    </div>
  );
});

export default BannerForm;
