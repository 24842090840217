import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Aboutvisa from "./AboutVisa";
import Applyingstudentvisa from "./ApplyingStudentVisa";
import Eligibilityvisa from "./EligibilityVisa";
import Faqs from "./Faqs";

const Index = React.memo(function index() {
  return (
    <>
      <Header />
      <Aboutvisa />
      <Eligibilityvisa />
      <Applyingstudentvisa />
      <Faqs />
      <Footer />
    </>
  );
});

export default Index;
