import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title} - Search Education</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Search Education",
  description: "",
  keywords: "search education, overseas education consultant, australia, canada, ireland, united kingdom, consultant",
};

export default Meta;
