import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";

const Journeyservice = React.memo(function () {
  return (
    <section className="othersericesec journey-servicesec lightgrey compadding">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Search Education Makes Your</span>{" "}
            Journey Hassle Free
          </h2>

          {/* <p>
            The Customs Law of the country prevents an individual from bringing
            in drugs, steroids, firearms, and weapons into the country.
          </p> */}
        </div>

        <div className="sec-row">
          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/prepration.png"
                  alt="Getting Visa Guidence"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Visa Interview Preparation</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/doc-arrangement.png"
                  alt="Help in Loan"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Documentation Arrangements</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/visa-application.png"
                  alt="International Bank Account"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Help With Visa Application</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/application-journey.png"
                  alt="Forex"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Strategize Visa Application Journey</p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-exploremore text-center">
          <a href="/services" className="btn">
            Explore More
          </a>
        </div>
      </div>
    </section>
  );
});

export default Journeyservice;
