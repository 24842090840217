import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../actions/userActions";
import moment from "moment";

export default function LeftPanel(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeText = "our-profile-list active";
  const inactiveText = "our-profile-list ";

  const [dashboard, setDashboard] = useState(activeText);
  const [myprofile, setMyprofile] = useState(inactiveText);
  const [application, setApplication] = useState(inactiveText);
  const [compare, setCompare] = useState(inactiveText);
  const [wishlist, setWishlist] = useState(inactiveText);
  const [courses, setCourses] = useState(inactiveText);
  const [message, setMessage] = useState(inactiveText);
  const [event, setEvent] = useState(inactiveText);

  const clearAll = () => {
    setDashboard(inactiveText);
    setMyprofile(inactiveText);
    setApplication(inactiveText);
    setCompare(inactiveText);
    setWishlist(inactiveText);
    setCourses(inactiveText);
    setMessage(inactiveText);
    setEvent(inactiveText);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    const setActiveTab = () => {
      const url = window.location.pathname;
      const suburl = url.split("/")[2];

      if (suburl === "dashboard") {
        clearAll();
        setDashboard(activeText);
      } else if (suburl === "myprofile") {
        clearAll();
        setMyprofile(activeText);
      } else if (suburl === "ongoing_application") {
        clearAll();
        setApplication(activeText);
      } else if (suburl === "compare") {
        clearAll();
        setCompare(activeText);
      } else if (suburl === "wishlist") {
        clearAll();
        setWishlist(activeText);
      } else if (suburl === "all-courses") {
        clearAll();
        setCourses(activeText);
      } else if (suburl === "messages") {
        clearAll();
        setMessage(activeText);
      } else if (suburl === "event") {
        clearAll();
        setEvent(activeText);
      } else if (suburl === "myprofile_edit") {
        clearAll();
        setMyprofile(activeText);
      } else if (suburl === "ongoing_application_detail") {
        clearAll();
        setApplication(activeText);
      } else {
        clearAll();
      }
    };
    setActiveTab();
  }, [dispatch]);

  const [show, setshow] = useState(false);

  return (
    <section className="dashboard">
      <div className="container">
        <div className="sec-row">
          <div className="toggle-sidebar-btn-wrapper">
            <div
              className={show ? "sidebar-btn closesidebar" : "sidebar-btn"}
              onClick={(e) => setshow(!show)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            className={
              show
                ? "sec-md-col-15 dashborad-leftbox sidebar sticky visible"
                : "sec-md-col-15 dashborad-leftbox sidebar sticky"
            }
          >
            <div className="dashborad-leftbox-content">
              <div className="our-profile-wrapper">
                <a href="/" className="transparent">
                  <img src="/img/white-logo.png" alt="White-Logo" />
                </a>
                <ul className="our-profile-listing">
                  <li className={dashboard}>
                    <a
                      href="/panel/dashboard"
                      className="our-profile-list-item"
                    >
                      <img
                        src="/img/dashboard-icons.png"
                        alt="Dashboard Icons"
                      />
                      Dashboard
                    </a>
                  </li>

                  <li className={myprofile}>
                    <a
                      href="/panel/myprofile"
                      className="our-profile-list-item"
                    >
                      <img src="/img/profile-icon.png" alt="Profile Icon" />
                      My Profile
                    </a>
                  </li>
                  <li className={application}>
                    <a
                      href="/panel/ongoing_application"
                      className="our-profile-list-item"
                    >
                      <img
                        src="/img/applications-orange.png"
                        alt="Applications Orange"
                      />
                      Ongoing Applications
                    </a>
                  </li>
                  <li className={compare}>
                    <a href="/course_compare" className="our-profile-list-item">
                      <img src="/img/compare-orange.png" alt="compare Orange" />
                      Compare
                    </a>
                  </li>
                  <li className={wishlist}>
                    <a href="/panel/wishlist" className="our-profile-list-item">
                      <img src="/img/heart-orange.png" alt="Heart Orange" />
                      Wishlist
                    </a>
                  </li>
                  <li className={courses}>
                    <a href="/courses" className="our-profile-list-item">
                      <img src="/img/courses-orange.png" alt="Courses Orange" />
                      All Courses
                    </a>
                  </li>
                  <li className={message}>
                    <a href="/panel/messages" className="our-profile-list-item">
                      <img src="/img/message-orange.png" alt="Message Orange" />
                      Messages
                    </a>
                  </li>
                  <li className={event}>
                    <a href="/panel/event" className="our-profile-list-item">
                      <img src="/img/event-icon.png" alt="Event Icon" />
                      Events
                    </a>
                  </li>
                  <li className={inactiveText}>
                    <Link
                      onClick={handleLogout}
                      className="our-profile-list-item"
                    >
                      LogOut
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dashboard-calander">
                <h4 className="calander-time">{moment().format("hh:mm")}</h4>
                <div className="calander-info">
                  <span className="date">{moment().format("DD")}</span>
                  <h5 className="year">
                    <span>Today </span>
                    {moment().format("MMM YYYY")}
                  </h5>
                  <span className="dashboard-calander-image">
                    <img
                      src="/img/dashboard-calander.png"
                      alt="Dashboard Calender"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {props.children}
        </div>
      </div>
    </section>
  );
}
