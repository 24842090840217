import React from "react";
import BannerForm from "../Common/BannerForm";

const Aboutgetvisa = React.memo(function Aboutgetvisa() {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{ backgroundImage: `url('/img/get-visa-banner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Seamless, Simple, Reliable</h4>
              <h1>Get Your Visa Approved</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-get-visa compadding">
        <div className="container">
          <div className="about-get-visa-wrapper flex-box align-center">
            <div className="about-get-visa-details-info headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">What is a</span> Visa?
              </h2>

              <h3>
                What is a Visa and why is there so much rush around this word
                when flying abroad?
              </h3>

              <p>
                Visa is a government-recognized document that is either stamped
                or glued onto the passport of the person flying abroad. This is
                the proof of official permission that has been granted to the
                individual to legally enter a foreign country.
              </p>

              <p>
                Visas are of myriad types & they are subcategorized & subdivided
                into various sections. For instance, student visas are those
                which are issued specifically to students for educational
                purposes. This piece of parchment is essential to gain admission
                to any of the Australian universities. Just like any other
                nation, Australia too is regulated by its very own Immigration
                Laws. These visas lay down the dos & don'ts for a person while
                staying in the country.
              </p>
            </div>

            <div className="about-get-visa-imagebox full-width-925">
              <div className="about-get-image-leftbox">
                <div
                  className="get-visa-image-left bg-style"
                  style={{ backgroundImage: `url('/img/passport.jpg')` }}
                ></div>

                <div
                  className="get-visa-image-bottomleft bg-style"
                  style={{
                    backgroundImage: `url('/img/travel-yellow-image.png')`,
                  }}
                ></div>
              </div>

              <div className="about-get-image-righttbox">
                <div
                  className="get-visa-image-right bg-style"
                  style={{
                    backgroundImage: `url('/img/travel-red-image.png')`,
                  }}
                ></div>

                <div
                  className="get-visa-image-bottomright bg-style"
                  style={{ backgroundImage: `url('/img/get-visa.jpg')` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutgetvisa;
