import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../Common/Footer";
import TestimonialSec from "../Home/TestimonialSec";
import Header from "../Common/Header";
import MetaTags from "../../core/MetaTags";

const Index = React.memo(function Index() {
  return (
    <>
      <Header />
      <MetaTags title="" name="" description="" keywords="" robots="" />
      {/* <!-- Start Inner Page banner --> */}

      <section
        className="innrbanner about-banner"
        style={{
          backgroundImage: `url('/img/about-banner.png')`,
          backgroundColor: "#07070B",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">About Us</h4>

              <h1>Overseas Education Consultancy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="study-Satisfied compadding">
        <div className="container">
          <div className="flex-box study-Satisfied-content-wrapper headingbox">
            <div className="study-Satisfied-leftbox full-width-730">
              <h2>
                <span className="themecolor">100%</span> Satisfied
              </h2>

              <p>For My Study in Abroad</p>
            </div>

            <div className="study-Satisfied-rightbox full-width-730">
              <h6>
                Where Technology, Excellence & Understanding come together!
              </h6>

              <h3>
                Education sector is evolving, especially in the global scenario.
                And hence we at Search Education exists to deliver to these
                changing needs of the globe in this sector.
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="about-work-history compadding pd-tzero">
        <div className="container">
          <div className="flex-box work-history-wrapper">
            <div className="work-history-details headingbox full-width-925">
              <h2>History</h2>

              <p>
                Established <strong>seven years ago,</strong> we believe in
                making every student's dream to <strong>'Study Abroad'</strong>{" "}
                a reality. As a student you have complete 'Right to the Right
                Education through the Right source of information'. Our
                dedicated team ensures that you have a plethora of options
                matching your needs. These options will help you decide the best
                out of all shared options matching your need. The basic choices
                revolve around education program, budget and career opportunity
                linked to it. Our core experience in this industry has allowed
                us to have best educationist. They are always on the run to
                offer customized solutions for every aspirant.
              </p>

              <p>
                Search Education engages with students through a one on one
                approach both offline and <strong>online - 365</strong> days a
                year during the entire 'Student Recruitment Cycle'. This cycle
                begins with Profile building, identifying options based on past
                academic scores and other relevant documents, helping grab the
                offers from the institutions/universities planned, and prepare
                for visa interview until the student reaches the final
                destination.
              </p>
            </div>

            <div className="work-history-imagebox full-width-925">
              <div
                className="work-history-image bg-style"
                style={{
                  backgroundImage: `url('/img/team-work.jpg')`,
                }}
              ></div>

              <div
                className="work-history-image work-history-second-image bg-style"
                style={{
                  backgroundImage: `url(/img/working-office.jpg)`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-mission-vision lightgrey">
        <div className="our-miss-vis-wrapper flex-box align-center">
          <div
            className="our-miss-vis-image full-width-925 hide-925 bg-style"
            style={{
              backgroundImage: `url(/img/discussion.png)`,
            }}
          ></div>

          <div className="our-miss-vis-content-box-main full-width-925">
            <div className="our-miss-vis-content-box flex-box">
              <div className="our-mission-imagebox-main">
                <span className="quotes">
                  <img src="/img/quotes-blue.png" alt="quotes-blue" />
                </span>

                <div className="our-mission-imagebox">
                  <img src="/img/launch.png" alt="launch" />
                </div>
              </div>

              <div className="our-mission-content-box headingbox">
                <h3>
                  <span className="themecolor">Our </span>Mission
                </h3>

                <p>
                  We believe there are no shortcuts to a world class education.
                  Our mission is to facilitate the international academic
                  journey by assisting students throughout the challenging
                  transition into their post study aspirations and become the
                  best versions of themselves.
                </p>
              </div>
            </div>

            <div className="our-miss-vis-content-box flex-box">
              <div className="our-mission-imagebox-main">
                <span className="quotes">
                  <img src="/img/quotes-blue.png" alt="quotes-blue" />
                </span>

                <div className="our-mission-imagebox">
                  <img src="/img/flag.png" alt="flag" />
                </div>
              </div>

              <div className="our-mission-content-box headingbox">
                <h3>
                  <span className="themecolor">Our </span>vision
                </h3>

                <p>
                  By 2025 Search Education is committed to seeing 100,000
                  international students acquire quality education and become
                  successful global professionals contributing meaningfully to
                  the society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourwork-culture our-culture compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Our </span>Work Culture
            </h2>

            <p>
              Our elite team members work round the globe from{" "}
              <strong>3 continents,</strong> assisting the students in every
              single step of their <strong>overseas education journey.</strong>
            </p>
          </div>

          <div className="ourwork-culture-box-wrapper our-cultures-box-wrapper flex-box">
            <div className="ourwork-culture our-cultures full-width-925">
              <div
                className="our-cultures-image bg-style"
                style={{
                  backgroundImage: `url(/img/team-group.jpg)`,
                }}
              ></div>

              <h3>Innovative Approaches</h3>
            </div>

            <div className="ourwork-culture our-cultures full-width-925">
              <div
                className="our-cultures-image bg-style"
                style={{
                  backgroundImage: `url(/img/project-discussion.jpg)`,
                }}
              ></div>

              <h3>
                Personalised
                <br /> Service
              </h3>
            </div>

            <div className="ourwork-culture our-cultures full-width-925">
              <div
                className="our-cultures-image bg-style"
                style={{
                  backgroundImage: `url(/img/communication.jpg)`,
                }}
              ></div>

              <h3>Transparent Communication</h3>
            </div>

            <div className="ourwork-culture our-cultures full-width-925">
              <div
                className="our-cultures-image bg-style"
                style={{
                  backgroundImage: `url(/img/enjoy-with-work.jpg)`,
                }}
              ></div>

              <h3>Integrity</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="education-dreams-foregin compadding">
        <div className="container">
          <div className="education-dreams-wrapper flex-box">
            <div className="education-dreams-image-box full-width-925">
              <div
                className="education-dreams-image bg-style"
                style={{
                  backgroundImage: `url(/img/people-looking-face.jpg)`,
                }}
              ></div>

              <div
                className="education-dreams-image education-dreams-secondimage bg-style"
                style={{
                  backgroundImage: `url(/img/library-book.jpg)`,
                }}
              ></div>
            </div>

            <div className="education-dreams-content headingbox mb-0 full-width-925">
              <h2>
                <span className="themecolor">Making the Dreams of </span>Foreign
                <br /> Education a Reality
              </h2>

              <p>
                At Search Education, we understand what is required to ensure
                recruitment of committed and passionate students who aspire for
                extraordinary achievements. We constantly strive to surpass
                industry standards by using avant - guard technology and
                rigorous selection mechanisms to match students with our
                partners. Using a finer lens, we look closely at a student’s
                academic qualifications, financial background, character and
                career goals to ensure the right fit. With an exciting diversity
                in their educational backgrounds most of our team members hold
                global degrees, making them more empathetic towards the
                challenges faced by international education aspirants. To help
                our students steer through their struggles of studying abroad,
                the dynamic and adept team of Search Education remains
                accessible to them even during their international academic
                tenure.
              </p>

              <p>
                At Search Education we ardently believe that an association is
                for a lifetime and in our endeavor to stand true to our beliefs,
                we support our students for their post study aspirations to
                ensure a successful career and help our partners achieve their
                business goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-founder lightgrey compadding">
        <div className="container">
          <div className="about-founder-wrapper flex-box">
            <div className="about-founder-details full-width-925 headingbox mb-0">
              {/* <img src="/img/quotes.png" alt="quotes" /> */}
              <LazyLoadImage src="/img/quotes.png" alt="quotes" />
              <p>
                Ensuring all aspiring students have{" "}
                <strong>access to quality education</strong> from universities
                and colleges across the globe, we spectacularly pioneered AI
                based algorithms and automation in different processes, which
                has granted us a technology driven edge over others.
              </p>

              <p>
                I am proud of where we have reached and I am sure that the path
                ahead will bring abundant prosperity and success for all our
                stakeholders!
              </p>

              <h5>- Bhupendra Kunwar</h5>
            </div>

            <div className="founder-image-box">
              <span className="founder-text">Founder</span>

              <div
                className="about-founder-image bg-style"
                style={{
                  backgroundImage: `url('/img/founder-image.png')`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Our Testimonial --> */}

      <TestimonialSec />

      <Footer />
    </>
  );
});

export default Index;
