import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
} from "../constants/notificationConstants";
import api2 from "../core/api2";

export const listNotifications = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTIFICATION_LIST_REQUEST });
    const { data } = await api2.get(`/student/Notifications`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({ type: NOTIFICATION_LIST_FAIL, payload: res });
    } else {
      dispatch({ type: NOTIFICATION_LIST_SUCCESS, payload: res });
    }
  } catch (ex) {
    dispatch({
      type: NOTIFICATION_LIST_FAIL,
      payload: `Error - ${ex.message}`,
    });
  }
};
