import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Banner from "./Banner";
import Counsellerexpert from "./Counsellerexpert";
import Counselleroffer from "./Counselleroffer";
import Counsellingfeatured from "./Counsellingfeatured";
import Counsellingsearchcountry from "./Counsellingsearchcountry";
import Efficientsignup from "./EfficientSignup";
import Efficientstudy from "./EfficientStudy";
import Testimonialsec from "./TestimonialSec";

export default function Index() {
  return (
    <>
      <Header />
      <Banner />
      <Counsellerexpert />
      <Counselleroffer />
      <Efficientstudy />
      <Testimonialsec />
      <Counsellingsearchcountry />
      <Counsellingfeatured />
      <Efficientsignup />
      <Footer />
    </>
  );
}
