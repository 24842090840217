import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Nextcourse = React.memo(function Nextcourse({ det }) {
  return (
    <>
      <div className="nextcourse-section compadding">
        <div className="container newcontainer">
          <div className="flex-box">
            <div className="col-5 list-details-heading ">
              <span className="smh">Next Course of Action</span>
              <h2>
                <strong>Carry On </strong>or Change Course?
              </h2>
              {/* <LazyLoadingImages src="/img/12group.png" alt="group" className="mobile-img"/> */}
              <p>
                The Test of English as a Foreign Language (TOEFL) or
                International English Language Testing System (IELTS) is
                required for most international applicants. For details, please
                review the “International Applicants” tab. In order to qualify
                for the 2022 Summer Intensive Program, the exam must be taken on
                or after June 1, 2020. The score is valid for 2 years.
              </p>
              <div className="listing-details-info-box">
                <strong className="lt">Minimum Scores:</strong>
                <ul className="detail-info-list">
                  <li>
                    TOEFL IBT: 103 - must score no less than 20 on each of the
                    four
                  </li>
                  <li>test components</li>
                  <li>
                    IELTS - 7.5 - must score no less than 6 on each band score.
                    PTE Academic- 73
                  </li>
                </ul>
                <strong className="lt mt">Submitting test scores</strong>
                <ul className="detail-info-list">
                  <li>
                    TOEFL: Code: 4852 and department code is 02 (graduate school
                    of management)
                  </li>
                  <li>
                    IELTS: USC will only accept IELTS scores sent electronically
                    from the IELTS test center where you took the test. Paper
                    test report forms received by postal mail or email are not
                    accepted.
                  </li>
                </ul>
              </div>

              <a href="/courses" className="btn">
                Browse course
              </a>
            </div>
            <div className="col-4">
              <LazyLoadingImages src="/img/12group.png" alt="group" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="nextcourse-section compadding">
    <div className="container newcontainer">
       <div className="flex-box">
          <div className="col-5 list-details-heading ">
             <span className="smh">Next Course of Action</span>
             <h2><strong>Carry On </strong>or Change Course?</h2>
             <LazyLoadingImages src="/img/12group.png" alt="group" className="mobile-img"/>
             <p>The Test of English as a Foreign Language (TOEFL) or International English Language Testing System (IELTS) is required for most international applicants. For details, please review the “International Applicants” tab. In order to qualify for the 2022 Summer Intensive Program, the exam must be taken on or after June 1, 2020. The score is valid for 2 years.</p>
             <div className="listing-details-info-box">
                <strong className="lt">Minimum Scores:</strong>
                <ul className="detail-info-list">
                   <li>TOEFL IBT: 103 - must score no less than 20 on each of the four </li>
                   <li>test components</li>
                   <li>IELTS - 7.5 - must score no less than 6 on each band score.
                      PTE Academic- 73</li>
                </ul>
                <strong className="lt mt">Submitting test scores</strong>
                <ul className="detail-info-list">
                   <li>TOEFL: Code: 4852 and department code is 02 (graduate school of management)</li>
                   <li>IELTS: USC will only accept IELTS scores sent electronically from the IELTS test center where you took the test. Paper test report forms received by postal mail or email are not accepted.</li>
                </ul>
             </div>               
             <a href="/" className="btn">Browse course</a>
             
          </div>
          <div className="col-4">
             <LazyLoadingImages src="/img/12group.png" alt="group"/>
          </div>
       </div>
    </div>
 </div> */}
    </>
  );
});

export default Nextcourse;
