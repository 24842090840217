export const ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST = 'ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST'
export const ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS = 'ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS'
export const ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL = 'ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL'
export const ENGLISH_PROFICIENCY_TEST_MODULE_GET_RESET = 'ENGLISH_PROFICIENCY_TEST_MODULE_GET_RESET'

export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAIL = 'VERIFY_TOKEN_FAIL'
export const VERIFY_TOKEN_RESET = 'VERIFY_TOKEN_RESET'

export const IPCHECKER_CREATE_REQUEST= 'IPCHECKER_CREATE_REQUEST'
export const IPCHECKER_CREATE_SUCCESS= 'IPCHECKER_CREATE_SUCCESS'
export const IPCHECKER_CREATE_FAIL= 'IPCHECKER_CREATE_FAIL'
