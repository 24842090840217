import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Typepteexam = React.memo(function () {
  return (
    <section className="type-pte-exam compadding">
      <div className="container">
        <div className="comm-content-wrapper-width">
          <div className="headingbox letter-normal">
            <h2>
              <span className="themecolor">Types of</span> IELTS Exams
            </h2>
            <p>
              The IELTS Preparation courses in this specialization are intended
              for those seeking to boost their scores on the IELTS exam. Whether
              you have taken the test before, these courses will help you to
              become more familiar with the various types of questions on the
              test.
            </p>
          </div>
          <div className="type-pte-exam-detail flex-box">
            <div className="type-pte-exam-image full-width-540">
              <LazyLoadingImages
                src="/img/perfect-young-woman-using-smart-phone-with-leptop.jpg"
                alt="student"
              />
            </div>
            <div className="type-pte-exam-detail-info letter-normal full-width-540">
              <h5>Students/Education IELTS Exams</h5>
              <p>
                To begin with, go through the eligibility criteria above and
                check if you are qualified enough to get into the Skilled
                Migration Program by undergoing the Points Test.
              </p>
              <ul className="type-pte-exam-list">
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Recognised as the top rated education system in the world
                </li>
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Highly organised, controlled and protected education and
                  political environment by Government.
                </li>
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Recognised as the top rated education system in the world
                </li>
              </ul>
            </div>
          </div>
          <div className="type-pte-exam-detail flex-box">
            <div className="type-pte-exam-image full-width-540">
              <LazyLoadingImages
                src="/img/business-team-working-office.jpg"
                alt="student"
              />
            </div>
            <div className="type-pte-exam-detail-info letter-normal full-width-540">
              <h5>Professional/Migration IELTS</h5>
              <p>
                To begin with, go through the eligibility criteria above and
                check if you are qualified enough to get into the Skilled
                Migration Program by undergoing the Points Test.
              </p>
              <ul className="type-pte-exam-list">
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Recognised as the top rated education system in the world
                </li>
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Highly organised, controlled and protected education and
                  political environment by Government.
                </li>
                <li>
                  <span>
                    <LazyLoadingImages
                      src="/img/orange-check.png"
                      alt="student"
                    />
                  </span>
                  Recognised as the top rated education system in the world
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Typepteexam;
