import React from "react";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const OurPartnerSec = React.memo(function () {
  const partnerJson = [
    { partnerName: "p_allianz.png" },
    { partnerName: "p_amber.png" },
    { partnerName: "p_bupa.png" },
    { partnerName: "p_casita.png" },
    { partnerName: "p_hdfc_credila.png" },
    { partnerName: "p_idp_ielts.png" },
    { partnerName: "p_la.png" },
    { partnerName: "p_nib.png" },
    { partnerName: "p_phonebox.jpg" },
    { partnerName: "p_thomas_cook.png" },
    { partnerName: "p_university_living.png" },
  ];
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="ourpartnersec">
        <div className="container compadding">
          <div className="flexbox">
            <div className="headingbox">
              <h2>
                <span className="smh"></span>Our Partners
              </h2>
            </div>
            <div className="partnerscroll">
              <Slider className="partnerslider" {...options}>
                {partnerJson.map((ptjs, index) => (
                  <li className="partnerslide" key={index + 1}>
                    <div className="partnerlogo equal-height">
                      <LazyLoadingImages
                        alt="Partner Logo"
                        src={`./assets/img/${ptjs.partnerName}`}
                      />
                    </div>
                  </li>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default OurPartnerSec;
