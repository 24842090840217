import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../../../actions/messageActions";
import { useState } from "react";
import { useEffect } from "react";
import { MESSAGE_ADD_NEW_RESET } from "../../../constants/messageConstants";

export default function NewMessage({ show, setshow, getmessages }) {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((s) => s.newMessageInsert);
  const [Message_Sub, setmessage_Sub] = useState("");
  const [Message_body, setMessage_body] = useState("");
  const insertMessage = () => {
    dispatch(
      addMessage({
        Message_Sub,
        Message_body,
      })
    );
  };
  useEffect(() => {
    if (success) {
      getmessages();
      setTimeout(() => {
        setshow("");
        dispatch({ type: MESSAGE_ADD_NEW_RESET });
      }, 2000);
    }
  }, [dispatch, success]);
  return (
    <div className={show ? `write-modal ${show}` : "write-modal"}>
      <div className="modal-overlay write-modal-toggle"></div>
      <div className="write-message-modal modal-transition">
        <div className="write-message-modal-inner">
          <div className="write-modal-inner">
            <h2>Write Messages</h2>
            <span
              className="close-modal write-modal-toggle"
              onClick={(e) => setshow("")}
            >
              <img src="/img/close-black-icon.png" />
            </span>
            <form className="write-modal-form">
              <div className="form-group write-modal-form-group">
                <label for="yourtopic">Topic</label>
                <input
                  type="text"
                  className="form-control"
                  id="yourtopic"
                  name="yourtopic"
                  placeholder="Type Your Topic Here ..."
                  max={50}
                  value={Message_Sub}
                  onChange={e=>setmessage_Sub(e.target.value)}
                />
              </div>
              <div className="form-group write-modal-form-group">
                <label for="YourMessage">Your Message</label>
                <textarea
                  id="YourMessage"
                  name="Your Message"
                  rows="3"
                  className="form-control"
                  placeholder="Write Message here"
                  value={Message_body}
                  onChange={e=>setMessage_body(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group write-modal-form-group text-center">
                <button type="button" className="btn btn-submit" onClick={insertMessage}>
                  Send
                </button>
              </div>
              <div>
                <span>{success} succ</span>
                <span>{error} err</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
