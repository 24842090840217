import React from "react";

const CounterDataSec = React.memo(function () {
  return (
    <section className="counterdatasec compadding">
      <div className="container">
        <div className="sec-row">
          <div className="sec-md-col-60 equal-height">
            <div className="counterdatabox">
              <ul>
                <li>
                  <div className="innerbox">
                    <h4>19,000 +</h4>
                    <p>
                      <strong>Success </strong>Stories
                    </p>
                  </div>
                </li>
                <li>
                  <div className="innerbox">
                    <h4>2,000 +</h4>
                    <p>
                      <strong>Helped</strong> Students
                    </p>
                  </div>
                </li>
                <li>
                  <div className="innerbox">
                    <h4>99.99 %</h4>
                    <p>
                      <strong>Acceptance</strong> rate
                    </p>
                  </div>
                </li>
                <li>
                  <div className="innerbox">
                    <h4>8,000 +</h4>
                    <p>
                      <strong>Educated</strong> Teacher
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="sec-md-col-40 equal-height">
            <div className="knowmoredata hide-500">
              <div className="knowmoredatacol">
                <div className="knowmoredatainnr">
                  <div className="groupvectoricon">
                    <img src="/img/groupvector.png" alt="Group Icon" />
                  </div>
                  <h3>To Know More About us</h3>
                  <a href="/about_us" className="btn">
                    <img src="/img/arrows-rightc.png" alt="Arrow" />
                  </a>
                </div>
              </div>
            </div>
            <div className="show-500 text-center">
              <a
                href="/about_us"
                className="btn btnthemcolor"
                style={{ marginTop: "40px" }}
              >
                More About Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CounterDataSec;
