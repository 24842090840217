import {
  FINANCIAL_SOURCE_LIST_REQUEST,
  FINANCIAL_SOURCE_LIST_SUCCESS,
  FINANCIAL_SOURCE_LIST_FAIL,
} from "../constants/financialSourceConstants";
import api2 from "../core/api2";

export const listFinancialSource = () => async (dispatch) => {
  try {
    dispatch({ type: FINANCIAL_SOURCE_LIST_REQUEST });
    const { data } = await api2.get(`/financialsource`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({
        type: FINANCIAL_SOURCE_LIST_FAIL,
        payload: data.Result,
      });
    } else {
      dispatch({
        type: FINANCIAL_SOURCE_LIST_SUCCESS,
        payload: data.Result,
      });
    }
  } catch (ex) {
    dispatch({
      type: FINANCIAL_SOURCE_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
