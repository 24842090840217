import React, { useEffect } from "react";
import LeftPanel from "../Common/leftPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  applyCourse,
  getShortlistedCourse,
  removeShortlistedCourse,
} from "../../../actions/courseActions";
import {
  COURSE_APPLY_RESET,
  COURSE_REMOVE_SHORTLIST_RESET,
} from "../../../constants/courseConstants";
import { useState } from "react";
import Header from "../Common/Header";

export default function Index() {
  const dispatch = useDispatch();
  const [iid, setiid] = useState("0");
  const { loading, error, courseShortlists } = useSelector(
    (state) => state.courseShortlisted
  );

  const {
    loading: loadingAdd,
    success: successAdd,
    error: errorAdd,
  } = useSelector((s) => s.courseApply);

  const {
    loading: loadingRemove,
    success: successRemove,
    error: errorRemove,
  } = useSelector((s) => s.removeCourseShortlist);

  const getshortlistedcourses = () => {
    dispatch(getShortlistedCourse());
  };

  const handleApply = (id) => {
    dispatch(applyCourse(id));
    setiid(id);
  };

  const handleRemove = (id) => {
    dispatch(removeShortlistedCourse(id));
  };

  useEffect(() => {
    if (!courseShortlists) {
      getshortlistedcourses();
    }
    if (successAdd) {
      dispatch({ type: COURSE_APPLY_RESET });
      handleRemove(iid);
    }
    if (successRemove) {
      setTimeout(() => {
        dispatch({ type: COURSE_REMOVE_SHORTLIST_RESET });
        getshortlistedcourses();
      }, 2000);
    }
  }, [dispatch, courseShortlists, successAdd, successRemove]);
  return (
    <Header>
      {courseShortlists ? (
        courseShortlists.length > 0 ? (
          <div className="featuredbg-style dashboard-allcourses mb-900-zero">
            <div className="top-cntnr flex-box align-center">
              <div className="title-grp">
                <h5>
                  {/* Showing
                                <strong><span className="availblecourse">10</span> out of
                                  <span className="totalcourse">200</span></strong> */}
                  My Shortlisted Courses
                </h5>
                <p>
                  <span style={{ backgroundColor: "red" }}>{errorAdd}</span>
                  <span style={{ backgroundColor: "green" }}>{successAdd}</span>

                  <span style={{ backgroundColor: "red" }}>{errorRemove}</span>
                  <span style={{ backgroundColor: "green" }}>
                    {successRemove}
                  </span>
                </p>
              </div>
            </div>
            {courseShortlists ? (
              courseShortlists.length > 0 ? (
                courseShortlists.map((crsh, index) => (
                  <div className="all-courses-box-wrapper" key={index + 1}>
                    <div className="all-courses-box-inner flex-box">
                      <div className="all-coursesimage">
                        <img
                          src={
                            crsh.Banner402x300
                              ? `${process.env.REACT_APP_SED_DOC_URL}/university/Banner402x300/${crsh.Banner402x300}`
                              : "/img/mstpopularuniversity.jpg"
                          }
                          alt="dashboard"
                        />
                      </div>
                      <div className="all-coursescontent">
                        <div className="all-courseslogoimage">
                          <img
                            src={
                              crsh.Logo74x74
                                ? `${process.env.REACT_APP_SED_DOC_URL}/university/Logo74x74/${crsh.Logo74x74}`
                                : "/img/universitylogo2.png"
                            }
                            alt="dashboard"
                          />
                        </div>
                        <div className="all-coursescontentinner flex-box">
                          <div className="all-coursescontenleft-box">
                            <p className="location">
                              <img src="/img/locationicon.png" alt="Map" />
                              <strong>{crsh.University_Name}</strong>{" "}
                              {crsh.Country}
                            </p>
                            <h5>
                              {crsh.Course_Name} - SEDWT{crsh.Id}
                            </h5>
                          </div>
                          <div className="all-coursescontenright-box flex-box">
                            <a
                              onClick={() => handleApply(crsh.Course_Id)}
                              className="course-cta ad-compare"
                            >
                              Apply
                            </a>
                            <a
                              href={`/course/${crsh.Course_Id}`}
                              className="course-cta view-course"
                            >
                              View Course
                            </a>
                            <a
                              onClick={() => handleRemove(crsh.Course_Id)}
                              className="heart"
                            >
                              <img src="/img/trase.png" alt="delete" />
                            </a>
                          </div>
                          <div className="durationfeesem flex-box">
                            <div className="colhalf text-center">
                              <p>
                                <span>Program type</span>
                                {crsh.Study_Level}
                              </p>
                            </div>
                            <div className="colhalf text-center">
                              <p>
                                <span>Course fee Per Sem</span>
                                {crsh.Country_Symbol} {crsh.Yearly_Tuition_Fees}
                              </p>
                            </div>
                            <div className="colhalf text-center">
                              <p>
                                <span>Enrollment Last date </span>As per Intake
                              </p>
                            </div>
                            <div className="colhalf text-center">
                              <p>
                                <span>Duration</span>
                                {crsh.Duration ? crsh.Duration + " months" : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  You have not added any course to your wishlist start{" "}
                  <a href="/courses">Browsing Now.</a>
                </div>
              )
            ) : (
              <div>
                You have not added any course to your wishlist start{" "}
                <a href="/courses">Browsing Now.</a>
              </div>
            )}
          </div>
        ) : (
          <div class="featuredbg-style dashboard-allcourses mb-900-zero">
            <div class="no-wishlist text-center">
              <div class="txt">
                <h2>
                  Do not have any <span>course</span> under your
                  <br /> Wishlist start browsing now
                </h2>
                <a href="/courses" class="btn text-center">
                  Browse Courses
                </a>
              </div>
            </div>
          </div>
        )
      ) : (
        <div class="featuredbg-style dashboard-allcourses mb-900-zero">
          <div class="no-wishlist text-center">
            <div class="txt">
              <h2>
                Do not have any <span>course</span> under your
                <br /> Wishlist start browsing now
              </h2>
              <a href="/courses" class="btn text-center">
                Browse Courses
              </a>
            </div>
          </div>
        </div>
      )}
    </Header>
  );
}
