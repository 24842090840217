import React, { useEffect, useState } from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import $ from "jquery";
import { useSelector } from "react-redux";
import axios from "axios";
// import {
//   AustraliaTestimonialJson,
//   IndiaTestimonialJson,
//   NepalTestimonialJson,
// } from "../Home/TestimonialSec";

const Testimonialsec = React.memo(function Testimonialsec() {
  const [activeSec, setActiveSec] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [TestimonialJson, setTestimonialJson] = useState([]);

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const getTestimonials = async (cntry) => {
    if (cntry && cntry == "nepal") {
      const testimonialRecs = await axios.get("./data/NepalTestimonial.json");
      setTestimonialJson(testimonialRecs.data);
    } else {
      const testimonialRecs = await axios.get("./data/IndiaTestimonial.json");
      setTestimonialJson(testimonialRecs.data);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  const handlePlayVideo = (url) => {
    setVideoUrl("");
    setVideoUrl(
      <video
        width="400"
        preload="auto"
        playsinline=""
        controls
        autoplay="/"
        poster="/img/satisfiedstudentimg.png"
        id="video-popup"
      >
        <source src={url} type="video/mp4" />
      </video>
    );
    setActiveSec(true);
  };

  const options = {
    slidesToShow: 3,
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <>
      <section className="testimonialsec counselling-student-testimonial lightgrey compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Help More Than</span>1 Lakh Students
            </h2>
          </div>

          <div className="sec-row">
            <div className="sec-md-col-75 slide-right-box">
              {/* {country ? country.country_name === "Australia" ? (
                <Slider className="testimonailslider" {...options}>
                  {AustraliaTestimonialJson.map((rec, index) => {
                    return rec.contentType === "text" ? (
                      <div className="testimonailslide" key={index}>
                        <div className="testimonialslidecntnt">
                          <div className="testimonial-inner">
                            <h5 className="overflow">{rec.testimonialText}</h5>
                            <div className="client-info flex-box">
                              <div className="client-image">
                                <LazyLoadingImages src="/img/default.jpg" />
                              </div>
                              <div className="client-details">
                                <h6>{rec.studentname}</h6>
                                <div className="star-rating">
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="testimonailslide" key={index}>
                        <div className="satisfiedstudentimg">
                          <div className="satisfied-image">
                            <video
                              width="400"
                              preload="auto"
                              muted
                              autoplay="/"
                              playsinline="/"
                              loop="/"
                              id="myVideo"
                            >
                              <source
                                src={`/img/${rec.studentVideo}`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>

                          <div className="student-info flex-box">
                            <span
                              className="video-play-button popup-btn"
                              onClick={(e) =>
                                handlePlayVideo(`/img/${rec.studentVideo}`)
                              }
                            >
                              <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                            </span>
                            <div className="student-detail">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star-o"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : country.country_name === "Nepal" ? (
                <Slider className="testimonailslider" {...options}>
                  {NepalTestimonialJson.map((rec, index) => {
                    return rec.contentType === "text" ? (
                      <div className="testimonailslide" key={index}>
                        <div className="testimonialslidecntnt">
                          <div className="testimonial-inner">
                            <h5 className="overflow">{rec.testimonialText}</h5>
                            <div className="client-info flex-box">
                              <div className="client-image">
                                <LazyLoadingImages src="/img/default.jpg" />
                              </div>
                              <div className="client-details">
                                <h6>{rec.studentname}</h6>
                                <div className="star-rating">
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="testimonailslide" key={index}>
                        <div className="satisfiedstudentimg">
                          <div className="satisfied-image">
                            <video
                              width="400"
                              preload="auto"
                              muted
                              autoplay="/"
                              playsinline="/"
                              loop="/"
                              id="myVideo"
                            >
                              <source
                                src={`/img/${rec.studentVideo}`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>

                          <div className="student-info flex-box">
                            <span
                              className="video-play-button popup-btn"
                              onClick={(e) =>
                                handlePlayVideo(`/img/${rec.studentVideo}`)
                              }
                            >
                              <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                            </span>
                            <div className="student-detail">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star-o"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : ( */}
              <Slider className="testimonailslider" {...options}>
                {TestimonialJson?.map((rec, index) => {
                  return rec.contentType === "text" ? (
                    <div className="testimonailslide" key={index}>
                      <div className="testimonialslidecntnt">
                        <div className="testimonial-inner">
                          <h5 className="overflow">{rec.testimonialText}</h5>
                          <div className="client-info flex-box">
                            <div className="client-image">
                              <LazyLoadingImages src="/img/default.jpg" />
                            </div>
                            <div className="client-details">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="testimonailslide" key={index}>
                      <div className="satisfiedstudentimg">
                        <div className="satisfied-image">
                          <video
                            width="400"
                            preload="auto"
                            muted
                            autoplay="/"
                            playsinline="/"
                            loop="/"
                            id="myVideo"
                          >
                            <source
                              src={`/img/${rec.studentVideo}`}
                              type="video/mp4"
                            ></source>
                          </video>
                        </div>

                        <div className="student-info flex-box">
                          <span
                            className="video-play-button popup-btn"
                            onClick={(e) =>
                              handlePlayVideo(`/img/${rec.studentVideo}`)
                            }
                          >
                            <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                          </span>
                          <div className="student-detail">
                            <h6>{rec.studentname}</h6>
                            <div className="star-rating">
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star-o"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              {/* ) : ("")} */}
            </div>
          </div>
        </div>
      </section>
      <div className={activeSec ? "video-popup active" : "video-popup"}>
        <div className="popup-bg"></div>
        <div className="popup-content">
          {videoUrl}
          <button
            className="close-btn"
            onClick={(e) => {
              setActiveSec(false);
              setVideoUrl("");
            }}
          >
            <img src="/img/close-bg.png" alt="student" />
          </button>
        </div>
      </div>
    </>
  );
});

export default Testimonialsec;
