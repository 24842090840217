import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getAppliedCourse } from "../../../actions/courseActions";
import moment from "moment/moment";
import { COURSE_APPLY_LIST_RESET } from "../../../constants/courseConstants";

export default function OngoingApplications() {
  const dispatch = useDispatch();
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const courseApplied = useSelector((state) => state.courseApplied);
  const { loading, error, courseapplys } = courseApplied;

  const getApplications = () => {
    dispatch(getAppliedCourse());
  };

  useEffect(() => {
    if (!courseapplys) {
      getApplications();
      if (error) dispatch({ type: COURSE_APPLY_LIST_RESET });
    }
  }, [dispatch, courseapplys, loading, error]);

  return loading ? (
    <div className="shortlistedcourses ongoing-applications featuredbg-style">
      <div className="headingbox mb-tzero">
        <h2>Ongoing Applications</h2>
      </div>
      <div className="shortlistedcours-main ongoing-applications-main">
        Loading...
      </div>
    </div>
  ) : courseapplys && courseapplys ? (
    <div className="shortlistedcourses ongoing-applications featuredbg-style">
      <div className="headingbox mb-tzero">
        <h2>Ongoing Applications</h2>
      </div>
      <div className="shortlistedcours-main ongoing-applications-main">
        <Slider className="ongoingapplicationsslider" {...options}>
          {courseapplys.map((crs) => {
            return (
              <div className="ongoingapplicationsslide">
                <div className="ongoingapplicationsinner equal-height">
                  <div className="ongoingapplicationscntnt">
                    <h6>{crs.University_Name}</h6>
                    <h3>{crs.Course_Name}</h3>
                    <div className="degree-duration flex-box">
                      <div className="degree-duration-info">
                        <h4>Duration</h4>
                        <h5>{crs.Duration}</h5>
                      </div>
                      <div className="degree-duration-info">
                        <h4>Degree Level</h4>
                        <h5>{crs.Study_Level}</h5>
                      </div>
                      <div className="degree-duration-info">
                        <h4>Location</h4>
                        <h5>{crs.Country}</h5>
                      </div>
                    </div>
                    <div className="application-status-details">
                      {crs.Application_Status ? (
                        <p className="status application-status application-status-inprogress">
                          Status: <strong>{crs.Application_Status}</strong>
                        </p>
                      ) : (
                        ""
                      )}

                      {crs.APPLICATION_SUBMISSION_DATE != null ? (
                        <p className="applied-date application-status">
                          Applied On{" "}
                          <strong>
                            {" "}
                            {moment(crs.APPLICATION_SUBMISSION_DATE).format(
                              "DD MMM YYYY"
                            )}
                          </strong>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <a
                      href={`/course/${crs.Course_Id}`}
                      className="view-details"
                    >
                      <img src="/img/arrow-white.png" alt="Arrow White" />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  ) : (
    <></>
  );
}
