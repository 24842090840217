import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Aboutvisasec = React.memo(function () {
  return (
    <div>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/divers-group-people-is-working-office.png')`,
          backgroundColor: `#0B0B28`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec skilled-work-regional compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper just-center">
            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  General Skilled Migration
                  <br />
                </span>{" "}
                Subclass 190
              </h2>

              <p>
                The Skilled Work Regional Visa – Subclass 491 is a culmination
                of two visa types, namely
              </p>

              <ul className="skilled-independent-visa-info-list full-width-925">
                <li className="skilled-independent-visa-info-list-item flex-box align-center">
                  <LazyLoadingImages
                    src="/img/check-circle-image.png"
                    alt="student-image"
                  />
                  <span>The Skilled Independent Subclass 189</span>
                </li>
                <li className="skilled-independent-visa-info-list-item flex-box align-center">
                  <LazyLoadingImages
                    src="/img/check-circle-image.png"
                    alt="student-image"
                  />
                  <span>The Skilled Regional Nominated Subclass 491</span>
                </li>
              </ul>

              <p>
                This is a permanent settlement visa to Australia that does not
                require the applicant to be sponsored by the employer.
              </p>
            </div>

            <div className="about-visa-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/group-business-executives.jpg"
                alt="student-image"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Aboutvisasec;
