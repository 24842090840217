import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Exchangeassistance = React.memo(function Exchangeassistance() {
  return (
    <section className="exchange-assistance compadding forex-card-work">
      <div className="container">
        <div className="exchange-assistance-wrapper forex-card-work-wrapper flex-box align-center">
          <div className="forex-card-image-box full-width-925">
            <div className="forex-card-image">
              <LazyLoadingImages
                src="/img/visa-passport.jpg"
                alt="visa-passport-image"
              />
            </div>

            <span className="forex-visa-card-image">
              <LazyLoadingImages src="/img/visa-card.png" alt=" visa-card" />
            </span>
          </div>

          <div className="exchange-assistance-content headingbox mb-zero letter-normal full-width-925">
            <h2>
              <span className="themecolor">How Does A</span> Forex Card work?
            </h2>

            <p>
              You can load your Travel Card as per the applicable Forex Card
              rates of the bank when you are still in India. The card can be
              loaded with most of the popular currencies. Once your card is
              loaded with foreign currency of your choice, you are no longer
              required to worry about the fluctuating exchange rates.
            </p>

            <p>
              Once you reach your destination, you can then use the Travel Card
              just like you use a Debit Card for making payments. Moreover, you
              can also use the Internet Banking portal of the bank to reload the
              Travel Card anytime you wish.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Exchangeassistance;
