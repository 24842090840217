export const MESSAGE_ADD_REQUEST = 'MESSAGE_ADD_REQUEST'
export const MESSAGE_ADD_SUCCESS = 'MESSAGE_ADD_SUCCESS'
export const MESSAGE_ADD_FAIL = 'MESSAGE_ADD_FAIL'
export const MESSAGE_ADD_RESET = 'MESSAGE_ADD_RESET'

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAIL = 'MESSAGE_LIST_FAIL'
export const MESSAGE_LIST_RESET = 'MESSAGE_LIST_RESET'

export const MESSAGE_INTERNAL_DETAIL_REQUEST = 'MESSAGE_INTERNAL_DETAIL_REQUEST'
export const MESSAGE_INTERNAL_DETAIL_SUCCESS = 'MESSAGE_INTERNAL_DETAIL_SUCCESS'
export const MESSAGE_INTERNAL_DETAIL_FAIL = 'MESSAGE_INTERNAL_DETAIL_FAIL'
export const MESSAGE_INTERNAL_DETAIL_RESET = 'MESSAGE_INTERNAL_DETAIL_RESET'

export const MESSAGE_ADD_NEW_REQUEST = 'MESSAGE_ADD_NEW_REQUEST'
export const MESSAGE_ADD_NEW_SUCCESS = 'MESSAGE_ADD_NEW_SUCCESS'
export const MESSAGE_ADD_NEW_FAIL = 'MESSAGE_ADD_NEW_FAIL'
export const MESSAGE_ADD_NEW_RESET = 'MESSAGE_ADD_NEW_RESET'