import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Amberstudent = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner innr-student-banner-sec"
        style={{
          backgroundImage: `url('/img/housing-banner.png')`,
          backgroundColor: `#0B0B28`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Student Accommodation Assistance</h4>
              <h1>Home Away From Home</h1>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="amber-student compadding">
        <div className="container">
          <div className="flex-box amber-student-wrapper align-center">
            <div className="amber-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/gabriel-beaudry.jpg"
                alt="student-image"
              />
            </div>

            <div className="amber-details headingbox mb-zero letter-normal full-width-925">
              <h2>
                <span className="themecolor">What Is</span> University Living
              </h2>

              <p>
                University Living is a platform for cross-border students to
                book long-term accommodation near their colleges without the
                hassle of negotiation, non-standardised and cumbersome
                paperwork, lack of credit score; and broken payment process.
              </p>
              <p>
                Millions of people move to foreign countries each year for work,
                education, and commercial reasons. All face numerous issues with
                regulatory compliance, logistics, and cultural integration.
              </p>
              <p>
                Every year, University Living solves the difficulty of house
                hunting and booking for 80 million students from all over the
                world.
              </p>

              {/* <p>
                More than a million people leave their home country every year
                and move to another land for studies, work or livelihood. All
                face multiple problems starting from cultural integration to
                logistics and much more.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="lightgrey compadding">
        <div className="container">
          <div className="headingbox letter-normal text-center com-headingbox">
            <h2>
              <span className="themecolor">How it works at</span> University
              Living
            </h2>

            {/* <p>
              There are a set of documents that are required for each type of
              visa. Follow on below to know in detail about every visa type
            </p> */}
          </div>

          <div className="flex-box how-it-work-wrapper">
            <div className="how-it-work-list full-width-925">
              <div className="how-it-work-list-item flex-box align-center">
                <div className="how-it-work-icon">
                  <LazyLoadingImages
                    src="/img/choose.png"
                    alt="student-image"
                  />
                </div>

                <div className="how-it-work-list-content headingbox mb-zero letter-normal">
                  <h4>Discover</h4>

                  <p>
                    Look through the numerous flats featured on the University
                    Living portal and filter the ones that meet your
                    requirements. If you are not satisfied with the listings
                    given on the website, they will find you a flat that meets
                    your preferences.
                  </p>
                </div>
              </div>

              <div className="how-it-work-list-item flex-box align-center">
                <div className="how-it-work-icon">
                  <LazyLoadingImages
                    src="/img/finalize.png"
                    alt="student-image"
                  />
                </div>

                <div className="how-it-work-list-content headingbox mb-zero letter-normal">
                  <h4>Finalize</h4>

                  <p>
                    Their expert property consultants will help you with
                    additional information you need regarding the property and
                    surrounding area including details about the rent, deposit,
                    and application fee related to the lease procedure.
                  </p>
                </div>
              </div>

              <div className="how-it-work-list-item flex-box align-center">
                <div className="how-it-work-icon">
                  <LazyLoadingImages
                    src="/img/paperwork.png"
                    alt="student-image"
                  />
                </div>

                <div className="how-it-work-list-content headingbox mb-zero letter-normal">
                  <h4>Paperwork</h4>

                  <p>
                    University Living will get the paperwork started for you
                    when you've decided on a property. Paperwork includes
                    signing your leasing agreement and other documents. It may
                    involve paying the first month's rent in addition to other
                    charges like a one-time application fee.
                  </p>
                </div>
              </div>

              <div className="how-it-work-list-item flex-box align-center">
                <div className="how-it-work-icon">
                  <LazyLoadingImages
                    src="/img/booked.png"
                    alt="student-image"
                  />
                </div>

                <div className="how-it-work-list-content headingbox mb-zero letter-normal">
                  <h4>Booked</h4>

                  <p>
                    You've now successfully reserved an apartment in the city
                    you're flying to. The only thing left is packing!
                  </p>
                </div>
              </div>
            </div>

            <div className="how-it-work-image full-width-925">
              <LazyLoadingImages src="/img/furniture.jpg" alt="student-image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Amberstudent;
