import React from "react";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const EducationPartner = React.memo(function () {
  const options = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    infinite: true,
    slidesToShow: window.innerWidth <= 830 ? 4 : 6,
    rows: 3,
    centerPadding: "20px",
    slidesToScroll: 2,
    variableWidth: true,
  };

  const options1 = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    rows: 2,
    variableWidth: true,
  };
  return (
    <>
      <section className="educationpartner">
        <div className="container compadding">
          <div className="headingbox text-center">
            <h2>
              <span className="smh">Most Popular</span>Education Partners
            </h2>
          </div>
          {/* <!-- Desktop Slider --> */}
          <Slider className="educationpartnerslider hide-500" {...options}>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo2.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo3.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo4.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo5.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo6.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo7.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo8.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo9.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo10.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo12.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo13.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo14.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo15.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo16.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo17.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo18.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo19.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo20.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo2.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo3.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo4.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo5.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo6.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo7.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo8.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo9.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo10.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo12.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo13.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo14.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo15.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo16.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo17.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo18.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo19.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo20.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
          </Slider>

          {/* <!-- Mobile SLider --> */}
          <Slider className="mobeducationpartnerslider show-500" {...options1}>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo2.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo3.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo4.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo5.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo6.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo7.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo8.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo9.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo10.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo12.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo13.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo14.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo15.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo16.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo17.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo18.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo19.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo20.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo2.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo3.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo4.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo5.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo6.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo7.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo8.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo9.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo10.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo12.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo13.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo14.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo15.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo16.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo17.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo18.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo19.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo20.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
            <div className="">
              <div className="educationpartnerlogo equal-height">
                <LazyLoadingImages
                  src="/img/educationpartnerlogo11.png"
                  alt="Partner Logo"
                />
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
});

export default EducationPartner;
