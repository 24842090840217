import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppliedCourse } from "../../../actions/courseActions";
import LeftPanel from "../Common/leftPanel";
import Header from "../Common/Header";

export default function Index() {
  const dispatch = useDispatch();

  const courseApplied = useSelector((state) => state.courseApplied);
  const { loading, error, courseapplys } = courseApplied;

  const getCoursesApplied = () => {
    dispatch(getAppliedCourse());
  };

  useEffect(() => {
    if (!courseapplys) {
      getCoursesApplied();
    }
  }, [dispatch, courseapplys]);
  return (
    <Header>
      {courseapplys ? (
        <div className="sec-lg-col-75 dashboard-rightbox common-dashboard-search-box sec-col-83">
          <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
            <div className="coursesearch mb-tzero">
              <form action="" className="searchofcourses">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for Courses...."
                />
                <button type="submit" name="coursesarchsubmit" className="btn">
                  <img src="/img/searchicon.png" alt="Search Icon" />
                </button>
              </form>
            </div>
            <div className="notification-bellbox">
              <div
                className="notification-bell"
                style={{
                  backgroundImage: `url('/img/bell.png')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >
                <span className="notification">
                  <img src="/img/notification-circle.png" alt="dashboard" />
                </span>
              </div>
            </div>
          </div>
          <div className="featuredbg-style dash-common-heading-style ongoing-applications-heading">
            <h1>Ongoing Applications</h1>

            {courseapplys ? (
              courseapplys.map((courseappl) => (
                <div className="dash-application-box dash-application-box-shadow">
                  <div className="appcour-status-box flex-box align-center">
                    <div className="appcour-status-topbox-wrapper flex-box align-center">
                      <ul className="ongoing-cour-info ongoing-cour-listing-info">
                        <li
                          className={
                            courseappl.ApplicationStatus ===
                            "Application Rejected"
                              ? "ongoing-cour-info-box status-rejected"
                              : "ongoing-cour-info-box ongoing-cour-listing-info-box status-accepted"
                          }
                        >
                          Status:{" "}
                          <strong>{courseappl.ApplicationStatus}</strong>
                        </li>
                        {courseappl.INTAKE_YEAR ? (
                          <li className="ongoing-cour-info-box ongoing-cour-listing-info-box ongoing-cour-date">
                            Admission Date:{" "}
                            <strong>
                              {courseappl.Month},{courseappl.INTAKE_YEAR}
                            </strong>
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="ongoing-cour-info-box ongoing-cour-listing-info-box ongoing-cour-country">
                          Country: <strong>{courseappl.Country}</strong>
                        </li>
                        <li className="ongoing-cour-info-box ongoing-cour-listing-info-box ongoing-cour-submitted-date bg-grey">
                          Submitted On:{" "}
                          <strong>
                            {moment(
                              courseappl.APPLICATION_SUBMISSION_DATE.split(
                                "T"
                              )[0]
                            ).format("DD-MMM-YYYY")}
                          </strong>
                        </li>
                      </ul>
                    </div>
                    {/* <span className="edit-appcour-status">
                          <img src="/img/edit.png" alt="dashboard" />
                        </span> */}
                  </div>
                  <a
                    href={`/course/${courseappl.Course_Id}`}
                    className="appCour-name"
                  >
                    <h3>
                      {courseappl.Course_Name} - SED{courseappl.Id}{" "}
                    </h3>
                  </a>
                  <h6>
                    <b>University/College Name</b>: -{" "}
                    {courseappl.University_Name}
                  </h6>

                  {/* <div className="stag-progress">
                        <div className="stag-progress-bar stag1">
                          <p>Select Course</p>
                        </div>
                        <div className="stag-progress-bar stag2">
                          <p>Update Details</p>
                        </div>
                        <div className="stag-progress-bar stag3">
                          <p>Upload Documents</p>
                        </div>
                        <div className="stag-progress-bar stag4">
                          <p>Under Review</p>
                        </div>
                        <div className="stag-progress-bar stag5">
                          <p>Application Accepted </p>
                        </div>
                      </div> */}
                  <a
                    href={`/course/${courseappl.Course_Id}`}
                    className="view-details"
                    tabindex="0"
                    title="View Course"
                  >
                    <img src="/img/arrow-white.png" alt="View Course" />
                  </a>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div class="featuredbg-style dashboard-allcourses mb-900-zero">
          <div class="no-wishlist text-center">
            <div class="txt">
              <h2>
                Do not have any <span>course</span> under your
                <br /> Ongoing Application section start browsing now
              </h2>
              <a href="/courses" class="btn text-center">
                Browse Courses
              </a>
            </div>
          </div>
        </div>
      )}
    </Header>
  );
}
