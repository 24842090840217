import React from "react";

const ServicesCountList = React.memo(function () {
  return (
    <section className="services-count-list">
      <div className="container">
        <div className="services-list-wrapper flex-box">
          <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/anete-lusina.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>Pre-Departure Briefing & Insurance</h3>

              <p>
                Jitters on the day of departure!! Don't worry! We are here to
                take care of your unknown fears and discomforts.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile. The entire process begins
                with reviewing the student’s academic performance. This helps us
                navigate the possible career types that offers the best chances
                of admission.
              </p>
              <a href="/consult_a_counselor" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div>

          <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/medium-shot-smiley-woman.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>Visa Application & Migration Assistance</h3>

              <p>
                Jitters on the day of departure!! Don't worry! We are here to
                take care of your unknown fears and discomforts.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile. The entire process begins
                with reviewing the student’s academic performance. This helps us
                navigate the possible career types that offers the best chances
                of admission. We give special emphasis on interacting with the
                students to understand their interests. This insight is then
                used to narrow down the choices that may be suitable for her /
                him. In case the student has some predetermined choices, we will
                include at the initial profiling stage. Yet, we will share the
                final decision for the most suitable career option only after
                careful evaluation and consideration.
              </p>

              <a href="/get_visa" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div>

          {/* <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/female-staff-checking-boarding-pass.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>Visa Application & Migration Assistance</h3>

              <p>
                Depending upon your intent to make the international travel,
                Search Education can help you secure.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile.
              </p>

              <a href="/" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div> */}

          <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/consulting-with-lawyer.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>Help in Loan</h3>

              <p>
                Scholarships are blessings for those with excellent academic
                performance in the past.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile. The entire process begins
                with reviewing the student’s academic performance. This helps us
                navigate the possible career types that offers the best chances
                of admission. We give special emphasis on interacting with the
                students to understand their interests.
              </p>

              <a href="/hdfc_credila_edu_loan" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div>

          <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/pay-goods-by-credit-card-through.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>International Bank Account</h3>

              <p>
                The initial questions that we ask any student starts with -
                which Program, then any university preference followed by
                country.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile. The entire process begins
                with reviewing the student’s academic performance. This helps us
                navigate the possible career types that offers the best chances
                of admission. We give special emphasis on interacting with the
                students to understand their interests. This insight is then
                used to narrow down the choices that may be suitable for her /
                him. In case the student has some predetermined choices, we will
                include at the initial profiling stage. Yet, we will share the
                final decision for the most suitable career option only after
                careful evaluation and consideration.
              </p>

              <a href="/consult_a_counselor" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div>

          <div className="services-imagebox full-width-925">
            <div
              className="services-image bg-style"
              style={{
                backgroundImage: `url('/img/man-selecting-one-money.jpg')`,
              }}
            ></div>

            <div className="services-details headingbox mb-zero letter-normal counter-content">
              <h6>
                <span className="themecolor">/ </span>
              </h6>

              <h3>Forex</h3>

              <p>
                Scholarships are blessings for those with excellent academic
                performance in the past.
              </p>

              <p className="moretext">
                {" "}
                Hence, we assist students building their profiles based on their
                capabilities and interests. And we find an university and a
                course corresponding to the profile. The entire process begins
                with reviewing the student’s academic performance. This helps us
                navigate the possible career types that offers the best chances
                of admission. We give special emphasis on interacting with the
                students to understand their interests. This insight is then
                used to narrow down the choices that may be suitable for her /
                him. In case the student has some predetermined choices, we will
                include at the initial profiling stage. Yet, we will share the
                final decision for the most suitable career option only after
                careful evaluation and consideration. This would be done over
                the call or in-person meetings.
              </p>

              <a href="/forex" className="btn moreless-button">
                Explore
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ServicesCountList;
