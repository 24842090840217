import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import CareerForm from "./CareerForm";
import careerJson from "../../data/opportunities.json";
import { useRef } from "react";
import { Button } from "react-bootstrap";

const Currentopening = React.memo(function Currentopening() {
  const applySec = useRef(null);
  function scrollToSection() {
    // window.scrollTo({
    //   top: applySec.current?.offsetTop,
    //   behavior: "smooth",
    // });
    // -- Second way
    applySec.current?.scrollIntoView({ behavior: "smooth" });
    // -- Third way
    // const element=document.getElementById("applySec");
    // element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
  return (
    <section className="current-openings lightgrey compadding">
      <div className="container">
        <div className="headingbox text-center">
          <h2>
            <span className="themecolor">Current </span>Openings
          </h2>
        </div>
        {careerJson.map((crj, index) => (
          <div className="current-openings-box" key={index}>
            <div className="flex-box current-openings-wrapper headingbox align-center mb-0">
              <div className="current-openings-box-top-heading">
                <h4>{crj.position}</h4>
                <h5>
                  {crj.location} | {crj.experience} | {crj.period}
                </h5>
              </div>
              <div className="current-openings-apply-now">
                <a
                  onClick={scrollToSection}
                  className="btn current-openings-modal-toggle"
                >
                  Apply Now
                </a>
              </div>
            </div>
            <p>{crj.description}</p>
          </div>
        ))}
      </div>
      <div className="current-openings-modal-main">
        <div className="overlay" id="current-openings-overlay"></div>
        <div
          className="modal-main modal current-openings-modal"
          data-id="modal1"
        >
          <div className="current-openings-modal-content">
            <div className="current-openings-modal-content-inner text-box">
              <span className="close-modal current-openings-modal-toggle">
                <LazyLoadingImages
                  src="/img/close-black-icon.png"
                  alt="arrow"
                />
              </span>
              <div className="job-description headingbox mb-0">
                <h3>Job Description</h3>
                <div className="job-dec-details">
                  <h6 className="themecolor">The Opportunity</h6>
                  <p>
                    As our team continues to grow at a rapid pace in our India
                    office, we are adding to our Talent Acquisition team. We are
                    looking for a passionate and results driven Talent Recruiter
                    who provides expertise through partnering with hiring teams,
                    sourcing, and selection of quality candidates from internal
                    and external talent pools. Reporting to the Human Resources
                    Manager in our India office and working closely with our
                    Canada head office Talent team, you will be supporting the
                    team with finding top candidates for roles that are crucial
                    to the further growth of the company.
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Roles & Responsibilities</h6>
                  <p>
                    Creating comprehensive talent sourcing and recruitment
                    strategies, leveraging internal and external resources in
                    order to hire the best talent in the market Developing
                    strong relationships with hiring managers, candidates and
                    cross-functional partners in order to create a best in class
                    recruitment experience Building effective networks and
                    relationships to maintain a strong pipeline of top talent
                    for present and forecasted hires Owning a seat at the table
                    with business leaders to understand and drive an effective
                    Engineering recruitment strategy Maintaining accurate
                    documentation on recruitment activities, from pipeline
                    status to strategy results Applying sound judgement handling
                    escalations independently and knowing when to escalate
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Requirements</h6>
                  <p>
                    3+ years of fast-paced, high-volume talent recruiting
                    experience A drive to provide the best candidate experience
                    Strong organizational skills, managing multiple tasks at
                    once Experience using data insights to develop hiring
                    recommendations and improving processes Strong communication
                    and presentation skills Working knowledge of ATS tools and
                    technologies (Lever, BambooHr, Linkedin Recruiter, Gsuite
                    etc..)
                  </p>
                </div>
                <div
                  ref={applySec}
                  className="career-popup-form contact-form-wrapper"
                >
                  <CareerForm />
                </div>
                <div className="job-dec-details job-apply-now">
                  <h6>
                    To apply : Send your CV to
                    <span className="themecolor">
                      <a href="mailto:hr@searchedu.com.au">
                        hr@searchedu.com.au
                      </a>
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-main current-openings-modal"
          data-id="modal2"
        >
          <div className="current-openings-modal-content">
            <div className="current-openings-modal-content-inner text-box">
              <span className="close-modal current-openings-modal-toggle">
                <LazyLoadingImages
                  src="/img/close-black-icon.png"
                  alt="arrow"
                />
              </span>
              <div className="job-description headingbox mb-0">
                <h3>Job Description</h3>
                <div className="job-dec-details">
                  <h6 className="themecolor">The Opportunity</h6>
                  <p>
                    As our team continues to grow at a rapid pace in our India
                    office, we are adding to our Talent Acquisition team. We are
                    looking for a passionate and results driven Talent Recruiter
                    who provides expertise through partnering with hiring teams,
                    sourcing, and selection of quality candidates from internal
                    and external talent pools. Reporting to the Human Resources
                    Manager in our India office and working closely with our
                    Canada head office Talent team, you will be supporting the
                    team with finding top candidates for roles that are crucial
                    to the further growth of the company.
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Roles & Responsibilities</h6>
                  <p>
                    Creating comprehensive talent sourcing and recruitment
                    strategies, leveraging internal and external resources in
                    order to hire the best talent in the market Developing
                    strong relationships with hiring managers, candidates and
                    cross-functional partners in order to create a best in class
                    recruitment experience Building effective networks and
                    relationships to maintain a strong pipeline of top talent
                    for present and forecasted hires Owning a seat at the table
                    with business leaders to understand and drive an effective
                    Engineering recruitment strategy Maintaining accurate
                    documentation on recruitment activities, from pipeline
                    status to strategy results Applying sound judgement handling
                    escalations independently and knowing when to escalate
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Requirements</h6>
                  <p>
                    3+ years of fast-paced, high-volume talent recruiting
                    experience A drive to provide the best candidate experience
                    Strong organizational skills, managing multiple tasks at
                    once Experience using data insights to develop hiring
                    recommendations and improving processes Strong communication
                    and presentation skills Working knowledge of ATS tools and
                    technologies (Lever, BambooHr, Linkedin Recruiter, Gsuite
                    etc..)
                  </p>
                </div>
                <div className="job-dec-details job-apply-now">
                  <h6>
                    To apply : Send your CV to{" "}
                    <span className="themecolor">
                      <a href="mailto:hr@searchedu.com.au">
                        hr@searchedu.com.au
                      </a>
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-main current-openings-modal"
          data-id="modal3"
        >
          <div className="current-openings-modal-content">
            <div className="current-openings-modal-content-inner text-box">
              <span className="close-modal current-openings-modal-toggle">
                <LazyLoadingImages
                  src="/img/close-black-icon.png"
                  alt="arrow"
                />
              </span>
              <div className="job-description headingbox mb-0">
                <h3>Job Description</h3>
                <div className="job-dec-details">
                  <h6 className="themecolor">The Opportunity</h6>
                  <p>
                    As our team continues to grow at a rapid pace in our India
                    office, we are adding to our Talent Acquisition team. We are
                    looking for a passionate and results driven Talent Recruiter
                    who provides expertise through partnering with hiring teams,
                    sourcing, and selection of quality candidates from internal
                    and external talent pools. Reporting to the Human Resources
                    Manager in our India office and working closely with our
                    Canada head office Talent team, you will be supporting the
                    team with finding top candidates for roles that are crucial
                    to the further growth of the company.
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Roles & Responsibilities</h6>
                  <p>
                    Creating comprehensive talent sourcing and recruitment
                    strategies, leveraging internal and external resources in
                    order to hire the best talent in the market Developing
                    strong relationships with hiring managers, candidates and
                    cross-functional partners in order to create a best in class
                    recruitment experience Building effective networks and
                    relationships to maintain a strong pipeline of top talent
                    for present and forecasted hires Owning a seat at the table
                    with business leaders to understand and drive an effective
                    Engineering recruitment strategy Maintaining accurate
                    documentation on recruitment activities, from pipeline
                    status to strategy results Applying sound judgement handling
                    escalations independently and knowing when to escalate
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Requirements</h6>
                  <p>
                    3+ years of fast-paced, high-volume talent recruiting
                    experience A drive to provide the best candidate experience
                    Strong organizational skills, managing multiple tasks at
                    once Experience using data insights to develop hiring
                    recommendations and improving processes Strong communication
                    and presentation skills Working knowledge of ATS tools and
                    technologies (Lever, BambooHr, Linkedin Recruiter, Gsuite
                    etc..)
                  </p>
                </div>
                <div className="job-dec-details job-apply-now">
                  <h6>
                    To apply : Send your CV to{" "}
                    <span className="themecolor">
                      <a href="mailto:hr@searchedu.com.au">
                        hr@searchedu.com.au
                      </a>
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-main current-openings-modal"
          data-id="modal4"
        >
          <div className="current-openings-modal-content">
            <div className="current-openings-modal-content-inner text-box">
              <span className="close-modal current-openings-modal-toggle">
                <LazyLoadingImages
                  src="/img/close-black-icon.png"
                  alt="arrow"
                />
              </span>
              <div className="job-description headingbox mb-0">
                <h3>Job Description</h3>
                <div className="job-dec-details">
                  <h6 className="themecolor">The Opportunity</h6>
                  <p>
                    As our team continues to grow at a rapid pace in our India
                    office, we are adding to our Talent Acquisition team. We are
                    looking for a passionate and results driven Talent Recruiter
                    who provides expertise through partnering with hiring teams,
                    sourcing, and selection of quality candidates from internal
                    and external talent pools. Reporting to the Human Resources
                    Manager in our India office and working closely with our
                    Canada head office Talent team, you will be supporting the
                    team with finding top candidates for roles that are crucial
                    to the further growth of the company.
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Roles & Responsibilities</h6>
                  <p>
                    Creating comprehensive talent sourcing and recruitment
                    strategies, leveraging internal and external resources in
                    order to hire the best talent in the market Developing
                    strong relationships with hiring managers, candidates and
                    cross-functional partners in order to create a best in class
                    recruitment experience Building effective networks and
                    relationships to maintain a strong pipeline of top talent
                    for present and forecasted hires Owning a seat at the table
                    with business leaders to understand and drive an effective
                    Engineering recruitment strategy Maintaining accurate
                    documentation on recruitment activities, from pipeline
                    status to strategy results Applying sound judgement handling
                    escalations independently and knowing when to escalate
                  </p>
                </div>
                <div className="job-dec-details">
                  <h6 className="themecolor">Requirements</h6>
                  <p>
                    3+ years of fast-paced, high-volume talent recruiting
                    experience A drive to provide the best candidate experience
                    Strong organizational skills, managing multiple tasks at
                    once Experience using data insights to develop hiring
                    recommendations and improving processes Strong communication
                    and presentation skills Working knowledge of ATS tools and
                    technologies (Lever, BambooHr, Linkedin Recruiter, Gsuite
                    etc..)
                  </p>
                </div>
                <div className="job-dec-details job-apply-now">
                  <h6>
                    To apply : Send your CV to{" "}
                    <span className="themecolor">
                      <a href="mailto:hr@searchedu.com.au">
                        hr@searchedu.com.au
                      </a>
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="career-contact-form contact-form-wrapper full-width-925">
          <CareerForm />
        </div>
        {/* <div className="flex-box career-path-wrapper headingbox">
          <div className="career-pathleftbox full-width-925">
            <h2>
              <span className="themecolor">Unable To Find </span>A Suitable
              Career Path
            </h2>
            <p>
              Creating comprehensive talent sourcing and recruitment strategies,
              leveraging internal and external resources in order to hire the
              best talent.
            </p>
          </div>
          <div className="career-pathrightbox full-width-925">
            <h4>
              <span>Send your CV to</span>{" "}
              <Link to="mailto:hr@searchedu.com.au">hr@searchedu.com.au</Link>
            </h4>
          </div>
        </div> */}
      </div>
    </section>
  );
});

export default Currentopening;
