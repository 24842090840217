import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listCourseUniversity } from "../../actions/courseActions";
import { useParams } from "react-router-dom";

const AvailableCourses = React.memo(function AvailableCourses() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, error, courseUniList } = useSelector(
    (s) => s.CourseUniversity
  );
  const getcourses = () => {
    dispatch(listCourseUniversity(id));
  };
  useEffect(() => {
    if (!courseUniList) {
      getcourses();
    }
  }, [dispatch, courseUniList, id]);
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return courseUniList ? (
    <div
      className="mostpopularcoursesec listing-details-info-box available"
      id="available-course"
    >
      <div className="headingbox list-details-heading ">
        <h2>
          <strong>Available</strong> Courses
        </h2>
      </div>
      <div className="mostpopularuniversitysliderpro">
        <Slider className="mostpopularuniversityslider" {...options}>
          {courseUniList.length > 0
            ? courseUniList.map((crsl, index) => (
                <div className="mstpopularuniversityslide">
                  <div className="mstpopularuniversityinnr">
                    <div className="mstpopularimg">
                      <LazyLoadingImages
                        src={
                          crsl.Banner402x300
                            ? `${process.env.REACT_APP_SED_DOC_URL}/University/Banner402x300/${crsl.Banner402x300}`
                            : `/img/Banner402x300.jpg`
                        }
                        alt="mostpopularuniversity"
                      />
                    </div>
                    <div className="mstpopularcntnt">
                      <p className="location">{crsl.Course_Name}</p>
                      <div className="durationfeesem">
                        <div className="colhalf">
                          <p>
                            <span>Duration</span>
                            {crsl.Duration} months
                          </p>
                        </div>
                        <div className="colhalf">
                          <p>
                            <span>Course fee</span>
                            {crsl.Country_Symbol} {crsl.Yearly_Tuition_Fees}
                          </p>
                        </div>
                      </div>
                      <a href={`/course/${crsl.Course_Id}`} className="btn">
                        <LazyLoadingImages
                          src="/img/arrows-rightc.png"
                          alt="Arrow"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </Slider>
      </div>
    </div>
  ) : (
    ""
  );
});

export default AvailableCourses;
