import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const SearchCity = React.memo(function ({ removeCity }) {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const cityData = [
    {
      imageId: "1",
      imageUrl: "/img/Cities-04.webp",
      cityName: "Sydney",
      redirectLink: "/study_in_sydney",
    },
    {
      imageId: "2",
      imageUrl: "/img/Cities-05.webp",
      cityName: "Brisbane",
      redirectLink: "/study_in_brisbane",
    },
    {
      imageId: "3",
      imageUrl: "/img/Cities-06.webp",
      cityName: "Adelaide",
      redirectLink: "/study_in_adelaide",
    },
    {
      imageId: "4",
      imageUrl: "/img/Cities-07.webp",
      cityName: "Perth",
      redirectLink: "/study_in_perth",
    },
    {
      imageId: "5",
      imageUrl: "/img/Cities-03.webp",
      cityName: "Melbourne",
      redirectLink: "/study_in_melbourne",
    },
    {
      imageId: "6",
      imageUrl: "/img/Cities-10.webp",
      cityName: "Hobart",
      redirectLink: "/study_in_hobart",
    },
    {
      imageId: "7",
      imageUrl: "/img/Cities-08.webp",
      cityName: "Darwin",
      redirectLink: "/study_in_darwin",
    },
  ];

  return (
    <Slider className="searchcountryslider" {...options}>
      {cityData.map((cntry, index) => {
        return cntry.cityName === removeCity ? (
          ""
        ) : (
          <div className="searchcountryslide" key={index}>
            <a href={cntry.redirectLink}>
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{
                    backgroundImage: `url('${cntry.imageUrl}')`,
                  }}
                ></div>
                <p>
                  Study in <strong>{cntry.cityName}</strong>
                </p>
              </div>
            </a>
          </div>
        );
      })}
    </Slider>
  );
});

export default SearchCity;
