import {
  CAREER_CREATE_REQUEST,
  CAREER_CREATE_SUCCESS,
  CAREER_CREATE_FAIL,
} from "../constants/careerConstants";
import api2 from "../core/api2";

export const createCareer = (careerJson) => async (dispatch) => {
  try {
    dispatch({
      type: CAREER_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await api2.post(`/career`, careerJson);

    const response = data.Result;

    if (response.includes("Error")) {
      dispatch({
        type: CAREER_CREATE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: CAREER_CREATE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: CAREER_CREATE_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
