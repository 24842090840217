import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useDispatch, useSelector } from "react-redux";
import { contactJson } from "../../models/userModel";
import { Link } from "react-router-dom";
import CountryListSelect from "../Common/CountryListSelect";
import { createContact } from "../../actions/contactActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";

const Index = React.memo(function Index() {
  const dispatch = useDispatch();
  const [contactRec, setContactRec] = useState(contactJson);
  const [ierror, setIerror] = useState("");

  const [active, setActive] = useState("india");

  const handleActive = (vl) => {
    if (active === vl) setActive("india");
    else setActive(vl);
  };

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, success, error } = contactCreate;

  const handleRec = (e) => {
    setContactRec({
      ...contactRec,
      [e.target.name]: e.target.value,
      full_name: contactRec.First_Name + " " + contactRec.Last_Name,
      Contact_Platform: "wb",
      Link: window.location.href,
      Comments: contactRec.AComments + `, Country: ${country?.country_name}`,
    });
  };

  const handleSubmit = () => {
    try {
      var errorm = "";
      if (contactRec.First_Name === "" || contactRec.First_Name === undefined)
        errorm = "Name cannot be Blank!";
      if (contactRec.Phone_No === "" || contactRec.Phone_No === undefined)
        errorm = "Phone Number cannot be Blank!";
      if (contactRec.Email_Address === "" || contactRec.full_name === undefined)
        errorm = "Email Address cannot be Blank!";
      if (contactRec.AComments === "" || contactRec.AComments === undefined)
        errorm = "Please type your Message!";

      if (errorm === "") {
        setIerror("");
        dispatch(
          createContact({
            ...contactRec,
            Link: window.location.href,
            country: country?.country_name,
          })
        );
      } else {
        setIerror(errorm);
      }
    } catch (ex) {
      setIerror(ex.message);
    }
  };

  useEffect(() => {
    if (success) {
      setIerror("");
      setTimeout(() => {
        dispatch({ type: CONTACT_CREATE_RESET });
      }, 2000);
    }
  }, [success]);

  return (
    <>
      <Helmet>
        <title>Search Education - Contact Us</title>
        <meta name="title" content="Search Education - Contact Us" />
      </Helmet>

      <Header />
      {/* <!-- Start Inner Page banner --> */}

      <section
        className="innrbanner"
        style={{
          backgroundImage: `url('/img/businessman-touching-virtual-screen.png')`,
          backgroundColor: "#07070B",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Search Education</h4>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-info-sec compadding">
        <div className="container">
          <div className="flex-box contact-details-wrapper just-center">
            <div className="contact-details-info headingbox letter-normal full-width-925">
              <h2>
                <span className="themecolor">
                  Interested in knowing more?
                  <br />
                </span>
                Connect now!
              </h2>
              <p>
                Thinking to <strong>Study Abroad </strong>with Search Education?
                Fill in your details and our team will call you back at the
                earliest!
              </p>
            </div>
            <div className="contact-form-wrapper full-width-925">
              <form className="contact-form flex-box" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="firstname">First Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="First_Name"
                    id="firstname"
                    value={contactRec.First_Name}
                    onChange={(e) => handleRec(e)}
                  />
                </div>
                <div className="form-group">
                  <label for="lastname">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Last_Name"
                    id="lastname"
                    value={contactRec.Last_Name}
                    onChange={(e) => handleRec(e)}
                  />
                </div>
                <div className="form-group">
                  <label for="email">Email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="Email_Address"
                    id="email"
                    value={contactRec.Email_Address}
                    onChange={(e) => handleRec(e)}
                  />
                </div>
                <div className="form-group">
                  <label for="Phonenumber">Number*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Phone_No"
                    id="Phonenumber"
                    value={contactRec.Phone_No}
                    onChange={(e) => handleRec(e)}
                  />
                </div>
                <div className="form-group">
                  <label for="select-country">Select Country</label>
                  <select
                    className="form-control"
                    name="Country_Id"
                    value={contactRec.Country_Id}
                    onChange={(e) => handleRec(e)}
                  >
                    <option value="0">--Select--</option>
                    <CountryListSelect />
                  </select>
                </div>
                <div className="form-group">
                  <label for="select-state">Select State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="State"
                    id="State"
                    value={contactRec.State}
                    onChange={(e) => handleRec(e)}
                  />
                </div>
                <div className="form-group col-full">
                  <label for="yourmessage">Your message*</label>
                  <textarea
                    rows="3"
                    className="form-control"
                    id="yourmessage"
                    name="AComments"
                    value={contactRec.AComments}
                    onChange={(e) => handleRec(e)}
                  ></textarea>
                </div>
                <div className="form-group col-full">
                  {!loading ? (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="button" className="btn">
                      Loading ...
                    </button>
                  )}
                  <p style={{ color: "white", backgroundColor: "green" }}>
                    {success}
                  </p>
                  <p style={{ color: "white", backgroundColor: "red" }}>
                    {error}
                    {ierror}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-address-sec lightgrey compadding">
        <div className="container">
          <div className="headingbox contact-address-heading letter-normal text-center com-headingbox">
            <h2>
              <span className="themecolor">Our </span>Presence
            </h2>
            <p>
              For students seeking exceptional help and study abroad options. We
              create the ideal environment in which every student's desire may
              become a reality.
            </p>
          </div>
          <div
            className="contact-address-details bg-style"
            style={{ backgroundImage: `url('/img/white-world-map.png')` }}
          >
            <div className="contact-address-info-wrapper flex-box just-center">
              <ul className="countries-tabs-nav full-width-925">
                <li className={active === "india" ? "active" : ""}>
                  <Link onClick={() => handleActive("india")}>
                    <span className="countries-icon">
                      <LazyLoadingImages
                        src="/img/india-circle-flag.png"
                        alt="image"
                      />
                    </span>
                    India
                  </Link>
                </li>
                <li className={active === "australia" ? "active" : ""}>
                  <Link onClick={() => handleActive("australia")}>
                    <span className="countries-icon">
                      <LazyLoadingImages
                        src="/img/australia-circle-flag.png"
                        alt="image"
                      />
                    </span>
                    Australia
                  </Link>
                </li>
                <li className={active === "nepal" ? "active" : ""}>
                  <Link onClick={() => handleActive("nepal")}>
                    <span className="countries-icon">
                      <LazyLoadingImages
                        src="/img/nepal-circle-flag.png"
                        alt="image"
                      />
                    </span>
                    Nepal
                  </Link>
                </li>
                <li className={active === "chile" ? "active" : ""}>
                  <Link onClick={() => handleActive("chile")}>
                    <span className="countries-icon">
                      <LazyLoadingImages
                        src="/img/chile-circle-flag.png"
                        alt="image"
                      />
                    </span>
                    Chile
                  </Link>
                </li>
              </ul>
              <div className="countries-tabs-content full-width-925">
                <div
                  className={
                    active === "india"
                      ? "tab-content blk"
                      : "tab-content d-none"
                  }
                  id="india"
                >
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>India - Delhi</h6>
                    <p>
                      Office no. 1409-1412 (14th Floor), Ansal Tower 38, Nehru
                      Place, New Delhi, 110019{" "}
                    </p>
                    <p>
                      Phone No: +91 11-41043004, +911141043005, +911141043006
                    </p>
                  </div>
                  {/* <div className="tab-content-info headingbox letter-normal">
                    <h6>India - Sri Nagar</h6>
                    <p>
                      Top Floor, Mir Mall, Opposite District Police Lines,
                      Balgarden,Karan Nagar rd,Srinagar, Jammu and Kashmir,
                      190010
                    </p>
                    <p>Phone No: 0755-12345678</p>
                  </div> */}
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>India - Chandigarh</h6>
                    <p>
                      SCO 491-492, First Floor, Sector 35C, Chandigarh, 160022
                    </p>
                    <p>Phone No: +91 172-4192306</p>
                  </div>
                </div>

                <div
                  className={
                    active === "australia"
                      ? "tab-content blk"
                      : "tab-content d-none"
                  }
                  id="australia"
                >
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Sydney - Australia</h6>
                    <p>
                      Suite 501, 147 King Street, Sydney, NSW 2000 - Australia
                    </p>
                    <p>Phone No: +61 2 9221 7711</p>
                  </div>
                </div>

                <div
                  className={
                    active === "nepal"
                      ? "tab-content blk"
                      : "tab-content d-none"
                  }
                  id="nepal"
                >
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Kathmandu - Nepal</h6>
                    <p>
                      3rd & 4th Floor, Bandipur Building, Putalisadak,
                      Kathmandu, Nepal
                    </p>
                    <p>Phone No: +977 1 4544203, +977 9808152223</p>
                  </div>
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Mahendranagar - Nepal</h6>
                    <p>Madan Chowk, Mahendranagar, Nepal</p>
                    <p>Phone No: +977 99-523177, +977 9848758016</p>
                  </div>

                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Chitwan - Nepal</h6>
                    <p>Bharatpur, Nepal</p>
                    <p>Phone No: +977 56596784, +977 9821256313</p>
                  </div>

                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Birgunj - Nepal</h6>
                    <p>
                      3rd Floor, Rastriya Banijya Bank Building, Birgunj, Nepal
                    </p>
                    <p>Phone No: +977 51 591758, +977 9816012365</p>
                  </div>
                  {/* <div className="tab-content-info headingbox letter-normal">
                    <h6>Dang -nepal</h6>
                    <p>
                      Dang,Tulsipur 05, Ga line(Near Global IME Bank), Nepal
                    </p>
                    <p>Phone No: 0755-12345678</p>
                  </div> */}
                </div>

                <div
                  className={
                    active === "chile"
                      ? "tab-content blk"
                      : "tab-content d-none"
                  }
                  id="chile"
                >
                  <div className="tab-content-info headingbox letter-normal">
                    <h6>Las Condes - Chile</h6>
                    <p>Av.Apoquindo 6550, Oficina 205, Las Condes, Chile</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="contact-counselling compadding bg-style"
        style={{
          backgroundImage: `url('/img/young-pretty-woman-working-laptop.jpg')`,
          backgroundColor: "#091F33",
        }}
      >
        <div className="container">
          <div className="contact-counselling-wrapper full-width-925">
            <div className="headingbox letter-normal contact-counselling-heading">
              <h2>
                <span className="themecolor">1 to 1 </span>Counselling
              </h2>
              <p>
                We provide 1 to 1 counseling in which an individual gets a
                private and personalized talk with a skilled counselor. The
                counselor focuses on the individual's particular needs and
                issues and gives specialized guidance and assistance.
              </p>
            </div>
            <div className="flex-box contact-counselling-list">
              <div className="contact-counselling-list-item headingbox letter-normal">
                <h4>
                  <span className="themecolor">19,000 +</span>
                </h4>
                <p>
                  <strong>Success</strong> Stories
                </p>
              </div>
              <div className="contact-counselling-list-item headingbox letter-normal">
                <h4>
                  <span className="themecolor">2,000 +</span>
                </h4>
                <p>
                  <strong>Helped</strong> Students
                </p>
              </div>
              <div className="contact-counselling-list-item headingbox letter-normal">
                <h4>
                  <span className="themecolor">99.99 %</span>
                </h4>
                <p>
                  <strong>Acceptance</strong> rate
                </p>
              </div>
              <div className="contact-counselling-list-item headingbox letter-normal">
                <h4>
                  <span className="themecolor">8,000 +</span>
                </h4>
                <p>
                  <strong>Educated</strong> Teacher
                </p>
              </div>
            </div>
            <a href="/booking" className="btn">
              Schedule Call
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
