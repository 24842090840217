import React from "react";
import Slider from "react-slick";

export default function RecommendedCourse() {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };
  return (
    <Slider className="recommendedcoursesslider shortlistedcoursesslider" {...options}>
      <div className="recommendedcoursesslide">
        <div className="recommendedcoursesinner">
          <div className="recommendedcoursescntnt">
            <h3>Business Administration & Management - BA</h3>
            <h5>Harvard University</h5>
            <h6 className="courses-degree">Honors Degree</h6>
            <div className="courses-duration flex-box">
              <div className="courses-duration-info">
                <img src="/img/curved-location.png" alt="curved location" />
                <h4>Melbourne</h4>
              </div>
              <div className="courses-duration-info">
                <img src="/img/time.png" alt="time" />
                <h4>3 Years</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recommendedcoursesslide">
        <div className="recommendedcoursesinner">
          <div className="recommendedcoursescntnt">
            <h3>Business Administration & Management - BA</h3>
            <h5>Harvard University</h5>
            <h6 className="courses-degree">Honors Degree</h6>
            <div className="courses-duration flex-box">
              <div className="courses-duration-info">
                <img src="/img/curved-location.png" alt="curved location" />
                <h4>Melbourne</h4>
              </div>
              <div className="courses-duration-info">
                <img src="/img/time.png" alt="time" />
                <h4>3 Years</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recommendedcoursesslide">
        <div className="recommendedcoursesinner">
          <div className="recommendedcoursescntnt">
            <h3>Business Administration & Management - BA</h3>
            <h5>Harvard University</h5>
            <h6 className="courses-degree">Honors Degree</h6>
            <div className="courses-duration flex-box">
              <div className="courses-duration-info">
                <img src="/img/curved-location.png" alt="curved location" />
                <h4>Melbourne</h4>
              </div>
              <div className="courses-duration-info">
                <img src="/img/time.png" alt="time" />
                <h4>3 Years</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recommendedcoursesslide">
        <div className="recommendedcoursesinner">
          <div className="recommendedcoursescntnt">
            <h3>Business Administration & Management - BA</h3>
            <h5>Harvard University</h5>
            <h6 className="courses-degree">Honors Degree</h6>
            <div className="courses-duration flex-box">
              <div className="courses-duration-info">
                <img src="/img/curved-location.png" alt="curved location" />
                <h4>Melbourne</h4>
              </div>
              <div className="courses-duration-info">
                <img src="/img/time.png" alt="time" />
                <h4>3 Years</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}
