import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUniversity } from "../../actions/universityActions";

const UniversitySelect = React.memo(function UniversitySelect({ id }) {
  const dispatch = useDispatch();

  const univeristyList = useSelector((state) => state.univeristyList);
  const { loading, error, universityData } = univeristyList;

  const getUniversityList = () => {
    dispatch(listUniversity(id));
  };

  useEffect(() => {
    getUniversityList();
  }, [dispatch, id]);

  return (
    <>
      {universityData ? (
        universityData.map((lis) => (
          <option key={lis.University_Id} value={lis.University_Id}>
            {lis.University_Name}
          </option>
        ))
      ) : (
        <option>Loading...</option>
      )}
    </>
  );
});

export default UniversitySelect;
