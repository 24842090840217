import React from "react";
import { Link } from "react-router-dom";

const OurCourses = React.memo(function OurCourses() {
  return (
    <div
      className="professional-courses bg-style"
      style={{
        backgroundImage: `url('img/three-people-smiling.png')`,
      }}
    >
      <div className="pr-courses-content text-center">
        <h6>Our Courses</h6>

        <h4>Explore Over 1000+ Professional Courses</h4>
      </div>

      <div className="professional-courses-cta text-center">
        <Link to="/courses" className="btn text-center">
          Browse Courses
        </Link>
      </div>
    </div>
  );
});

export default OurCourses;
