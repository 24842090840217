import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Aboutvisa from "./about-visa";
import Applyingstuvisa from "./applying-stu-visa";
import Eligibilityvisa from "./eligibility-visa";

export default function index() {
  return (
    <>
      <Header />
      <Aboutvisa />
      <Eligibilityvisa />
      <Applyingstuvisa />
      <Footer />
    </>
  );
}
