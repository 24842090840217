import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { listPopularUniversity } from "../../../actions/universityActions";
import { useEffect } from "react";

export default function FeaturedColleges() {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const dispatch = useDispatch();

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const univeristyPopularList = useSelector(
    (state) => state.univeristyPopularList
  );
  const { loading, error, universityPData } = univeristyPopularList;

  const getPopularData = () => {
    if (country) {
      dispatch(listPopularUniversity(country));
    } else {
      dispatch(listPopularUniversity("India"));
    }
  };

  useEffect(() => {
    if (!universityPData) {
      getPopularData();
    }
  }, [dispatch, universityPData]);

  return universityPData ? (
    <Slider className="featuredcollegesslider" {...options}>
      {universityPData.map((rec) => (
        <div className="featured-collegesslide" key={rec.University_Id}>
          <div className="featured-collegesinner featured-collegessinner equal-height">
            <div className="featured-collegeimg">
              <img
                src={`${process.env.REACT_APP_SED_DOC_URL}/university/Banner402x300/${rec.Banner402x300}`}
                alt={rec.University_Name}
              />
            </div>
            <div className="featuredcollegescntnt">
              <div className="countrywith-rank flex-box">
                <h4>{rec.Country}</h4>
                <h5>{/* Rank: <span className="rank">72/2909</span> */}</h5>
              </div>
              <h3>{rec.University_Name}</h3>
              {/* <h6>
                Courses Offered: <span className="courses offered">129</span>
              </h6> */}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  ) : (
    ""
  );
}
