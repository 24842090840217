import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFinancialSource } from "../actions/financialSourceActions";

export default function FinancialSources() {
  const dispatch = useDispatch();
  const { loading, error, financialSourcesL } = useSelector(
    (s) => s.financialSourceList
  );
  const getfinancialSource = () => {
    dispatch(listFinancialSource());
  };
  useEffect(() => {
    if (!financialSourcesL) {
      getfinancialSource();
    }
    
  }, [dispatch, financialSourcesL]);

  return financialSourcesL
    ? financialSourcesL.length > 0
      ? financialSourcesL.map((fnsc, index) => (
          <option key={index + 1} value={fnsc.Id}>
            {fnsc.FinancialSource}
          </option>
        ))
      : <option>No Record Exist!</option>
    : <option>{error}</option>
}
