import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Efficientstudy = React.memo(function Efficientstudy() {
  return (
    <section
      className="efficient-study bg-style"
      style={{
        backgroundImage: `url('/img/group-cheerful-happy-students.png')`,
        backgroundColor: "#25253F",
      }}
    >
      <div className="container">
        <div className="efficient-study-wrapper">
          <div className="efficient-courses-benefits-main text-white">
            <div className="headingbox">
              <h2>
                <span className="themecolor">
                  We Have Helped Students
                  <br />
                </span>
                <span> Fast & Efficient Way To Study Abroad</span>
              </h2>
            </div>
            <div className="efficient-courses-benefits flex-box space-between">
              <div className="efficient-courses-benefits-list text-white">
                <div className="efficient-courses-benefits-list-icon">
                  <LazyLoadingImages
                    src="/img/light-green-check-icon.png"
                    alt="student-image"
                  />
                </div>
                <div className="efficient-courses-benefits-list-content">
                  <h3>24 +</h3>
                  <h4>Available Courses</h4>
                </div>
              </div>
              <div className="efficient-courses-benefits-list text-white">
                <div className="efficient-courses-benefits-list-icon">
                  <LazyLoadingImages
                    src="/img/light-green-check-icon.png"
                    alt="student-image"
                  />
                </div>
                <div className="efficient-courses-benefits-list-content">
                  <h3>1.2 Lakh</h3>
                  <h4>Applications</h4>
                </div>
              </div>
              <div className="efficient-courses-benefits-list text-white">
                <div className="efficient-courses-benefits-list-icon">
                  <LazyLoadingImages
                    src="/img/light-green-check-icon.png"
                    alt="student-image"
                  />
                </div>
                <div className="efficient-courses-benefits-list-content">
                  <h3>2 Lac</h3>
                  <h4>Shortlist Course</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Efficientstudy;
