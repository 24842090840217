import {
  EVENT_DETAIL_FAIL,
  EVENT_DETAIL_REQUEST,
  EVENT_DETAIL_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
} from "../constants/eventConstants";

export const listEventReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { loading: true };
    case EVENT_LIST_SUCCESS:
      return { loading: false, eventlists: action.payload };
    case EVENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const detailEventReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DETAIL_REQUEST:
      return { loading: true };
    case EVENT_DETAIL_SUCCESS:
      return { loading: false, eventDet: action.payload };
    case EVENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
