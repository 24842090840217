import React, { useEffect, useState } from "react";
import LeftPanel from "../Common/leftPanel";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { detailUser } from "../../../actions/userActions";
import RightSection from "../Common/RightSection";
import moment from "moment/moment";
import { getDocumentList } from "../../../actions/documentActions";
import OngoingApplications from "./OngoingApplications";
import InterestedIn from "./InterestedIn";

export default function Index() {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, userDet } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userDt } = userLogin;

  const documentList = useSelector((state) => state.documentList);
  const {
    loading: loadingDocument,
    error: errorDocument,
    documentlists,
  } = documentList;

  const getContactDetails = (e) => {
    dispatch(detailUser());
  };
  const getdocuments = (e) => {
    dispatch(getDocumentList());
  };

  // const progressAnimate = () => {
  //   var getPercent = $(".progress-wrap").data("progress-percent") / 100;
  //   var getProgressWrapWidth = $(".progress-wrap").width();
  //   var progressTotal = getPercent * getProgressWrapWidth;
  //   var animationLength = 2500;
  //   $(".progress-bar").stop().animate(
  //     {
  //       left: progressTotal,
  //     },
  //     animationLength
  //   );
  // };

  useEffect(() => {
    // progressAnimate();
    if (userDet === undefined) {
      getContactDetails();
    }
    if (!documentlists) {
      getdocuments();
    }
  }, [dispatch, userDet, documentlists]);

  return (
    <LeftPanel>
      <div className="dashboard-profile-main common-dashboard-search-box">
        <div className="sec-lg-col-60 dashboard-rightbox dashboard-profile-box-wrapper">
          <div className="featuredbg-style">
            <div className="dashboard-profile flex-box align-center">
              <div className="dashboard-profile-box">
                <div className="profile-image">
                  <img src="/img/student-icon.jpg" alt="student" />
                </div>
                <span className="edit-profile">
                  <img src="/img/edit.png" alt="student" />
                </span>
              </div>
              <div className="profile-details">
                <h3>{userDt.Contact_Name}</h3>
                <h4></h4>
                {/* <label for="file">
                          Your Profile is 75% get complete!
                        </label>
                        <div
                          className="progress-wrap progress"
                          data-progress-percent="75"
                        >
                          <div className="progress-bar progress"></div>
                        </div> */}
              </div>
            </div>
            <div className="dashboard-profile-tabs">
              <div className="dashboard-my-profile-tabs-wrapper">
                <ul className="dashboard-profile-tabs-nav">
                  <li>
                    <a href="#personaldetails">Personal Details</a>
                  </li>
                  <li>
                    <a href="#educationhistory">Education History</a>
                  </li>
                  <li>
                    <a href="#futureeducationinterest">
                      Future Education Interest
                    </a>
                  </li>
                  <li>
                    <a href="#requiredexams">Required Exams</a>
                  </li>
                  <li>
                    <a href="#documents">Documents</a>
                  </li>
                </ul>
              </div>
              {userDet ? (
                <div id="dashboard-profile-tabs-content">
                  <div id="personaldetails" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Personal Details</h2>
                      </div>
                      <div className="tab-content-heading-right-box">
                        <a
                          href="/panel/myprofile_edit"
                          className="edit-profile-tabs"
                        >
                          <img src="/img/edit-orange.png" alt="student" /> Edit
                        </a>
                      </div>

                      {/*<div className="tab-content-heading-left-box">
                              <h2>About Me</h2>
                              <p>
                                Hi I'm Priyanka Sharma, has been the industry's
                                standard dummy text To an English person, it
                                will seem like simplified English, as a
                                skeptical Cambridge.
                              </p>
                            </div> 
                            <div className="tab-content-heading-right-box">
                              <a
                                href="/panel/myprofile_edit"
                                className="edit-profile-tabs"
                              >
                                <img
                                  src="/img/edit-orange.png"
                                  alt="student"
                                />
                                Edit
                              </a>
                            </div>*/}
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="firstname">First Name</label>
                          <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            className="form-control"
                            placeholder=""
                            value={userDet.First_Name}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="lastname">Last Name</label>
                          <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            className="form-control"
                            placeholder=""
                            value={userDet.Last_Name}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="dateofbirth">Date Of Birth</label>
                          <input
                            type="text"
                            id="dateofbirth"
                            name="dateofbirth"
                            className="form-control"
                            placeholder=""
                            value={
                              userDet.Date_Of_Birth
                                ? moment(
                                    userDet.Date_Of_Birth.split("T")[0]
                                  ).format("DD-MMM-YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="gender">Gender</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/gender-icon.png" alt="student" />
                            </span>
                            <input
                              type="text"
                              id="gender"
                              name="gender"
                              className="form-control"
                              placeholder=""
                              value={userDet.GenderCategory}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="mobilenumber">Mobile Number</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/tel-icon.png" alt="student" />
                            </span>
                            <input
                              type="tel"
                              id="mobilenumber"
                              name="mobilenumber"
                              className="form-control"
                              placeholder=""
                              value={userDet.Phone_No}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="emailaddress">Email Address</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/gmail-icon.png" alt="student" />
                            </span>
                            <input
                              type="text"
                              id="emailaddress"
                              name="emailaddress"
                              className="form-control"
                              placeholder=""
                              value={userDet.Email_Address}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="passportnumber">Passport Number</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img
                                src="/img/passport-number.png"
                                alt="student"
                              />
                            </span>
                            <input
                              type="text"
                              id="passportnumber"
                              name="passportnumber"
                              className="form-control"
                              placeholder=""
                              value={userDet.Passport_number}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group personal-form-input-group">
                          <label for="city">City</label>
                          <input
                            type="text"
                            id="city"
                            className="form-control"
                            placeholder=""
                            value={userDet.City}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="state">State</label>
                          <input
                            type="text"
                            id="state"
                            className="form-control"
                            placeholder=""
                            value={userDet.State}
                            disabled
                          />
                        </div>

                        <div className="form-group personal-form-input-group ">
                          <label for="country">Country </label>
                          <input
                            type="text"
                            id="country"
                            className="form-control"
                            placeholder=""
                            value={userDet.Country}
                            disabled
                          />
                        </div>
                      </div>
                      {/* <div className="submit-form">
                              <button type="submit" className="submit-cta">
                                Save
                              </button>
                            </div> */}
                    </form>
                  </div>
                  <div id="educationhistory" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Education History</h2>
                      </div>
                      <div className="tab-content-heading-right-box">
                        <a
                          href="/panel/myprofile_edit"
                          className="edit-profile-tabs"
                        >
                          <img src="/img/edit-orange.png" alt="student" />
                          Edit
                        </a>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group">
                          <label for="levelgualification">
                            Highest Level Of Qualification
                          </label>
                          <input
                            type="text"
                            id="levelgualification"
                            className="form-control"
                            placeholder=""
                            value={userDet.E_Status}
                            disabled
                          />
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="collegename">College Name</label>
                                <input
                                  type="text"
                                  id="collegename"
                                  className="form-control"
                                  placeholder=""
                                  value={userDet.}
                                  disabled
                                />
                              </div>
                              <div className="form-group personal-form-input-group">
                                <label for="State">State</label>
                                <input
                                  type="text"
                                  id="State"
                                  className="form-control"
                                  placeholder=""
                                  disabled
                                />
                              </div>
                              <div className="form-group personal-form-input-group">
                                <label for="Country">Country</label>
                                <input
                                  type="text"
                                  id="Country"
                                  className="form-control"
                                  placeholder=""
                                  disabled
                                />
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="gradingsystem">Grading System</label>
                          <input
                            type="text"
                            id="gradingsystem"
                            className="form-control"
                            placeholder=""
                            value={userDet.GradingSystem}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="finalscore">Final Score</label>
                          <input
                            type="text"
                            id="finalscore"
                            className="form-control"
                            placeholder=""
                            value={userDet.Academic_Percentage}
                            disabled
                          />
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="yearadmission">
                                  Year Of Admission
                                </label>
                                <input
                                  type="text"
                                  id="yearadmission"
                                  className="form-control"
                                  placeholder=""
                                  
                                  disabled
                                />
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="yearcompletion">Year Of Completion</label>
                          <input
                            type="text"
                            id="yearcompletion"
                            className="form-control"
                            placeholder=""
                            value={userDet.Year_Of_Completion}
                            disabled
                          />
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <h5>Do You Have College Transcripts</h5>
                                <p className="dashboard-radio-form">
                                  <input
                                    type="radio"
                                    className="dashboard-radio-input"
                                    id="checkyes"
                                    name="radio-group"
                                    checked
                                    disabled
                                  />
                                  <label for="checkyes">Yes</label>
                                </p>
                                <p className="dashboard-radio-form">
                                  <input
                                    type="radio"
                                    className="dashboard-radio-input"
                                    id="checkno"
                                    name="radio-group"
                                    checked
                                    disabled
                                  />
                                  <label for="checkno">No</label>
                                </p>
                              </div> */}
                      </div>
                    </form>
                  </div>
                  <div id="futureeducationinterest" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Study Abroad Information</h2>
                      </div>
                      <div className="tab-content-heading-right-box">
                        <a
                          href="/panel/myprofile_edit"
                          className="edit-profile-tabs"
                        >
                          <img src="/img/edit-orange.png" alt="student" />
                          Edit
                        </a>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group">
                          <label for="studycountry">Country</label>
                          <input
                            type="text"
                            id="studycountry"
                            className="form-control"
                            placeholder=""
                            value={userDet.I_Country}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="degreecategory">Degree Category</label>
                          <input
                            type="text"
                            id="degreecategory"
                            className="form-control"
                            placeholder=""
                            value={userDet.E_Status}
                            disabled
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="degreelevel">Degree Level</label>
                          <input
                            type="text"
                            id="degreelevel"
                            className="form-control"
                            placeholder=""
                            value={userDet.InterestedLevelOfStudy}
                            disabled
                          />
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="studymethod">Study Method</label>
                                <div className="study-select-group">
                                  <span className="study-select-icon">
                                    <img
                                      src="/img/select-option.png"
                                      alt="student"
                                    />
                                  </span>
                                  <select
                                    name="studymethod"
                                    className="form-control"
                                    id="studymethod"
                                    disabled
                                  >
                                    <option value="On Campus">On Campus</option>
                                    <option value="On College">
                                      On College
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="admissionyear">Admission Year</label>
                          <div className="study-select-group">
                            <input
                              type="text"
                              id="admissionyear"
                              className="form-control"
                              placeholder=""
                              value={userDet.Intake_Year}
                              disabled
                            />
                          </div>
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="needscholarship">
                                  Need Scholarship
                                </label>
                                <div className="study-select-group">
                                  <select
                                    name="needscholarship"
                                    className="form-control"
                                    id="needscholarship"
                                    disabled
                                  >
                                    <option value="On Campus">On Campus</option>
                                    <option value="On College">
                                      On College
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="financialassistance">
                            Financial Source
                          </label>
                          <div className="study-select-group">
                            <input
                              type="text"
                              id="financial"
                              className="form-control"
                              placeholder=""
                              value={userDet.Financial_Source}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div id="requiredexams" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Required Exams</h2>
                      </div>
                      <div className="tab-content-heading-right-box">
                        <a
                          href="/panel/myprofile_edit"
                          className="edit-profile-tabs"
                        >
                          <img src="/img/edit-orange.png" alt="student" /> Edit
                        </a>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="form-row">
                        <div className="your-score-option-box flex-box align-center">
                          <h5>Your IELTS Score</h5>
                        </div>

                        <div className="personal-form-input-wrapper flex-box">
                          <div className="form-group personal-form-input-group colhalf">
                            <label for="financialassistance">
                              English Test Attempted
                            </label>
                            <div className="study-select-group">
                              <input
                                type="text"
                                id="EnglishTestAttempted"
                                className="form-control"
                                placeholder=""
                                value={userDet.EnglishTestAttempted}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group personal-form-input-group colhalf">
                            <label for="financialassistance">
                              English Test Score
                            </label>
                            <div className="study-select-group">
                              <input
                                type="text"
                                id="EnglishTestScore"
                                className="form-control"
                                placeholder=""
                                value={userDet.English_Test_Score}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div id="documents" className="tab-content">
                    <form className="personal-form-details">
                      <div className="uploaded-documents-wrapper uploaded-documents-heading tab-content-heading-box">
                        <h2>Uploaded Documents</h2>

                        {documentlists ? (
                          documentlists.map((doclist) => (
                            <div className="uploaded-documents flex-box align-center">
                              <div className="uploaded-documents-name">
                                <h5>{doclist.DocumentName}</h5>
                              </div>
                              <div className="uploaded-documents-options">
                                <div className="uploded-file-group flex-box align-center">
                                  {/* <div className="uploded-file-check upload-file-doc-image">
                                      <img
                                        src="/img/college-transcripts.jpg"
                                        alt="student"
                                      />
                                    </div> */}
                                  <div className="uploded-file-check download-uploded-file">
                                    <img
                                      src="/img/arrow-down-orange.png"
                                      alt="student"
                                    />
                                  </div>
                                  {/* <div className="uploded-file-check preview-uploded-file">
                                      <img
                                        src="/img/eyes.png"
                                        alt="student"
                                      />
                                    </div> */}
                                  <div className="uploded-file-check delete-uploded-file">
                                    <img
                                      src="/img/delete-file.png"
                                      alt="student"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div style={{ color: "red" }}>
                            Please upload Documents.
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>Processing...</>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-profile-search-box common-searchbox-style flex-box align-center">
          <div className="coursesearch mb-tzero">
            <form action="" className="searchofcourses">
              <input
                type="text"
                className="form-control"
                placeholder="Search for Courses...."
              />
              <button type="submit" name="coursesarchsubmit" className="btn">
                <img src="/img/searchicon.png" alt="Search Icon" />
              </button>
            </form>
          </div>
          <div className="notification-bellbox">
            <div
              className="notification-bell"
              style={{
                backgroundImage: `url('/img/bell.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <span className="notification">
                <img src="/img/notification-circle.png" alt="student" />
              </span>
            </div>
          </div>
        </div>

        {/* Right Section */}

        <div className="sec-md-col-20 profile-details-info-wrapper profile-others-details-info-wrapper sticky">
          <div className="profile-others-details-info">
            <InterestedIn />
            <OngoingApplications />
          </div>
        </div>
      </div>
    </LeftPanel>
  );
}
