import React, { useEffect, useState } from "react";
import LeftPanel from "../Common/leftPanel";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { detailUser } from "../../../actions/userActions";
import RightSection from "../Common/RightSection";
import moment from "moment/moment";
import { getDocumentList } from "../../../actions/documentActions";
import OngoingApplications from "../MyProfile/OngoingApplications";
import InterestedIn from "../MyProfile/InterestedIn";
import { contactJson } from "../../../models/userModel";
import EnglishTestAttempted from "../../../components/EnglishTestAttempted";
import Gender from "../../../components/Gender";
import FinancialSources from "../../../components/FinancialSources";
import DegreeCategory from "../../../components/DegreeCategory";
import GradingSystem from "../../../components/GradingSystem";
import CountrySelect from "../../../components/CountrySelect";
import EducationalStatus from "../../../components/EducationalStatus";
import YearCompletion from "../../../components/YearCompletion";
import InterestedCountry from "../../../components/InterestedCountry";
import InterestedLevelOfStudy from "../../../components/InterestedLevelOfStudy";
import InterestedFieldOfStudy from "../../../components/InterestedFieldOfStudy";
import AdmissionYear from "../../../components/AdmissionYear";
import { createHandlerBoundToURL } from "workbox-precaching";
import { updateUser } from "../../../actions/userActions";
import { USER_UPDATE_RESET } from "../../../constants/userConstants";
import DatePicker from "react-date-picker";

export default function Index() {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, userDet } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userDt } = userLogin;

  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = useSelector((s) => s.userUpdate);

  const [contactRec, setContactRec] = useState(contactJson);

  const documentList = useSelector((state) => state.documentList);
  const {
    loading: loadingDocument,
    error: errorDocument,
    documentlists,
  } = documentList;

  const getContactDetails = (e) => {
    dispatch(detailUser());
  };
  const getdocuments = (e) => {
    dispatch(getDocumentList());
  };

  // const progressAnimate = () => {
  //   var getPercent = $(".progress-wrap").data("progress-percent") / 100;
  //   var getProgressWrapWidth = $(".progress-wrap").width();
  //   var progressTotal = getPercent * getProgressWrapWidth;
  //   var animationLength = 2500;
  //   $(".progress-bar").stop().animate(
  //     {
  //       left: progressTotal,
  //     },
  //     animationLength
  //   );
  // };

  const handleChange = (e) => {
    // console.log(e.target.value, e.target.name);
    setContactRec({ ...contactRec, [e.target.name]: e.target.value });
  };

  const handleUpdate = (e) => {
    // console.log(contactRec);
    dispatch(updateUser(contactRec));
  };

  useEffect(() => {
    // progressAnimate();
    if (userDet === undefined) {
      getContactDetails();
    } else {
      setContactRec({ ...userDet });
    }
    if (!documentlists) {
      getdocuments();
    }
    if (successUpdate) {
      setTimeout(() => {
        dispatch({
          type: USER_UPDATE_RESET,
        });
      }, 2000);
    }
  }, [dispatch, userDet, documentlists, successUpdate]);

  return (
    <LeftPanel>
      <div className="dashboard-profile-main common-dashboard-search-box">
        <div className="sec-lg-col-60 dashboard-rightbox dashboard-profile-box-wrapper">
          <div className="featuredbg-style">
            <div className="dashboard-profile flex-box align-center">
              <div className="dashboard-profile-box">
                <div className="profile-image">
                  <img src="/img/student-icon.jpg" alt="student" />
                </div>
              </div>
              <div className="profile-details">
                <h3>{userDt.Contact_Name}</h3>
                <h4></h4>
                {/* <label for="file">
                          Your Profile is 75% get complete!
                        </label>
                        <div
                          className="progress-wrap progress"
                          data-progress-percent="75"
                        >
                          <div className="progress-bar progress"></div>
                        </div> */}
              </div>
            </div>
            <div className="dashboard-profile-tabs">
              <div className="dashboard-my-profile-tabs-wrapper">
                <ul className="dashboard-profile-tabs-nav">
                  <li>
                    <a href="#personaldetails">Personal Details</a>
                  </li>
                  <li>
                    <a href="#educationhistory">Education History</a>
                  </li>
                  <li>
                    <a href="#futureeducationinterest">
                      Future Education Interest
                    </a>
                  </li>
                  <li>
                    <a href="#requiredexams">Required Exams</a>
                  </li>
                  <li>
                    <a href="#documents">Documents</a>
                  </li>
                </ul>
              </div>
              {userDet ? (
                <div id="dashboard-profile-tabs-content">
                  <div id="personaldetails" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Personal Details</h2>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="firstname">First Name</label>
                          <input
                            type="text"
                            id="firstname"
                            name="First_Name"
                            className="form-control"
                            placeholder=""
                            value={contactRec.First_Name}
                            onChange={handleChange}
                          />
                          <input type="hidden" value={contactRec.Contact_Id} />
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="lastname">Last Name</label>
                          <input
                            type="text"
                            id="lastname"
                            name="Last_Name"
                            className="form-control"
                            placeholder=""
                            value={contactRec.Last_Name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="dateofbirth">Date Of Birth</label>
                          {/* <DatePicker onChange={handleChange} value={contactRec.Date_Of_Birth} /> */}

                          <input
                            type="date"
                            id="dateofbirth"
                            name="Date_Of_Birth"
                            className="form-control"
                            placeholder=""
                            value={contactRec.Date_Of_Birth}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="gender">Gender</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/gender-icon.png" alt="student" />
                            </span>
                            <select
                              id="gender"
                              name="Gender"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Gender}
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <Gender />
                            </select>
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="mobilenumber">Mobile Number</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/tel-icon.png" alt="student" />
                            </span>
                            <input
                              type="text"
                              id="mobilenumber"
                              name="Phone_No"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Phone_No}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="emailaddress">Email Address</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img src="/img/gmail-icon.png" alt="student" />
                            </span>
                            <input
                              type="text"
                              id="emailaddress"
                              name="Email_Address"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Email_Address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group colhalf">
                          <label for="passportnumber">Passport Number</label>
                          <div className="input-form-group">
                            <span className="form-input-icon">
                              <img
                                src="/img/passport-number.png"
                                alt="student"
                              />
                            </span>
                            <input
                              type="text"
                              id="passportnumber"
                              name="Passport_number"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Passport_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="form-group personal-form-input-group">
                          <label for="city">City</label>
                          <input
                            type="text"
                            id="City"
                            name="City"
                            className="form-control"
                            placeholder=""
                            value={contactRec.City}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="state">State</label>
                          <input
                            type="text"
                            id="state"
                            className="form-control"
                            placeholder=""
                            name="State"
                            value={contactRec.State}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group personal-form-input-group ">
                          <label for="country">Country </label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="country"
                              className="form-control"
                              placeholder=""
                              name="Country_Id"
                              value={contactRec.Country_Id}
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <CountrySelect />
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="submit-form">
                              <button type="submit" className="submit-cta">
                                Save
                              </button>
                            </div> */}
                    </form>
                  </div>
                  <div id="educationhistory" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Education History</h2>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group">
                          <label for="levelgualification">
                            Highest Level Of Qualification
                          </label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="levelgualification"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Educational_Status}
                              name="Educational_Status"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <EducationalStatus />
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="collegename">College Name</label>
                                <input
                                  type="text"
                                  id="collegename"
                                  className="form-control"
                                  placeholder=""
                                  value={contactRec.}
                                  
                                />
                              </div>
                              <div className="form-group personal-form-input-group">
                                <label for="State">State</label>
                                <input
                                  type="text"
                                  id="State"
                                  className="form-control"
                                  placeholder=""
                                  
                                />
                              </div>
                              <div className="form-group personal-form-input-group">
                                <label for="Country">Country</label>
                                <input
                                  type="text"
                                  id="Country"
                                  className="form-control"
                                  placeholder=""
                                  
                                />
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="gradingsystem">Grading System</label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="gradingsystem"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Grading_System}
                              name="Grading_System"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <option value="1">Percentage</option>
                              <option value="2">CGPA</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="finalscore">Final Score</label>
                          <input
                            type="text"
                            id="finalscore"
                            className="form-control"
                            placeholder=""
                            value={contactRec.Academic_Percentage}
                            name="Academic_Percentage"
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="yearadmission">
                                  Year Of Admission
                                </label>
                                <input
                                  type="text"
                                  id="yearadmission"
                                  className="form-control"
                                  placeholder=""
                                  
                                  
                                />
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="yearcompletion">Year Of Completion</label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="yearcompletion"
                              className="form-control"
                              placeholder=""
                              value={contactRec.Year_Of_Completion}
                              name="Year_Of_Completion"
                              onChange={handleChange}
                            >
                              <option></option>
                              <YearCompletion />
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <h5>Do You Have College Transcripts</h5>
                                <p className="dashboard-radio-form">
                                  <input
                                    type="radio"
                                    className="dashboard-radio-input"
                                    id="checkyes"
                                    name="radio-group"
                                    checked
                                    
                                  />
                                  <label for="checkyes">Yes</label>
                                </p>
                                <p className="dashboard-radio-form">
                                  <input
                                    type="radio"
                                    className="dashboard-radio-input"
                                    id="checkno"
                                    name="radio-group"
                                    checked
                                    
                                  />
                                  <label for="checkno">No</label>
                                </p>
                              </div> */}
                      </div>
                    </form>
                  </div>
                  <div id="futureeducationinterest" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Study Abroad Information</h2>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="personal-form-input-wrapper flex-box">
                        <div className="form-group personal-form-input-group">
                          <label for="studycountry">Country</label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="studycountry"
                              className="form-control"
                              name="I_Country"
                              value={contactRec.I_Country}
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <InterestedCountry />
                            </select>
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="degreecategory">
                            Interested Field of Study
                          </label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="degreecategory"
                              className="form-control"
                              value={contactRec.Interested_Field_Of_Study}
                              name="Interested_Field_Of_Study"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <InterestedFieldOfStudy />
                            </select>
                          </div>
                        </div>
                        <div className="form-group personal-form-input-group">
                          <label for="degreelevel">
                            Interested Level of Study
                          </label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="degreelevel"
                              className="form-control"
                              value={contactRec.Interested_Level_Of_Study}
                              name="Interested_Level_Of_Study"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <InterestedLevelOfStudy />
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="studymethod">Study Method</label>
                                <div className="study-select-group">
                                  <span className="study-select-icon">
                                    <img
                                      src="/img/select-option.png"
                                      alt="student"
                                    />
                                  </span>
                                  <select
                                    name="studymethod"
                                    className="form-control"
                                    id="studymethod"
                                    
                                  >
                                    <option value="On Campus">On Campus</option>
                                    <option value="On College">
                                      On College
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="admissionyear">Admission Year</label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              id="admissionyear"
                              className="form-control"
                              value={contactRec.Intake_Year}
                              name="Intake_Year"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <AdmissionYear />
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group personal-form-input-group">
                                <label for="needscholarship">
                                  Need Scholarship
                                </label>
                                <div className="study-select-group">
                                  <select
                                    name="needscholarship"
                                    className="form-control"
                                    id="needscholarship"
                                    
                                  >
                                    <option value="On Campus">On Campus</option>
                                    <option value="On College">
                                      On College
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                        <div className="form-group personal-form-input-group">
                          <label for="financialassistance">
                            Financial Source
                          </label>
                          <div className="study-select-group">
                            <span class="study-select-icon">
                              <img
                                src="/img/select-option.png"
                                alt="select-icon"
                              />
                            </span>
                            <select
                              type="text"
                              id="financial"
                              className="form-control"
                              value={contactRec.Financial_Source}
                              name="Financial_Source"
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              <FinancialSources />
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div id="requiredexams" className="tab-content">
                    <div className="tab-content-heading-box flex-box align-center">
                      <div className="tab-content-heading-left-box">
                        <h2>Required Exams</h2>
                      </div>
                    </div>
                    <form className="personal-form-details">
                      <div className="form-row">
                        <div className="your-score-option-box flex-box align-center">
                          <h5>Your IELTS Score</h5>
                        </div>

                        <div className="personal-form-input-wrapper flex-box">
                          <div className="form-group personal-form-input-group colhalf">
                            <label for="financialassistance">
                              English Test Attempted
                            </label>
                            <div className="study-select-group">
                              <span class="study-select-icon">
                                <img
                                  src="/img/select-option.png"
                                  alt="select-icon"
                                />
                              </span>
                              <select
                                type="text"
                                id="English_Test_Attempted"
                                className="form-control"
                                placeholder=""
                                name="English_Test_Attempted"
                                value={contactRec.English_Test_Attempted}
                                onChange={handleChange}
                              >
                                <option>Choose...</option>
                                <EnglishTestAttempted />
                              </select>
                            </div>
                          </div>
                          <div className="form-group personal-form-input-group colhalf">
                            <label for="financialassistance">
                              English Test Score
                            </label>
                            <div className="study-select-group">
                              <input
                                type="text"
                                id="English_Test_Score"
                                className="form-control"
                                placeholder=""
                                value={contactRec.English_Test_Score}
                                name="English_Test_Score"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="submit-form">
                        <input
                          type="button"
                          class="submit-cta"
                          value={loading ? "loading" : "Save"}
                          disabled={loading}
                          onClick={handleUpdate}
                        />
                      </div>
                      <div>
                        <span className="bg-success">{successUpdate}</span>
                        <span className="bg-danger">{errorUpdate}</span>
                      </div>
                    </form>
                  </div>
                  <div id="documents" className="tab-content">
                    <form className="personal-form-details">
                      <div className="uploaded-documents-wrapper uploaded-documents-heading tab-content-heading-box">
                        <h2>Uploaded Documents</h2>

                        {documentlists ? (
                          documentlists.map((doclist) => (
                            <div className="uploaded-documents flex-box align-center">
                              <div className="uploaded-documents-name">
                                <h5>{doclist.DocumentName}</h5>
                              </div>
                              <div className="uploaded-documents-options">
                                <div className="uploded-file-group flex-box align-center">
                                  {/* <div className="uploded-file-check upload-file-doc-image">
                                      <img
                                        src="/img/college-transcripts.jpg"
                                        alt="student"
                                      />
                                    </div> */}
                                  <div className="uploded-file-check download-uploded-file">
                                    <img
                                      src="/img/arrow-down-orange.png"
                                      alt="student"
                                    />
                                  </div>
                                  {/* <div className="uploded-file-check preview-uploded-file">
                                      <img
                                        src="/img/eyes.png"
                                        alt="student"
                                      />
                                    </div> */}
                                  <div className="uploded-file-check delete-uploded-file">
                                    <img
                                      src="/img/delete-file.png"
                                      alt="student"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div style={{ color: "red" }}>
                            Please upload Documents.
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>Processing...</>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-profile-search-box common-searchbox-style flex-box align-center">
          <div className="coursesearch mb-tzero">
            <form action="" className="searchofcourses">
              <input
                type="text"
                className="form-control"
                placeholder="Search for Courses...."
              />
              <button type="submit" name="coursesarchsubmit" className="btn">
                <img src="/img/searchicon.png" alt="Search Icon" />
              </button>
            </form>
          </div>
          <div className="notification-bellbox">
            <div
              className="notification-bell"
              style={{
                backgroundImage: `url('/img/bell.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <span className="notification">
                <img src="/img/notification-circle.png" alt="student" />
              </span>
            </div>
          </div>
        </div>

        {/* Right Section */}

        <div className="sec-md-col-20 profile-details-info-wrapper profile-others-details-info-wrapper sticky">
          <div className="profile-others-details-info">
            <InterestedIn />
            <OngoingApplications />
          </div>
        </div>
      </div>
    </LeftPanel>
  );
}
