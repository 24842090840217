import React from 'react'
import LeftPanel from '../Common/leftPanel'

export default function index() {
  return (
    <div>
      <LeftPanel/>
        <section className="dashboard">
        <div className="container">
            <div className="sec-row">
              <div className="toggle-sidebar-btn-wrapper">
                <div className="sidebar-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
              </div>
             
                <div className="sec-lg-col-75 dashboard-rightbox common-dashboard-search-box sec-col-83">
                    <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
                        <div className="coursesearch mb-tzero">
                            <form action="" className="searchofcourses">
                                <input type="text" className="form-control" placeholder="Search for Courses...."/>
                                <button type="submit" name="coursesarchsubmit" className="btn">
                                <img src="/img/searchicon.png" alt="Search Icon"/>
                                </button>
                            </form>
                        </div>
                        <div className="notification-bellbox">
                            <div className="notification-bell" style={{backgroundImage: `url('/img/bell.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}}>
                                <span className="notification"><img src="/img/notification-circle.png"alt="dashboard"/></span>
                              </div>
                        </div>
                    </div>
                    <div className="featuredbg-style ongoing-applications-details">
                        <div className="dash-application-box dash-application-detail-box">
                            <div className="appcour-status-box flex-box align-center">
                                <div className="appcour-status-leftbox">
                                    <h6>AppCour16</h6>
                                </div>
                                <div className="ongoing-cour-info-box-wrapper">
                                    <span className="ongoing-cour-info-box status-accepted">
                                        Status: <strong>Accepted</strong>
                                    </span>
                                    <span className="ongoing-cour-info-box bg-grey">Created On: 12-10-2021</span>
                                </div>
                                <ul className="ongoing-cour-info ongoing-cour-detail-info">
                                    <li className="ongoing-cour-info-box ongoing-messagebox ongoingmessages bg-grey">
                                        <img src="/img/gmail-box.png"alt="dashboard"/>
                                    </li>
                                    <li className="ongoing-cour-info-box ongoing-deleted ongoingmessages bg-grey">
                                        <img src="/img/delete-file.png"alt="dashboard"/>
                                    </li>
                                </ul>
                            </div>
                            <div className="stag-progress">
                                <div className="stag-progress-bar stag1">
                                    <p>Select Course</p>
                                </div>
                                <div className="stag-progress-bar stag2">
                                    <p>Update Details</p>
                                </div>
                                <div className="stag-progress-bar stag3">
                                    <p>Upload Documents</p>
                                </div>
                                <div className="stag-progress-bar stag4">
                                    <p>Under Review</p>
                                </div>
                                <div className="stag-progress-bar stag5">
                                    <p>Application Accepted </p>
                                </div>
                            </div>
                        </div>
                        <div className="ongoing-courses-detail-box">
                            <p className="location"><img src="/img/locationicon.png"alt="dashboard"/> Vrije Universiteit Brussel, Belgium</p>
                            <h3>Marine and Lacustrine Science
                                and Management - MSc</h3>
                                <div className="ongoing-courses-detail-info-wrapper flex-box">
                                    <div className="ongoing-courses-detail-info">
                                        <p>Program type</p>
                                        <h6>Undergraduate</h6>
                                    </div>
                                    <div className="ongoing-courses-detail-info">
                                        <p>Duration</p>
                                        <h6>3 Years</h6>
                                    </div>
                                    <div className="ongoing-courses-detail-info">
                                        <p>Course fee</p>
                                        <h6>1,000 USD</h6>
                                    </div>
                                    <div className="ongoing-courses-detail-info">
                                        <p>Mode</p>
                                        <h6>On-campus</h6>
                                    </div>
                                    <div className="ongoing-courses-detail-info">
                                        <p>Year of Admission</p>
                                        <h6>22 December 2024</h6>
                                    </div>
                                </div>
                        </div>
                        <div className="ongoing-application-others-details">
                            <ul className="ongoing-application-tabs">
                               <a href="#PersonalDetails"><li className="ongoing-application-tab-link" id="ongoing-appli-personal-details-link" data-link="ongoing-appli-personal-details">Personal Details</li></a> 
                             <a href="#EducationDetails"><li className="ongoing-application-tab-link" id="ongoing-appli-education-details-link" data-link="ongoing-appli-education-details">Education Details</li></a>   
                             <a href="#ExamsDetails" >  <li className="ongoing-application-tab-link" id="ongoing-appli-exam-details-link" data-link="ongoing-appli-exam-details">Exams Details</li></a>
                           <a href="#DocumentsDetails" >    <li className="ongoing-application-tab-link" id="ongoing-appli-documents-details-link" data-link="ongoing-appli-documents-details">Documents Details</li></a>
                            </ul>
                            <div className="ongoing-application-details-box" id="ongoing-appli-personal-details">
                                <div className="ongoing-application-details-heading-box flex-box align-center">
                                    <h3 id="PersonalDetails">Personal Details </h3>
                                    <span className="ongoing-appli-details-update">Update Now</span>
                                </div>
                                <form className="ongoing-application-details-form">
                                    <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="firstname">First Name</label>
                                            <input type="text" id="firstname" name="firstname" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="middlename">Middle Name</label>
                                            <input type="text" id="middlename" name="middlename" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="lastname">Last Name</label>
                                            <input type="text" id="lastname" name="lastname" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="dateofbirth">Date Of Birth</label>
                                            <input type="date" id="dateofbirth" name="dateofbirth" className="form-control date-of-birth" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="gender">Gender</label>
                                            <div className="input-form-group">
                                                <span className="form-input-icon">
                                                  <img src="/img/gender-icon.png"alt="dashboard"/>
                                                </span>
                                                <input type="text" id="gender" name="gender" className="form-control" placeholder="" disabled=""/>
                                            </div>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="mobilenumber">Mobile Number</label>
                                            <div className="input-form-group">
                                              <span className="form-input-icon">
                                                <img src="/img/tel-icon.png"alt="dashboard"/>
                                              </span>
                                              <input type="tel" id="mobilenumber" name="mobilenumber" className="form-control" placeholder="" disabled=""/>
                                            </div>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="emailaddress">Email Address</label>
                                            <div className="input-form-group">
                                              <span className="form-input-icon">
                                                <img src="/img/gmail-icon.png"alt="dashboard"/>
                                              </span>
                                              <input type="text" id="emailaddress" name="emailaddress" className="form-control" placeholder="" disabled=""/>
                                            </div>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="passportnumber">Passport Number</label>
                                            <div className="input-form-group">
                                              <span className="form-input-icon">
                                                <img src="/img/passport-number.png"alt="dashboard"/>
                                              </span>
                                              <input type="text" id="passportnumber" name="passportnumber" className="form-control" placeholder="" required/>
                                            </div>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="address">Address</label>
                                            <div className="input-form-group">
                                              <span className="form-input-icon">
                                                <img src="/img/address-icon.png"alt="dashboard"/>
                                              </span>
                                              <input type="text" id="address" name="address" className="form-control" placeholder="" required/>
                                            </div>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="city">City</label>
                                            <input type="text" id="city" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="state">State</label>
                                            <input type="text" id="state" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="pincode">Pincode</label>
                                            <input type="text" id="pincode" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group">
                                            <label for="country">Country </label>
                                            <input type="text" id="country" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                    </div>
                                  </form>
                            </div>
                            <div className="ongoing-application-details-box" id="ongoing-appli-education-details">
                                <div className="ongoing-application-details-heading-box flex-box align-center">
                                    <h3 id="EducationDetails">Education Details</h3>
                                    <span className="ongoing-appli-details-update">Update Now</span>
                                </div>
                                <form className="ongoing-application-details-form">
                                    <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="levelgualification">Highest Level Of Qualification</label>
                                            <input type="text" id="levelgualification" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="collegename">College Name</label>
                                            <input type="text" id="collegename" className="form-control" placeholder="" disabled="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="State">State</label>
                                            <input type="text" id="State" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="Country">Country</label>
                                            <input type="text" id="Country" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="gradingsystem">Grading System</label>
                                            <input type="text" id="gradingsystem" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="finalscore">Final Score</label>
                                            <input type="text" id="finalscore" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="dateofbirth">Year Of Admission</label>
                                            <input type="date" id="dateofbirth" name="dateofbirth" className="form-control date-of-birth" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-25">
                                            <label for="yearadmission">Year Of Admission</label>
                                            <input type="text" id="yearadmission" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group col-full">
                                            <h5>Do You Have College Transcripts</h5>
                                            <p className="dashboard-radio-form">
                                                <input type="radio" className="dashboard-radio-input" id="checkyes" name="radio-group" checked="" disabled=""/>
                                                <label for="checkyes">Yes</label>
                                              </p>
                                              <p className="dashboard-radio-form">
                                                <input type="radio" className="dashboard-radio-input" id="checkno" name="radio-group" checked="" disabled=""/>
                                                <label for="checkno">No</label>
                                              </p>
                                        </div>
                                    </div>
                                  </form>
                            </div>
                            <div className="ongoing-application-details-box" id="ongoing-appli-exam-details">
                                <div className="ongoing-application-details-heading-box flex-box align-center">
                                    <h3 id="ExamsDetails">Exams Details</h3>
                                    <span className="ongoing-appli-details-update">Update Now</span>
                                </div>
                                  <form className="ongoing-application-details-form flex-box">
                                    <div className="form-row">
                                      <div className="your-score-option-box flex-box align-center">
                                        <h5>Your IELTS Score</h5>
                                      </div>
                                      <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                            <label for="IELTSListening">Listening</label>
                                            <input type="text" id="IELTSListening" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                            <label for="Reading">Reading</label>
                                            <input type="text" id="Reading" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                            <label for="Writing">Writing</label>
                                            <input type="text" id="Writing" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                          <label for="Speaking">Speaking</label>
                                          <input type="text" id="Speaking" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                          <label for="Bandscore">Overall Band Score</label>
                                          <input type="text" id="Bandscore" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-15">
                                          <label for="CEFRLevel">CEFR Level</label>
                                          <input type="text" id="CEFRLevel" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="your-score-option-box flex-box align-center">
                                        <h5>Your GRE Score</h5>
                                      </div>
                                      <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-grp-score-group">
                                          <h6>Verbal Reasoning</h6>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="IELTSListening">Listening</label>
                                            <input type="text" id="IELTSListening" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="Reading">Reading</label>
                                            <input type="text" id="Reading" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                        </div>
                                        <div className="form-grp-score-group">
                                          <h6>Quantitative  Reasoning</h6>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="IELTSListening">Listening</label>
                                            <input type="text" id="IELTSListening" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="Reading">Reading</label>
                                            <input type="text" id="Reading" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                        </div>
                                        <div className="form-grp-score-group">
                                          <h6>Analytical Writing</h6>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="IELTSListening">Listening</label>
                                            <input type="text" id="IELTSListening" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                          <div className="form-group ongoing-appli-form-group">
                                            <label for="Reading">Reading</label>
                                            <input type="text" id="Reading" className="form-control" placeholder="" disabled=""/>
                                          </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="your-score-option-box flex-box align-center">
                                        <h5>Your GMAT Score</h5>
                                      </div>
                                      <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="GMATVerbal">Verbal</label>
                                            <input type="text" id="GMATVerbal" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="GMATQuantitative">Quantitative</label>
                                            <input type="text" id="GMATQuantitative" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="GMATTotal">Total</label>
                                            <input type="text" id="GMATTotal" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                          <label for="GMATWriting">Analytical Writing</label>
                                          <input type="text" id="GMATWriting" className="form-control" placeholder="" required/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                          <label for="GMATReasoning">Integrated Reasoning</label>
                                          <input type="text" id="GMATReasoning" className="form-control" placeholder="" required/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="your-score-option-box flex-box align-center">
                                        <h5>Your CPE Score</h5>
                                      </div>
                                      <div className="ongoing-application-details-form-input-wrapper flex-box">
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="CPEReading">Reading</label>
                                            <input type="text" id="CPEReading" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="CPElenguage">Use of English</label>
                                            <input type="text" id="CPElenguage" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                            <label for="CPEWriting">Writing</label>
                                            <input type="text" id="CPEWriting" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                          <label for="CPEListening">Listening</label>
                                          <input type="text" id="CPEListening" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                        <div className="form-group ongoing-appli-form-group form-col-19">
                                          <label for="CPESpeaking">Speaking</label>
                                          <input type="text" id="CPESpeaking" className="form-control" placeholder="" disabled=""/>
                                        </div>
                                    </div>
                                    </div>
                                  </form>
                            </div>
                            <div className="ongoing-application-details-box uploaded-documents-wrapper" id="ongoing-appli-documents-details">
                                <div className="ongoing-application-details-heading-box flex-box align-center">
                                    <h3 id="DocumentsDetails">Documents Details</h3>
                                    <span className="ongoing-appli-details-update">Update Now</span>
                                </div>
                                <form className="ongoing-application-details-form">
                                    <div className="uploaded-documents flex-box align-center">
                                        <div className="uploaded-documents-name">
                                          <h5>Visa Photocopy</h5>
                                        </div>
                                        <div className="uploaded-documents-options">
                                          <div className="uploded-file-group flex-box align-center">
                                            <div className="uploded-file-check upload-file-doc-image">
                                              <img src="/img/visa-photocopy.jpg"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check download-uploded-file">
                                              <img src="/img/arrow-down-orange.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check preview-uploded-file">
                                              <img src="/img/eyes.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check delete-uploded-file">
                                              <img src="/img/delete-file.png"alt="dashboard"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uploaded-documents flex-box align-center">
                                        <div className="uploaded-documents-name">
                                          <h5>Aadhar Card/ Driving License</h5>
                                        </div>
                                        <div className="uploaded-documents-options">
                                          <div className="uploded-file-group flex-box align-center">
                                            <div className="uploded-file-check upload-file-doc-image">
                                              <img src="/img/aadharcard.jpg"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check download-uploded-file">
                                              <img src="/img/arrow-down-orange.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check preview-uploded-file">
                                              <img src="/img/eyes.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check delete-uploded-file">
                                              <img src="/img/delete-file.png"alt="dashboard"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uploaded-documents flex-box align-center">
                                        <div className="uploaded-documents-name">
                                          <h5>College Transcripts</h5>
                                        </div>
                                        <div className="uploaded-documents-options">
                                          <div className="uploded-file-group flex-box align-center">
                                            <div className="uploded-file-check upload-file-doc-image">
                                              <img src="/img/college-transcripts.jpg"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check download-uploded-file">
                                              <img src="/img/arrow-down-orange.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check preview-uploded-file">
                                              <img src="/img/eyes.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check delete-uploded-file">
                                              <img src="/img/delete-file.png"alt="dashboard"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uploaded-documents flex-box align-center">
                                        <div className="uploaded-documents-name">
                                          <h5>10th Marksheet</h5>
                                        </div>
                                        <div className="uploaded-documents-options">
                                          <div className="uploded-file-group flex-box align-center">
                                            <div className="uploded-file-check upload-file-doc-image">
                                              <img src="/img/10th-marksheet.jpg"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check download-uploded-file">
                                              <img src="/img/arrow-down-orange.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check preview-uploded-file">
                                              <img src="/img/eyes.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check delete-uploded-file">
                                              <img src="/img/delete-file.png"alt="dashboard"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uploaded-documents flex-box align-center">
                                        <div className="uploaded-documents-name">
                                          <h5>12th Marksheet</h5>
                                        </div>
                                        <div className="uploaded-documents-options">
                                          <div className="uploded-file-group flex-box align-center">
                                            <div className="uploded-file-check upload-file-doc-image">
                                              <img src="/img/12th-marksheet.jpg"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check download-uploded-file">
                                              <img src="/img/arrow-down-orange.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check preview-uploded-file">
                                              <img src="/img/eyes.png"alt="dashboard"/>
                                            </div>
                                            <div className="uploded-file-check delete-uploded-file">
                                              <img src="/img/delete-file.png"alt="dashboard"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </form>
                            </div>
                            <div className="counselor-message">
                                <h5>Counselor Message</h5>
                                <p>With that in mind, would you rather go with the boring <strong>“Thank you for
                                  contacting us”</strong> message or spend a few minutes writing something more 
                                  effective? If you choose the latter option, this article is for you.</p>
                            </div>
                            <div className="ongoing-appli-change-course headingbox mb-zero">
                                <h6>Next Course of Action</h6>
                                <h2><span className="themecolor">Carry On </span>or Change Course?</h2>
                                <p>A student visa as the name suggests is a government signed/stamped document
                                    that gives the person holding it the official right to enter into the
                                    country for educational purposes.</p>
                                    <a href="/courses" className="btn">Browse course</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
