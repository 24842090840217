import {
  GENDER_LIST_REQUEST,
  GENDER_LIST_SUCCESS,
  GENDER_LIST_FAIL,
} from "../constants/genderConstants";
import api2 from "../core/api2";

export const listGender = () => async (dispatch) => {
  try {
    dispatch({
      type: GENDER_LIST_REQUEST,
    });
    const { data } = await api2.get(`/gender`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({
        type: GENDER_LIST_FAIL,
        payload: res,
      });
    } else {
      dispatch({
        type: GENDER_LIST_SUCCESS,
        payload: res,
      });
    }
  } catch (ex) {
    dispatch({
      type: GENDER_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
