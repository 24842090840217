import React, { useState, useEffect, useCallback } from "react";
import { contactJson } from "../../models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";

const BookFree = React.memo(function BookFree() {
  const dispatch = useDispatch();
  const [contactArray, setContactArray] = useState(contactJson);

  const [ierror, setIerror] = useState("");
  const [ContactOpen, setContactOpen] = useState(false);

  const {
    loading,
    success: successC,
    error,
  } = useSelector((s) => s.contactCreate);

  // const getLocation = useSelector((state) => state.getLocation);
  // const { country } = getLocation;

  const handleFormChange = (e) => {
    setContactArray({
      ...contactArray,
      Link: window.location.href,
      // Comments:
      //   "Contact Filled the form using the Website Register Yourself Form on Website. Country: " +
      //   country.country_name,
      Activity: "Website IELTS Training Form Submitted.",
      Activity_Des:
        "IELTS Training Form submitted using the Website. Country: ",
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var errorm = "";
    if (contactArray.full_name === "" || contactArray.full_name === undefined)
      errorm = "Full name cannot be Blank!";
    if (contactArray.Phone_No === "" || contactArray.Phone_No === undefined)
      errorm = "Phone Number cannot be Blank!";
    if (
      contactArray.Email_Address === "" ||
      contactArray.Email_Address === undefined
    )
      errorm = "Email Address cannot be Blank!";

    if (errorm === "") {
      setIerror("");
      dispatch(
        createContact({
          ...contactArray,
          url: window.location.href,
          Comments:
            "Inquiry For : " +
            contactArray?.Comments +
            " " +
            `<br/>Contact Filled the form using the Website Register Yourself Form on Website.`,
        })
      );
    } else {
      setIerror(errorm);
    }
  };

  useEffect(() => {
    if (successC) {
      setContactArray(contactJson);

      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
        setContactOpen(false);
      }, 3000);
    }
  }, [dispatch, successC]);

  const handleToggle = (e) => {
    e.preventDefault();
    // if (!ContactOpen) {
    // }
    setContactOpen(!ContactOpen);
  };

  const ContactClose = useCallback(() => {
    setContactOpen(false);
    setContactArray(contactJson);
  }, [setContactOpen]);

  return (
    <div className={ContactOpen ? "sidebar-form  show" : "sidebar-form"}>
      {ContactOpen ? (
        ""
      ) : (
        <div onClick={handleToggle} className="call-action">
          <span>{/*<AiOutlineMail />*/}</span>
          <span>Book a Free Consultation</span>
        </div>
      )}

      <div
        className="Close_btn"
        onClick={() => {
          ContactClose();
          //   goToTop();
          //   setCloseForce(false);
        }}
      >
        <span>x</span>
      </div>

      <div className="">
        {/* <p>
            <AiOutlineMail />
          </p> */}
        <p style={{ textAlign: "center", color: "black" }}>
          {" "}
          15-miute free consultation <br /> with our Registered Migration Agent.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          {/*<label>Full Name</label> */}

          <input
            type="text"
            placeholder="Name"
            className="form-control"
            name="full_name"
            value={contactArray.full_name}
            onChange={handleFormChange}
            required
          />
        </div>

        <div className="form-group">
          {/*<label>Email Address</label> */}

          <input
            type="text"
            placeholder="Email"
            className="form-control"
            name="Email_Address"
            value={contactArray.Email_Address}
            onChange={handleFormChange}
            required
          />
        </div>

        <div className="form-group">
          {/*  <label>Phone</label>*/}

          <input
            type="text"
            placeholder="Phone"
            className="form-control"
            name="Phone_No"
            value={contactArray.Phone_No}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-group">
          {/*<label>Message</label> */}

          <textarea
            type="text"
            placeholder="Inquiry For"
            className="form-control"
            name="Comments"
            value={contactArray.Comments}
            onChange={handleFormChange}
            required
          />
        </div>
        {error || ierror ? (
          <span style={{ color: "red" }}>{error || ierror}</span>
        ) : (
          ""
        )}

        {successC ? <span style={{ color: "green" }}>{successC}</span> : ""}
        <input
          type="submit"
          value={loading ? "Loading..." : "Send message"}
          disabled={loading}
          id="submit-btn"
        />
      </form>
    </div>
  );
});

export default BookFree;
