export const DOCUMENT_LIST_REQUEST='DOCUMENT_LIST_REQUEST'
export const DOCUMENT_LIST_SUCCESS='DOCUMENT_LIST_SUCCESS'
export const DOCUMENT_LIST_FAIL='DOCUMENT_LIST_FAIL'

export const DOCUMENT_ADD_REQUEST = 'DOCUMENT_ADD_REQUEST'
export const DOCUMENT_ADD_SUCCESS = 'DOCUMENT_ADD_SUCCESS'
export const DOCUMENT_ADD_FAIL = 'DOCUMENT_ADD_FAIL'
export const DOCUMENT_ADD_RESET = 'DOCUMENT_ADD_RESET'

export const DOCUMENT_REMOVE_REQUEST = 'DOCUMENT_REMOVE_REQUEST'
export const DOCUMENT_REMOVE_SUCCESS = 'DOCUMENT_REMOVE_SUCCESS'
export const DOCUMENT_REMOVE_FAIL = 'DOCUMENT_REMOVE_FAIL'
export const DOCUMENT_REMOVE_RESET = 'DOCUMENT_REMOVE_RESET'
