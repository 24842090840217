import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
import { useState } from "react";

const Index = React.memo(function Index() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };

  return (
    <>
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/employers-banner.png')`,
          backgroundColor: `#07070B`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Work in Abroad</h4>
              <h1>Employer Sponsored Visa</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-employers-sec compadding">
        <div className="container">
          <div className="flex-box about-employers-visa-wrapper align-center">
            <div className="about-employers-content headingbox mb-zero full-width-925">
              <h2>
                <span className="themecolor">
                  Temporary Skills
                  <br />
                  Shortage
                </span>{" "}
                Subclass 482
              </h2>

              <p>
                The TSS Employer-Sponsored Visa, as the name sounds, is a
                temporary visa that allows people to move to Australia on a
                full-time 4-year term.
              </p>

              <p>
                This visa is, as of now, the most common type of visa available
                for any nominated employer to hire human resources for their
                firm. This visa helps the Australian businesses to reach out to
                efficient, skilled labor for their company. This visa is for
                those occupations that are mentioned in either of the two lists.
              </p>
            </div>

            <div className="about-employers-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/about-employers-image.jpg"
                alt="About Employee"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="term-streams-sec">
        <div className="container">
          <div className="term-streams-content common-stu-visa-heading text-center headingbox mb-zero full-width-925">
            <h2>
              <span className="themecolor">Term</span> Streams
            </h2>

            <p>
              The TSS Employer-Sponsored Visa, as the name sounds, is a
              temporary visa that allows people to move to Australia on a
              full-time 4-year term.
            </p>
          </div>

          <div className="terms-strems-service-box-wrapper Stream-heading headingbox">
            <h3>
              <span className="themecolor">Short</span> Term Stream
            </h3>

            <div className="flex-box terms-strems-service-info-wrapper">
              <div className="terms-strems-service-info-box-main full-width-925">
                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages src="/img/stay.png" alt="stay" />

                    <h4>Stay</h4>
                  </div>

                  <p>
                    up to 2 years or up to 4 years if an International Trade
                    Obligation (ITO) applies.
                  </p>
                </div>

                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages
                      src="/img/visa-processing.png"
                      alt="Visa Processing"
                    />

                    <h4>Visa Processing Time</h4>
                  </div>

                  <p>
                    Ideally, 75% of the applications get approved within 6
                    months from the date of applying while 90% get approved
                    within 9 months.
                  </p>
                </div>
              </div>

              <div className="terms-strems-service-deccription full-width-925">
                <p>
                  For an employee who gets his visa for an occupation that falls
                  under this category, the basic application charges would sum
                  up to AUD 1,265.
                </p>

                <p>
                  The validity of this visa category is for a period of 2 years,
                  except if there is an ITO (International Trade Obligation) in
                  which case the validity can be up to 4 years. An employee can
                  move in and out of Australia as many times as they feel like
                  while they enjoy the validity of the visa.
                </p>

                <a href="/get_visa" className="btn terms-strems-enquire">
                  Enquire Now!
                </a>
              </div>
            </div>
          </div>

          <div className="terms-strems-service-box-wrapper Stream-heading headingbox">
            <h3>
              <span className="themecolor">Medium</span> Term Stream
            </h3>

            <div className="flex-box terms-strems-service-info-wrapper">
              <div className="terms-strems-service-info-box-main full-width-925">
                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages src="/img/stay.png" alt="stay" />

                    <h4>Stay</h4>
                  </div>

                  <p>
                    up to 2 years or up to 4 years if an International Trade
                    Obligation (ITO) applies.
                  </p>
                </div>

                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages
                      src="/img/visa-processing.png"
                      alt="Visa Processing"
                    />

                    <h4>Visa Processing Time</h4>
                  </div>

                  <p>
                    Ideally, 75% of the applications get approved within 6
                    months from the date of applying while 90% get approved
                    within 9 months.
                  </p>
                </div>
              </div>

              <div className="terms-strems-service-deccription full-width-925">
                <p>
                  For an employee who gets his visa for an occupation that falls
                  under this category, the basic application charges would sum
                  up to AUD 1,265.
                </p>

                <p>
                  The validity of this visa category is for a period of 2 years,
                  except if there is an ITO (International Trade Obligation) in
                  which case the validity can be up to 4 years. An employee can
                  move in and out of Australia as many times as they feel like
                  while they enjoy the validity of the visa.
                </p>

                <a href="/get_visa" className="btn terms-strems-enquire">
                  Enquire Now!
                </a>
              </div>
            </div>
          </div>

          <div className="terms-strems-service-box-wrapper Stream-heading headingbox">
            <h3>
              <span className="themecolor">Labour</span> Agreement Stream
            </h3>

            <div className="flex-box terms-strems-service-info-wrapper">
              <div className="terms-strems-service-info-box-main full-width-925">
                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages src="/img/stay.png" alt="stay" />

                    <h4>Stay</h4>
                  </div>

                  <p>
                    up to 2 years or up to 4 years if an International Trade
                    Obligation (ITO) applies.
                  </p>
                </div>

                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages
                      src="/img/visa-processing.png"
                      alt="Visa Processing"
                    />

                    <h4>Visa Processing Time</h4>
                  </div>

                  <p>
                    Ideally, 75% of the applications get approved within 6
                    months from the date of applying while 90% get approved
                    within 9 months.
                  </p>
                </div>
              </div>

              <div className="terms-strems-service-deccription full-width-925">
                <p>
                  This visa type is exclusively limited to those skilled workers
                  who have been nominated by an employer having a Labour
                  Agreement. A Labour Agreement is a term of understanding made
                  between the Australian government authorities and the
                  employer. This visa allows the employee to work in Australia
                  for a temporary period.
                </p>

                <p>
                  For an employee who gets his visa for an occupation that falls
                  under this category, the basic application charges would sum
                  up to AUD 2,645. The validity of this visa category is for a
                  period of 4 years subject to the terms as mentioned in the
                  agreement. A person under this visa category can study in
                  Australia however they will not be granted any government
                  assistance for this. An employee can move in and out of
                  Australia as many times as they feel like while they enjoy the
                  validity of the visa.
                </p>

                <a href="/get_visa" className="btn terms-strems-enquire">
                  Enquire Now!
                </a>
              </div>
            </div>
          </div>

          <div className="terms-strems-service-box-wrapper Stream-heading headingbox">
            <h3>
              <span className="themecolor">Subsequent</span> Entrant
            </h3>

            <div className="flex-box terms-strems-service-info-wrapper">
              <div className="terms-strems-service-info-box-main full-width-925">
                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages src="/img/stay.png" alt="stay" />

                    <h4>Stay</h4>
                  </div>

                  <p>
                    up to 2 years or up to 4 years if an International Trade
                    Obligation (ITO) applies.
                  </p>
                </div>

                <div className="terms-strems-service-info-box">
                  <div className="flex-box align-center terms-strems-service-name">
                    <LazyLoadingImages
                      src="/img/visa-processing.png"
                      alt="Visa Processing"
                    />

                    <h4>Visa Processing Time</h4>
                  </div>

                  <p>
                    Ideally, 75% of the applications get approved within 6
                    months from the date of applying while 90% get approved
                    within 9 months.
                  </p>
                </div>
              </div>

              <div className="terms-strems-service-deccription full-width-925">
                <p>
                  For an employee who gets his visa for an occupation that falls
                  under this category, the basic application charges would sum
                  up to AUD 1,265.
                </p>

                <p>
                  The validity of this visa category is for a period of 2 years,
                  except if there is an ITO (International Trade Obligation) in
                  which case the validity can be up to 4 years. An employee can
                  move in and out of Australia as many times as they feel like
                  while they enjoy the validity of the visa.
                </p>

                <a href="/get_visa" className="btn terms-strems-enquire">
                  Enquire Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="docu-required-stu-visa-sec lightgrey compadding eligibility-required-482">
        <div className="container">
          <div className="headingbox validity-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Eligibility for TSS - 482</span>{" "}
              Employer-Sponsored Visa
            </h2>

            <p>
              For employees who wish to work in a firm where the employer is
              eligible to hire foreign staff, the person must
            </p>
          </div>

          <div className="flex-box required-docu-wrapper">
            <div className="required-documents-box">
              <span className="attach-required-documents">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="Attach Docs"
                />
              </span>

              <h5>
                Have the prerequisite skills, qualifications, & knowledge of the
                post that he/she is applying for.
              </h5>
            </div>

            <div className="required-documents-box">
              <span className="attach-required-documents">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="Attach Docs"
                />
              </span>

              <h5>Have worked in the given fields for a period of 2 years.</h5>
            </div>

            <div className="required-documents-box">
              <span className="attach-required-documents">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="Attach Docs"
                />
              </span>

              <h5>
                Have the required English language standards so as to work in
                the Australian dialect.
              </h5>
            </div>

            <div className="required-documents-box">
              <span className="attach-required-documents">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="Attach Docs"
                />
              </span>

              <h5>
                Be of sound health & possess a good character certificate.
              </h5>
            </div>

            <div className="required-documents-box">
              <span className="attach-required-documents">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="Attach Docs"
                />
              </span>

              <h5>
                Have their health insurance that is required for the time they
                are in Australia.{" "}
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="applying-stu-visa-sec compadding">
        <div className="container">
          <div className="headingbox applying-stu-visa-heading common-stu-visa-heading text-center">
            <h6>Applying for the</h6>

            <h2>
              <span className="themecolor">Australian</span> Student Visa
            </h2>

            <p>
              The validity of the Australian Student Visa depends on the course
              duration of the student. In general:
            </p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/choose-study-abroad.png"
                  alt="Choose Study Abroad"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Choose a Study Abroad Course</h4>

                <p>
                  First thing first, you need to select your stream/course of
                  study. As stated earlier, Australia offers several types of
                  courses that you can choose from. You may opt for any of the
                  vocational or university courses as per your desire.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/enroll-courses.png"
                  alt="Enroll Courses"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Enroll in the Chosen Course</h4>

                <p>
                  To get yourself enrolled you submit the course fee along with
                  the documents such as your passport, school certificates, etc.
                  After you have sent your signed Letter of Offer form you will
                  receive a Confirmation of Enrollment (COE) certificate from
                  the institute.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/apply-student-visa.png"
                  alt="Apply Student"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Apply for the Student Visa</h4>

                <p>
                  Now you are all fit and ready to apply for the Australian
                  Student Visa. You can get the help of our expert team to guide
                  you through the process.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/immigration-process-visa.png"
                  alt="Immigration Process"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Immigration will Process Your Visa</h4>

                <p>
                  After you have sent your visa application, the immigration
                  authorities will scrutinize it further. The process at this
                  stage may take a few minutes or a few months as the case may
                  be.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/ready-chekout.png"
                  alt="Ready Checkout"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Get ready to fly to Australia</h4>

                <p>
                  The immigration department will inform you once your
                  application is processed via email. If the visa is approved
                  you are free to enter Australia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                Can I leave and re-enter Australia using my student Visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                Can I work in Australia using my Student Visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Can my family come to visit me if I have an Australian Student
                Visa?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
