import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Browserelatedcourse from "./RelatedCourses";
import Requirementsinfo from "./RequirementsInfo";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../actions/courseActions";
import Adcomparecourselisting from "../Course/AdCompareCourselisting";

const Index = React.memo(function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const courseDetail = useSelector((state) => state.courseDetail);
  const { loading, course, error } = courseDetail;

  useEffect(() => {
    dispatch(getCourse(id));
  }, [dispatch]);

  return (
    <>
      <div className="header-white">
        <Header />
      </div>

      {!loading ? (
        course ? (
          <Requirementsinfo courseId={id} data={course} />
        ) : (
          <h1>{error}</h1>
        )
      ) : (
        <h1>{loading}</h1>
      )}

      <Adcomparecourselisting />
      {/* <Browserelatedcourse /> */}
      <Footer />
    </>
  );
});

export default Index;
