import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Applyingstudentvisa = React.memo(function Applyingstudentvisa() {
  return (
    <section className="applying-stu-visa-sec compadding">
      <div className="container">
        <div className="headingbox applying-stu-visa-heading common-stu-visa-heading text-center">
          <h6>Applying for the</h6>

          <h2>
            <span className="themecolor">Australian</span> Student Visa
          </h2>

          <p>
            The validity of the Australian Student Visa depends on the course
            duration of the student. In general:
          </p>
        </div>

        <div className="flex-box applying-stu-visa-wrapper">
          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/choose-study-abroad.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Choose a Study Abroad Course</h4>

              <p>
                First thing first, you need to select your stream/course of
                study. As stated earlier, Australia offers several types of
                courses that you can choose from. You may opt for any of the
                vocational or university courses as per your desire.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/enroll-courses.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Enroll in the Chosen Course</h4>

              <p>
                To get yourself enrolled you submit the course fee along with
                the documents such as your passport, school certificates, etc.
                After you have sent your signed Letter of Offer form you will
                receive a Confirmation of Enrollment (COE) certificate from the
                institute.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/apply-student-visa.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Apply for the Student Visa</h4>

              <p>
                Now you are all fit and ready to apply for the Australian
                Student Visa. You can get the help of our expert team to guide
                you through the process.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/immigration-process-visa.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Immigration will Process Your Visa</h4>

              <p>
                After you have sent your visa application, the immigration
                authorities will scrutinize it further. The process at this
                stage may take a few minutes or a few months as the case may be.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/ready-chekout.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Get ready to fly to Australia</h4>

              <p>
                The immigration department will inform you once your application
                is processed via email. If the visa is approved you are free to
                enter Australia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Applyingstudentvisa;
