import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import {
  COURSE_COMPARE_LIST,
  COURSE_COMPARE_LIST_RESET,
} from "../../constants/courseConstants";
import { listCompareCourse } from "../../actions/courseActions";

const Adcomparecourselisting = React.memo(function Adcomparecourselisting() {
  const dispatch = useDispatch();
  const [courseJson, setCourseJson] = useState("");

  const handleClear = () => {
    localStorage.removeItem("cc");
    localStorage.removeItem("ccids");
    dispatch({
      type: COURSE_COMPARE_LIST_RESET,
    });
    setCourseJson("");
    dispatch(listCompareCourse(localStorage.removeItem("ccids")));
  };

  const { courseList } = useSelector((state) => state.CourseCompare);

  const handleRecords = () => {
    // setCourseJson(localStorage.getItem("cc")
    //   ? JSON.parse(localStorage.getItem("cc"))
    //   : []);
  };

  const handleRemove = (e, id) => {
    // remove the item from localstorage of ccids
    let recids = localStorage.getItem("ccids").split(",");
    const index = recids.indexOf(id.toString());
    recids.splice(index, 1);
    localStorage.setItem("ccids", recids.toString());

    // remove the item from localstorage of cc
    let rec = localStorage.getItem("cc");
    let recObj = rec ? JSON.parse(rec) : [];
    recObj.splice(
      recObj.findIndex((item) => item.Course_Id === id),
      1
    );
    localStorage.setItem("cc", JSON.stringify(recObj));
    dispatch({
      type: COURSE_COMPARE_LIST,
      payload: JSON.stringify(recObj),
    });
    dispatch(listCompareCourse(localStorage.getItem("ccids")));
  };

  useEffect(() => {
    if (courseList) {
      setCourseJson(courseList ? JSON.parse(courseList) : []);
    }
  }, [dispatch, courseList]);

  return (
    <>
      {courseJson ? (
        courseJson.length > 0 ? (
          <section className="ad-compare-courselisting ad-compare-sticky-sec sticky">
            <div className="container">
              <div className="ad-compare-sticky-sec-inner">
                <div className="ad-compare-wrapper flex-box align-center">
                  <div className="ad-compare-box-wrapper">
                    {[1, 2, 3, 4].map((data, i) => {
                      let totalRec = courseJson ? courseJson.length : 0;
                      if (totalRec > i) {
                        return (
                          <div className="ad-compare-box text-center" key={i}>
                            <div className="ad-compare-detail">
                              <span className="close close-ad-compare-detail">
                                <a
                                  onClick={(e) =>
                                    handleRemove(e, courseJson[i].Course_Id)
                                  }
                                >
                                  <LazyLoadingImages
                                    src="/img/close.png"
                                    alt="student-image"
                                  />
                                </a>
                              </span>

                              <div className="compare-logo">
                                <LazyLoadingImages
                                  src={
                                    courseJson[i].Logo74x74
                                      ? `${process.env.REACT_APP_SED_DOC_URL}/university/logo74x74/${courseJson[i].Logo74x74}`
                                      : `/img/university_img.svg`
                                  }
                                  alt={`${courseJson[i].University_Name}`}
                                />
                              </div>

                              <div className="ad-compare-detail-info">
                                <p className="location">
                                  <LazyLoadingImages
                                    src="/img/locationicon.png"
                                    alt="student-image"
                                  />
                                  {courseJson[i].University_Name}
                                </p>

                                <h4>{courseJson[i].Course_Name}</h4>
                              </div>
                            </div>
                          </div>
                        );
                      } else
                        return (
                          <div className="ad-compare-box text-center">
                            <div className="add-compare-course">
                              <LazyLoadingImages
                                src="/img/add-courses-icon.png"
                                alt="Add Course"
                              />
                            </div>
                          </div>
                        );
                    })}
                  </div>

                  <div className="compare-btn-group">
                    <a href="/course_compare" className="btn">
                      Compare
                    </a>

                    <button
                      className="btn whitebtn compare-clear-cta"
                      onClick={(e) => handleClear(e)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
});

export default Adcomparecourselisting;
