import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import axios from "axios";
// import $ from "jquery";

// export const IndiaTestimonialJson = [
//   {
//     studentname: "Gourav Tyagi",
//     contentType: "text",
//     testimonialText:
//       "Tried and tested. The best visa service provider for study visa in delhi. One to one assistance, Experienced counsellors, daily follow ups are some of the best qualities of search education. They took care of end to end visa process for my Canada file along with my doubts and queries.",
//     stars: 4,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Armaan Rafique",
//     contentType: "text",
//     testimonialText:
//       "This is a Honest review. I chose Search Education as my academic counselor and the quality and services provided are unmatchable. I truly appreciate the help provided, the quality and services provided from starting day of either selecting college and country or loan approval and until arrival of my Visa. It has been a unforgettable journey and a connection for lifetime. I would like to recommend everyone to get in touch with Search Education and get quality services .At last I would like to thank all the Search team to be with me, and helping me throughout to start my new journey.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Riya Kathuria",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Riya_kathuria.mp4",
//   },
//   {
//     studentname: "Amritanksh Singh",
//     contentType: "text",
//     testimonialText:
//       "If anyone wanna go out and planning for a good counsellor so directly go to the search education as it helps students a lot.moreover, I got my admission with the help of search education and the process was very smooth, infact the counsellors use to talk to me everyday and guided me in my very process with a calm nature. I got my visa within 5 days & I would recommend everyone to come here for your success future to study abroad.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Gurarpan Singh Sohi",
//     contentType: "text",
//     testimonialText:
//       "One of my friends recommended about Search Education team who help students looking for international education. I had a wonderful experience, the team helped me from the start i.e. preparing for the ielts exam, choosing the right college and right course as I was applying after a working experience of 11 years and scheduling our biometric appointment or opening a GIC account. They helped me till the last step. Kudos to the good work Search education team is doing. Thanks once again for all the assistance in getting a student visa for me.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sudhanshu Joshi",
//     contentType: "text",
//     testimonialText:
//       "I had an amazing experience with search education. They helped me in getting enrolled in my desired course i.e. Masters of professional accounting from Edith Cowan University Perth, Australia. The entire process was hassle free in the comfort of my home without requiring to visit their office. Search Education also helped me in getting my visa. The entire process was free of cost.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sansita Dewani",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "sansdha.mp4",
//   },
//   {
//     studentname: "Ansh sharma",
//     contentType: "text",
//     testimonialText:
//       "Literally the best team they have, for helping out students who are looking forward for their higher education abroad. Good support and guidance.Im thankfull to the full team, for reaching out me each time and clearing my doubts and confusions on things regarding universities till getting my visa. Thank you so much. And ofcourse the documentation team. Thank you so much for helping me out in completing all my documents perfectly on time. I would definitely recommend search education for my friends and relatives whoever is looking forward to study abroad.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Shreoshi Das",
//     contentType: "text",
//     testimonialText:
//       "I’m Shreoshi from New Delhi and I’ll be pursuing my second masters in September’21 from UK. I’m extremely glad that I came across Search Education and the help that I received from them throughout the application process is commendable. They guided me in every step of the procedure be it Visa application, health insurance, accommodation and Covid related formalities. I also managed to get a scholarship from the university. I thank the team wholeheartedly for their support!!",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Simranpreet kaur",
//     contentType: "text",
//     testimonialText:
//       "I'm very thankful of  team search education. Because I got my Australian visa on a call in 15 days only without visiting to them. In 2021 I got 3 refusals 2  from Canada  and 1 from New Zealand and I lost my hope but search education  give me a hope for Australia and proceed my file and now I'm in Australia. Thank you so much team search education. I strongly recommend this institute to the students who want to go abroad.   ",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sukhwinder Kaur",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Sukhwinder_Kaur.mp4",
//   },
//   {
//     studentname: "Dharmender Grewal",
//     contentType: "text",
//     testimonialText:
//       "I want to thank Search Education for my UK visa process, they worked on my application, From option shortlisting till visa filing the process was smooth and without processing fees. I got my visa in 5 days. They were cooperative and attentive throughout the process. I highly recommend Search Education because they're trustworthy and there services are excellent.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sansita Dewani",
//     contentType: "text",
//     testimonialText:
//       "I've been well guided by their counsellors since day 1. Their IELTS coach has helped me immensely so much so that I managed to score a whopping 8 band score with a 9 band in reading and 8.5 in listening. Search Education has been guiding me with utmost care and they continue to guide me. I highly recommend Search Education to everyone looking to pursue their higher education abroad.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Awin William",
//     contentType: "text",
//     testimonialText:
//       "Had a great time learning about ielts i had very little time to prepare for the test but just in 2-3 days of preparation I was able to get 7.5 bands overall. Thank you Search Education.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Karandeep",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Karamdeep_Reel.mp4",
//   },
// ];

// export const NepalTestimonialJson = [
//   {
//     studentname: "Amrit Gautam",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Amrit_Gautam.mp4",
//   },
//   {
//     studentname: "Khagendra Oli",
//     contentType: "text",
//     testimonialText:
//       "Selecting search education was my one of the best decision of my life .Specially for my destination country suraj sir and  alisha mam and all staff member helped alot.So I`m thankfull and recommend all for your abroad destination.",
//     stars: 4,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sejal Aryal",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Sejal_Aryal.mp4",
//   },
//   {
//     studentname: "Hemant Shah",
//     contentType: "text",
//     testimonialText:
//       "I am currently taking IELTS Preparation classes at Search Education and I am very happy with it. The Teaching method is awesome. Mock test on every Sundays of the week has really help me hone my english language. Thank you so much for the wonderful classes.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Rohit Upadhaya",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Rohit_Upadhaya.mp4",
//   },
//   {
//     studentname: "urjana bajracharya",
//     contentType: "text",
//     testimonialText:
//       "I have been to many consultancies seeking academic and professional advices, but Search Education was the only one which satisfied me. Moreover, the services and staffs here are very friendly and motivating. Thumbs up guys 👍",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Malika Ayer",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Malika_Ayer.mp4",
//   },
//   {
//     studentname: "Swoit",
//     contentType: "text",
//     testimonialText:
//       "Totally in love with their services. New idea of applying abroad to study. Really helpful to the students. No extra charges, no applying charges, absolutely free. Traditional ways of sending students abroad shall now perish....",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Bhusan",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Bhusan.mp4",
//   },
//   {
//     studentname: "Aaditya Mahata",
//     contentType: "text",
//     testimonialText:
//       "I have had great experience with members of Search Education as they are supportive, motivating and expert in their field. Their right guidance through the Student Visa process is worth the praise. Thank You Search Education Team for your wonderful work.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Avinash Khatri",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Avinash_Khatri.mp4",
//   },
//   {
//     studentname: "Nabin Bhusal",
//     contentType: "text",
//     testimonialText:
//       "My experience at search has been absolutely amazing. I got my visa approved recently and I am thankful to the amazing team at search. It's literally no fee consultancy. I recommend this institute to anyone looking to pursue their career abroad. Special thanks to Prabhat sir who has been a great guide throughout. I am grateful for all the help by Amisha mam. Good luck!",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Sujana Pun",
//     contentType: "video",
//     testimonialText: "",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "Sujana_Pun_Titok.mp4",
//   },
// ];

// export const AustraliaTestimonialJson = [
//   {
//     studentname: "Prashant Tamang",
//     contentType: "text",
//     testimonialText:
//       "Thank you Search Education. Special thanks to Mr. Raj Bista. They provide extremely proffessional services. Despite a lot of visa refusal cases, they managed to mom’s visa. I wanna thank Mr. Raj once again for his exceptional proffessional service, friendliness, continuous follow up my case. And finally i was able to hear the good news from him. I would strongly recommend this education consultancy for all.",
//     stars: 4,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "ranjan kharel",
//     contentType: "text",
//     testimonialText:
//       "Thank you so much Search edu staff. Great service done by all the team members on my student visa and 485. Their service is very reliable and good. Thanks once again search edu.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Soumya Shrestha",
//     contentType: "text",
//     testimonialText:
//       "Search Education has been my number 1 choice for all visa related queries after moving to Australia. Whether it be consultation or application process itself, Search Education has made it tremendously easy for me get everything done. I would especially like to thank Ms. Rameela Bista for her continuous support with regards to all of my queries. She has been nothing but helpful throughout the years. We all know how lengthy the application process and all those required forms could be. Ms. Rameela has been able to guide me through any hinderance with ease and made the whole process a lot easier. If anyone is facing any difficulties with their application processes, I would highly recommend that you go through Ms. Rameela and Search Education.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Malla Narendra",
//     contentType: "text",
//     testimonialText:
//       "I've been taking the help of Search Education for visa and immigration purpose since 2018 and they're very keen with their support for every Consequences. Education consultants and migration agents of this consulting solution are empowered with their expertise in related field",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Jags",
//     contentType: "text",
//     testimonialText:
//       "Highly recommend. Raj an absolute gem of a person, was so helpful during entire process.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "sanchita satyal",
//     contentType: "text",
//     testimonialText:
//       "Thank you Raj for your valuable feedback and Support.Your suggestion lead me to right path for my carrier ahead..I highly recommend search education",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Naresh Adhikari",
//     contentType: "text",
//     testimonialText:
//       "I think Search education is probably the best education consultant for students planning to come to study and other migration services. The whole management is a big support anytime. They are always ready and happy to help.Their service was excellent and professional.Absolutely, its worth a try.",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "Udhav Karkee",
//     contentType: "text",
//     testimonialText:
//       "Very much helpful team. Thank you Raj and Julia for your assistance. Definitely recommend to every one who needs education, visa and migration assistance. ",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
//   {
//     studentname: "james musikoyo",
//     contentType: "text",
//     testimonialText: "Positive: Communication, Professionalism, Quality, Value",
//     stars: 5,
//     studentImage: "default.jpg",
//     studentVideo: "",
//   },
// ];

const TestimonialSec = React.memo(function ({ cntry }) {
  const [activeSec, setActiveSec] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [TestimonialJson, setTestimonialJson] = useState([]);

  const { country } = useSelector((state) => state.getLocation);

  const handlePlayVideo = (url) => {
    setVideoUrl("");
    setVideoUrl(
      <video
        width="400"
        preload="auto"
        playsinline=""
        controls
        autoplay=""
        poster="/img/satisfiedstudentimg.png"
        id="video-popup"
      >
        <source src={url} type="video/mp4" />
      </video>
    );
    setActiveSec(true);
  };

  const options = {
    slidesToShow: 3,
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const getTestimonials = async (cntry) => {
    if (cntry && cntry == "nepal") {
      const testimonialRecs = await axios.get("./data/NepalTestimonial.json");
      setTestimonialJson(testimonialRecs.data);
    } else {
      const testimonialRecs = await axios.get("./data/IndiaTestimonial.json");
      setTestimonialJson(testimonialRecs.data);
    }
  };

  useEffect(() => {
    getTestimonials();
    // if (cntry && cntry == "nepal") {
    //   setTestimonialJson(NepalTestimonialJson);
    // } else {
    //   setTestimonialJson(IndiaTestimonialJson);
    // }
  }, []);

  return (
    <>
      {/* <TestimonialSec /> */}
      <section className="testimonialsec compadding">
        <div className="container">
          <div className="headingbox text-center">
            <h2>
              <span className="smh">Our Testimonials</span>What They Say
            </h2>
          </div>
          <div className="satisfiedstudentcntntimg show-900">
            <div className="satisfiedstudentcntnt">
              <div className="satisfiedstudentcntntinnr">
                <h2>
                  <strong>
                    100%
                    <br />
                  </strong>{" "}
                  Satisfied
                  <br /> For My Studies
                  <br /> Overseas
                </h2>
              </div>
            </div>
          </div>
          <div className="sec-row">
            <div className="sec-md-col-25 hide-900">
              <div className="satisfiedstudentcntntimg">
                <div className="satisfiedstudentcntnt">
                  <div className="satisfiedstudentcntntinnr">
                    <h2>
                      <strong>
                        100%
                        <br />
                      </strong>{" "}
                      Satisfied
                      <br /> For My Study
                      <br /> in Abroad
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-md-col-75 slide-right-box">
              {/* {country ? country?.country_name === "Australia" ? (
                <Slider className="testimonailslider" {...options}>
                  {AustraliaTestimonialJson.map((rec, index) => {
                    return rec.contentType === "text" ? (
                      <div className="testimonailslide" key={index}>
                        <div className="testimonialslidecntnt">
                          <div className="testimonial-inner">
                            <h5 className="overflow">{rec.testimonialText}</h5>
                            <div className="client-info flex-box">
                              <div className="client-image">
                                <LazyLoadingImages src="/img/default.jpg" />
                              </div>
                              <div className="client-details">
                                <h6>{rec.studentname}</h6>
                                <div className="star-rating">
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="testimonailslide" key={index}>
                        <div className="satisfiedstudentimg">
                          <div className="satisfied-image">
                            <video
                              width="400"
                              preload="auto"
                              muted
                              autoplay="/"
                              playsinline="/"
                              loop="/"
                              id="myVideo"
                            >
                              <source
                                src={`/img/${rec.studentVideo}`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>

                          <div className="student-info flex-box">
                            <span
                              className="video-play-button popup-btn"
                              onClick={(e) =>
                                handlePlayVideo(`/img/${rec.studentVideo}`)
                              }
                            >
                              <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                            </span>
                            <div className="student-detail">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : country?.country_name === "Nepal" ? (
                <Slider className="testimonailslider" {...options}>
                  {NepalTestimonialJson.map((rec, index) => {
                    return rec.contentType === "text" ? (
                      <div className="testimonailslide" key={index}>
                        <div className="testimonialslidecntnt">
                          <div className="testimonial-inner">
                            <h5 className="overflow">{rec.testimonialText}</h5>
                            <div className="client-info flex-box">
                              <div className="client-image">
                                <LazyLoadingImages src="/img/default.jpg" />
                              </div>
                              <div className="client-details">
                                <h6>{rec.studentname}</h6>
                                <div className="star-rating">
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                  <span className="fa fa-star checked"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="testimonailslide" key={index}>
                        <div className="satisfiedstudentimg">
                          <div className="satisfied-image">
                            <video
                              width="400"
                              preload="auto"
                              muted
                              autoplay="/"
                              playsinline="/"
                              loop="/"
                              id="myVideo"
                            >
                              <source
                                src={`/img/${rec.studentVideo}`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </div>

                          <div className="student-info flex-box">
                            <span
                              className="video-play-button popup-btn"
                              onClick={(e) =>
                                handlePlayVideo(`/img/${rec.studentVideo}`)
                              }
                            >
                              <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                            </span>
                            <div className="student-detail">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : ( */}
              <Slider className="testimonailslider" {...options}>
                {TestimonialJson.map((rec, index) => {
                  return rec.contentType === "text" ? (
                    <div className="testimonailslide" key={index}>
                      <div className="testimonialslidecntnt">
                        <div className="testimonial-inner">
                          <h5 className="overflow">{rec.testimonialText}</h5>
                          <div className="client-info flex-box">
                            <div className="client-image">
                              <LazyLoadingImages src="/img/default.jpg" />
                            </div>
                            <div className="client-details">
                              <h6>{rec.studentname}</h6>
                              <div className="star-rating">
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="testimonailslide" key={index}>
                      <div className="satisfiedstudentimg">
                        <div className="satisfied-image">
                          <video
                            width="400"
                            preload="auto"
                            muted
                            autoplay="/"
                            playsinline="/"
                            loop="/"
                            id="myVideo"
                          >
                            <source
                              src={`/img/${rec.studentVideo}`}
                              type="video/mp4"
                            ></source>
                          </video>
                        </div>

                        <div className="student-info flex-box">
                          <span
                            className="video-play-button popup-btn"
                            onClick={(e) =>
                              handlePlayVideo(`/img/${rec.studentVideo}`)
                            }
                          >
                            <LazyLoadingImages src="/img/orange-video-play-button-new.png" />
                          </span>
                          <div className="student-detail">
                            <h6>{rec.studentname}</h6>
                            <div className="star-rating">
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                              <span className="fa fa-star checked"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              {/* ) : ("")} */}
            </div>
          </div>
        </div>
      </section>
      <div className={activeSec ? "video-popup active" : "video-popup"}>
        <div className="popup-bg"></div>
        <div className="popup-content">
          {/* <video
            width="400"
            preload="auto"
            autoplay=""
            defaultMuted="true"
            muted
            playsinline=""
            controls
            poster="/img/satisfiedstudentimg.png"
            id="video-popup"
          >
            <source src="/img/Jeevan_Reel.mp4" type="video/mp4" />
          </video> */}
          {videoUrl}
          <button
            className="close-btn"
            onClick={(e) => {
              setActiveSec(false);
              setVideoUrl("");
            }}
          >
            <img src="/img/close-bg.png" alt="student" />
          </button>
        </div>
      </div>
    </>
  );
});

export default TestimonialSec;
