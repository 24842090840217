import React from 'react'
import LeftPanel from '../Common/leftPanel'
import Adcomparesticky from './ad-compare-sticky'
import Dashboardallcourses from './dashboard-all-courses'

export default function Index () {
  return (
    <>
    <LeftPanel/>
      <Dashboardallcourses/>
      <Adcomparesticky/>
    </>
  )
}
