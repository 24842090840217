import {
  GENDER_LIST_REQUEST,
  GENDER_LIST_SUCCESS,
  GENDER_LIST_FAIL,
  GENDER_LIST_RESET,
} from "../constants/genderConstants";

export const genderListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GENDER_LIST_REQUEST:
      return { loading: true };
    case GENDER_LIST_SUCCESS:
      return { loading: false, genders: payload };
    case GENDER_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
