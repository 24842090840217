import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useState } from "react";
import { contactJson } from "../../models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { useEffect } from "react";
import CountrySelect from "../Common/CountrySelect";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";

const EnquireSec = React.memo(function EnquireSec({ imgSrc }) {
  const dispatch = useDispatch();
  const [contactArray, setContactArray] = useState(contactJson);
  const [Ierror, setierror] = useState("");

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, error, success } = contactCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const handleChange = (e) => {
    setContactArray({
      ...contactArray,
      Link: window.location.href,
      Comments:
        "Contact Filled the form using the Enquire Now Form on Website. Country: " +
        country?.country_name,
      Activity: "Website Enquiry Form Submitted.",
      Activity_Des:
        "Enquiry Form submitted using the Website. Country: " +
        country?.country_name,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    var errormsg = "";
    if (contactArray.full_name === "" || contactArray.full_name === undefined)
      errormsg = "--- Name is Required! ---";
    if (
      contactArray.Email_Address === "" ||
      contactArray.Email_Address === undefined
    )
      errormsg += "--- Email is Required! ---";
    if (contactArray.Phone_No === "" || contactArray.Phone_No === undefined)
      errormsg += "--- Phone is Required!---";
    if (
      contactArray.Interested_Country === "" ||
      contactArray.Interested_Country === undefined
    )
      errormsg += "--- Interested Country is Required!--- ";
    if (errormsg == "") {
      dispatch(createContact(contactArray));
    } else {
      setierror(errormsg);
    }
  };

  useEffect(() => {
    if (success) {
      setContactArray(contactJson);
      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 3000);
    }
  }, [dispatch, success]);

  return (
    <section
      className="innrbanner event-detail-banner marg-16"
      // style={{ backgroundImage: `url('/img/event-listing-banner.png')` }}
      style={{
        backgroundImage: `url(${
          imgSrc
            ? process.env.REACT_APP_SED_DOC_URL + "/Events/" + imgSrc
            : "/img/event-listing-banner.png"
        })`,
      }}
    >
      <div className="container">
        <div className="event-detail-banner-form-wrapper flex-box">
          <form className="event-detail-banner-form">
            <h4>Register Now</h4>

            <div className="form-group-wrapper">
              <div className="form-group banner-form-group">
                <label for="name">Name</label>

                <div className="event-detail-banner-input-group">
                  <span className="form-icon">
                    <LazyLoadingImages
                      src="/img/user-icon.png"
                      alt="user-icon"
                    />
                  </span>

                  <input
                    type="text"
                    placeholder="Name"
                    name="full_name"
                    id="name"
                    value={contactArray.full_name}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="form-group banner-form-group">
                <label for="Email">Email</label>

                <div className="event-detail-banner-input-group">
                  <span className="form-icon">
                    <LazyLoadingImages
                      src="/img/form-email.png"
                      alt="student-image"
                    />
                  </span>

                  <input
                    type="text"
                    placeholder="Email"
                    name="Email_Address"
                    id="Email"
                    value={contactArray.Email_Address}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="form-group banner-form-group">
                <label for="PhoneNumber">Phone Number</label>

                <div className="event-detail-banner-input-group">
                  <span className="form-icon">
                    <LazyLoadingImages
                      src="/img/email-icon.png"
                      alt="student-image"
                    />
                  </span>

                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="Phone_No"
                    id="PhoneNumber"
                    value={contactArray.Phone_No}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="form-group banner-form-group">
                <label for="Interested_Country">Interested Country</label>

                <div className="event-detail-banner-input-group">
                  {/* <span
                    className="form-icon"
                    style={{ float: "right", right: "15px" }}
                  >
                    <LazyLoadingImages
                      src="/img/select-option.png"
                      alt="student-image"
                    />
                  </span> */}

                  <select
                    id="Interested_Country"
                    name="Interested_Country"
                    className="form-control form-padding"
                    value={contactArray.Interested_Country}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="0">--Select--</option>
                    <CountrySelect />
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group banner-form-group event-detail-form-cta">
              {loading ? (
                <button type="button" className="btn event-detail-form-submit">
                  Loading...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn event-detail-form-submit"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              )}
            </div>
            <p>
              <span className="bg-success">{success}</span>
              <span className="bg-danger">{error}</span>
              <span
                className="bg-danger"
                dangerouslySetInnerHTML={{ __html: Ierror }}
              ></span>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
});

export default EnquireSec;
