import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { listPopularCourse } from "../../actions/courseActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { docUrl } from "../../core/urls";
const MostPopularCourses = React.memo(function ({ cntry }) {
  var dispatch = useDispatch();

  const popularCourseList = useSelector((state) => state.popularCourseList);
  const { loading, error, popularcourses } = popularCourseList;

  // const { country } = useSelector((state) => state.getLocation);

  var options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const getPopularCourse = () => {
    if (cntry && cntry == "nepal") {
      dispatch(listPopularCourse("Nepal"));
    } else {
      dispatch(listPopularCourse("India"));
    }
  };

  useEffect(() => {
    if (!popularcourses) {
      getPopularCourse();
    }
  }, [dispatch, popularcourses]);

  return (
    <>
      <section className="mostpopularcoursesec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Explore Our</span>Most Popular Courses
            </h2>
          </div>
          <div className="mostpopularuniversitysliderpro">
            <Slider className="mostpopularuniversityslider" {...options}>
              {popularcourses
                ? popularcourses.map((val, index) => {
                    var courseUrl = "/course/" + val.Course_Id;
                    return (
                      <div
                        className="mstpopularuniversityslide"
                        key={index + 1}
                      >
                        <div className="mstpopularuniversityinnr">
                          <div className="mstpopularimg">
                            <LazyLoadingImages
                              src={`${docUrl}/University/Banner402x300/${val.Banner402x300}`}
                              alt={val.University_Name}
                            />
                          </div>
                          <div className="mstpopularcntnt">
                            <div className="mstpopularlogo">
                              <LazyLoadingImages
                                src={`${docUrl}/University/Logo74x74/${val.Logo74x74}`}
                                alt={val.University_Name}
                              />
                            </div>
                            <h5>
                              {val.Course_Name}, {val.Country}
                            </h5>
                            <p className="location">
                              <LazyLoadingImages
                                src="/img/locationicon.png"
                                alt="Map"
                              />
                              {val.University_Name}
                            </p>
                            <div className="durationfeesem">
                              <div className="colhalf">
                                <p>
                                  <span>Duration</span>
                                  {val.Duration} months
                                </p>
                              </div>
                              <div className="colhalf">
                                <p>
                                  <span>Course fee</span>
                                  {val.Country_Symbol} {val.Yearly_Tuition_Fees}
                                </p>
                              </div>
                            </div>
                            <a href={courseUrl} className="btn" target="_blank">
                              <LazyLoadingImages
                                src="/img/arrows-rightc.png"
                                alt="Arrow"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
});

export default MostPopularCourses;
