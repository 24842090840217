import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Counselleroffer = React.memo(function Counselleroffer() {
  return (
    <section className="counseller-offer othersericesec compadding">
      <div className="container">
        <div className="headingbox com-headingbox letter-normal">
          <h2>
            <span className="themecolor">What We</span> Offer
          </h2>
          <p>
            Our elite team members work round the globe from
            <strong>3 continents,</strong> assisting the students in every
            single step of their <strong>overseas education journey.</strong>
          </p>
        </div>

        <div className="sec-row comm-content-wrapper-width">
          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/getting.png"
                  alt="Getting Visa Guidence"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Getting Visa Guidence</p>

                <a href="/get_visa" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <a href="/hdfc_credila_edu_loan" className="btn">
                  <LazyLoadingImages
                    src="/img/course-career.png"
                    alt="Help in Loan"
                  />
                </a>
              </div>

              <div className="otherservicecntnt">
                <p>Course & career Counselling</p>

                <a href="/career" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/browse-courses.png"
                  alt="International Bank Account"
                />
              </div>

              <div className="otherservicecntnt">
                <p>
                  Browse
                  <br /> Courses
                </p>

                <a href="/courses" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/scholarshipguidline.png"
                  alt="Forex"
                />
              </div>

              <div className="otherservicecntnt">
                <p>
                  Scholarship
                  <br /> assistance
                </p>

                <a href="/consult_a_counselor" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages src="/img/loan-guidance.png" alt="Forex" />
              </div>

              <div className="otherservicecntnt">
                <p>
                  Loan
                  <br /> Guidance
                </p>

                <a href="/hdfc_credila_edu_loan" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/passport-cerficate.png"
                  alt="Forex"
                />
              </div>

              <div className="otherservicecntnt">
                <p>
                  Visa
                  <br /> Applications
                </p>

                <a href="/student_visa" className="btn">
                  <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Counselleroffer;
