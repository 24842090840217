import {
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST,
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS,
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_FAIL,
  VERIFY_TOKEN_SUCCESS,
  IPCHECKER_CREATE_REQUEST,
  IPCHECKER_CREATE_FAIL,
  IPCHECKER_CREATE_SUCCESS,
} from "../constants/websiteConstants";
import api2 from "../core/api2";

export const tokenVerify = (tokenJson) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFY_TOKEN_REQUEST,
    });
    const { data } = await api2.post(`/sbcm/ttk/wbieacd1704`, tokenJson);
    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: VERIFY_TOKEN_FAIL,
        payload: response,
      });
    } else {
      localStorage.setItem("tkiltacId", tokenJson.tokenId);
      localStorage.setItem("tkiltac", tokenJson.token);
      const dt = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString();
      const dt1 = dt.split("T")[0];
      localStorage.setItem("exprydt", dt1 + " 00:00:00");
      dispatch({
        type: VERIFY_TOKEN_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: VERIFY_TOKEN_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const GetEnglishModuleData = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST,
    });
    const loginId = localStorage.getItem("tkiltacId");
    const token = localStorage.getItem("tkiltac");
    const recJson = { id: id, loginId, token };
    const { data } = await api2.post(`/website/ieltsacad`, recJson);
    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS,
        payload: response[0],
      });
    }
  } catch (error) {
    dispatch({
      type: ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const insertIP = (IPJson) => async (dispatch) => {
  try {
    dispatch({
      type: IPCHECKER_CREATE_REQUEST,
    });
    const { data } = await api2.post(`/wipcapture`, IPJson);
    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: IPCHECKER_CREATE_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: IPCHECKER_CREATE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: IPCHECKER_CREATE_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
