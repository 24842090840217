import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";

const EnrollSearchTest = React.memo(function () {
  return (
    <section className="enrollsearchtest pd-tzero compadding">
      <div className="container">
        <div className="headingbox text-center">
          <h2>
            <span className="smh">Enroll For</span>
            <span className="themecolor">Search </span> Tests
          </h2>
        </div>
        <div className="sec-row">
          <div className="sec-md-col-33 enrollsearchcol">
            <div className="enrollsearchbox">
              <div className="enrollicon">
                <LazyLoadingImages src="/img/ielitsicon.png" alt="Icon" />
              </div>
              <h2>IELTS</h2>
              <p>
                IELTS is the high-stakes English test for study, migration or
                work
              </p>
              <a href="/ielts_training" className="btn">
                <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
              </a>
            </div>
          </div>
          <div className="sec-md-col-33 enrollsearchcol">
            <div className="enrollsearchbox">
              <div className="enrollicon">
                <LazyLoadingImages src="/img/pteicon.png" alt="Icon" />
              </div>
              <h2>PTE</h2>
              <p>Unbiased English testing for study abroad and immigration</p>
              <a href="/pte_training" className="btn">
                <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
              </a>
            </div>
          </div>
          <div className="sec-md-col-33 enrollsearchcol">
            <div className="enrollsearchbox">
              <div className="enrollicon">
                <LazyLoadingImages src="/img/NAATIcon.png" alt="Icon" />
              </div>
              <h2>NAATI CCL</h2>
              <p>A way to Australian PR via a point-based visa application</p>
              <a href="/contactus" className="btn">
                <LazyLoadingImages src="/img/arrows-right.png" alt="Arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default EnrollSearchTest;
