import React from "react";
import OurCourses from "./OurCourses";
import ConsultNow from "./ConsultNow";

const RegisterInEvent = React.memo(function RegisterInEvent() {
  return (
    <div className="event-right-box" id="register-now">
      {/* <div className="register-right-form">
        <div className="register-form-heading text-center">
          <h3>Register Yourself</h3>
        </div>

        <div className="register-form-content">
          <p>
            This includes entrepreneurs, marketing managers, marketing
            specialists, & small business owners.
          </p>
          <EventForm formClass={"register-form"} />
        </div>
      </div> */}

      <OurCourses />

      <ConsultNow />
    </div>
  );
});
export default RegisterInEvent;
