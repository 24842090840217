import {
  CAREER_CREATE_REQUEST,
  CAREER_CREATE_SUCCESS,
  CAREER_CREATE_FAIL,
} from "../constants/careerConstants";

export const careerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CAREER_CREATE_REQUEST:
      return { loading: true };
    case CAREER_CREATE_SUCCESS:
      return { loading: false, success: action.payload };
    case CAREER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CAREER_CREATE_FAIL:
      return { loading: false, success: "", error: "" };
    default:
      return state;
  }
};
