import React from "react";
import Header from "../Common/Header";
import { Helmet } from "react-helmet";
import Footer from "../Common/Footer";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import PopularUniversitySec from "../Home/PopularUniversitySec";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getEventList } from "../../actions/eventActions";
import { Link } from "react-router-dom";
import Meta from "../Common/Meta";
import Moment from "moment";

const Index = React.memo(function Index() {
  const dispatch = useDispatch();

  const listEvent = useSelector((state) => state.listEvent);
  const { loading, error, eventlists } = listEvent;

  const eventJson = [
    {
      Event_Id: 1,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
    {
      Event_Id: 2,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
    {
      Event_Id: 3,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
    {
      Event_Id: 4,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
    {
      Event_Id: 5,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
    {
      Event_Id: 6,
      Event_Name: "Event Name",
      Event_Location: "Event Location",
      Event_Date: "Event Date",
    },
  ];

  useEffect(() => {
    if (!eventlists) {
      dispatch(getEventList());
    }
  }, [dispatch, eventlists]);

  return (
    <>
      <Meta title={"Event Listing"} />
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <section
        className="innrbanner event-listing-banner flex-box align-center"
        style={{ backgroundImage: `url('/img/event-listing-banner.png')` }}
      >
        <div className="container">
          <div className="event-listing-banner-content">
            <h5>Our Events</h5>

            <h1>Explore Over 500+ Events</h1>
          </div>
        </div>
      </section>

      <section className="our-picks-sec pd-bzero">
        <div className="container">
          <div className="flex-box our-picks-heading-top-wrapper align-center">
            <div className="our-picks-top-heading headingbox sec-md-col-40">
              <h2>
                <span className="themecolor">Our </span>Picks
              </h2>

              <p>Discover best experiences, hand-picked by our experts.</p>
            </div>

            <div className="our-picks-event-type sec-md-col-55 flex-box align-center">
              <span className="our-picks-even">
                <LazyLoadingImages
                  src="/img/event-select.png"
                  alt="event-select"
                />{" "}
                Event Type :
              </span>

              <span className="our-picks-event-cta-group">
                <a href="/" className="btn whitebtn">
                  Past Event
                </a>

                <a href="/" className="btn">
                  Upcoming Event
                </a>
              </span>
            </div>
          </div>
          <div className="flex-box our-pics-box-wrapper">
            {eventlists
              ? eventlists.map((eventrec) => (
                  <div className="our-picks-box" key={eventrec.Id}>
                    <div className="our-picks-box-inner">
                      <div className="our-picks-image">
                        <a href={`/event/${eventrec.Id}`} target="_blank">
                          <LazyLoadingImages
                            src={
                              eventrec.ImageOfEvent
                                ? `${process.env.REACT_APP_SED_DOC_URL}/Events/${eventrec.ImageOfEvent}`
                                : "/img/universityimage1.jpg"
                            }
                            alt={eventrec.EventName}
                          />
                        </a>
                      </div>

                      <div className="our-picks-content">
                        <p className="location">
                          <LazyLoadingImages
                            src="/img/locationicon.png"
                            alt="Map"
                          />
                          {eventrec.Branch} - {eventrec.ModeEvent}
                        </p>

                        <h5>{eventrec.EventName}</h5>

                        <div className="event-listing-vanue">
                          <p>
                            {Moment(eventrec.StartDate).format("MMM DD, YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}

            {/* <div className="our-picks-box">
              <div className="our-picks-box-inner">
                <div className="our-picks-image">
                  <a href="/event-detail.html">
                    <LazyLoadingImages
                      src="/img/education-fair.jpg"
                      alt="featured"
                    />
                  </a>
                </div>

                <div className="our-picks-content">
                  <p className="location">
                    <LazyLoadingImages
                      src="/img/locationicon.png"
                      alt="Map"
                    />
                    Le Meridien New Delhi
                  </p>

                  <h5>Australian Education Fair (Study in Australia)</h5>

                  <div className="event-listing-vanue">
                    <p>Feb 24 l 06:00 PM (IST)</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="our-picks-box">
              <div className="our-picks-box-inner">
                <div className="our-picks-image">
                  <a href="/event-detail.html">
                    <LazyLoadingImages
                      src="/img/education-fair.jpg"
                      alt="featured"
                    />
                  </a>
                </div>

                <div className="our-picks-content">
                  <p className="location">
                    <LazyLoadingImages
                      src="/img/locationicon.png"
                      alt="Map"
                    />
                    Scientology, 10, Mathura Road
                  </p>

                  <h5>How to Achieve 100% Mind Potential (Free Seminar)</h5>

                  <div className="event-listing-vanue">
                    <p>Feb 24 l 06:00 PM (IST)</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="our-picks-box">
              <div className="our-picks-box-inner">
                <div className="our-picks-image">
                  <a href="/event-detail.html">
                    <LazyLoadingImages
                      src="/img/universityimage1.jpg"
                      alt="featured"
                    />
                  </a>
                </div>

                <div className="our-picks-content">
                  <p className="location">
                    <LazyLoadingImages
                      src="/img/locationicon.png"
                      alt="Map"
                    />
                    Scientology, 10, Mathura Road
                  </p>

                  <h5>How to Achieve 100% Mind Potential (Free Seminar)</h5>

                  <div className="event-listing-vanue">
                    <p>Feb 24 l 06:00 PM (IST)</p>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Featured Html Code */}
            {/* <div className="our-picks-box our-pics-box-featured">
              <div className="our-picks-featured-inner flex-box align-center">
                <div className="our-picks-image featured">
                  <a href="/event-detail.html">
                    <LazyLoadingImages
                      src="/img/boy-with-glasses.jpg"
                      alt="featured"
                    />
                  </a>
                </div>

                <div className="our-picks-content">
                  <p className="location">
                    <LazyLoadingImages
                      src="/img/locationicon.png"
                      alt="Map"
                    />
                    Scientology, 10, Mathura Road
                  </p>

                  <h5>How to Achieve 100% Mind Potential (Free Seminar)</h5>

                  <div className="event-listing-vanue">
                    <p>Feb 24 l 06:00 PM (IST)</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* Pagination Section */}
      {/* <section className="our-picks-sec2 featured-listing">
        <div className="container">
          <div className="pagination">
            <a href="/" className="prev">
              <LazyLoadingImages src="/img/grey-arrow.png" alt="grey-arrow" />
            </a>

            <a href="/" className="active">
              1
            </a>

            <span>of</span>

            <a href="/">24</a>

            <a href="/" className="next">
              <LazyLoadingImages
                src="/img/orange-arrow.png"
                alt="orrange-arrow"
              />
            </a>
          </div>
        </div>
      </section> */}

      <PopularUniversitySec secName="Featured" />

      {/* <!-- Start Listing SEction --> */}
      <Footer />
    </>
  );
});

export default Index;
