export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_REGISTER_VERIFY_REQUEST = 'USER_REGISTER_VERIFY_REQUEST'
export const USER_REGISTER_VERIFY_SUCCESS = 'USER_REGISTER_VERIFY_SUCCESS'
export const USER_REGISTER_VERIFY_FAIL = 'USER_REGISTER_VERIFY_FAIL'
export const USER_REGISTER_VERIFY_RESET = 'USER_REGISTER_VERIFY_RESET'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL'
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL'
export const USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL'
export const USER_FORGOT_PASSWORD_RESET = 'USER_FORGOT_PASSWORD_RESET'

export const USER_FORGOT_PASSWORD2_REQUEST = 'USER_FORGOT_PASSWORD2_REQUEST'
export const USER_FORGOT_PASSWORD2_SUCCESS = 'USER_FORGOT_PASSWORD2_SUCCESS'
export const USER_FORGOT_PASSWORD2_FAIL = 'USER_FORGOT_PASSWORD2_FAIL'
export const USER_FORGOT_PASSWORD2_RESET = 'USER_FORGOT_PASSWORD2_RESET'

export const USER_COUNSELLOR_REQUEST = 'USER_COUNSELLOR_REQUEST'
export const USER_COUNSELLOR_SUCCESS = 'USER_COUNSELLOR_SUCCESS'
export const USER_COUNSELLOR_FAIL = 'USER_COUNSELLOR_FAIL'
export const USER_COUNSELLOR_RESET = 'USER_COUNSELLOR_RESET'

export const USER_DASHBOARD_REQUEST = 'USER_DASHBOARD_REQUEST'
export const USER_DASHBOARD_SUCCESS = 'USER_DASHBOARD_SUCCESS'
export const USER_DASHBOARD_FAIL = 'USER_DASHBOARD_FAIL'

export const USER_INTERESTED_IN_REQUEST = 'USER_INTERESTED_IN_REQUEST'
export const USER_INTERESTED_IN_SUCCESS = 'USER_INTERESTED_IN_SUCCESS'
export const USER_INTERESTED_IN_FAIL = 'USER_INTERESTED_IN_FAIL'

