import {
    ENGLISH_TEST_ATTEMPTED_LIST_REQUEST,
    ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS,
    ENGLISH_TEST_ATTEMPTED_LIST_FAIL
} from '../constants/englishTestAttemptedConstants'

export const englishTestAttemptedListReducer = (state={}, {type,payload}) => {
    switch (type){
        case ENGLISH_TEST_ATTEMPTED_LIST_REQUEST:
            return {loading:true}
        case ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS:
            return {loading:false, englishtests:payload}
        case ENGLISH_TEST_ATTEMPTED_LIST_FAIL:
            return {loading:false,error:payload}
        default:
            return state;
    }

}