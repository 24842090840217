export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST'
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS'
export const COURSE_LIST_FAIL = 'COURSE_LIST_FAIL'

export const COURSE_DETAIL_REQUEST = 'COURSE_DETAIL_REQUEST'
export const COURSE_DETAIL_SUCCESS = 'COURSE_DETAIL_SUCCESS'
export const COURSE_DETAIL_FAIL = 'COURSE_DETAIL_FAIL'

export const COURSE_LIST_TOTAL_REQUEST = 'COURSE_LIST_TOTAL_REQUEST'
export const COURSE_LIST_TOTAL_SUCCESS = 'COURSE_LIST_TOTAL_SUCCESS'
export const COURSE_LIST_TOTAL_FAIL = 'COURSE_LIST_TOTAL_FAIL'

export const COURSE_APPLY_REQUEST = 'COURSE_APPLY_REQUEST'
export const COURSE_APPLY_SUCCESS = 'COURSE_APPLY_SUCCESS'
export const COURSE_APPLY_FAIL = 'COURSE_APPLY_FAIL'
export const COURSE_APPLY_RESET = 'COURSE_APPLY_RESET'

export const COURSE_APPLY_STATUS_REQUEST = 'COURSE_APPLY_STATUS_REQUEST'
export const COURSE_APPLY_STATUS_SUCCESS = 'COURSE_APPLY_STATUS_SUCCESS'
export const COURSE_APPLY_STATUS_FAIL = 'COURSE_APPLY_STATUS_FAIL'

export const COURSE_APPLY_LIST_REQUEST = 'COURSE_APPLY_LIST_REQUEST'
export const COURSE_APPLY_LIST_SUCCESS = 'COURSE_APPLY_LIST_SUCCESS'
export const COURSE_APPLY_LIST_FAIL = 'COURSE_APPLY_LIST_FAIL'
export const COURSE_APPLY_LIST_RESET = 'COURSE_APPLY_LIST_RESET'

export const COURSE_APPLY_LIST_TOTAL_REQUEST = 'COURSE_APPLY_LIST_TOTAL_REQUEST'
export const COURSE_APPLY_LIST_TOTAL_SUCCESS = 'COURSE_APPLY_LIST_TOTAL_SUCCESS'
export const COURSE_APPLY_LIST_TOTAL_FAIL = 'COURSE_APPLY_LIST_TOTAL_FAIL'
export const COURSE_APPLY_LIST_TOTAL_RESET = 'COURSE_APPLY_LIST_TOTAL_RESET'

export const COURSE_SHORTLIST_REQUEST = 'COURSE_SHORTLIST_REQUEST'
export const COURSE_SHORTLIST_SUCCESS = 'COURSE_SHORTLIST_SUCCESS'
export const COURSE_SHORTLIST_FAIL = 'COURSE_SHORTLIST_FAIL'

export const COURSE_REMOVE_SHORTLIST_REQUEST = 'COURSE_REMOVE_SHORTLIST_REQUEST'
export const COURSE_REMOVE_SHORTLIST_SUCCESS = 'COURSE_REMOVE_SHORTLIST_SUCCESS'
export const COURSE_REMOVE_SHORTLIST_FAIL = 'COURSE_REMOVE_SHORTLIST_FAIL'
export const COURSE_REMOVE_SHORTLIST_RESET = 'COURSE_REMOVE_SHORTLIST_RESET'

export const COURSE_SHORTLIST_LIST_REQUEST = 'COURSE_SHORTLIST_LIST_REQUEST'
export const COURSE_SHORTLIST_LIST_SUCCESS = 'COURSE_SHORTLIST_LIST_SUCCESS'
export const COURSE_SHORTLIST_LIST_FAIL = 'COURSE_SHORTLIST_LIST_FAIL'

export const COURSE_COMPARE_LIST = 'COURSE_COMPARE_LIST'
export const COURSE_COMPARE_LIST_RESET = 'COURSE_COMPARE_LIST_RESET'

export const COURSE_COMPARE_DETAIL_REQUEST = 'COURSE_COMPARE_DETAIL_REQUEST'
export const COURSE_COMPARE_DETAIL_SUCCESS = 'COURSE_COMPARE_DETAIL_SUCCESS'
export const COURSE_COMPARE_DETAIL_FAIL = 'COURSE_COMPARE_DETAIL_FAIL'
export const COURSE_COMPARE_DETAIL_RESET = 'COURSE_COMPARE_DETAIL_RESET'

export const COURSE_LIST_TRENDING_REQUEST = 'COURSE_LIST_TRENDING_REQUEST'
export const COURSE_LIST_TRENDING_SUCCESS = 'COURSE_LIST_TRENDING_SUCCESS'
export const COURSE_LIST_TRENDING_FAIL = 'COURSE_LIST_TRENDING_FAIL'

export const COURSE_LIST_POPULAR_REQUEST = 'COURSE_LIST_POPULAR_REQUEST'
export const COURSE_LIST_POPULAR_SUCCESS = 'COURSE_LIST_POPULAR_SUCCESS'
export const COURSE_LIST_POPULAR_FAIL = 'COURSE_LIST_POPULAR_FAIL'

export const COURSE_LIST_UNIVERSITY_REQUEST = 'COURSE_LIST_UNIVERSITY_REQUEST'
export const COURSE_LIST_UNIVERSITY_SUCCESS = 'COURSE_LIST_UNIVERSITY_SUCCESS'
export const COURSE_LIST_UNIVERSITY_FAIL = 'COURSE_LIST_UNIVERSITY_FAIL'