import React from "react";
import AdSection1 from "../Home/AdSection1";
import CounterDataSec from "../Home/CounterDataSec";
import EducationPartner from "../Home/EducationPartner";
import EnrollSearchTest from "../Home/EnrollSearchTest";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import HowItWorks from "../Home/HowItWorks";
import MainBanner from "../Home/MainBanner";
import MostPopularCourses from "../Home/MostPopularCourses";
import OtherServiceSec from "../Home/OtherServiceSec";
import OurPartnerSec from "../Home/OurPartnerSec";
import PopularUniversitySec from "../Home/PopularUniversitySec";
import SearchCountrySec from "../Home/SearchCountrySec";
import SearchDreamCourse from "../Home/SearchDreamCourse";
import TestimonialSec from "../Home/TestimonialSec";
import TrendingCourseSec from "../Home/TrendingCourseSec";
import Meta from "../../components/Meta";

const Index = React.memo(function () {
  const metaData = {
    title: "Search Education – Study Abroad Consultant | Free Counselling",
    mtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    mdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    ogdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogimage: "https://www.searcheducation.com/img/img_3.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle:
      "Search Education – Study Abroad Consultant | Free Counselling",
    twitterDescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    twitterImage: "https://www.searcheducation.com/img/img_3.jpg",
  };
  return (
    <>
      <Header />
      <Meta metaData={metaData} />
      <MainBanner cntry={"nepal"} />

      <SearchDreamCourse />

      <PopularUniversitySec secName="Search" cntry={"nepal"} />

      <HowItWorks />

      <MostPopularCourses cntry={"nepal"} />

      <EnrollSearchTest />

      <AdSection1 />

      <OtherServiceSec />

      <TrendingCourseSec cntry={"nepal"} />

      <SearchCountrySec />

      <TestimonialSec cntry={"nepal"} />

      <EducationPartner />

      <CounterDataSec />

      <OurPartnerSec />

      <Footer />
    </>
  );
});

export default Index;
