import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listInterestedFieldOfStudy } from "../actions/interestedFieldOfStudyActions";

export default function InterestedFieldOfStudy() {
  const dispatch = useDispatch();
  const { loading, error, interestedFields } = useSelector(
    (s) => s.interestedFieldOfStudyList
  );
  const getInterestedFields = () => {
    dispatch(listInterestedFieldOfStudy());
  };
  useEffect(()=>{
    if(!interestedFields){
      getInterestedFields();
    }
  },[dispatch,interestedFields])
  return interestedFields
    ? interestedFields.length > 0
      ? interestedFields.map((alcd, index) => (
          <option value={alcd.Id} key={alcd.Id}>
            {alcd.Field_Of_Study}
          </option>
        ))
      : ""
    : "";
}
