import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import BannerForm from "../Common/BannerForm";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import Meta from "../../components/Meta";

const Index = React.memo(function () {
  const [active, setActive] = useState("");

  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };

  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const metaData = {
    title: "Study in New Zealand | | Free Counselling – Search Education",
    mtitle: "Study in New Zealand | Free Counselling – Search Education",
    mdescription:
      "Unlock world-class educational opportunities in the UK with expert guidance from our study-abroad consultants. Discover your path to global success!",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Study in New Zealand | Free Counselling – Search Education",
    ogdescription:
      "Unlock world-class educational opportunities in the UK with expert guidance from our study-abroad consultants. Discover your path to global success!",
    ogimage:
      "https://www.searcheducation.com/img/highway-view-toronto-city.png",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle: "Study in New Zealand | Free Counselling – Search Education",
    twitterDescription:
      "Unlock world-class educational opportunities in the UK with expert guidance from our study-abroad consultants. Discover your path to global success!",
    twitterImage:
      "https://www.searcheducation.com/img/highway-view-toronto-city.png",
  };
  return (
    <>
      <Header />
      <Meta metaData={metaData} />
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/highway-view-toronto-city.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>New Zealand</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>

              <div className="canada-university-info-list flex-box">
                <div className="canada-university-info-list-item">
                  <h4>39,000+</h4>
                  <p>International Students</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>20,000 NZ$</h4>
                  <p>Avg Living costs/pa</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>8+</h4>
                  <p>Universities</p>
                </div>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding study-in-newzeland">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/mountain.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Study In</span> New Zealand
              </h2>
              <p>
                New Zealand has achieved great progress in the field of foreign
                education in recent years. This is a lovely area to live and
                learn, with excellent educational opportunities and a fantastic
                way of life.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/girl-write-with-park.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <p>
                It boasts a diverse educational system with eight universities,
                sixteen institutes of technology and polytechnics, and a number
                of private schools, all of which are known for providing
                world-class education.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="visa-of-country compadding education-in-country education-ireland lightgrey">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">Why Study in the </span>New Zealand
            </h2>
            <p>
              In New Zealand, higher education institutions include
              universities, schools, private institutions, and polytechnics.
              There are eight colleges in New Zealand, all of which offer
              high-quality education. Many students develop their abilities and
              ideas while in the country since they are encouraged to think
              independently and generate their own answers. Here are a few of
              the best reasons to continue your education in New Zealand:
            </p>
          </div>
          <div className="flex-box visa-of-country-wrapper just-center com-reverse-row">
            <div className="visa-of-country-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/newz-flag.png" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <h2>Education In New Zealand</h2>
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    The education system in New Zealand, which was influenced by
                    the UK, emphasizes research-based teaching, which means
                    you'll learn how to deal with situations in a planned and
                    organized manner.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    New Zealand has a wide range of academic options for
                    students. Professors with advanced degrees, well-equipped
                    laboratories, and access to cutting-edge technology,
                    resources, and opportunities abound in the country. In
                    addition to the numerous study choices, you will have access
                    to additional benefits as a Ph.D. student.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-box visa-of-country-wrapper just-center com-reverse-row">
            <div className="visa-of-country-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/building.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    As an international student in New Zealand, you can work up
                    to 20 hours per week during semesters and full-time during
                    holidays. In reality, if you pursue a research master's or
                    doctoral degree, you will be able to work full-time.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="newzealand-visa-cost-sec compadding">
        <div className="container">
          <div className="flex-box newzealand-visa-cost-wrapper just-center">
            <div className="newzealand-visa-cost full-width-925">
              <div className="newzealand-visa-cost-image">
                <LazyLoadingImages src="/img/cost-of-study.jpg" />
              </div>
              <div className="newzealand-visa-cost-content headingbox mb-0 letter-normal">
                <h2>
                  <span className="themecolor">Cost Of Studying In</span> New
                  zealand
                </h2>
                <p>
                  The New Zealand Dollar ($NZD) is the country's currency.
                  Colleges and universities in New Zealand are free to determine
                  their own tuition costs. As a result, the amount you pay will
                  differ based on the organisation you select.
                </p>
                <p>
                  For international students, a bachelor's degree will cost
                  between $22,000 and $32,000, while a postgraduate programme
                  would cost between $26,000 and $37,000.
                </p>
                <p>
                  If you choose a course in medical, engineering, or veterinary
                  science, your expenditures will most likely be greater.
                </p>
                <p>
                  All candidates pay the same amount for a Ph.D., which ranges
                  from $6,500 to $9,000 per year. For more information on the
                  cost of your specific course, contact your institution.
                  Scholarships are available to international students at all
                  levels, including those pursuing a Ph.D.
                </p>
              </div>
            </div>
            <div className="newzealand-visa-cost full-width-925">
              <div className="newzealand-visa-cost-image">
                <LazyLoadingImages src="/img/visa-of-study.jpg" />
              </div>
              <div className="newzealand-visa-cost-content headingbox mb-0 letter-normal">
                <h2>
                  <span className="themecolor">Visa for</span> New Zealand
                </h2>
                <p>
                  If you plan to stay in New Zealand for more than three months,
                  you'll need to apply for a student visa. A visa is not
                  required for students from Australia and other countries with
                  which New Zealand has an agreement. After acquiring your visa,
                  you can stay in the nation for up to four years and work up to
                  20 hours each week. You can apply for a visa at a New Zealand
                  embassy or consulate in your home country, but applying online
                  saves you 10% on the application fee.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="study-in-newzeland-sec lightgrey compadding">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/building.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Education In </span>New Zealand
              </h2>
              <p>
                Since New Zealand was once a British colony, its higher
                education system is very similar to that of the United Kingdom.
                As a result, qualifications earned in New Zealand are often
                transferable to institutions in the United Kingdom and
                Australia. The New Zealand Qualifications Authority must
                accredit all educational institutions that grant qualifications
                (NZQA). There are four types of higher education institutions in
                New Zealand
              </p>
              <ul className="flex-box study-courses-tags-list">
                <li className="study-courses-tags-list-item">Universities</li>
                <li className="study-courses-tags-list-item">
                  Polytechnics & Institutes Of Technology
                </li>
                <li className="study-courses-tags-list-item">
                  Colleges Of Education
                </li>
                <li className="study-courses-tags-list-item">
                  Private Training Provider
                </li>
              </ul>
            </div>
          </div>
          <div className="study-courses-tags-list-wrapper headingbox letter-normal mb-0">
            <p>
              New Zealand's universities are mostly research-based and
              state-owned. They offer courses ranging from a certificate to a
              doctorate, with most of them lasting a year. Some courses are only
              offered for one semester and can be started during the academic
              year. State-owned polytechnics and institutes of technology
              deliver classes that are comparable to those offered at
              universities. Many also provide highly regarded postgraduate
              programmes on an international scale. Colleges of education are
              teacher-training programmes that are either affiliated with or
              combined with the local university. Training in a particular
              discipline, such as tourism management, hospitality management,
              cooking, or business, is often available from private training
              providers.
            </p>
            <p>
              The structure of New Zealand degrees is somewhat similar to that
              of the United Kingdom, ranging from qualifications to PhDs, but
              only university institutions offer the full range. Polytechnics
              and institutes of technology offer degree programmes as well as
              postgraduate programmes. Certificate and diploma programmes are
              popular in private training. A bachelor's degree takes three to
              four years to complete on average. Following that, you can enrol
              in a postgraduate programme to receive a graduate certificate, a
              Master's degree, or a doctorate. Master's degrees take one to two
              years to complete and entail a higher level of study, including
              studying that isn't typically covered in undergraduate classes.
            </p>
          </div>
        </div>
      </section>
      <section className="eligibility-visa family-sponsored-eligibility-491 compadding">
        <div className="container">
          <div className="eligibility-type-heading headingbox">
            <h2>
              <span className="themecolor">Popular Courses Offered At</span> New
              Zealand
            </h2>
          </div>
          <div className="flex-box eligibility-type-wrapper just-center">
            <div className="eligibility-type-list-wrapper eligibility-for-applicant full-width-925">
              <div className="headingbox letter-normal">
                <h2>
                  Some Of The Popular Courses Offered At New Zealand
                  Universities Include
                </h2>
              </div>
              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Civil and Mechanical Engineering</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Construction Management</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5> Logistic and Supply Chain Management</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Information Technology</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Hospitality and Tourism Management</h5>
                </div>
              </div>
            </div>

            <div className="eligibility-type-list-wrapper eligibility-for-sponsor full-width-925">
              <div className="headingbox letter-normal">
                <h2>
                  Some Of the Highest-ranked Universities Of New Zealand Include
                </h2>
              </div>
              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Victoria University of Wellington, Wellington </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>University of Canterbury, Christchurch</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>The University of Waikato, Hamilton, North Island</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Lincoln University, Lincoln</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Auckland University of Technology, Auckland</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trendingcoursesec compadding ireland-courses lightgrey">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Top </span>Courses In New Zealand
            </h2>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="application-process compadding">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/career-counseling.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/best-college.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/offer-letter.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/approverd-visa.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                What documents are required for applying for a visa in New
                Zealand?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  You must have a confirmation letter stating that you have been
                  accepted into an NZQA-accredited course and that you have paid
                  the required fees to be qualified for a student visa. The
                  following items must be included in this documentation:
                </p>

                <ul>
                  <li>
                    The course's name and the amount of time it takes to
                    complete it
                  </li>
                  <li>
                    Proof that the course and its provider fulfil New Zealand's
                    foreign student specifications.
                  </li>
                  <li>
                    Proof of complete payment of the course, or, if the course
                    is longer than one year, proof of payment of the first
                    annual fee
                  </li>
                  <li>
                    Whether or not you must pay course fees and whether the fees
                    are charged at a lower domestic rate or a higher
                    international rate.
                  </li>
                  <li>Whether you are a full-time or part-time student?</li>
                </ul>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                Can one work while studying in New Zealand?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  If you have a student visa, you will be able to work up to 20
                  hours per week during the school year and full-time (40 hours
                  per week) during the summer vacation. If you are a
                  research-oriented master's or doctoral student, you will be
                  able to work full-time during the academic year and planned
                  vacations.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Is it true that New Zealand qualifications are accepted all over
                the world?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, New Zealand degrees are recognised around the world and
                  are recognised for their high educational standards.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                What are the options for the scholarship for studying in New
                Zealand?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  Most universities, particularly at the postgraduate level,
                  offer a limited number of scholarships to international
                  students. There are funding opportunities for research
                  projects and graduate studies in various fields, including
                  medicine, pharmaceuticals, and product design.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("05")}>
                What job opportunities are available after graduation from New
                Zealand universities?
                <span
                  className={active === "05" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "05" ? "panel blk" : "panel"}>
                <p>
                  Competent graduates and professional professionals can find a
                  wide range of prospects in New Zealand. The construction
                  industry is growing, with more hotels being constructed
                  throughout the country to accommodate the increasing number of
                  tourists. Engineers, tourism and hospitality graduates,
                  medical professionals, and caregivers are in high demand, and
                  international students who graduate will have a strong chance
                  of finding work in New Zealand. It is recommended that you
                  apply for a 12-month job-search visa after completing your
                  degree in New Zealand. This makes it easy to look for work.
                  Students can apply for residency permits after completing
                  around five to six years of service in New Zealand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
