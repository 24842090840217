import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Otherservices = React.memo(function Otherservices() {
  return (
    <section className="othersericesec compadding education-loan-why-us lightgrey">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Why</span> Choose Us
          </h2>

          <p>
            We ensure 100% transparency throughout the education loan process.
            Our education loan counsellors will stay by your side every step of
            the way.
          </p>
        </div>

        <div className="sec-row">
          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/transparency.png"
                  alt="Getting Visa Guidence"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Transparency</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/flexible-collateral.png"
                  alt="Help in Loan"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Flexible Collateral</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/supports.png"
                  alt="International Bank Account"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Support</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages src="/img/secure.png" alt="Forex" />
              </div>

              <div className="otherservicecntnt">
                <p>Secure</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Otherservices;
