import {
  ENGLISH_TEST_ATTEMPTED_LIST_REQUEST,
  ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS,
  ENGLISH_TEST_ATTEMPTED_LIST_FAIL,
} from "../constants/englishTestAttemptedConstants";
import api2 from "../core/api2";

export const listEnglishTestAttempted = () => async (dispatch) => {
  try {
    dispatch({ type: ENGLISH_TEST_ATTEMPTED_LIST_REQUEST });
    const { data } = await api2.get(`/englishTest`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({
        type: ENGLISH_TEST_ATTEMPTED_LIST_FAIL,
        payload: data.Result,
      });
    } else {
      dispatch({
        type: ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS,
        payload: data.Result,
      });
    }
  } catch (ex) {
    dispatch({
      type: ENGLISH_TEST_ATTEMPTED_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
