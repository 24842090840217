import React from "react";

import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Visaapplingprocess = React.memo(function Visaapplingprocess() {
  return (
    <section className="applying-stu-visa-sec visa-appling-process compadding">
      <div className="container">
        <div className="headingbox applying-stu-visa-heading com-headingbox common-stu-visa-heading letter-normal text-center">
          <h2>
            <span className="themecolor">Visa Applying</span> Process
          </h2>

          <p>The application steps to avail a 482- TSS Visa are as follows</p>
        </div>

        <div className="flex-box applying-stu-visa-wrapper">
          <div className="applying-stu-courses-box flex-box align-center ">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/choose-study-abroad.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Select the type of visa you are applying for</h4>

              <p>
                To begin with, check whether the applicant is eligible to apply
                for this visa. For this, the applicant must have an eligible
                family member who is living in the regional area of the country.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/enroll-courses.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>Next, Check your eligibility</h4>

              <p>
                Secure at least the minimum passing marks in the skill
                assessment test in any of the occupations mentioned in the
                MLTSSL.{" "}
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/apply-student-visa.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>
                Fill the online application form entering all required
                information
              </h4>

              <p>
                Apart from these requirements, the applicant must be nominated
                by a relative residing in the regional area of the country.
              </p>
            </div>
          </div>

          <div className="applying-stu-courses-box flex-box align-center">
            <div className="applying-stu-courses-image">
              <LazyLoadingImages
                src="/img/immigration-process-visa.png"
                alt="student-image"
              />
            </div>

            <div className="applying-stu-courses-content letter-normal">
              <h4>
                Appear for the visa interview along with all required documents
              </h4>

              <p>
                After doing this process the applicant will receive their
                invitation to apply for the visa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Visaapplingprocess;
