import React from "react";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Freeworld = React.memo(function () {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return (
    <section className="free-world compadding lightgrey">
      <div className="container">
        <div className="headingbox com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Believe In A Border</span> Free World!
          </h2>

          <p>
            More than a million people leave their home country every year and
            move to another land for studies, work or livelihood. University
            Living solves the house-hunting problem for over 5 million students
            every year.
          </p>
        </div>
      </div>

      <div className="free-world-wrapper" id="free-world-slider">
        <Slider {...options}>
          <div className="free-world-image">
            <LazyLoadingImages
              src="/img/gabriel-beaudry.jpg"
              alt="student-image"
            />
          </div>

          <div className="free-world-image">
            <LazyLoadingImages
              src="/img/pexels-cottonbro.jpg"
              alt="student-image"
            />
          </div>

          <div className="free-world-image">
            <LazyLoadingImages
              src="/img/gabriel-beaudry.jpg"
              alt="student-image"
            />
          </div>

          <div className="free-world-image">
            <LazyLoadingImages
              src="/img/pexels-cottonbro.jpg"
              alt="student-image"
            />
          </div>
        </Slider>
      </div>
    </section>
  );
});

export default Freeworld;
