import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCareer } from "../../actions/careerActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { careerJson } from "../../models/careerModel";
import BannerForm from "../Common/BannerForm";

const Ourmisson = React.memo(function Ourmisson() {
  const dispatch = useDispatch();
  const [careerData, setCareerData] = useState(careerJson);

  const careerCreate = useSelector((state) => state.careerCreate);
  const { loading, success, error } = careerCreate;

  const handleData = (e) => {
    setCareerData({ ...careerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    dispatch(createCareer(careerData));
  };

  useEffect(() => {
    if (success) {
      setCareerData(careerJson);
    }
  }, [dispatch, success]);

  return (
    <>
      <section
        className="innrbanner"
        style={{ backgroundImage: `url('/img/career-banner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Career</h4>
              <h1>Join Search Education</h1>
            </div>
            {/* <BannerForm /> */}
          </div>
        </div>
      </section>
      <section className="our-mission">
        <div className="container">
          <div className="headingbox text-center">
            <h2>
              <span className="themecolor">Our </span>Mission
            </h2>
            <LazyLoadingImages src="/img/quotes.png" alt="quotes-image" />
            <p>
              We believe there are no shortcuts to a world class education. Our
              mission is to facilitate the international academic journey by
              assisting students throughout the challenging transition into
              their post study aspirations and become the best versions of
              themselves.
            </p>
          </div>
        </div>
      </section>
    </>
  );
});

export default Ourmisson;
