import {
    GRADING_SYSTEM_LIST_REQUEST,
    GRADING_SYSTEM_LIST_SUCCESS,
    GRADING_SYSTEM_LIST_FAIL,
    GRADING_SYSTEM_LIST_RESET
} from '../constants/gradingSystemConstants'

export const gradingListReducer = (state={}, {type,payload}) => {
    switch (type){
        case GRADING_SYSTEM_LIST_REQUEST:
            return {loading:true}
        case GRADING_SYSTEM_LIST_SUCCESS:
            return {loading:false, gradings:payload}
        case GRADING_SYSTEM_LIST_FAIL:
            return {loading:false,error:payload}
        default:
            return state;
    }

}