import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMonth } from "../actions/monthActions";
import { useEffect } from "react";

export default function Months() {
  const dispatch = useDispatch();
  const { loading, error, months } = useSelector((s) => s.monthList);
  const getMonths = () => {
    dispatch(listMonth());
  };
  useEffect(() => {
    if(!months){
      getMonths();
    }
  }, [dispatch, months]);
  return months ? (
    months.map((mnt, index) => (
      <option key={index + 1} value={mnt.Id}>
        {mnt.Month}
      </option>
    ))
  ) : (
    <option>{error}</option>
  );
}
