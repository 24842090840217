import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Counsellerexpert = React.memo(function Counsellerexpert() {
  return (
    <section className="counseller-expert applying-stu-visa-sec compadding lightgrey">
      <div className="container">
        <div className="comm-content-wrapper-width">
          <div className="headingbox applying-stu-visa-heading common-stu-visa-heading">
            <h2>
              <span className="themecolor">Get Counselling By</span> The
              Industry Experts
            </h2>

            <p>
              Our elite team members work round the globe from 3 continents,
              assisting the students in every single step of their overseas
              education journey.
            </p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/choose-study-abroad.png"
                  alt="choose-study-abroad"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>With More Than 20 Years Of Experience</h4>

                <p>
                  To begin with, go through the eligibility criteria above and
                  check if you are qualified enough to get into the Skilled
                  Migration Program by undergoing the Points Test.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/enroll-courses.png"
                  alt="enroll-courses"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Helped 1.2 Lakhs Students Applications</h4>

                <p>
                  Next, you need to submit your Expression of Interest (EOI)
                  document. This visa is granted on a points-based test. The EOI
                  document will hold your points for various things such as age,
                  educational qualification, proficiency in the English
                  language, etc.{" "}
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/apply-student-visa.png"
                  alt="apply-student"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>More Than 120+ Universities On Board</h4>

                <p>
                  Apart from these requirements, the applicant must be nominated
                  by a state or territory of Australia. The applicant needs to
                  apply for the nomination stating that they are ready to work
                  in that particular area of the country.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/immigration-process-visa.png"
                  alt="imigration"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Fast, Reliable and Transparent Process</h4>

                <p>
                  494- Skilled Employer Sponsored (Regional) Visa is then
                  granted to the applicant if they are found to fulfill all the
                  conditions required to avail the visa.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="button-box">
            <a href="/courses" className="btn">
              Apply Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Counsellerexpert;
