import React, { useState } from "react";

const Faqs = React.memo(function () {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  return (
    <section className="faqs">
      <div className="container">
        <div className="text-box text-center faq-heading headingbox">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="faq-wrapper">
          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("01")}>
              Before submitting an application for any scholarship, it's crucial
              to properly investigate the requirements and check that you meet
              them.
              <span
                className={active === "01" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "01" ? "panel blk" : "panel"}>
              <p>
                Sydney is a well-liked destination for students from all over
                the world due to its abundance of well regarded institutions and
                universities. These are the top five colleges in Sydney.
              </p>
              <ul>
                <li>
                  The University of Sydney is renowned for its top-notch
                  medical, law and engineering courses.
                </li>
                <li>
                  The University of New South Wales is notably well-known for
                  its medical, engineering, business, and technology courses.
                </li>
                <li>
                  The Australian National University is a renowned university
                  with a solid reputation for research and teaching in a wide
                  range of subjects.
                </li>
                <li>
                  Macquarie University is renowned for providing courses in
                  business, economics, and languages.
                </li>
                <li>
                  Western Sydney University is well known for its courses in the
                  social sciences, education, and nursing.
                </li>
              </ul>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("02")}>
              How much does it cost to live in Sydney as a student?
              <span
                className={active === "02" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "02" ? "panel blk" : "panel"}>
              <p>
                Depending on housing, lifestyle, and individual spending
                preferences, the cost of living for students in Sydney can vary
                greatly. However, living expenses for a student in Sydney might
                range from $400 to $800 per week.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("03")}>
              How much does a student visa for Sydney cost?
              <span
                className={active === "03" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "03" ? "panel blk" : "panel"}>
              <p>
                The cost of a student visa for Sydney varies depending on the
                kind of visa, the duration of the stay, and the country of
                origin. Starting in 2023, an Australian student visa will cost
                AUD $620. Health examinations, biometrics, and other services
                might, however, come with additional costs. For the most recent
                information on visa expenses, it is advisable to visit the
                Department of Home Affairs website, which is run by the
                Australian government.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("04")}>
              What professions are open to students in Sydney following
              graduation?
              <span
                className={active === "04" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "04" ? "panel blk" : "panel"}>
              <p>
                Depending on their degree and credentials, graduates in Sydney
                can choose from a wide range of jobs. Finance, business,
                healthcare, engineering, technology, education, and the creative
                industries are a few of the industries that are well-liked in
                Sydney. Graduates may find employment in a variety of
                industries, including the government, non-profits, start-up
                businesses, or huge corporations. In Sydney, software
                developers, nurses, accountants, project managers, and teachers
                are some of the professions in high demand. It is significant to
                remember that exact work chances and specifications may change
                depending on the sector and person.
              </p>
            </div>
          </div>

          <div className="accordion-group accordion-ques">
            <button className="accordion" onClick={() => handleActive("05")}>
              How might search education assist me in my studies in Sydney?
              <span
                className={active === "05" ? "faq-icon intro" : "faq-icon"}
              ></span>
            </button>

            <div className={active === "05" ? "panel blk" : "panel"}>
              <p>
                Students wishing to study abroad can get professional advice and
                assistance from Search Education. We help students with every
                stage of the study abroad process, from choosing a course and
                university to applying for visas and getting ready to go. Our
                knowledgeable counselors can assist students with the
                challenging application process, provide guidance on funding and
                scholarship options, and support with language competence exams.
                We are in an excellent spot to give students the assistance they
                require to meet their academic objectives and help them to
                successfully settle in a foreign country.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Faqs;
