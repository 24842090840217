import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  forgotPasswordReducer,
  forgotPasswordVerifyReducer,
  userChangePasswordReducer,
  userCounsellorReducer,
  userDashboardTotalReducer,
  userDetailsReducer,
  userInterestInReducer,
  userLoginReducer,
  userLogoutReducer,
  userRegisterReducer,
  userRegisterVerifyReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  univeristyListReducer,
  univeristyPopularListReducer,
  universityDetailReducer
} from "./reducers/universityReducers";
import { studyLevelListReducer } from "./reducers/studyLevelReducers";
import { preferredIntakeListReducer } from "./reducers/preferredIntakeReducers";
import {
  messageInsertReducer,
  messageInternalListReducer,
  messageListReducer,
  newMessageInsertReducer,
} from "./reducers/messageReducers";
import {
  documentAddReducer,
  documentListReducer,
  documentRemoveReducer,
} from "./reducers/documentReducers";
import {
  compareCourseDetailReducer,
  courseAppliedReducer,
  courseAppliedStatusReducer,
  courseAppliedTotalReducer,
  courseApplyReducer,
  CourseCompareReducer,
  courseDetailReducer,
  courseListReducer,
  courseListTotalReducer,
  courseShortlistedReducer,
  courseShortlistReducer,
  CourseUniversityReducer,
  popularCourseListReducer,
  removeCourseShortlistReducer,
  trendingCourseListReducer,
} from "./reducers/courseReducers";
import { allCountryListReducer, countryListReducer } from "./reducers/countryReducers";
import { contactCreateReducer } from "./reducers/contactReducers";
import { careerCreateReducer } from "./reducers/careerReducers";
import { bookingCreateReducer } from "./reducers/bookingReducers";
import {
  getLocationReducer,
  subscribeCreateReducer,
} from "./reducers/otherReducers";
import { detailEventReducer, listEventReducer } from "./reducers/eventReducer";
import {
  IPInsertReducer,
  englishGetModuleReducer,
  verifyTokenReducer,
} from "./reducers/websiteReducers";
import { genderListReducer } from "./reducers/genderReducers";
import { monthListReducer } from "./reducers/monthReducer";
import { notificationListReducer } from "./reducers/notificationsReducers";
import { englishTestAttemptedListReducer } from "./reducers/englishTestAttemptedReducer";
import { financialSourceListReducer } from "./reducers/financialSourceReducers";
import { educationalStatusListReducer } from "./reducers/educationalStatusReducers";
import { gradingListReducer } from "./reducers/gradingSystemReducer";
import { interestedLevelOfStudyListReducer } from "./reducers/interestedLevelOfStudyReducers";
import { interestedFieldOfStudyListReducer } from "./reducers/interestedFieldOfStudyReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userLogout: userLogoutReducer,
  userRegister: userRegisterReducer,
  userRegisterVerify: userRegisterVerifyReducer,
  userDetails: userDetailsReducer,
  userInterestIn: userInterestInReducer,
  userUpdate: userUpdateReducer,
  userCounsellor: userCounsellorReducer,
  userDashboardTotal: userDashboardTotalReducer,
  forgotPassword: forgotPasswordReducer,
  forgotPasswordVerify: forgotPasswordVerifyReducer,
  userChangePassword: userChangePasswordReducer,
  univeristyList: univeristyListReducer,
  univeristyPopularList: univeristyPopularListReducer,
  universityDetail:universityDetailReducer,
  studyLevelList: studyLevelListReducer,
  preferredIntakeList: preferredIntakeListReducer,
  messageList: messageListReducer,
  messageInternalList: messageInternalListReducer,
  messageInsert: messageInsertReducer,
  newMessageInsert: newMessageInsertReducer,
  documentList: documentListReducer,
  documentAdd: documentAddReducer,
  documentRemove: documentRemoveReducer,
  courseList: courseListReducer,
  courseListTotal: courseListTotalReducer,
  courseDetail: courseDetailReducer,
  courseApplied: courseAppliedReducer,
  courseAppliedTotal:courseAppliedTotalReducer,
  courseApply: courseApplyReducer,
  courseAppliedStatus: courseAppliedStatusReducer,
  courseShortlisted: courseShortlistedReducer,
  courseShortlist: courseShortlistReducer,
  removeCourseShortlist: removeCourseShortlistReducer,
  countryList: countryListReducer,
  allCountryList: allCountryListReducer,
  contactCreate: contactCreateReducer,
  careerCreate: careerCreateReducer,
  bookingCreate: bookingCreateReducer,
  subscribeCreate: subscribeCreateReducer,
  getLocation: getLocationReducer,
  trendingCourseList: trendingCourseListReducer,
  popularCourseList: popularCourseListReducer,
  CourseCompare: CourseCompareReducer,
  listEvent: listEventReducer,
  detailEvent: detailEventReducer,
  verifyToken: verifyTokenReducer,
  englishGetModule: englishGetModuleReducer,
  compareCourseDetail: compareCourseDetailReducer,
  genderList: genderListReducer,
  monthList: monthListReducer,
  notificationList: notificationListReducer,
  CourseUniversity: CourseUniversityReducer,
  englishTestAttemptedList: englishTestAttemptedListReducer,
  financialSourceList: financialSourceListReducer,
  educationalStatusList: educationalStatusListReducer,
  gradingList: gradingListReducer,
  interestedLevelOfStudyList: interestedLevelOfStudyListReducer,
  interestedFieldOfStudyList: interestedFieldOfStudyListReducer,
  IPInsert: IPInsertReducer
});

const userInfoFromStorage = localStorage.getItem("accinf");
// ? JSON.parse(localStorage.getItem("accinf"))
// : null;

const userdtFromStorage = localStorage.getItem("usinf")
  ? JSON.parse(localStorage.getItem("usinf"))
  : null;

const compareCourseStorage = localStorage.getItem("cc")
  ? localStorage.getItem("cc")
  : "";

const initialState = {
  userLogin: { userInfo: userInfoFromStorage, userDt: userdtFromStorage },
  CourseCompare: { courseList: compareCourseStorage }
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
