import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { listPopularUniversity } from "../../actions/universityActions";
import { useEffect } from "react";

const Counsellingfeatured = React.memo(function Counsellingfeatured() {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  const dispatch = useDispatch();

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const univeristyPopularList = useSelector(
    (state) => state.univeristyPopularList
  );
  const { loading, error, universityPData } = univeristyPopularList;

  const getPopularData = () => {
    if (country) {
      dispatch(listPopularUniversity(country));
    } else {
      dispatch(listPopularUniversity("India"));
    }
  };

  useEffect(() => {
    if (!universityPData) {
      getPopularData();
    }
  }, [dispatch, universityPData]);

  return (
    <section className="counselling-featured-colleges trendingcoursesec compadding lightgrey">
      <div className="container">
        <div className="headingbox">
          <h2>
            Featured <span className="themecolor">Colleges</span>
          </h2>
        </div>

        <div className="popularuniversitysliderpro">
          {universityPData ? (
            <Slider className="popularuniversityslider" {...options}>
              {universityPData.map((rec) => (
                <div className="popularuniversityslide" key={rec.University_Id}>
                  <div className="popularuniversityinnr equal-height">
                    <div className="popularuniversityimg">
                      <LazyLoadingImages
                        src={`${process.env.REACT_APP_SED_DOC_URL}/university/Banner402x300/${rec.Banner402x300}`}
                        alt="University"
                      />
                    </div>
                    <div className="popularuniversitycntnt">
                      <div className="popularuniversitylogo">
                        <LazyLoadingImages
                          src={`${process.env.REACT_APP_SED_DOC_URL}/university/Logo74x74/${rec.Logo74x74}`}
                          alt="University Logo"
                        />
                      </div>
                      <h5>
                        <a
                          href={`/college_detail/${rec.University_Id}`}
                          target="_blank"
                        >
                          {rec.University_Name}
                        </a>
                      </h5>
                      <p>
                        <LazyLoadingImages
                          src="/img/locationicon.png"
                          alt="Map"
                        />
                        {rec.Country}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div>{error}</div>
          )}
          {/* <Slider className="popularuniversityslider" {...options}>
            <div className="popularuniversityslide">
              <div className="popularuniversityinnr equal-height">
                <div className="popularuniversityimg">
                  <LazyLoadingImages
                    src="/img/trendingcourse.jpg"
                    alt="student-image"
                  />
                </div>

                <div className="popularuniversitycntnt">
                  <h5>Marketing & Advertising</h5>

                  <p>
                    3 years<span className="sperater">|</span>Post Graduated
                  </p>

                  <a href="/" className="btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="popularuniversityslide">
              <div className="popularuniversityinnr equal-height">
                <div className="popularuniversityimg">
                  <LazyLoadingImages
                    src="/img/trendingcourse2.jpg"
                    alt="student-image"
                  />
                </div>

                <div className="popularuniversitycntnt">
                  <h5>Computer Science Engineering</h5>

                  <p>
                    3 years<span className="sperater">|</span>Post Graduated
                  </p>

                  <a href="/" className="btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="popularuniversityslide">
              <div className="popularuniversityinnr equal-height">
                <div className="popularuniversityimg">
                  <LazyLoadingImages
                    src="/img/trendingcourse3.jpg"
                    alt="student-image"
                  />
                </div>

                <div className="popularuniversitycntnt">
                  <h5>Mechanical Engineering</h5>

                  <p>
                    3 years<span className="sperater">|</span>Post Graduated
                  </p>

                  <a href="/" className="btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="popularuniversityslide">
              <div className="popularuniversityinnr equal-height">
                <div className="popularuniversityimg">
                  <LazyLoadingImages
                    src="/img/trendingcourse4.jpg"
                    alt="student-image"
                  />
                </div>

                <div className="popularuniversitycntnt">
                  <h5>Master of Business Administration</h5>

                  <p>
                    3 years<span className="sperater">|</span>Post Graduated
                  </p>

                  <a href="/" className="btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="popularuniversityslide">
              <div className="popularuniversityinnr equal-height">
                <div className="popularuniversityimg">
                  <LazyLoadingImages
                    src="/img/trendingcourse2.jpg"
                    alt="student-image"
                  />
                </div>

                <div className="popularuniversitycntnt">
                  <h5>Computer Science Engineering</h5>

                  <p>
                    3 years<span className="sperater">|</span>Post Graduated
                  </p>

                  <a href="/" className="btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </Slider> */}
        </div>
      </div>
    </section>
  );
});

export default Counsellingfeatured;
