import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageList } from "../../../actions/messageActions";
import { useEffect } from "react";
import LeftPanel1 from "../Common/LeftPanel1";
import CounsellorSec from "../Dashboard/CounsellorSec";
import moment from "moment";
import NewMessage from "./NewMessage";

export default function Index() {
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const { loading, error, messagelists } = useSelector((s) => s.messageList);
  const getmessages = () => {
    dispatch(getMessageList());
  };
  const handleshow=()=>{
    setShow(true)
    getmessages()
  }
  useEffect(() => {
    if (!messagelists) {
      getmessages();
    }
  }, [dispatch]);
  return (
    <LeftPanel1
      mid={
        <>
        <div className="featuredbg-style message-style">
          <div className="headingbox">
            <div className="text-box">
              <h3>My Messages</h3>

              {messagelists ? (
                messagelists.length > 0 ? (
                  <div className="count-box">
                    <span>{messagelists.length}</span>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="add-icon write-message write-modal-toggle" onClick={e=>setShow("is-visible")}>
              <img src="/img/add-courses-icon.png" alt="Add Icon" />
            </div>
            {/* <div className="button-box">
              <span className="filer-select-arrow">
                <img src="/img/select-bottom-arrow.png" />
              </span>
              <select className="sortbybtn-select">
                <option value="Most Popular">All Messages</option>
                <option>High to Low</option>
                <option>Low to High</option>
              </select>
            </div> */}
          </div>

          {/* messages section */}
          {messagelists ? (
            messagelists.length > 0 ? (
              messagelists.map((msl, index) => (
                <div className="message-box" key={index + 1}>
                  <div className="top-box">
                    <h6>{moment(msl.Created_Date).format("DD-MMM-YYYY")}</h6>
                    <div className="all-coursescontenright-box flex-box">
                      {/* <a href="#" className="course-cta ad-compare">
                        Mark as Read
                      </a>
                      <a href="#" className="course-cta view-course">
                        Mark Important
                      </a>
                      <a href="#" className="heart">
                        <img src="/img/bin-frame.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="middle-box">
                    <h3>{msl.Message_Sub}</h3>
                    <p>{msl.Message_body}</p>
                  </div>
                  <a
                    href={`/panel/message/${msl.Id}`}
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" />
                  </a>
                </div>
              ))
            ) : (
              <div className="message-box">
                <div className="top-box"></div>
                <div className="middle-box">
                  <p>No Record Exist!</p>
                </div>
              </div>
            )
          ) : (
            <div className="message-box">
              <div className="top-box"></div>
              <div className="middle-box">
                <p>{error}</p>
              </div>
            </div>
          )}
        </div>
        {/* <NewMessage show={show} setshow={setShow} getmessages={getmessages()} /> */}
        <NewMessage show={show} setshow={handleshow} />
        </>
      }
      right={<CounsellorSec />}
    />
  );
}
