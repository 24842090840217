import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const SearchDreamCourse = React.memo(function ({ country }) {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  return (
    <>
      <section className="searchdreamcoursesec">
        <div className="container">
          <div className="searchdreaminnr">
            <form className="flexbox">
              <div className="headingform">
                <h4>Search Your Dream Course</h4>
              </div>
              <div className="courseselect">
                <label>Course</label>
                {/* <select name="selectcourse" id="selectcourse">
                  <option value="Courses">Courses</option>
                  <option value="Marketing & Advertising">
                    Marketing & Advertising
                  </option>
                  <option value="Computer Science Engineering">
                    Computer Science Engineering
                  </option>
                  <option value="Engineering">Engineering</option>
                  <option value="Master of Business Administration">
                    Master of Business Administration
                  </option>
                </select> */}
              </div>
              <div className="searchcourseform">
                <div className="coursesearch">
                  <input
                    type="text"
                    id="searchcourse"
                    placeholder="Search Courses...."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter")
                        navigate(`/courses/search/${keyword}`);
                    }}
                  />
                  <button
                    type="button"
                    name="coursesarchsubmit"
                    className="btn"
                    onClick={() => navigate(`/courses/search/${keyword}`)}
                  >
                    <LazyLoadingImages
                      src="/img/searchicon.png"
                      alt="Search Icon"
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
});

export default SearchDreamCourse;
