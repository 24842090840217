import React from "react";
import { Helmet } from "react-helmet";

export default function MetaTags(props) {
  return (
    <>
      <Helmet>
        <title>
          {props.title}
        </title>
        <meta
          name="name"
          title={props.name}
        />

        <meta
          name="description"
          content={props.description}
        />

        <meta
          name="Keywords"
          content={props.keywords}
        />

        <meta name="robots" content={props.robots} />
      </Helmet>
    </>
  );
}
