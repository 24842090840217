import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchCity from "./SearchCity";

const SearchCountrySec = React.memo(function () {
  const { country } = useSelector((state) => state.getLocation);
  const countryData = [
    {
      imageUrl: "/img/country.jpg",
      countryName: "Australia",
      redirectLink: "/study_in_australia",
    },
    {
      imageUrl: "/img/country2.jpg",
      countryName: "Canada",
      redirectLink: "/study_in_canada",
    },
    {
      imageUrl: "/img/country3.jpg",
      countryName: "United Kingdom",
      redirectLink: "/study_in_uk",
    },
    {
      imageUrl: "/img/country4.jpg",
      countryName: "Ireland",
      redirectLink: "/study_in_ireland",
    },
    {
      imageUrl: "/img/country5.jpg",
      countryName: "New Zealand",
      redirectLink: "/study_in_newzealand",
    },
  ];

  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return (
    <section className="searchcountrysec compadding">
      <div className="container">
        <div className="headingbox">
          <h2>
            <span className="smh">Most Popular</span>
            <span className="themecolor">Search</span>
            {country ? (
              country?.country_name == "Australia" ? (
                <> City</>
              ) : (
                <> Country</>
              )
            ) : (
              ""
            )}
          </h2>
        </div>
        <div className="searchcountrysliderpro">
          {/* {country ? (
            country?.country_name == "Australia" ? (
              <SearchCity removeCity="None" />
            ) : ( */}
          <Slider className="searchcountryslider" {...options}>
            {countryData.map((cntry, index) => {
              return (
                <div className="searchcountryslide" key={index}>
                  <a href={cntry.redirectLink}>
                    <div className="countrylistbox">
                      <div
                        className="countryimage"
                        style={{
                          backgroundImage: `url('${cntry.imageUrl}')`,
                        }}
                      ></div>
                      <p>
                        Study in <strong>{cntry.countryName}</strong>
                      </p>
                    </div>
                  </a>
                </div>
              );
            })}
          </Slider>
          {/* )
          ) : (
            ""
          )} */}
        </div>
      </div>
    </section>
  );
});

export default SearchCountrySec;
