import React from "react";
import HeaderAustralia from "./HeaderAustralia";
import { useNavigate } from "react-router-dom";
import RequestAQuote from "./RequestQuote";
import { useState } from "react";
import BookFree from "./BookFreeCounsultaion";
import FooterAustralia from "./FooterAustralia";
import MigrationServiceCard from "./MigrationCard";

const AustraliaMagrationService = React.memo(
  function AustraliaMagrationService() {
    // const metaData = {
    //   title: "Search Education – Study Abroad Consultant | Free Counselling",
    //   mtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    //   mdescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   ogtype: "website",
    //   ogurl: "",
    //   ogtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    //   ogdescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   ogimage: "https://www.searcheducation.com/img/img_3.jpg",
    //   twitterCard: "summary_large_image",
    //   twitterUrl: "",
    //   twitterTitle:
    //     "Search Education – Study Abroad Consultant | Free Counselling",
    //   twitterDescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   twitterImage: "https://www.searcheducation.com/img/img_3.jpg",
    // };

    const [createSec, setcreateSec] = useState(false);

    const MigrationServicesJson = [
      {
        Heading: "General Skilled Migration Visas",
        image:
          "/img/AustraliaPage/MigrationServices/General-Skilled-Migration-Visas.png",
        Desc: "General Skilled Migration encompasses a range of visas such as the Skilled Independent (subclass 189), the Skilled Nominated (subclass 190), and the Skilled Regional Nominated (subclass 491). These visas offer permanent residency or a pathway to it and do not require employer sponsorship.",
      },
      {
        Heading: "Employer Sponsored Visas",
        image:
          "/img/AustraliaPage/MigrationServices/Employer-Sponsored-Visas.png",
        Desc: "Employer-sponsored visas in Australia, such as the Temporary Skill Shortage (TSS) Visa (Subclass 482), Employer Nomination Scheme (ENS) Visa (Subclass 186), Regional Sponsored Migration Scheme (RSMS) Visa (Subclass 187), and Skilled Employer Sponsored Regional (Provisional) Visa (Subclass 494), allow businesses to sponsor skilled workers to fill critical job vacancies. These visas provide pathways to permanent residency and help address skill shortages by enabling employers to nominate qualified workers who meet specific criteria and possess the necessary skills and qualifications.",
      },
      {
        Heading: "Training Visa",
        image: "/img/AustraliaPage/MigrationServices/Training-Visas.png",
        Desc: "The Training Visa (Subclass 407) in Australia allows individuals to participate in workplace-based occupational training activities to improve their skills for their job, area of tertiary study, field of expertise, or in a professional development training program. This temporary visa helps enhance skills through structured workplace training or a professional development program in Australia, typically for up to two years. Applicants must be sponsored by an approved sponsor and meet specific requirements related to their training program and qualifications.",
      },
      // {
      //   Heading: "Skill Assessment",
      //   image: "/img/AustraliaPage/MigrationServices/Skill-Assessment.png",
      //   Desc: "A skills assessment is a crucial step for many Australian visa applications, verifying that an applicant's skills and qualifications meet Australian standards for their nominated occupation. Conducted by relevant assessing authorities, this process ensures that the applicant's educational background, work experience, and skills align with the requirements of the specific occupation. A positive skills assessment is often necessary for skilled migration, employer-sponsored, and other visa categories.",
      // },
      // {
      //   Heading: "EOI Lodgement",
      //   image: "/img/AustraliaPage/MigrationServices/EOI-Lodgement.png",
      //   Desc: "Expression of Interest (EOI) and Registration of Interest (ROI) lodgement are essential steps in the Australian visa application process for skilled migration. An EOI is submitted through the SkillSelect system, where applicants provide details about their skills, work experience, and qualifications. It allows the Australian government to assess eligibility for various visa subclasses, such as the Skilled Independent (subclass 189) and Skilled Nominated (subclass 190). An ROI is often required for certain regional visas and involves expressing interest in migrating to a specific Australian state or territory. Both EOI and ROI help streamline the selection process for skilled migrants.",
      // },
      // {
      //   Heading: "Migration Consultation",
      //   image: "/img/AustraliaPage/MigrationServices/migration-consultation.png",
      //   Desc: "",
      // },
      {
        Heading: "Visitor Visas",
        image: "/img/AustraliaPage/MigrationServices/visitor-visa.png",
        Desc: "Visitor visas for Australia, such as the Visitor Visa (Subclass 600), allow individuals to travel to Australia for tourism, business visitor activities, or visiting family and friends. These visas are typically granted for three, six, or twelve months, depending on the purpose and circumstances of the visit. Applicants must demonstrate sufficient funds to support their stay, meet health and character requirements, and intend to leave Australia before the visa expires. Visitor visas do not permit work or long-term study in Australia.",
      },
      {
        Heading: "Parent Visa",
        image: "/img/AustraliaPage/MigrationServices/parent-visa.png",
        Desc: "Parent visas in Australia, such as the Parent Visa (Subclass 103) and the Contributory Parent Visa (Subclass 143), allow eligible parents of Australian citizens, permanent residents, or eligible New Zealand citizens to live in Australia. These visas require applicants to meet the balance of family test, proving that at least half of their children reside in Australia or more children live in Australia than in any other single country. The Contributory Parent Visa has a faster processing time but involves higher application fees. These visas offer pathways to reunite families and provide permanent residency, allowing parents to live, work, and study in Australia.",
      },
      {
        Heading: "Partner Visas",
        image: "/img/AustraliaPage/MigrationServices/partner-visa.png",
        Desc: "Partner visas in Australia, such as the Partner Visa (Subclass 820/801) and the Prospective Marriage Visa (Subclass 300), allow spouses, de facto partners, or fiancés of Australian citizens, permanent residents, or eligible New Zealand citizens to live in Australia. The Subclass 820/801 visa process starts with a temporary visa (820) and leads to a permanent visa (801) after demonstrating a continuing relationship. The Prospective Marriage Visa (Subclass 300) allows fiancés to come to Australia to marry their partner within nine months. Applicants must prove their genuine relationship and meet health and character requirements. These visas provide a pathway to permanent residency and enable partners to live together in Australia.",
      },
    ];

    const navigate = useNavigate();

    const OpenStudentVisitor = () => {
      setcreateSec(true);
    };

    return (
      <>
        <HeaderAustralia />
        {/* <Meta metaData={metaData} /> */}
        <section
          className="efficient-study bg-style"
          style={{
            // backgroundImage: `url('/img/group-cheerful-happy-students.png')`,
            backgroundColor: "#25253F",
          }}
        >
          <div className="container">
            <div className="efficient-study-wrapper">
              <div
                className=" text-white"
                style={{
                  display: "flex",
                  flexDirection: window.innerWidth <= 480 ? "column" : "row",
                  width: "100%",
                  gap: "5%",
                }}
              >
                <div>
                  <div
                    style={{ width: "100%" }}
                    className="eligibility-stu-image"
                  >
                    <img
                      style={{ borderRadius: "10px" }}
                      src="/img/AustraliaPage/Julia-Yuan.png"
                      alt="student"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: window.innerWidth <= 500 ? "18px" : "0px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h2>Message from Julia</h2>
                  </div>

                  <div>
                    <p>
                      With 15 years of experience as a registered migration
                      agent, I have been dedicated to helping our clients
                      achieve their dreams of studying, working and migrating to
                      Australia. After over 6 years with Search Education, I am
                      honored to have had the opportunity to assist clients with
                      their visa issues. Our team is composed of hardworking,
                      onerous and caring professionals. By working together, we
                      have effectively resolved visa problems and streamlined
                      visa processing. Our robust CRM management system enables
                      us to deliver efficient and effective service. It has been
                      a privilege to provide professional migration advice and
                      support throughout your journey.
                    </p>
                  </div>

                  <div style={{ textAlign: "center", marginLeft: "40px" }}>
                    <h4>Julia Yuan, Registered Migration Agent.</h4>
                    <h4>MARA 0960025</h4>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="Book_Free">
          {" "}
          <BookFree />{" "}
        </div>

        <RequestAQuote createSec={createSec} setcreateSec={setcreateSec} />
        {/* What we can do start */}

        <section className="eligibility-stu-visa-sec compadding">
          <div className="container">
            <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
              {/* <h2>
              <span className="themecolor">What </span> we do
            </h2> */}
            </div>

            <div className="flex-box eligibility-stu-visa-wrapper">
              {MigrationServicesJson?.map((ev, i) => (
                <MigrationServiceCard ev={ev} />
              ))}
            </div>
          </div>
        </section>
        <FooterAustralia />
      </>
    );
  }
);

export default AustraliaMagrationService;
