import {
    STUDY_LEVEL_LIST_REQUEST,
    STUDY_LEVEL_LIST_SUCCESS,
    STUDY_LEVEL_LIST_FAIL,
  } from "../constants/studyLevelConstants";

  export const studyLevelListReducer = (state = {}, action) => {
    switch (action.type) {
      case STUDY_LEVEL_LIST_REQUEST:
        return { loading: true };
      case STUDY_LEVEL_LIST_SUCCESS:
        return { loading: false, studyLevelData: action.payload };
      case STUDY_LEVEL_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };