import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Eligibilityvisa = React.memo(function Eligibilityvisa() {
  return (
    <section className="eligibility-visa lightgrey compadding">
      <div className="container">
        <div className="eligibility-type-heading headingbox">
          <h2>
            <span className="themecolor">Eligibility for</span> 491
          </h2>

          <p>To get hold of this visa type, the applicant must</p>
        </div>

        <div className="flex-box eligibility-type-wrapper">
          <div className="eligibility-type-list-wrapper full-width-925">
            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have the required skills to work in an occupation mentioned in
                  the MLTSSL.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>Be between the age of 18 to 45.</h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>Be well versed in English dialect.</h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Be of sound health and possess their character certificates.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have secured the passing marks in the skilled migration points
                  test which is currently 90-95.
                </h5>
              </div>
            </div>

            <div className="eligibility-type-list flex-box">
              <div className="eligibility-type-infoicon">
                <LazyLoadingImages
                  src="/img/attach-docs.png"
                  alt="student-image"
                />
              </div>

              <div className="eligibility-type-info">
                <h5>
                  Have a sponsoring relative living in the regional area of the
                  country. If the relative of the applicant resides in any of
                  the major cities, they cannot become a sponsor.
                </h5>
              </div>
            </div>
          </div>

          <div className="eligibility-type-image full-width-925">
            <LazyLoadingImages
              src="/img/happy-people.jpg"
              alt="student-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Eligibilityvisa;
