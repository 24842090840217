import {
    PREFERRED_INTAKE_LIST_REQUEST,
    PREFERRED_INTAKE_LIST_SUCCESS,
    PREFERRED_INTAKE_LIST_FAIL,
  } from "../constants/preferredIntakeConstants";

  export const preferredIntakeListReducer  = (state = {}, action) => {
    switch (action.type) {
      case PREFERRED_INTAKE_LIST_REQUEST:
        return { loading: true };
      case PREFERRED_INTAKE_LIST_SUCCESS:
        return { loading: false, preferredIntakeData: action.payload };
      case PREFERRED_INTAKE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  