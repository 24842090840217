import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  listCompareCourse,
  listCourse,
  listTotalCourse,
  shortlistCourse,
} from "../../actions/courseActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { courseSearchJson } from "../../models/courseModel";
import ReactPaginate from "react-paginate";
import CountrySelect from "../Common/CountrySelect";
import UniversitySelect from "../Common/UniversitySelect";
import StudyLevelSelect from "../Common/StudyLevelSelect";
import PreferredIntakeSelect from "../Common/PreferredIntakeSelect";
import BannerForm from "../Common/BannerForm";
import { COURSE_COMPARE_LIST } from "../../constants/courseConstants";
import Months from "../../components/Months";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const Courselisting = React.memo(function Courselisting(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [SliderValue, setSliderValue] = useState({ min: 100, max: 60000 });
  const [searchtxt, setSearchtxt] = useState("");
  const { keyword } = useParams();
  const [searchcourse, setsearchcourse] = useState("");
  const [show, setshow] = useState();

  const [ttl, setttl] = useState(100);

  const { loading, courses, error } = useSelector((state) => state.courseList);
  const { userInfo } = useSelector((state) => state.userLogin);

  const {
    loading: loadingTotal,
    courseTotal,
    error: errorTotal,
  } = useSelector((state) => state.courseListTotal);

  const [courseSrchJson, setcourseSrchJson] = useState(courseSearchJson);
  const [searchtext, setsearchText] = useState("");

  const handleChange = (e) => {
    setcourseSrchJson({ ...courseSrchJson, [e.target.name]: e.target.value });
    //getcourses();
  };
  const handleFChange = (e) => {
    //console.log([e.target.name], e.target.value);
  };

  const { courseList: courseCompareList } = useSelector(
    (state) => state.CourseCompare
  );

  const {
    loading: loadingshortlist,
    success,
    error: errorShortlist,
  } = useSelector((state) => state.courseShortlist);

  const handleSrchPagination = (page) => {
    setcourseSrchJson({
      ...courseSrchJson,
      PageNo: parseInt(page.selected) + 1,
    });
    getcourses();
  };

  const handleCompare = (id, logo, uname, cname) => {
    let rec = localStorage.getItem("cc");
    let recObj = rec ? JSON.parse(rec) : [];
    let recids = localStorage.getItem("ccids")
      ? localStorage.getItem("ccids")
      : "";

    let newItem = {
      Course_Id: id,
      Logo74x74: logo,
      University_Name: uname,
      Course_Name: cname,
    };

    if (recids.split(",").indexOf(id.toString()).toString() === "-1") {
      if (recids === "") {
        recObj.push(newItem);
        localStorage.setItem("cc", JSON.stringify(recObj));
        recids = id;
        localStorage.setItem("ccids", recids);
        dispatch({
          type: COURSE_COMPARE_LIST,
          payload: JSON.stringify(recObj),
        });
        dispatch(listCompareCourse(localStorage.getItem("ccids")));
      } else {
        if (recObj.length <= 3) {
          recObj.push(newItem);
          localStorage.setItem("cc", JSON.stringify(recObj));
          recids += "," + id;
          localStorage.setItem("ccids", recids);
          dispatch({
            type: COURSE_COMPARE_LIST,
            payload: JSON.stringify(recObj),
          });
          dispatch(listCompareCourse(localStorage.getItem("ccids")));
        } else {
          alert("You can compare upto 4 courses only!");
        }
      }
    } else {
      alert("Course already in Compare!");
    }
  };

  const getcourses = () => {
    dispatch(
      listCourse(keyword ? keyword : "", {
        ...courseSrchJson,
      })
    );
    dispatch(
      listTotalCourse(keyword ? keyword : "", {
        ...courseSrchJson,
      })
    );
  };

  const handleWishlist = (e, id) => {
    if (userInfo === undefined || userInfo === "" || userInfo === null) {
      // alert("Login to add into your wishlist.");
    } else {
      dispatch(shortlistCourse(id));
    }
  };

  useEffect(() => {
    getcourses();
    if (success) {
      alert("Successfully added to your wishlist!");
    }
  }, [dispatch, keyword, courseSrchJson, success]);

  return (
    <>
      <section
        className="innrbanner forex-banner"
        style={{ backgroundImage: `url('/img/listingbanner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Find Courses</h4>
              <h1>We'll help you find your dream program</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="courselistingpg compadding">
        <div className="container">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/courses">Find a course</a>
              </li>
              <li className="active">
                <a href="/">Search Results</a>
              </li>
            </ul>
          </div>

          <div className="courselistingpginnr">
            <div className="sec-row">
              <div className="sec-md-col-33 sticky">
                <div
                  className={
                    show ? "courselistingsidebar show" : "courselistingsidebar"
                  }
                >
                  <div className="courselistingsidefilter">
                    <div id="theFixed">
                      <div className="more-filter" id="side-filter">
                        <div className="applyFilters">
                          <div className="text-uppercase show-filter-btn">
                            {" "}
                            <img src="/img/filter.png" alt="" />{" "}
                          </div>
                          <button
                            className="btn clearFilters-btn"
                            onClick={(e) => {
                              window.location.reload(false);
                              setshow(false);
                            }}
                          >
                            Clear Filters
                          </button>
                          <button
                            className="btn applyFilters-btn"
                            onClick={(e) => setshow(false)}
                          >
                            Apply Filters
                          </button>
                        </div>
                        <div className="mob-filter">
                          {/* <form> */}
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Search Keyword
                              </label>
                              <input
                                className="form-control form-control-sm"
                                placeholder="Type here..."
                                name="searchtxt"
                                value={searchtxt}
                                onChange={(e) => setSearchtxt(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    navigate(`/courses/search/${searchtxt}`);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Choose Country
                              </label>
                              <select
                                className="form-control form-control-sm"
                                name="Country"
                                value={courseSrchJson.Country}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Choose...</option>
                                <CountrySelect />
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Choose University Name
                              </label>
                              <select
                                className="form-control form-control-sm"
                                name="University"
                                value={courseSrchJson.University}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Choose...</option>
                                <UniversitySelect id={courseSrchJson.Country} />
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Choose Study level
                              </label>
                              <select
                                className="form-control form-control-sm"
                                name="StudyLevel"
                                value={courseSrchJson.StudyLevel}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Choose...</option>
                                <StudyLevelSelect />
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12 mb-0">
                              <label className="lable-title">
                                Choose preferred intake
                              </label>
                            </div>
                            <div className="form-group col-md-12">
                              <select
                                className="form-control form-control-sm"
                                name="Months"
                                value={courseSrchJson.Months}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Choose...</option>
                                <Months />
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Choose Course Duration
                              </label>
                              <select
                                className="form-control form-control-sm"
                                name="Duration"
                                value={courseSrchJson.Duration}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">Choose...</option>
                                <option value="1"> 1 year</option>
                                <option value="2"> 2 year</option>
                                <option value="3"> 3 year</option>
                                <option value="4"> 4 year</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-row ">
                            <div className="form-group col-md-12 ">
                              {/* <div className="multirange-input"> */}
                              <label className="lable-title">Choose Fee</label>
                              {/* <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value="25"
                                    className="range-1"
                                    style={{
                                      background:
                                        "linear-gradient(to right, rgb(242, 101, 36) 0%, rgb(242, 101, 36) 62%, rgb(221, 221, 221) 62%, rgb(221, 221, 221) 100%)",
                                    }}
                                  /> */}

                              {/* <RangeSlider
                                    onInput={handleFChange}
                                    min={100}
                                    max={60000}
                                    value={SliderValue}
                                    // reverse={false}
                                    className="range-1"
                                  />*/}
                              <div className="d-flex col-md-12">
                                <select
                                  className="form-control "
                                  name="Duration"
                                  value={courseSrchJson.Duration}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Choose...</option>
                                  <option value="1"> 1 year</option>
                                  <option value="2"> 2 year</option>
                                  <option value="3"> 3 year</option>
                                  <option value="4"> 4 year</option>
                                </select>
                                <div className="p-lr-20">to</div>
                                <select
                                  className="form-control"
                                  name="Duration"
                                  value={courseSrchJson.Duration}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Choose...</option>
                                  <option value="1"> 1 year</option>
                                  <option value="2"> 2 year</option>
                                  <option value="3"> 3 year</option>
                                  <option value="4"> 4 year</option>
                                </select>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="lable-title">
                                Choose Ielts Score
                              </label>
                              <select
                                id="lang"
                                className="form-control form-control-sm"
                                name="IELTSScore"
                                value={courseSrchJson.IELTSScore}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>Choose...</option>
                                <option value="4"> 4.0</option>
                                <option value="4.5"> 4.5</option>
                                <option value="5"> 5.0</option>
                                <option value="5.5"> 5.5</option>
                                <option value="6"> 6.0</option>
                                <option value="6.5"> 6.5</option>
                                <option value="7"> 7.0</option>
                                <option value="7.5"> 7.5</option>
                                <option value="8"> 8.0</option>
                                <option value="8.5"> 8.5</option>
                              </select>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec-md-col-67">
                <div className="top-cntnr">
                  <div className="title-grp">
                    <h5>
                      Showing &nbsp;
                      <strong>
                        <span className="availblecourse">10</span> out of &nbsp;
                        <span className="totalcourse">
                          {courseTotal && courseTotal[0].total}
                        </span>
                      </strong>
                      &nbsp;courses of Page <b>{courseSrchJson.PageNo}</b>.
                    </h5>
                  </div>

                  <div className="filters-sortby-grp">
                    <div className="filters-itm show-900">
                      <div
                        className="filtersbtn"
                        data-modal="filter"
                        onClick={(e) => setshow(true)}
                      >
                        <LazyLoadingImages
                          src="/img/filters-icon-black.png"
                          alt="Filter Icon"
                        />
                      </div>
                    </div>

                    <div className="sortby-itm">
                      <span className="lbl">Sort By:</span>

                      <select className="sortbybtn-select">
                        <option value="Most Popular">Most Popular</option>
                        <option>Price - High to Low</option>
                        <option>Price - Low to High</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="courselistingBoxinnr">
                  <div className="courselistingBox">
                    {!error ? (
                      <>
                        {courses !== undefined ? (
                          <>
                            {courses.map((cours) => {
                              return (
                                <div
                                  className="courselistingcol"
                                  key={cours.Course_Id}
                                >
                                  <div className="courselistinginnr">
                                    <div className="courseboxlistingimg">
                                      <a href={`/course/${cours.Course_Id}`}>
                                        <LazyLoadingImages
                                          src={
                                            cours.Banner402x300
                                              ? `${process.env.REACT_APP_SED_DOC_URL}/university/banner402x300/${cours.Banner402x300}`
                                              : `/img/Banner402x300.jpg`
                                          }
                                          alt={cours.University_Name}
                                        />
                                      </a>
                                      <div className="wishlist">
                                        <Link
                                          onClick={(e) =>
                                            handleWishlist(e, cours.Course_Id)
                                          }
                                        >
                                          <LazyLoadingImages
                                            src="/img/hearticon.png"
                                            alt="Wishlist"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="courseboxlistingcntnt">
                                      <div class="mstpopularlogo">
                                        <LazyLoadingImages
                                          src={
                                            cours.Logo74x74
                                              ? `${process.env.REACT_APP_SED_DOC_URL}/university/Logo74x74/${cours.Logo74x74}`
                                              : `/img/Logo74x74.jpg`
                                          }
                                          alt={cours.University_Name}
                                        />
                                      </div>
                                      <p className="location">
                                        <LazyLoadingImages
                                          src="/img/locationicon.png"
                                          alt="Map"
                                        />
                                        {cours.University_Name}
                                      </p>
                                      <h5>{cours.Course_Name}</h5>

                                      <div className="durationfeesem">
                                        <div className="colhalf">
                                          <p>
                                            <span>Program type</span>
                                            {cours.Study_Level}
                                            {/* Bachelor's */}
                                          </p>
                                        </div>

                                        <div className="colhalf">
                                          <p>
                                            <span>Course fee</span>
                                            {cours.Yearly_Tuition_Fees ==
                                            "0" ? (
                                              "NA"
                                            ) : (
                                              <>
                                                {cours.Yearly_Tuition_Fees}{" "}
                                                &nbsp; {cours.Country_Symbol}
                                              </>
                                            )}
                                          </p>
                                        </div>

                                        <div className="colhalf">
                                          <p>
                                            <span>Enrollment Last date </span>
                                            NA
                                          </p>
                                        </div>

                                        <div className="colhalf">
                                          <p>
                                            <span>Duration</span>
                                            {cours.Duration}
                                          </p>
                                        </div>
                                      </div>

                                      <ul className="btngrp">
                                        <li>
                                          <a
                                            href={`/course/${cours.Course_Id}`}
                                            className="btn"
                                            target="_blank"
                                          >
                                            View Course
                                          </a>
                                        </li>

                                        <li>
                                          <button
                                            className="btn whitebtn"
                                            onClick={() =>
                                              handleCompare(
                                                cours.Course_Id,
                                                cours.Logo74x74,
                                                cours.University_Name,
                                                cours.Course_Name
                                              )
                                            }
                                          >
                                            ADD TO COMPARE
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <p style={{ padding: "10px", fontWeight: "bold" }}>
                            Course Not Found!
                          </p>
                        )}
                        <div
                          className="listingad-image flex-box align-center bg-style"
                          style={{
                            backgroundImage: `radialGradient('70.72% 4687.48% at -3.47% 69.7%, #25253F 0%, rgba(37, 37, 63, 0) 100%'), url('/img/listingad-bg.png')`,
                            backgroundColor: "#25253F",
                          }}
                        >
                          <div className="listingad-content">
                            <h6>Talk To Experts</h6>
                            <h3>Are You Looking For An Experts</h3>
                          </div>

                          <div className="listingad-cta">
                            <a
                              href="/consult_a_counselor"
                              className="btn"
                              target="_blank"
                            >
                              Consult Now
                            </a>
                          </div>
                        </div>
                        <div style={{ margin: "auto" }}>
                          <ReactPaginate
                            // breakLabel="..."
                            previousLabel="<"
                            nextLabel=">"
                            onPageChange={handleSrchPagination}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={
                              courseTotal &&
                              Math.ceil(
                                courseTotal[0].total / courseSrchJson.PageSize
                              )
                            }
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="previousClass"
                            nextClassName="nextClass"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="activeClass"
                          />
                        </div>
                      </>
                    ) : (
                      <p>{error}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Courselisting;
