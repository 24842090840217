import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../actions/userActions";
import moment from "moment";
import LeftPanel from "./leftPanel";
import SearchSection from "./SearchSection";

export default function LeftPanel1({ mid, right }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchtxt, setsearchtxt] = useState("");
  const activeText = "our-profile-list active";
  const inactiveText = "our-profile-list ";

  const [dashboard, setDashboard] = useState(activeText);
  const [myprofile, setMyprofile] = useState(inactiveText);
  const [application, setApplication] = useState(inactiveText);
  const [compare, setCompare] = useState(inactiveText);
  const [wishlist, setWishlist] = useState(inactiveText);
  const [courses, setCourses] = useState(inactiveText);
  const [message, setMessage] = useState(inactiveText);
  const [event, setEvent] = useState(inactiveText);

  const clearAll = () => {
    setDashboard(inactiveText);
    setMyprofile(inactiveText);
    setApplication(inactiveText);
    setCompare(inactiveText);
    setWishlist(inactiveText);
    setCourses(inactiveText);
    setMessage(inactiveText);
    setEvent(inactiveText);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    const setActiveTab = () => {
      const url = window.location.pathname;
      const suburl = url.split("/")[2];

      if (suburl === "dashboard") {
        clearAll();
        setDashboard(activeText);
      }
      if (suburl === "myprofile") {
        clearAll();
        setMyprofile(activeText);
      }
      if (suburl === "ongoing_application") {
        clearAll();
        setApplication(activeText);
      }
      if (suburl === "compare") {
        clearAll();
        setCompare(activeText);
      }
      if (suburl === "wishlist") {
        clearAll();
        setWishlist(activeText);
      }
      if (suburl === "all-courses") {
        clearAll();
        setCourses(activeText);
      }
      if (suburl === "messages") {
        clearAll();
        setMessage(activeText);
      }
      if (suburl === "event") {
        clearAll();
        setEvent(activeText);
      }
      if (suburl === "myprofile_edit") {
        clearAll();
        setMyprofile(activeText);
      }
      if (suburl === "ongoing_application_detail") {
        clearAll();
        setApplication(activeText);
      } else {
        clearAll();
      }
    };
    setActiveTab();
  }, [dispatch]);

  return (
    <LeftPanel>
      <div class="sec-lg-col-60 dashboard-rightbox common-dashboard-search-box">
        <SearchSection />
        {mid}
      </div>
      {right}
    </LeftPanel>
  );
}
