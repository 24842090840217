import React from "react";

const PteTrainingEducation = React.memo(function () {
  return (
    <section className="pte-training-education compadding lightgrey">
      <div className="container">
        <div className="comm-content-wrapper-width">
          <div className="headingbox letter-normal">
            <h2>
              <span className="themecolor">PTE Training at</span> Search
              Education
            </h2>
            <p>
              Our elite team members work round the globe from{" "}
              <strong>3 continents,</strong> assisting the students in every
              single step of their <strong>overseas education journey.</strong>
            </p>
          </div>

          <div className="flex-box pte-training-education-list">
            <div className="pte-training-edu-list-item">
              <h6>
                Get Trained By
                <br /> <strong>Language Experts</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                Flexible
                <br /> <strong>Class Structure & Timings</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                20+ Years Of
                <br /> <strong>Training Students</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                1 Lakh+ Happy
                <br /> <strong>Students & Parents</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                Customized
                <br /> <strong>Courses & Lessons</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                Best PTE
                <br /> <strong>Mock Tests & Papers</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                Most
                <br /> Affordable
                <br /> <strong>PTE Courses</strong>
              </h6>
            </div>
            <div className="pte-training-edu-list-item">
              <h6>
                Free PTE &<br /> <strong>Career Counselling</strong>
              </h6>
            </div>
          </div>
          <div className="button-box">
            <a href="/contactus" className="btn">
              Enquire
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default PteTrainingEducation;
