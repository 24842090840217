import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Applicationprocess = React.memo(function () {
  return (
    <div>
      <section className="application-process compadding lightgrey">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages
                  src="/img/career-counseling.png"
                  alt="student"
                />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/best-college.png" alt="student" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" alt="student" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/offer-letter.png" alt="student" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages
                  src="/img/approverd-visa.png"
                  alt="student"
                />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" alt="student" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Applicationprocess;
