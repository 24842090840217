import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Aboutvisasec from "./AboutVisaSec";
import Applyingstuvisasec from "./ApplyingStuVisaSec";
import Eligibilityvisa from "./EligibilityVisa";

export default function index() {
  return (
    <>
      <Header />
      <Aboutvisasec />
      <Eligibilityvisa />
      <Applyingstuvisasec />
      <Footer />
    </>
  );
}
