import React from "react";
import Header from "../Common/Header";
import { Helmet } from "react-helmet";
import Footer from "../Common/Footer";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
import { useState } from "react";

const Index = React.memo(function Index() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };

  return (
    <>
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/group-young-business-people-working.png')`,
          backgroundColor: "#0B0B28",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec skilled-independent compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper just-center">
            <div className="about-visa-image full-width-925 hide-mobile image-auto-height">
              <LazyLoadingImages
                src="/img/woman-with-luggage-medical-mask.jpg"
                alt="image"
              />
            </div>

            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  Skilled Employer Sponsored
                  <br /> (Regional) Visa
                </span>{" "}
                Subclass 494
              </h2>

              <p>
                This Employer Sponsored Visa is temporary in nature but may let
                you obtain your permanent residency in Australia after 3 years
                of employment. It is specifically for regional areas of the
                country and just like any other employer sponsored visa the
                applicant must be nominated by an authorized sponsor. A person,
                on this visa, is allowed to switch between his jobs (confined to
                a region) provided he gets a new nominator within a period of 90
                days. The visa holder is entitled to medical facilities but is
                forbidden to apply for a skilled PR/820 partner for the next 3
                years.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="eligibility-visa lightgrey compadding eligibility-criteria-visa">
        <div className="container">
          <div className="flex-box eligibility-type-wrapper just-center">
            <div className="eligibility-type-list-wrapper full-width-925">
              <div className="eligibility-type-heading headingbox">
                <h2>
                  <span className="themecolor">Eligibility for- 494</span> -
                  Skilled Employer Sponsored (Regional) Visa
                </h2>

                <p>
                  For employees who wish to work in a regional firm where the
                  employer is eligible to sponsor foreign staff, the person must
                </p>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" alt="image" />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have the prerequisite skills, qualifications, and knowledge
                    of the post that he/she is applying for.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" alt="image" />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have worked in the given fields for a period of 3 years.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" alt="image" />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have the required English language standards so as to work
                    in the Australian dialect.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" alt="image" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Not be above the age of 45.</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages src="/img/attach-docs.png" alt="image" />
                </div>

                <div className="eligibility-type-info">
                  <h5>Be nominated by a sponsor in a regional area.</h5>
                </div>
              </div>
            </div>

            <div className="eligibility-type-image full-width-925">
              <LazyLoadingImages
                src="/img/passport-with-yellow-bg.jpg"
                alt="image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="applying-stu-visa-sec compadding">
        <div className="container">
          <div className="headingbox applying-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Process of</span> Application
            </h2>

            <p>
              The application steps to avail a 494- Skilled Employer Sponsored
              (Regional) Visa are as follows
            </p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/choose-study-abroad.png"
                  alt="image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Employer has to apply to be a sponsor</h4>

                <p>
                  The employer would begin the process from his end by applying
                  for the labor sponsorship status.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/enroll-courses.png" alt="image" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Employer granted to be a sponsor</h4>

                <p>
                  The employer after following the due process, if found suited,
                  will be granted the right to be a sponsor.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/apply-student-visa.png"
                  alt="image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Employer nominates the applicant</h4>

                <p>
                  The employer then has to nominate the applicant to hire them
                  for a skilled occupation post mentioned under the approved
                  list of occupations.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/immigration-process-visa.png"
                  alt="image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>You, the applicant apply for Visa</h4>

                <p>
                  494- Skilled Employer Sponsored (Regional) Visa is then
                  granted to the applicant if they are found to fulfill all the
                  conditions required to avail the visa.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/ready-chekout.png" alt="image" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Get ready to fly to Australia</h4>

                <p>
                  TSS Visa is then granted to the applicant if they are found to
                  fulfill all the conditions required to avail the visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                In which parts of Australia can a person work using this visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  As long as the employer is located in a designated regional
                  area of the country a person can work anywhere across
                  Australia when holding this visa except in Sydney, Brisbane,
                  and Melbourne.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                Do I need to be nominated by an employer to avail this Visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Can I get Permanent Residency status while working on this Visa?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
