import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Aboutvisa = React.memo(function () {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/skilled-banner.png')`,
          backgroundColor: "#0B0B28",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec skilled-independent compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper just-center">
            <div className="about-visa-image full-width-925 hide-mobile">
              <LazyLoadingImages
                src="/img/beautiful-stylish-staff-sitting-office-desk.jpg"
                alt="student-image"
              />
            </div>

            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  Skilled Independent Visa
                  <br />
                </span>{" "}
                Subclass 189
              </h2>

              <p>
                The subclass 189 Visa of the General Skilled Migration program
                permits people of foreign nationals to move to Australia for
                occupational work and doesn’t necessitate them to have an
                employer to sponsor their visa. The applicant however must be
                skilled in a job that is in high demand in Australia to avail
                this visa. The visa is allotted based on a number of factors
                such as the applicant’s occupation, work experience, education,
                and English proficiency.
              </p>

              <p>
                The General Skilled Migration Visa type is by far the most
                common form of permanent settlement plan to Australia. It lets
                the visa holder work and stay in the country permanently and
                does not bind them to a single employer. Apart from the grant to
                live and work in Australia the visa holder enjoys several other
                benefits. The visa holder can study in Australia or they can
                have their children enrolled in any of the public schools of
                Australia. The visa holder also enjoys public healthcare
                facilities on this visa.
              </p>
            </div>
          </div>
          <div className="flex-box skilled-independent-visa-info align-center just-center">
            <div className="skilled-independent-heading headingbox letter-normal mb-0 full-width-925">
              <h2>
                The Skilled Independent Visa – Subclass 189 Is A Culmination of
                Two Visa Types, Namely
              </h2>
            </div>
            <ul className="skilled-independent-visa-info-list full-width-925">
              <li className="skilled-independent-visa-info-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>The Skilled (State Nominated) - Subclass 190 Visa</span>
              </li>
              <li className="skilled-independent-visa-info-list-item flex-box align-center">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>The Skilled Regional Nominated – Subclass 491 Visa</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutvisa;
