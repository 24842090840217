import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  COURSE_DETAIL_FAIL,
  COURSE_LIST_TOTAL_REQUEST,
  COURSE_LIST_TOTAL_SUCCESS,
  COURSE_LIST_TOTAL_FAIL,
  COURSE_APPLY_REQUEST,
  COURSE_APPLY_SUCCESS,
  COURSE_APPLY_FAIL,
  COURSE_SHORTLIST_REQUEST,
  COURSE_SHORTLIST_SUCCESS,
  COURSE_SHORTLIST_FAIL,
  COURSE_APPLY_LIST_REQUEST,
  COURSE_APPLY_LIST_SUCCESS,
  COURSE_APPLY_LIST_FAIL,
  COURSE_SHORTLIST_LIST_REQUEST,
  COURSE_SHORTLIST_LIST_SUCCESS,
  COURSE_SHORTLIST_LIST_FAIL,
  COURSE_REMOVE_SHORTLIST_REQUEST,
  COURSE_REMOVE_SHORTLIST_FAIL,
  COURSE_REMOVE_SHORTLIST_SUCCESS,
  COURSE_LIST_POPULAR_REQUEST,
  COURSE_LIST_POPULAR_FAIL,
  COURSE_LIST_POPULAR_SUCCESS,
  COURSE_LIST_TRENDING_REQUEST,
  COURSE_LIST_TRENDING_FAIL,
  COURSE_LIST_TRENDING_SUCCESS,
  COURSE_APPLY_STATUS_REQUEST,
  COURSE_APPLY_STATUS_SUCCESS,
  COURSE_APPLY_STATUS_FAIL,
  COURSE_COMPARE_DETAIL_REQUEST,
  COURSE_COMPARE_DETAIL_FAIL,
  COURSE_COMPARE_DETAIL_SUCCESS,
  COURSE_APPLY_LIST_TOTAL_REQUEST,
  COURSE_APPLY_LIST_TOTAL_FAIL,
  COURSE_APPLY_LIST_TOTAL_SUCCESS,
  COURSE_LIST_UNIVERSITY_REQUEST,
  COURSE_LIST_UNIVERSITY_FAIL,
  COURSE_LIST_UNIVERSITY_SUCCESS,
} from "../constants/courseConstants";
import api2 from "../core/api2";

export const listCourse = (keyword, courseJson) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    courseJson["searchtxt"] = keyword;

    const { data } = await api2.post(
      `/courses/getCourses2`,
      courseJson,
      config
    );

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: COURSE_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listTotalCourse = (keyword, courseJson) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_TOTAL_REQUEST,
    });
    courseJson["searchtxt"] = keyword;

    const { data } = await api2.post(`/courses/getCourses2Total`, courseJson);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_LIST_TOTAL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_LIST_TOTAL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_LIST_TOTAL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCompareCourse = (CourseIds) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_COMPARE_DETAIL_REQUEST,
    });

    const { data } = await api2.post(`/courses/compare/getCourses`, {
      CourseIds: CourseIds,
    });

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_COMPARE_DETAIL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_COMPARE_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_COMPARE_DETAIL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getCourse = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DETAIL_REQUEST,
    });

    const { data } = await api2.get(`/courses/getCourse/${id}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_DETAIL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_DETAIL_SUCCESS,
        payload: data.Result[0],
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_DETAIL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getShortlistedCourse = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COURSE_SHORTLIST_LIST_REQUEST,
    });

    const { data } = await api2.get(`/student/courses/shortlistedcoursesList`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_SHORTLIST_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_SHORTLIST_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_SHORTLIST_LIST_FAIL,
      payload:
        error.response.data && error.response.data
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getAppliedCourse = () => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_APPLY_LIST_REQUEST,
    });
    const { data } = await api2.get(`/student/courses/getApplications`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_APPLY_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_APPLY_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_APPLY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getAppliedCourseTotal = () => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_APPLY_LIST_TOTAL_REQUEST,
    });
    const { data } = await api2.get(`/student/courses/getApplicationsTotal`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_APPLY_LIST_TOTAL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_APPLY_LIST_TOTAL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_APPLY_LIST_TOTAL_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getAppliedCourseStatus = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_APPLY_STATUS_REQUEST,
    });
    const { data } = await api2.get(`/web/applications/${courseId}`);

    const response = data.Result;

    dispatch({
      type: COURSE_APPLY_STATUS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: COURSE_APPLY_STATUS_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const applyCourse = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_APPLY_REQUEST,
    });
    const { data } = await api2.post(
      `/student/courses/ApplyCourse/${courseId}`
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: COURSE_APPLY_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_APPLY_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_APPLY_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const shortlistCourse = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_SHORTLIST_REQUEST,
    });
    const { data } = await api2.post(
      `/student/courses/ShortlistCourse/${courseId}`
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: COURSE_SHORTLIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_SHORTLIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_SHORTLIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const removeShortlistedCourse = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_REMOVE_SHORTLIST_REQUEST,
    });
    const { data } = await api2.delete(
      `/student/courses/RemoveShortlistCourse/${courseId}`
    );

    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: COURSE_REMOVE_SHORTLIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_REMOVE_SHORTLIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_REMOVE_SHORTLIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listPopularCourse = (country) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_POPULAR_REQUEST,
    });
    const { data } = await api2.get(`/courses2/popular/${country}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_LIST_POPULAR_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_LIST_POPULAR_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_LIST_POPULAR_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listTrendingCourse = (country) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_TRENDING_REQUEST,
    });
    const { data } = await api2.get(`/courses2/trending/${country}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_LIST_TRENDING_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_LIST_TRENDING_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_LIST_TRENDING_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCourseUniversity = (Id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_UNIVERSITY_REQUEST,
    });

    const { data } = await api2.get(`/courses/UniversityId/${Id}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: COURSE_LIST_UNIVERSITY_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: COURSE_LIST_UNIVERSITY_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: COURSE_LIST_UNIVERSITY_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
