import {
  UNIVERSITY_LIST_REQUEST,
  UNIVERSITY_LIST_SUCCESS,
  UNIVERSITY_LIST_FAIL,
  UNIVERSITY_POPULAR_LIST_REQUEST,
  UNIVERSITY_POPULAR_LIST_FAIL,
  UNIVERSITY_POPULAR_LIST_SUCCESS,
  UNIVERSITY_WEB_DETAILS_REQUEST,
  UNIVERSITY_WEB_DETAILS_SUCCESS,
  UNIVERSITY_WEB_DETAILS_FAIL,
} from "../constants/universityConstants";
import api2 from "../core/api2";

export const listUniversity = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UNIVERSITY_LIST_REQUEST,
    });
    let urls = "";
    if (id) urls = `/university/country/${id}`;
    else urls = `/university`;

    const { data } = await api2.get(urls);

    const response = data.Result;

    if (typeof response == "string") {
      dispatch({
        type: UNIVERSITY_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: UNIVERSITY_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: UNIVERSITY_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listPopularUniversity = (country) => async (dispatch) => {
  try {
    dispatch({
      type: UNIVERSITY_POPULAR_LIST_REQUEST,
    });
    const { data } = await api2.get(`/university/popular/${country}`);
    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: UNIVERSITY_POPULAR_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: UNIVERSITY_POPULAR_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: UNIVERSITY_POPULAR_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const detailUniversity = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UNIVERSITY_WEB_DETAILS_REQUEST,
    });
    const { data } = await api2.get(`/universityDetails/${id}`);
    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: UNIVERSITY_WEB_DETAILS_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: UNIVERSITY_WEB_DETAILS_SUCCESS,
        payload: response[0],
      });
    }
  } catch (error) {
    dispatch({
      type: UNIVERSITY_WEB_DETAILS_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
