import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listallCountry } from "../actions/countryActions";
import { useEffect } from "react";

export default function CountrySelect() {
  const dispatch = useDispatch();
  const { loading, error, allCountryData } = useSelector(
    (s) => s.allCountryList
  );
  const getCountryDatas = () => {
    dispatch(listallCountry());
  };
  useEffect(()=>{
    if(!allCountryData){
        getCountryDatas();
    }
  },[dispatch,allCountryData])
  return allCountryData
    ? allCountryData.length > 0
      ? allCountryData.map((alcd, index) => (
          <option value={alcd.Id} key={alcd.Id}>
            {alcd.Country}
          </option>
        ))
      : ""
    : "";
}
