import React, { useState } from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Educationhigherstudies = React.memo(function () {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  return (
    <>
      {/* <section className="education-higher-studies compadding">
        <div className="container">
          <div className="headingbox text-center">
            <h2>
              <span className="themecolor">Education Loan </span>For Higher
              Studies In
              <br /> India And Abroad
            </h2>
          </div>
          <div className="flex-box education-higher-studies-wrapper align-center just-center">
            <div className="education-higher-studies-image full-width-925">
              <LazyLoadingImages
                src="/img/girls-with-leptop.jpg"
                alt="student-image"
              />
            </div>
            <div className="education-higher-studies-content headingbox letter-normal mb-0 full-width-925">
              <h2>Get A Quality Education</h2>
              <p>
                Education is the most essential requirement for a successful.
                One certainly needs a good education to have a rewarding future.
                In order to get a quality education, people prefer the best
                institution or university in their country or abroad. Due to the
                rising need of at par education, there are various universities
                who provide the world-class education platform for the
                aspirants. However, their fees are skyrocketing day by day. The
                only solution to have a proper and a good education through
                these universities is by taking financial assistance through
                Education Loans.
              </p>
            </div>
          </div>
          <div className="flex-box education-higher-studies-wrapper align-center just-center">
            <div className="education-higher-studies-image full-width-925">
              <LazyLoadingImages
                src="/img/rupes-in-box.jpg"
                alt="student-image"
              />
            </div>
            <div className="education-higher-studies-content headingbox letter-normal mb-0 full-width-925">
              <h2>Advantages Of Education Loan</h2>
              <p>
                There are many reasons why one should opt for Education Loan
                instead of self-financing. Many Aspirants look for Education
                loan only for the reason as higher education they want to pursue
                is expensive. In the situations where they cannot pay the
                relevant fees they are bound to apply for loan. However, there
                are numerous other advantages of Education Loan such as the tax
                benefits under the section 80E of Income Tax Act, loan covers
                100% educational expenses, family ../assets remain intact,
                financial independence and opportunity to build good credit
                history for students and many more.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                What is an education loan?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  A. Financial institutions offer education loans to students
                  aspiring for education from India and overseas. Different
                  financial institutions offer different services and features
                  to their education loan applicants.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                {" "}
                Why should one prefer education loan over self-finance?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  A. Financial institutions offer education loans to students
                  aspiring for education from India and overseas. Different
                  financial institutions offer different services and features
                  to their education loan applicants.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Which documents are required for an education loan?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                What expenses are covered by an education loan?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("05")}>
                Can education loan applicants avail income tax benefits?
                <span
                  className={active === "05" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "05" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("06")}>
                For how long can an applicant claim tax benefits under Section
                80E?
                <span
                  className={active === "06" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "06" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("07")}>
                What is collateral?
                <span
                  className={active === "07" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "07" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("08")}>
                What is margin money?
                <span
                  className={active === "08" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "08" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("09")}>
                What is the process of applying for education loan?
                <span
                  className={active === "09" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "09" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("10")}>
                When is the right time to apply for an education loan?
                <span
                  className={active === "10" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "10" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("11")}>
                What is the advantage of applying for education loan even before
                admission is confirmed?
                <span
                  className={active === "11" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "11" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("12")}>
                What is moratorium?
                <span
                  className={active === "12" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "12" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("13")}>
                What are the education loan repayment terms?
                <span
                  className={active === "13" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "13" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("14")}>
                How much Moratorium Period is available?
                <span
                  className={active === "14" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "14" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("15")}>
                Is there any Tax benefits on HDFC Credila Education Loan?
                <span
                  className={active === "15" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "15" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("16")}>
                What is the loan disbursement process of HDFC Credila Education
                Loan?
                <span
                  className={active === "16" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "16" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Educationhigherstudies;
