import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Index = React.memo(function Index() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  return (
    <>
      <Header />
      {/* <!-- Start Inner Page banner --> */}
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/ens-employer.png')`,
          backgroundColor: "#0B0B28",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>
              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-visa-sec skilled-independent compadding">
        <div className="container">
          <div className="flex-box about-visa-wrapper just-center">
            <div className="about-visa-image full-width-925 hide-mobile image-auto-height">
              <LazyLoadingImages
                src="/img/girl-with-file.jpg"
                alt="Girl with File"
              />
            </div>

            <div className="about-visa-content headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">
                  ENS Employer Sponsored Visa
                  <br /> (Full Time) Visa
                </span>
                &nbsp;SUBCLASS- 186
              </h2>

              <p>
                The Employer Nominated Scheme (ENS) offers the subclass 186 visa
                type for people to work in Australia under a nominated employer
                for a full-time period. It is one of the few permanent residence
                visas available for skilled people looking to work in Australia.
                The visa provides the person with easy access to medical
                facilities and also lets them include their family members in
                their visa. The three subcategories of this visa are as follows:
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="eligibility-visa lightgrey compadding eligibility-criteria-visa">
        <div className="container">
          <div className="flex-box eligibility-type-wrapper just-center">
            <div className="eligibility-type-list-wrapper full-width-925">
              <div className="eligibility-type-heading headingbox">
                <h2>
                  Eligibility for - 186
                  <span className="themecolor">
                    &nbsp;ENS Employer Sponsored Visa
                  </span>
                </h2>

                <p>
                  Only those employees are eligible for the 186- ENS Employer
                  Sponsored Visa who:
                </p>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have been nominated by an employer. Also, it must be noted
                    that the nomination must have been approved within a maximum
                    time limit of 6 months before applying.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>Are below the age of 45.</h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have the required English language standard so as to work in
                    the Australian dialect.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Be of sound health and possess a good character certificate.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have their health insurance that is required for the time
                    they are in Australia.
                  </h5>
                </div>
              </div>

              <div className="eligibility-type-list flex-box">
                <div className="eligibility-type-infoicon">
                  <LazyLoadingImages
                    src="/img/attach-docs.png"
                    alt="Attach Document"
                  />
                </div>

                <div className="eligibility-type-info">
                  <h5>
                    Have all of the licenses that may be required for their work
                    in Australia.
                  </h5>
                </div>
              </div>
            </div>

            <div className="eligibility-type-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/sponsered-visa.jpg"
                alt="Sponsored Visa"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                Can a person who is outside Australia apply for the ENS 186
                Visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  Yes, a person can apply for the ENS 186 visa from inside and
                  outside of Australia both.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                Is the ENS 186 Visa a Permanent Visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, once a person gets their ENS 186 Visa they become a
                  permanent resident of Australia.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                Does a person need to undergo a skill assessment test to apply
                for the 186 visa?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, to get a visa via the direct entry scheme the applicant
                  must take the skill assessment test.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("04")}>
                Can a person apply for the 186 visa without any employer
                sponsoring them?
                <span
                  className={active === "04" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "04" ? "panel blk" : "panel"}>
                <p>
                  No, in order to avail the 186 visa the applicant needs to have
                  an employer who is ready to sponsor him/her.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Listing SEction --> */}
      <Footer />
    </>
  );
});

export default Index;
