import React from "react";
import Slider from "react-slick";
import HeaderAustralia from "./HeaderAustralia";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import BookFree from "./BookFreeCounsultaion";
import FooterAustralia from "./FooterAustralia";
import EducationPartnerAustralia from "./EducationPartner";

const AustraliaEducationStudet = React.memo(
  function AustraliaEducationStudet() {
    // const metaData = {
    //   title: "Search Education – Study Abroad Consultant | Free Counselling",
    //   mtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    //   mdescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   ogtype: "website",
    //   ogurl: "",
    //   ogtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    //   ogdescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   ogimage: "https://www.searcheducation.com/img/img_3.jpg",
    //   twitterCard: "summary_large_image",
    //   twitterUrl: "",
    //   twitterTitle:
    //     "Search Education – Study Abroad Consultant | Free Counselling",
    //   twitterDescription:
    //     "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    //   twitterImage: "https://www.searcheducation.com/img/img_3.jpg",
    // };
    const [createSec, setcreateSec] = useState(false);

    const CounsellorJson = [
      {
        Name: "Paras Kunwar",
        Desc: "",
        image: "/img/AustraliaPage/Counsellor/Paras-Kunwar.png",
        Email: "pkunwar@searchedu.com.au",
        Contact: "0404392002",
      },
      {
        Name: "Raj Bista",
        Desc: "",
        image: "/img/AustraliaPage/Counsellor/Raj-Bista.png",
        Email: "rbista@searchedu.com.au",
        Contact: "0426809 802",
      },
      {
        Name: "Ramila",
        Desc: "",
        image: "/img/AustraliaPage/Counsellor/Ramila-Bista.png",
        Email: "rabista@searchedu.com.au",
        Contact: "0452099 840",
      },
      {
        Name: "Claudia Cortes Brown",
        Desc: "",
        image: "/img/AustraliaPage/Counsellor/Claudia.png",
        Email: "hola@enelmundolatinos.com",
        Contact: "0420574310",
      },
      {
        Name: "Camille Sison",
        Desc: "",
        image: "/img/AustraliaPage/Counsellor/Camille.png",
        Email: "csison@searchedu.com.au",
        Contact: "0420415767",
      },
    ];

    const EducationJson = [
      {
        University: "Western Sydney University",
        Course: "Master of Nursing Practice (Preregistration)",
        image:
          "/img/AustraliaPage/University_logo_Australia/western_sydney_univeristy.png",
        Duration: "Duration: 24 Months",
        Location: "Location: Parramatta, Hawkesbury",
        StartDate: "Start Date: January 2025",
        Annual_Tution_Fee: "Annual Tuition Fee: $37,782* AUD",
      },
      {
        University: "Charles Darwin University",
        Course: "Master of Nursing Practise (Pre-registration)",
        image:
          "/img/AustraliaPage/University_logo_Australia/Charles_University.png",
        Duration: "Duration: 2 Years",
        Location: "Location:  Sydney, Darwin",
        StartDate: "Next Available intake: Mar 2025",
        Annual_Tution_Fee: "Course Fee: $35,600*",
        extra_det: "Applications open after August",
      },
      {
        University: "Excelsia College",
        Course: "Masters of Social Work (Qualifying)",
        image:
          "/img/AustraliaPage/University_logo_Australia/Excelsia_College.png",
        Duration: "Duration: 2 Years",
        Location: "Location: Macquarie Park",
        StartDate: "Next Intake: Feb 2025, Jul 2025",
        Annual_Tution_Fee: "Course Fee: AUD 20,520 per year",
        extra_det: "",
      },
      {
        University: "Western Sydney University",
        Course: "Master of Social Work (Qualifying)",
        image:
          "/img/AustraliaPage/University_logo_Australia/western_sydney_univeristy.png",
        Duration: "Duration: 2 Years",
        Location: "Location: Parramatta, Liverpool",
        StartDate: "Intake: July 2024, Jan 2025",
        Annual_Tution_Fee:
          "Tuition Fee: AUD $23,488 per year (after scholarship)",
        extra_det: "",
      },
      {
        University: "ACAP",
        Course: "Master of Social Work (Qualifying)",
        image:
          "/img/AustraliaPage/University_logo_Australia/ACAP-Australian-college.svg",
        Duration: "Duration: 2 Years",
        Location: "Location: Melbourne, Sydney",
        StartDate: "Intake: Sep 2024, Feb 2025",
        Annual_Tution_Fee: "Annual Tuition Fee: $29,360",
        extra_det: "",
      },
      {
        University: "Holmes Institute",
        Course: "Graduate Diploma of Early Childhood Teaching",
        image:
          "/img/AustraliaPage/University_logo_Australia/Holmes_Institute.png",
        Duration: "Duration: 2 trimesters",
        Location: "Location: Sydney CBD",
        StartDate: "Intake: March, July, November",
        Annual_Tution_Fee:
          "Tuition Fee: $13,500 per trimester ($4,000 scholarship available)",
        extra_det: "",
      },
      {
        University: "Excelsia College",
        Course: "Graduate Diploma of Early Childhood Teaching",
        image:
          "/img/AustraliaPage/University_logo_Australia/Excelsia_College.png",
        Duration: "Duration: 2 semesters",
        Location: "Location: Macquarie Park",
        StartDate: "Intake: Sep 2024, Feb 2025",
        Annual_Tution_Fee: "Tuition Fee: $9,750 per semester",
        extra_det: "",
      },
    ];

    const navigate = useNavigate();

    const OpenStudentVisitor = () => {
      setcreateSec(true);
    };

    const Counselloroptions = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow:
        window.innerWidth <= 480 ? 1 : window.innerWidth <= 830 ? 2 : 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    return (
      <>
        <HeaderAustralia Height={"100"} />
        {/* <Meta metaData={metaData} /> */}
        {/* <MainBanner /> */}
        {/* <section
        className="efficient-study bg-style"
        style={{
          // backgroundImage: `url('/img/group-cheerful-happy-students.png')`,
          backgroundColor: "#25253F",
        }}
      >
        <div className="container">
          <div className="efficient-study-wrapper">
            <div
              className=" text-white"
              style={{
                display: "flex",
                width: "100%",
                gap: "5%",
              }}
            >
              <div>
                <div
                  style={{ width: "100%" }}
                  className="eligibility-stu-image"
                >
                  <img
                    style={{ borderRadius: "10px" }}
                    src="/img/eligibility-health.jpg"
                    alt="student"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {" "}
                  <h2>Message from Julia</h2>{" "}
                </div>

                <div>
                  {" "}
                  <p>Message Text Here.</p>
                </div>

                <div style={{ textAlign: "center", marginLeft: "40px" }}>
                  {" "}
                  <h4>Julia Yuan, Registered Migration Agent.</h4>
                  <h4>MARA 0960025</h4>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <div className="Book_Free">
          <BookFree />
        </div>
        {/* What we can do start */}
        <section
          style={{ marginTop: "40px" }}
          className="eligibility-stu-visa-sec compadding"
        >
          <div className="container">
            <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
              <h2>
                <span className="themecolor">Speak to our Education </span>
                Counselors Today!
              </h2>
            </div>

            <div className="popularuniversitysec ">
              <Slider
                className="popularuniversityslider"
                {...Counselloroptions}
              >
                {CounsellorJson?.map((ev, i) => (
                  // <div index={i + 9180}   className="popularuniversityslide">
                  //   <div className="popularuniversityinnr equal-height">
                  //     <LazyLoadingImages src={ev?.image} alt="Counsellor" />
                  //   </div>

                  //   <div
                  //     style={{ textAlign: "center" }}
                  //     className="eligibility-stu-content"
                  //   >
                  //     <h4>{ev?.Name}</h4>

                  //     <p>QEAC Number</p>
                  //     <p>Contact Details</p>
                  //   </div>
                  // </div>
                  <div className="popularuniversityslide" key={i + 456}>
                    <div className="popularuniversityinnr equal-height">
                      <div className="popularuniversityimg">
                        <LazyLoadingImages src={ev?.image} alt="University" />
                      </div>
                      <div
                        style={{ padding: "10px", height: "100px" }}
                        className=""
                      >
                        {/* <div className="popularuniversitylogo">
                        <LazyLoadingImages
                          src={`${process.env.REACT_APP_SED_DOC_URL}/university/Logo74x74/${rec.Logo74x74}`}
                          alt="University Logo"
                        />
                      </div> */}
                        <h5 style={{ textAlign: "center" }}>
                          <a
                            // href={`/college_detail/${rec?.University_Id}`}
                            target="_blank"
                          >
                            <h5 style={{ fontWeight: "700" }}>{ev?.Name} </h5>
                          </a>
                        </h5>
                        <p style={{ textAlign: "center", color: "#f36525" }}>
                          {/* <LazyLoadingImages
                          src="/img/contact.png"
                          alt="Map"
                        /> */}
                          {ev?.Email}
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                          {ev?.Contact}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>

        {/* Featured Courses of this Month */}

        <section className="eligibility-stu-visa-sec ">
          <div className="container">
            <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
              <h2>
                <span className="themecolor">Featured Courses </span>
                of this Month
              </h2>
            </div>

            <div className={"Featured_Courses_Container custom-scroll-right"}>
              {EducationJson?.map((el, i) => (
                <div key={i + 450} className="Featured_Courses">
                  <div className="College_logo">
                    <img src={el?.image} alt="university logo" />
                  </div>
                  <div>
                    <div>
                      <a>
                        <h4
                          style={{
                            marginBottom: "6px",
                            fontWeight: "700",
                            letterSpacing: "2px",
                          }}
                        >
                          {el?.University}
                        </h4>
                      </a>

                      <a>
                        <h6
                          style={{
                            marginBottom: "6px",
                            color: "#f36525",
                            letterSpacing: "1.5px",
                          }}
                        >
                          {el?.Course}
                        </h6>
                      </a>
                    </div>
                    <div style={{ color: "black" }}>
                      <p style={{ letterSpacing: "1.5px" }}>{el?.Duration}</p>
                      <p style={{ letterSpacing: "1.5px" }}>{el?.Location}</p>
                      <p style={{ letterSpacing: "1.5px" }}>{el?.StartDate}</p>
                      <p style={{ letterSpacing: "1.5px" }}>
                        {el?.Annual_Tution_Fee}
                      </p>
                      <p style={{ letterSpacing: "1.5px", fontWeight: "700" }}>
                        {el?.extra_det}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <EducationPartnerAustralia />

        <section
          className="eligibility-stu-visa-sec "
          style={{ backgroundColor: "#f26524", padding: "0 20px" }}
        >
          <div className="container">
            <div
              style={{ paddingTop: "20px" }}
              className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center"
            >
              <h2 style={{ color: "white" }}>
                {/* <span className="themecolor">Our </span> */}
                Our Memberships
              </h2>
            </div>

            <div className="Our_Membership">
              <div className="Our_Member">
                <img
                  src={"/img/AustraliaPage/Australia_Membership/QUEAC.png"}
                  alt="membership"
                />
              </div>
              <div className="Our_Member">
                <img
                  src={
                    "/img/AustraliaPage/Australia_Membership/Migration_Agents.png"
                  }
                  alt="membership"
                />
              </div>
            </div>
          </div>
        </section>

        <FooterAustralia />
      </>
    );
  }
);

export default AustraliaEducationStudet;
