import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('s/img/building-bg.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Adelaide</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">Adelaide </span>offers to students
            </h2>
            <p>
              Adelaide is an excellent city to live in and it has the lowest
              cost of living among the major Australian cities. This factor
              combined with the excellent opportunities for higher education
              offered by the universities in and around the city make it a
              favoured destination for international students. With a population
              of only 1.5 million, Adelaide is a very clean city and a safe
              place to stay.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/hall.jpg" alt="course" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Education</h2>
              <p>
                Studying in Adelaide is cheap in comparison to the other major
                centres of education in Australia. The city has a fairly big
                student population. About 20,000 international students study at
                Adelaide. With a variety of courses, the options for students
                are many. The University of Adelaide is reputed to be a premier
                institution for research.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/shot-graduate-student.jpg"
                alt="course"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Financial help and scholarships</h2>
              <p>
                Scholarships and financial assistance are available. The terms
                vary and depend on the university and the bank, if one is
                considering taking a loan{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding lightgrey">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/happy-businesswoman-reading.jpg"
                alt="course"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Work opportunities</h2>
              <p>
                Work and internship opportunities for international students are
                many, in Adelaide. The students graduating from reputed
                universities in South Australia are in high demand.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/ladder.jpg" alt="course" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Weather conditions and attractions</h2>
              <p>
                With mild winters and warm summers, Adelaide is an ideal place
                for outdoor activities and sports.
              </p>

              <p>
                Adelaide is known as the food and wine capital of the country,
                and produces 50% of the country’s wine. It has a number of
                places for sightseeing. One will enjoy dining in Adelaide which
                caters to international tastes. The tag of the Festival City is
                apt since Adelaide hosts a number of events all year round.
              </p>

              <p>One will not regret studying in Adelaide</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
