import React from "react";

const Ourculture = React.memo(function Ourculture() {
  return (
    <section className="our-culture compadding">
      <div className="container">
        <div className="headingbox">
          <h2>
            <span className="themecolor">Our </span>Culture
          </h2>
          <p>
            Our elite team members work round the globe from{" "}
            <strong>3 continents,</strong> assisting the students in every
            single step of their <strong>overseas education journey.</strong>
          </p>
        </div>
        <div className="our-cultures-box-wrapper flex-box">
          <div className="our-cultures full-width-925">
            <div
              className="our-cultures-image bg-style"
              style={{
                backgroundImage: `url('/img/people-office-work.jpg')`,
              }}
            ></div>
            <h3>Strong leadership and effective communication</h3>
            <p>
              {" "}
              An important factor in ensuring a guiding force at work is strong
              leadership. Effective leaders not only inspire their team members
              but also guide and motivate them towards achieving the company's
              goals. Open and honest communication is also crucial for guiding
              employees towards success.
            </p>
          </div>
          <div className="our-cultures full-width-925">
            <div
              className="our-cultures-image bg-style"
              style={{ backgroundImage: `url('/img/study.jpg')` }}
            ></div>
            <h3>Innovative mindset</h3>
            <p>
              An innovative approach that embraces change and is willing to take
              risks to develop new and creative strategies. This approach
              encourages employees to think outside the box and come up with new
              ideas that can propel the organization forward.
            </p>
          </div>
          <div className="our-cultures full-width-925">
            <div
              className="our-cultures-image bg-style"
              style={{ backgroundImage: `url('/img/girls-group.jpg')` }}
            ></div>
            <h3>Learning and Development</h3>
            <p>
              We believe that investing in our employees' learning and
              development not only benefits them but also strengthens our
              company. We are committed to fostering a culture of continuous
              improvement and supporting our team members in achieving their
              professional goals.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Ourculture;
