import axios from "axios";

// const instance = axios.create({
//   baseURL: 'https://api23.searcheducation.com:4123'
// });

const instance = axios.create({
  baseURL: process.env.REACT_APP_MAINURL,
});

instance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("accinf");
  const acdt = localStorage.getItem("acdt");
  const usinf = localStorage.getItem("usinf");
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  else
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_ACESS_TOKEN}`;
  return config;
});

export default instance;
