import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/brisbane-local-marketing.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Brisbane</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding study-in-newzeland">
        <div className="container">
          <div className="headingbox letter-normal text-center">
            <h2>
              <span className="themecolor">Brisbane </span>“Education City”
            </h2>
            <p>
              Brisbane is recognised as the "Education City" because of its many
              outstanding universities and educational institutes. These
              universities provide a wide range of undergraduate and
              postgraduate programs in subjects such as business, engineering,
              science, the arts, and medicine. Brisbane is a popular location
              for overseas students seeking a high-quality education due to its
              reputation for academic brilliance and dynamic student life.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/cheerful-attractive-young-woman.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Brisbane</h2>
              <p>
                People who love sunshine will love Brisbane city due to 280 days
                of sunshine in a year. This capital of Queensland is the fastest
                growing and placed third behind Sydney and Melbourne. With
                rolling meadows, mountains with botanical gardens due to
                sunshine and the largest art center in Australia, Brisbane city
                is the sought after city. It’s so beautiful that people living
                there call themselves Brisbanites and identify themselves with
                it. It has youthful zeal as a flavor to its identity powered by
                the sunshine. River Brown is fed by the silt from nearby
                mountains and hills and therefore the name, however unsafe to
                swim in due to the bull sharks venturing upstream from the sea.
                Other water sports are performed where the river enters into the
                plains before merging into the Australian oceans
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/road-night.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Technological Capital</h2>
              <p>
                Brisbane is also known as Australia’s technological capital with
                strengths in biotech, mining research, medicine and green
                energy. This city with a sizable population is known for gentle
                architecture. Also this gets reflected in the way people behave
                socially, making it one of the safest cities with a zero to
                negligible crime rate. Brisbane, on the east coast, has its
                advantages of longer sunlight working hours making the port the
                third largest along with its rail and road network. Brisbane
                draws its architectural theme also from Russian immigration
                around 1914 when refugees sailed in from the communist regime. A
                soviet - Anglo mix is very evident/ As expected there was social
                unrest to a large influx which led to social disharmony and
                clashes which got quelled. Brisbane in addition has many
                festivals in Art, literary, sporting and culture and is a great
                place to visit, study and work in.
              </p>

              <p>
                The coasts named gold and sunshine, Mount Coot-Tha, Riverway
                walks, the vast open stretches of sun and coast are memorable
                trips to enjoy a vacation anytime of the year. For an
                international student this city offers all the variety through
                diversity.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
