import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { createBooking } from "../../actions/bookingActions";
import { bookingJson } from "../../models/userModel";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Alert from "../../components/Alert";

const Index = React.memo(function Index() {
  const dispatch = useDispatch();
  const [ierror, setIerror] = useState("");
  const [bookingRec, setbookingRec] = useState(bookingJson);

  const bookingCreate = useSelector((state) => state.bookingCreate);
  const { loading, success, error } = bookingCreate;

  const InterestedCountry = [
    { name: "Australia", checked: false },
    { name: "Canada", checked: false },
    { name: "United Kingdom", checked: false },
    { name: "USA", checked: false },
    { name: "Ireland", checked: false },
    { name: "New Zealand", checked: false },
    { name: "Dubai", checked: false },
    { name: "Germany", checked: false },
    { name: "Malta", checked: false },
    { name: "Sweden", checked: false },
    { name: "Poland", checked: false },
    { name: "France", checked: false },
    { name: "Finland", checked: false },
    { name: "Spain", checked: false },
    { name: "Others", checked: false },
  ];

  const [interested_Country1, setInterested_Country1] =
    useState(InterestedCountry);

  const handleBranch = (e) => {
    setbookingRec({ ...bookingRec, OfficeLocation: e.target.value });
  };

  const handleModeOfContact = (e) => {
    setbookingRec({ ...bookingRec, Mode_Of_Contact: e.target.value });
  };

  function getStudyDestination(val, chk) {
    var rec = [];
    if (bookingRec.StudyDestination !== "") {
      rec = bookingRec.StudyDestination.split(",");
    }
    var recc = [];
    if (chk === true) {
      recc = rec.push(val);
      return rec.toString();
    } else {
      const index = rec.indexOf(val);
      if (index > -1) {
        rec.splice(index, 1);
        return rec.toString();
      }
    }
  }

  const handleCheckboxes = (e) => {
    setbookingRec({
      ...bookingRec,
      StudyDestination: getStudyDestination(e.target.value, e.target.checked),
    });
  };

  const handleRec = (e) => {
    setbookingRec({
      ...bookingRec,
      Link: window.location.href,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // console.log(bookingRec);
    dispatch(createBooking(bookingRec));
  };

  useEffect(() => {
    if (success) {
      setbookingRec(bookingJson);
      setInterested_Country1(InterestedCountry);
      setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
  }, [dispatch, success]);

  return (
    <>
      <Helmet>
        <title>Book your appointment Now!</title>
      </Helmet>
      <Header />

      {/* <!-- Start Inner Page banner --> */}
      <section
        className="innrbanner"
        style={{
          backgroundImage: `url('/img/business-leader-interviewing-job.png')`,
          backgroundColor: "#0B0B28",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              {/* <h4 className="themecolor">Visa</h4> */}

              <h1> Book a FREE Counselling Session</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="booking-appointment compadding">
        <div className="container">
          <div className="booking-appointment-form-wrapper">
            <form
              className="booking-appointment-form  new_Booking_Form "
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <h5>
                  Preferred Study Destination<span>*</span>
                </h5>
                <div className="booking-appointment-form-field flex-box">
                  {interested_Country1.map((names) => (
                    <p className="booking-appoint-form">
                      <input
                        type="checkbox"
                        className="booking-radio-input"
                        id={`ic-${names.name}`}
                        value={names.name}
                        name={names.name}
                        onChange={(e) => handleCheckboxes(e)}
                      />
                      <label for={`ic-${names.name}`}>{names.name}</label>
                    </p>
                  ))}
                  {/* <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-australia"
                    value="Australia"
                  />
                  <label for="appointment-australia">Australia</label>
                </p>

                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-new-zealand"
                    value="New Zealand"
                  />
                  <label for="appointment-new-zealand">New Zealand</label>
                </p>
                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-united-kingdom"
                    value="United Kingdom"
                  />
                  <label for="appointment-united-kingdom">
                    United Kingdom
                  </label>
                </p>
                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-ireland"
                    value="Ireland"
                  />
                  <label for="appointment-ireland">Ireland</label>
                </p>
                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-canada"
                    value="Canada"
                  />
                  <label for="appointment-canada">Canada</label>
                </p>
                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-usa"
                    value="USA"
                  />
                  <label for="appointment-usa">USA</label>
                </p>
              
                <p className="booking-appoint-form">
                  <input
                    type="checkbox"
                    className="booking-radio-input"
                    id="appointment-dubai"
                    value="Dubai"
                  />
                  <label for="appointment-dubai">Dubai</label>
                </p> */}
                </div>
              </div>
              <div className="form-group">
                <h5>
                  Preferred Mode of Contact<span>*</span>
                </h5>
                <div
                  className="booking-appointment-form-field booking-appoin-form-circle flex-box"
                  value={bookingRec.Mode_Of_Contact}
                  onChange={handleModeOfContact}
                >
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-telephonic"
                      name="modeofcontact"
                      value="1"
                    />
                    <label for="appointment-telephonic">Telephonic Call</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-video"
                      name="modeofcontact"
                      value="2"
                    />
                    <label for="appointment-video">Video Conferencing</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-office"
                      name="modeofcontact"
                      value="3"
                    />
                    <label for="appointment-office">Office Visit</label>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <h5>
                  Nearest SED Office<span>*</span>
                </h5>
                <div
                  className="booking-appointment-form-field booking-appoin-form-circle flex-box"
                  onChange={handleBranch}
                >
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-sydney"
                      name="branch"
                      value="Sydney"
                    />
                    <label for="appointment-sydney">Sydney, Australia</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-delhi"
                      name="branch"
                      value="Delhi"
                    />
                    <label for="appointment-delhi">Delhi, India</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-sri-nagar"
                      name="branch"
                      value="SriNagar"
                    />
                    <label for="appointment-sri-nagar">Sri Nagar, India</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-chandigarh"
                      name="branch"
                      value="Chandigarh"
                    />
                    <label for="appointment-chandigarh">
                      Chandigarh, India
                    </label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-kathmandu"
                      name="branch"
                      value="Kathmandu"
                    />
                    <label for="appointment-kathmandu">Kathmandu, Nepal</label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-mahendranagar"
                      name="branch"
                      value="Mahendranagar"
                    />
                    <label for="appointment-mahendranagar">
                      Mahendranagar, Nepal
                    </label>
                  </p>
                  <p className="booking-appoint-form">
                    <input
                      type="radio"
                      className="booking-radio-input"
                      id="appointment-dang"
                      name="branch"
                      value="Dang"
                    />
                    <label for="appointment-dang">Dang, Nepal</label>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <h5>
                  Appointment Date & Time<span>*</span>
                </h5>
                <div className="appointment-select flex-box">
                  <p className="appointment-select-field">
                    <label for="date">Select Date</label>
                    <input
                      type="date"
                      name="AppointmentDate"
                      id="date"
                      className="form-control"
                      value={bookingRec.AppointmentDate}
                      onChange={(e) => handleRec(e)}
                    />
                  </p>
                  <p className="appointment-select-field">
                    <label for="selecttime">Select Time</label>
                    <span className="select-down-arrow">
                      <img src="/img/course-arrow-down.png" alt="student" />
                    </span>
                    <select
                      className="form-control"
                      id="selecttime"
                      name="AppointmentTime"
                      value={bookingRec.AppointmentTime}
                      onChange={(e) => handleRec(e)}
                    >
                      <option value="">Select Time</option>
                      <option value="10-11">10:00-11:00 AM</option>
                      <option value="11-12">11:00-12:00 AM</option>
                      <option value="12-1">12:00-1:00 PM</option>
                      <option value="1-2">1:00-2:00 PM</option>
                      <option value="2-3">2:00-3:00 PM</option>
                      <option value="3-4">3:00-4:00 PM</option>
                      <option value="4-5">4:00-5:00 PM</option>
                      <option value="5-6">5:00-6:00 PM</option>
                    </select>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <h5>
                  Fill details<span>*</span>
                </h5>
                <div className="appointment-select flex-box">
                  <p className="appointment-select-field">
                    <label for="fullname">Full Name</label>
                    <input
                      type="text"
                      name="Name"
                      id="fullname"
                      className="form-control"
                      placeholder="Your Name"
                      value={bookingRec.Name}
                      onChange={(e) => handleRec(e)}
                    />
                  </p>
                  <p className="appointment-select-field">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="Email"
                      id="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={bookingRec.Email}
                      onChange={(e) => handleRec(e)}
                    />
                  </p>
                  <p className="appointment-select-field">
                    <label for="fullname">Number</label>
                    <input
                      type="number"
                      name="Number"
                      id="phoneno"
                      className="form-control"
                      placeholder="Contact Number"
                      value={bookingRec.Number}
                      onChange={(e) => handleRec(e)}
                    />
                  </p>
                </div>
              </div>
              <div className="form-group Submit_btn_Booking ">
                {!loading ? (
                  <button
                    type="button"
                    className="btn"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                ) : (
                  <button type="button" className="btn">
                    Loading...
                  </button>
                )}
              </div>
              <div>
                <p>
                  {success ? <Alert varient={true} msg={success} /> : ""}
                  {error || ierror ? (
                    <Alert varient={false} msg={error || ierror} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
});

export default Index;
