import React from "react";
import EventForm from "./EventForm";

const RegisterYourself = React.memo(function RegisterYourself({ cpid }) {
  return (
    <section className="submit-yoursely" id="registerYourSelfSection">
      <div className="container">
        <div className="submit-yoursely-main-box border-style width-81">
          <div className="submit-yoursely-content-wrapper list-details-heading">
            <h2 id="register-now">
              <strong>Register</strong> Yourself
            </h2>

            {/* <p>
              This includes entrepreneurs, marketing managers, marketing
              specialists, and small business owners. The course is hugely
              interactive with projects and checklists.
            </p> */}
          </div>

          <div className="submit-form-wrapper">
            <EventForm formClass={"flex-box"} cpid={cpid} />
          </div>
        </div>
      </div>
    </section>
  );
});

export default RegisterYourself;
