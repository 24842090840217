import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Eligibilityeduloan = React.memo(function () {
  return (
    <section className="eligibility-edu-loan compadding">
      <div className="container">
        <div className="headingbox letter-normal text-center com-headingbox">
          <h2>
            <span className="themecolor">Eligibility</span> For A HDFC Credila
            Education Loan
          </h2>
          <p>
            There are a set of documents that are required for education loan.
            Follow on below to know in detail about it.
          </p>
        </div>
        <div className="flex-box space-between eligibility-edu-details-info align-center">
          <div className="eligibility-hdfc-content headingbox mb-0 letter-normal full-width-925">
            <h2>Eligibility Criteria</h2>
            <p>
              The student applying for the education loan should be an Indian
              citizen. The student’s co-borrower has to be student’s parent,
              spouse, sibling, in-laws, or uncle/aunt and he too has to be an
              Indian citizen.
            </p>
            <h2>HDFC Credila Loan Processing Charges</h2>
            <p>
              HDFC Credila processing fee amounts up to 1 % to 1.25% of the loan
              amount which includes all the legal fees and registration charges.
              There are no other hidden charges.
            </p>
          </div>
          <div className="eligibility-hdfc-image full-width-925">
            <LazyLoadingImages src="/img/money.jpg" alt="student-image" />
          </div>
        </div>
        <div className="flex-box eligibility-hdfc-services align-center">
          <div className="eligibility-hdfc-services-image full-width-925">
            <LazyLoadingImages
              src="/img/documents-word.jpg"
              alt="student-image"
            />
          </div>
          <div className="eligibility-hdfc-services-list-wrapper full-width-925">
            <div className="headingbox">
              <h2>Documents Required</h2>
            </div>
            <ul className="search-edu-visa-service-list">
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Fully completed and signed Application Form </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>
                  Copies of Age Proof, Identity Proof, and Residence Proof
                </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>2 Attested Passport size photograph</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>
                  Academic documents of the student such as mark sheets of all
                  qualifying courses and entrance exams{" "}
                </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Financial documents of the parents or co-borrower.</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-box eligibility-hdfc-services align-center">
          <div className="eligibility-hdfc-services-image full-width-925">
            <LazyLoadingImages src="/img/casier.jpg" alt="student-image" />
          </div>
          <div className="eligibility-hdfc-services-list-wrapper full-width-925">
            <div className="headingbox">
              <h2>HFDC Credila Education Loan Covers</h2>
            </div>
            <ul className="search-edu-visa-service-list">
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Fully completed and signed Application Form </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>
                  Copies of Age Proof, Identity Proof, and Residence Proof
                </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>2 Attested Passport size photograph</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>
                  Academic documents of the student such as mark sheets of all
                  qualifying courses and entrance exams{" "}
                </span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Financial documents of the parents or co-borrower.</span>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="flex-box eligibility-hdfc-services  align-center">
          <div className="eligibility-hdfc-services-image full-width-925">
            <LazyLoadingImages
              src="/img/count-rupues.jpg"
              alt="student-image"
            />
          </div>
          <div className="eligibility-hdfc-services-list-wrapper full-width-925">
            <div className="headingbox letter-normal">
              <h2>Value-Added Benefits</h2>
              <p>
                HDFC Credila’s Education Loan comes with irresistible offers
                worth Rs. 50,000/- on everything a student needs. Education Loan
                applicants of HDFC Credila can now enjoy special offers and
                privileges with CredilaOneStop on –
              </p>
            </div>
            <ul className="value-added-benefits-list search-edu-visa-service-list">
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Career Guidance</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>GRE/GMAT Test Prep</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Resume Writing</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Study Planner</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>International Calling</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Luggage</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Overseas Accommodation</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Travel</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Shipping/Parcel</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Health</span>
              </li>
              <li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>Stationery </span>
              </li><li className="search-edu-visa-service-list-item flex-box">
                <LazyLoadingImages
                  src="/img/check-circle-image.png"
                  alt="student-image"
                />
                <span>And Many More! </span>
              </li>
            </ul>
          </div>
        </div>
         */}
      </div>
    </section>
  );
});

export default Eligibilityeduloan;
