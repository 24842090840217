import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
} from "../constants/notificationConstants";

export const notificationListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTIFICATION_LIST_REQUEST:
      return { loading: true };
    case NOTIFICATION_LIST_SUCCESS:
      return { loading: false, notificationss: payload };
    case NOTIFICATION_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
