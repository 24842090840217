import React from "react";
import LeftPanel from "../Common/leftPanel";
import InterestedIn from "../MyProfile/InterestedIn";
import OngoingApplications from "../MyProfile/OngoingApplications";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listNotifications } from "../../../actions/notificationActions";
import moment from "moment";

export default function Index() {
  const dispatch = useDispatch();
  const { loading, error, notificationss } = useSelector(
    (s) => s.notificationList
  );
  const getNotifications = () => {
    dispatch(listNotifications());
  };
  useEffect(() => {
    if (!notificationss) {
      getNotifications();
    }
    //console.log(loading, error, notificationss);
  }, [dispatch, notificationss]);
  return (
    <div>
      <LeftPanel />
      <section className="dashboard">
        <div className="container">
          <div className="sec-row">
            <div className="toggle-sidebar-btn-wrapper">
              <div className="sidebar-btn">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="dashboard-profile-main common-dashboard-search-box">
              <div class="dashboard-profile-search-box common-searchbox-style flex-box align-center">
                <div class="coursesearch mb-tzero">
                  <form action="" class="searchofcourses">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search for Courses...."
                    />
                    <button type="submit" name="coursesarchsubmit" class="btn">
                      <img src="/img/searchicon.png" alt="Search Icon" />
                    </button>
                  </form>
                </div>
                <div class="notification-bellbox">
                  <div
                    class="notification-bell"
                    style={{
                      backgroundImage: `url(/img/bell.png)`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  >
                    <span class="notification">
                      <img src="/img/notification-circle.png" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="sec-lg-col-60 dashboard-rightbox dashboard-profile-box-wrapper">
                <div class="featuredbg-style message-style notification-style">
                  <div class="headingbox">
                    <div class="text-box">
                      <h3>My Notification</h3>
                      {
                        notificationss ? notificationss.length > 0 ? (<div class="count-box">
                        <span>{notificationss.length}</span>
                      </div>) : ("") : ("")
                      }
                      
                    </div>
                  </div>

                  {/* Notification Records */}
                  {notificationss ? (
                    notificationss.length > 0 ? (
                      <>
                        {notificationss.map((ntfm, index) => (
                          <a href={`/panel/${ntfm.WebsiteLink}`} style={{textDecoration:"none"}}>
                            <div class="message-box" key={index + 1}>
                              <div class="txt">
                                <h4>{ntfm.Notification}</h4>
                                <h6>
                                  {moment(ntfm.Created_Date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </h6>
                              </div>
                            </div>
                          </a>
                        ))}
                      </>
                    ) : (
                      <div class="message-box">
                        <div class="txt">
                          <h4>No Record Exist!</h4>
                        </div>
                      </div>
                    )
                  ) : (
                    <div class="message-box">
                      <div class="txt">
                        <h4>{error}</h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div class="sec-md-col-20 profile-details-info-wrapper profile-others-details-info-wrapper sticky">
                <div class="profile-others-details-info">
                  <InterestedIn />
                  <OngoingApplications />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
