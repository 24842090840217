import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listEducationalStatus } from '../actions/educationalStatusActions';

export default function EducationalStatus(){
    const dispatch = useDispatch();
    const {loading, error, educationSts} = useSelector(s=>s.educationalStatusList)
    const getEducationalStatus = () => {
        dispatch(listEducationalStatus())
    }
    useEffect(()=>{
        if(!educationSts){
            getEducationalStatus()
        }
    },[dispatch, educationSts])
    return educationSts ? educationSts.length > 0 ? educationSts.map((edst, index)=>(
        <option key={index+1} value={edst.Id}>{edst.E_Status}</option>
    )):(
        <option>No Record Exist!</option>
    ):(
        <option>{error}</option>
    )
}