import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  forgotPasswordUser,
  forgotPasswordVerifyUser,
  loginUser,
} from "../../actions/userActions";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import {
  USER_FORGOT_PASSWORD2_RESET,
  USER_FORGOT_PASSWORD_RESET,
  USER_LOGIN_RESET,
} from "../../constants/userConstants";

const LoginPopup = React.memo(function LoginPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ierror, setierror] = useState("");

  const [fpemail, setfpemail] = useState("");
  const [otp1, setotp1] = useState("");
  const [pwd1, setpwd1] = useState("");
  const [cpwd1, setcpwd1] = useState("");

  const [ierrorfp, setierrorfp] = useState("");
  const [ierrorfpv, setierrorfpv] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, success } = userLogin;

  const {
    loading: loadingfp,
    success: successfp,
    error: errorfp,
  } = useSelector((s) => s.forgotPassword);

  const {
    loading: loadingfpv,
    success: successfpv,
    error: errorfpv,
  } = useSelector((s) => s.forgotPasswordVerify);

  const handleForgotPassword1 = (e) => {
    e.preventDefault();
    setshow1(true);
    setshow2(false);
    setshow3(false);
    props.handleClose();
  };

  const handleForgotPassword2 = (e) => {
    e.preventDefault();
    setierrorfp("");
    dispatch(forgotPasswordUser({ Email: fpemail }));
  };

  useEffect(() => {
    if (successfp) {
      setshow1(false);
      setshow2(true);
      setshow3(false);
      props.handleClose();
      dispatch({ type: USER_FORGOT_PASSWORD_RESET });
    }
  }, [successfp]);

  const handleForgotPassword3 = (e) => {
    e.preventDefault();
    setierrorfpv("");
    if (pwd1 === cpwd1) {
      dispatch(
        forgotPasswordVerifyUser({ Email: fpemail, OTP: otp1, Password: pwd1 })
      );
    } else {
      setierrorfpv("Password and Confirm Password does not match!");
    }
  };

  useEffect(() => {
    if (successfpv) {
      setshow1(false);
      setshow2(false);
      setshow3(true);
      props.handleClose();

      setfpemail("");
      setotp1("");
      setpwd1("");
      setcpwd1("");
      dispatch({ type: USER_FORGOT_PASSWORD2_RESET });
    }
  }, [successfpv]);

  const closeAllPopup = (e) => {
    e.preventDefault();
    setshow1(false);
    setshow2(false);
    setshow3(false);
    props.handleClose();
  };

  const openLogin = (e) => {
    e.preventDefault();
    setshow1(false);
    setshow2(false);
    setshow3(false);
    props.handleOpen();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email == "" || password == "") {
      setierror("Email Address and Password both are required!");
    } else {
      const userJson = {
        Email: email,
        Password: password,
      };
      dispatch(loginUser(userJson));
    }
  };

  useEffect(() => {
    if (success) {
      setEmail("");
      setPassword("");
      dispatch({ type: USER_LOGIN_RESET });
    }
  }, [dispatch, success]);

  return (
    <>
      {/* Start of Login Modal */}
      <div
        className={
          props.show
            ? "signnup-process-modal login-process-modal is-visible"
            : "signnup-process-modal login-process-modal"
        }
        id="process-four"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner">
            <div className="process-modal-inner-info process-form-fullwidth">
              <span className="close-modal" onClick={props.handleClose}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <h4>Log In</h4>
              <form className="create-acco-form" onSubmit={handleLogin}>
                <div className="create-acco-form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="emailaddress"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="enterpassword"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <span
                    className="forgot-password"
                    onClick={handleForgotPassword1}
                  >
                    Forgot Your Password?
                  </span>
                </div>
                <div className="create-acco-form-group">
                  <button
                    type="submit"
                    className="btn btn-submit"
                    onClick={handleLogin}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Submit"}
                  </button>
                </div>
                <div>
                  <span className="sedtext-danger">{error}</span>
                  <span className="text-success">{success}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          show1
            ? "signnup-process-modal login-process-modal is-visible"
            : "signnup-process-modal login-process-modal "
        }
        id="process-five"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner">
            <div className="process-modal-inner-info process-form-fullwidth">
              <span className="close-modal" onClick={closeAllPopup}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <h4>Forgot Password</h4>
              <form className="create-acco-form">
                <div className="create-acco-form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="emailaddress"
                    name="fpemail"
                    placeholder="Enter Email Address"
                    onChange={(e) => setfpemail(e.target.value)}
                  />
                </div>
                <div className="create-acco-form-group">
                  <button
                    type="button"
                    className="btn btn-submit reset-password"
                    onClick={handleForgotPassword2}
                    disabled={loadingfp}
                  >
                    {loadingfp ? "Processing..." : "Reset Password"}
                  </button>
                  <br />
                  {errorfp ? (
                    <span className="sedtext-danger">{errorfp} Hell</span>
                  ) : (
                    ""
                  )}
                  {ierrorfp ? (
                    <span className="sedtext-danger">{ierrorfp} ther</span>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          show2
            ? "signnup-process-modal login-process-modal is-visible"
            : "signnup-process-modal login-process-modal"
        }
        id="process-six"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner">
            <div className="process-modal-inner-info process-form-fullwidth">
              <span className="close-modal" onClick={closeAllPopup}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <div className="verification text-box">
                <h4>
                  Verification OTP has been sent to your Email
                  <span>Note: OTP is valid for 15 Minutes only!</span>
                </h4>
              </div>
              <h4>Verify The OTP</h4>
              <form className="create-acco-form">
                <div className="create-acco-form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="emailaddress"
                    name="emailaddress"
                    placeholder="testaccount@gmail.com"
                    value={fpemail}
                    disabled
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="VerificationOTP"
                    name="otp1"
                    size={6}
                    placeholder="Verification OTP"
                    value={otp1}
                    onChange={(e) => setotp1(e.target.value)}
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="Password"
                    name="pwd1"
                    placeholder="Password"
                    value={pwd1}
                    onChange={(e) => setpwd1(e.target.value)}
                  />
                </div>
                <div className="create-acco-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="ConformPassword"
                    name="cpwd1"
                    placeholder="Confirm Password"
                    value={cpwd1}
                    onChange={(e) => setcpwd1(e.target.value)}
                  />
                </div>
                <div className="create-acco-form-group">
                  <button
                    type="button"
                    className="btn btn-submit set-passowrd"
                    onClick={handleForgotPassword3}
                    disabled={loadingfpv}
                  >
                    {loadingfpv ? "Processing..." : "Set Password"}
                  </button>
                  <br />
                  {errorfpv ? (
                    <span className="sedtext-danger">{errorfpv} Hell</span>
                  ) : (
                    ""
                  )}
                  {ierrorfpv ? (
                    <span className="sedtext-danger">{ierrorfpv} ther</span>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          show3
            ? "signnup-process-modal login-process-modal is-visible"
            : "signnup-process-modal login-process-modal"
        }
        id="process-seven"
      >
        <div className="modal-overlay"></div>
        <div className="process-modal modal-transition">
          <div className="process-modal-inner">
            <div className="process-modal-inner-info reset-successfully-form process-form-fullwidth">
              <span className="close-modal" onClick={closeAllPopup}>
                <LazyLoadingImages src="/img/close-bg.png" alt="student" />
              </span>
              <div className="verification text-box">
                <h4>
                  Your password has been reset successfully!
                  <span>
                    Please
                    <Link
                      to="/"
                      className="login-process-modal-toggle"
                      onClick={openLogin}
                    >
                      Login
                    </Link>
                    with your new password now.
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Login Modal */}
    </>
  );
});

export default LoginPopup;
