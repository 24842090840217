import React, { useState, useEffect } from "react";
import { contactJson } from "../../models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";
import { createSubscribe } from "../../actions/otherActions";

const EnquiryForm = React.memo(function () {
  const dispatch = useDispatch();
  const [contactArray, setContactArray] = useState(contactJson);
  const [subscribe, setSubscribe] = useState(false);
  const [ierror, setIerror] = useState("");

  const subscribeCreate = useSelector((state) => state.subscribeCreate);
  const {
    loading: loadingsubscribe,
    success: successsubscribe,
    error: errorsubscribe,
  } = subscribeCreate;

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, error, success: successC } = contactCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const checkHandler = () => {
    setSubscribe(!subscribe);
  };

  const handleChange = (e) => {
    setContactArray({
      ...contactArray,
      Link: window.location.href,
      Comments:
        "Contact Filled the form using the Website Register Yourself Form on Website. Country: " +
        country?.country_name,
      Activity: "Website IELTS Training Form Submitted.",
      Activity_Des:
        "IELTS Training Form submitted using the Website. Country: " +
        country?.country_name,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    var errorm = "";
    if (contactArray.full_name === "" || contactArray.full_name === undefined)
      errorm = "Full name cannot be Blank!";
    if (contactArray.Phone_No === "" || contactArray.Phone_No === undefined)
      errorm = "Phone Number cannot be Blank!";
    if (
      contactArray.Email_Address === "" ||
      contactArray.full_name === undefined
    )
      errorm = "Email Address cannot be Blank!";

    if (errorm === "") {
      setIerror("");
      if (subscribe) {
        dispatch(createContact(contactArray));
        dispatch(createSubscribe(contactArray.Email_Address));
      } else {
        dispatch(createContact(contactArray));
      }
    }
  };

  useEffect(() => {
    if (successC) {
      setContactArray(contactJson);
      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 3000);
    }
  }, [dispatch, successC]);

  return (
    <form className="flex-box align-center">
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="fname"
          placeholder="Enter Name"
          name="full_name"
          value={contactArray.full_name}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="phnumber"
          placeholder="Enter Number"
          name="Phone_No"
          value={contactArray.Phone_No}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="email"
          placeholder="Enter Email"
          name="Email_Address"
          value={contactArray.Email_Address}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group checkbox-box">
        <input
          type="checkbox"
          id="education-news"
          className="single-checkbox"
          name="subscribe"
          checked={subscribe}
          onChange={checkHandler}
        />

        <label for="education-news">
          <span>I want to receive overseas education news & updates</span>
        </label>
      </div>

      <div className="form-group apply-now">
        <input
          type="button"
          className="btn"
          disabled={loading ? loading : false}
          onClick={() => handleSubmit()}
          value={loading ? "Processing" : "Send Enquire"}
        />
      </div>
      <p>
        <span className="bg-success">{successC}</span>
        <span className="bg-error">
          {error}
          {ierror}
        </span>
      </p>
    </form>
  );
});

export default EnquiryForm;
