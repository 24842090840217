import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const HowItWorks = React.memo(function () {
  return (
    <>
      <section className="howitworksec compadding">
        <div className="container">
          <div className="headingbox text-center">
            <h2>
              <span className="smh">Our Process</span>How It Works
            </h2>
          </div>
          <div className="sec-row">
            <div className="sec-md-col-25">
              <div className="howitworkbox">
                <div className="howitworkboximg">
                  <LazyLoadingImages
                    src="/img/howitworkvector.png"
                    alt="Vector"
                  />
                </div>
                <div className="howitworkboxcntnt">
                  <h6>
                    Browse From More Than{" "}
                    <strong>1000 Courses & Colleges</strong>
                  </h6>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="howitworkbox">
                <div className="howitworkboximg">
                  <LazyLoadingImages
                    src="/img/howitworkvector2.png"
                    alt="Vector"
                  />
                </div>
                <div className="howitworkboxcntnt">
                  <h6>
                    Set Your Desired{" "}
                    <strong>Filters & Shortlist Courses</strong>
                  </h6>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="howitworkbox">
                <div className="howitworkboximg">
                  <LazyLoadingImages
                    src="/img/howitworkvector3.png"
                    alt="Vector"
                  />
                </div>
                <div className="howitworkboxcntnt">
                  <h6>
                    Compare Courses <strong>On The Go Before You Apply</strong>
                  </h6>
                </div>
              </div>
            </div>
            <div className="sec-md-col-25">
              <div className="howitworkbox">
                <div className="howitworkboximg">
                  <LazyLoadingImages
                    src="/img/howitworkvector4.png"
                    alt="Vector"
                  />
                </div>
                <div className="howitworkboxcntnt">
                  <h6>
                    Hassle Free Application
                    <strong>Tracking and Counselling </strong>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="howitworkelement">
          <div className="elementinnr">
            <LazyLoadingImages src="/img/shape.png" alt="Vector Element" />
          </div>
        </div>
      </section>
    </>
  );
});

export default HowItWorks;
