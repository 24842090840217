import React from "react";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const TrendingCourseSec = React.memo(function () {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return (
    <div>
      <section className="trendingcoursesec compadding ireland-courses">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Top </span>Courses In Perth
            </h2>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="student"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="/" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="student"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="/" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="student"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="/" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="student"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="/" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="student"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="/" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
});

export default TrendingCourseSec;
