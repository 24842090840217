import React from "react";

const Searchcountrysec = React.memo(function () {
  return (
    <section className="searchcountrysec compadding visa-countries-sec">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Popular Countries For</span> Visa
            Applications
          </h2>

          {/* <p>
            The Customs Law of the country prevents an individual from bringing
            in drugs, steroids, firearms, and weapons into the country.
          </p> */}
        </div>

        <div className="searchcountrysliderpro visa-countries-wrapper flex-box">
          <div className="searchcountryslide visa-countries-box">
            <a href="/study-in-australia">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/country.jpg')` }}
                ></div>

                <p>
                  Study in <strong>Australia</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="searchcountryslide visa-countries-box">
            <a href="/study-in-canada">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/country2.jpg')` }}
                ></div>

                <p>
                  Study in <strong>Canada</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="searchcountryslide visa-countries-box">
            <a href="/study-in-uk">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/country3.jpg')` }}
                ></div>

                <p>
                  Study in <strong>United Kingdom</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="searchcountryslide visa-countries-box">
            <a href="/study-in-ireland">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/country4.jpg')` }}
                ></div>

                <p>
                  Study in <strong>Ireland</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="searchcountryslide visa-countries-box">
            <a href="/study-in-newzealand">
              <div className="countrylistbox">
                <div
                  className="countryimage"
                  style={{ backgroundImage: `url('/img/country5.jpg')` }}
                ></div>

                <p>
                  Study in <strong>New Zealand</strong>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Searchcountrysec;
