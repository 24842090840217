import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SearchSection() {
  const [searchtxt, setSearchtxt] = useState("");
  const navigate = useNavigate();
  const { userDt } = useSelector((state) => state.userLogin);
  return (
    <div className="coursesearch">
      <form action="" className="searchofcourses">
        <input
          type="text"
          className="form-control"
          placeholder="Search for Courses...."
          value={searchtxt}
          onChange={(e) => setSearchtxt(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") navigate(`/courses/search/${searchtxt}`);
          }}
        />
        <button
          type="submit"
          name="coursesarchsubmit"
          className="btn"
          onClick={() => navigate(`/courses/search/${searchtxt}`)}
        >
          <img src="/img/searchicon.png" alt="Search Icon" />
        </button>
      </form>
    </div>
  );
}
