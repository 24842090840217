import {
  MONTH_LIST_REQUEST,
  MONTH_LIST_SUCCESS,
  MONTH_LIST_FAIL,
} from "../constants/monthConstants";

export const monthListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MONTH_LIST_REQUEST:
      return { loading: true };
    case MONTH_LIST_SUCCESS:
      return { loading: false, months: payload };
    case MONTH_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
