import {
  INTERESTED_LEVEL_OF_STUDY_LIST_REQUEST,
  INTERESTED_LEVEL_OF_STUDY_LIST_SUCCESS,
  INTERESTED_LEVEL_OF_STUDY_LIST_FAIL,
} from "../constants/interestedLevelOfStudyConstants";
import api2 from "../core/api2";

export const listInterestedLevelOfStudy = () => async (dispatch) => {
  try {
    dispatch({ type: INTERESTED_LEVEL_OF_STUDY_LIST_REQUEST });
    const { data } = await api2.get(`/InterestedLevel`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({
        type: INTERESTED_LEVEL_OF_STUDY_LIST_FAIL,
        payload: data.Result,
      });
    } else {
      dispatch({
        type: INTERESTED_LEVEL_OF_STUDY_LIST_SUCCESS,
        payload: data.Result,
      });
    }
  } catch (ex) {
    dispatch({
      type: INTERESTED_LEVEL_OF_STUDY_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
