import React, { useState } from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applyCourse,
  getAppliedCourseStatus,
  listCompareCourse,
} from "../../actions/courseActions";
import {
  COURSE_APPLY_RESET,
  COURSE_COMPARE_LIST,
} from "../../constants/courseConstants";

const Requirementsinfo = React.memo(function Requirementsinfo({
  courseId,
  data,
}) {
  const dispatch = useDispatch();
  const [clr, setclr] = useState("#F36525");

  const courseApply = useSelector((state) => state.courseApply);
  const { loading, error, success } = courseApply;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const courseAppliedStatus = useSelector((state) => state.courseAppliedStatus);
  const { result } = courseAppliedStatus;

  const randomColor = () => {
    let maxVal = 0xffffff; // 16777215
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);
    // Orange Color - "#F36525"
    // setclr(randColor);
    return randColor;
  };

  const handleApply = () => {
    if (userInfo) {
      dispatch(applyCourse(courseId));
    } else {
      alert("Login to apply!");
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({
          type: COURSE_APPLY_RESET,
        });
        window.location.reload();
      }, 3500);
    }
    if (!result) {
      dispatch(getAppliedCourseStatus(courseId));
    }
  }, [dispatch, success]);

  const handleCompare = (id, logo, uname, cname) => {
    let rec = localStorage.getItem("cc");
    let recObj = rec ? JSON.parse(rec) : [];
    let recids = localStorage.getItem("ccids")
      ? localStorage.getItem("ccids")
      : "";
    let newItem = {
      Course_Id: id,
      Logo74x74: logo,
      University_Name: uname,
      Course_Name: cname,
    };
    if (recids.split(",").indexOf(id.toString()).toString() === "-1") {
      if (recids === "") {
        recObj.push(newItem);
        localStorage.setItem("cc", JSON.stringify(recObj));
        recids = id;
        localStorage.setItem("ccids", recids);
        dispatch({
          type: COURSE_COMPARE_LIST,
          payload: JSON.stringify(recObj),
        });
        dispatch(listCompareCourse(localStorage.getItem("ccids")));
      } else {
        if (recObj.length <= 3) {
          recObj.push(newItem);
          localStorage.setItem("cc", JSON.stringify(recObj));
          recids += "," + id;
          localStorage.setItem("ccids", recids);
          dispatch({
            type: COURSE_COMPARE_LIST,
            payload: JSON.stringify(recObj),
          });
          dispatch(listCompareCourse(localStorage.getItem("ccids")));
        } else {
          alert("You can compare upto 4 courses only!");
        }
      }
    } else {
      alert("Course already in Compare!");
    }
  };

  return (
    <div>
      <section
        className="innrbanner listing-detail-banner"
        style={{
          backgroundImage: `url(${
            data.Banner1903x550
              ? `${process.env.REACT_APP_SED_DOC_URL}/university/banner1903x550/${data.Banner1903x550}`
              : "/img/Banner1903x500.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt listingdetailbanner-content">
            <div className="listingdetail-banner-heading">
              <div className="university-tags-box flex-box">
                <LazyLoadingImages
                  //src="/img/university-tag.jpg"
                  src={
                    data.Logo134x64
                      ? `${process.env.REACT_APP_SED_DOC_URL}/university/logo134x64/${data.Logo134x64}`
                      : "/img/Logo134x64.jpg"
                  }
                  alt={data.University_Name}
                />

                <h5>
                  {data.Logo134x64 ? (
                    data.University_Name
                  ) : (
                    <span style={{ color: "black" }}>
                      {data.University_Name}
                    </span>
                  )}{" "}
                </h5>
              </div>

              <h1 style={{ fontSize: "35px" }}>
                {data.Logo134x64 ? (
                  data.Course_Name
                ) : (
                  <span style={{ color: "black" }}>{data.Course_Name}</span>
                )}{" "}
              </h1>

              <div className="listingdetail-banner-tags">
                <a href="/">
                  {/* <LazyLoadingImages
                    src="/img/sydney-australia.png"
                    alt="student-image"
                  /> */}
                  {data.Campuses ? (
                    <div>
                      {data.Campuses.split(",").map((cmps, index) => {
                        var clrr = randomColor();
                        return (
                          <>
                            <span
                              key={index + 1}
                              style={{
                                backgroundColor: clr,
                                padding: "3px 8px",
                                borderRadius: "10px",
                                color: "white",
                              }}
                            >
                              {cmps}
                            </span>
                            &nbsp;&nbsp;&nbsp;
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="requirements-info-sec">
        <div className="container">
          <div className="flex-box">
            <div className="requirements-info flex-box">
              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/dollar-fee.png"
                  alt="student-image"
                />

                <div className="requirment-content">
                  <h5>Admission Fee</h5>
                  <h3>
                    {data.Country_Symbol} {data.Yearly_Tuition_Fees}
                  </h3>
                </div>
              </div>

              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/educationdegree.png"
                  alt="student-image"
                />

                <div className="requirment-content">
                  <h5>Degree Type</h5>

                  <h3>{data.Study_Level}</h3>
                </div>
              </div>

              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/education-duration.png"
                  alt="student-image"
                />

                <div className="requirment-content">
                  <h5>Duration</h5>

                  <h3>{data.Duration} months</h3>
                </div>
              </div>
            </div>

            <div className="listingdetail-entryscore">
              <div className="entryscore-counter flex-box">
                <div className="entryscore-counter-heading">
                  <h6>Entry Score</h6>
                </div>

                <div className="entryscore-info-wrapper flex-box align-center">
                  <div className="entryscore-info">
                    <LazyLoadingImages
                      src="/img/store-cup.png"
                      alt="student-image"
                    />

                    <div className="entryscore-info-content">
                      <h5>
                        <strong>IELTS</strong> : {data.IELTS_Score}
                      </h5>
                    </div>
                  </div>

                  {/* <div className="entryscore-info">
                    <LazyLoadingImages
                      src="/img/content-bar.png"
                      alt="student-image"
                    />

                    <div className="entryscore-info-content">
                      <h5>
                        <strong>PTE</strong> : 80
                      </h5>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="apply-dates-wrapper flex-box">
                <div className="datest-of-apply">
                  <h6>Dates To Apply</h6>

                  <ul className="month-name">
                    {data.Intakes &&
                      data.Intakes.split(",").map((int, index) => (
                        <li key={index + 1}>{int}</li>
                      ))}
                  </ul>
                </div>

                {/* <div className="available-scholar">
                    <h5>
                      Scholarship<span> Available</span>
                    </h5>
                  </div> */}
              </div>

              <div className="flex-box apply-cta-group">
                <Button
                  className="btn whitebtn"
                  onClick={() =>
                    handleCompare(
                      data.Course_Id,
                      data.Logo74x74,
                      data.University_Name,
                      data.Course_Name
                    )
                  }
                >
                  Add to compare
                </Button>

                <Button
                  className="btn whitebtn"
                  onClick={() =>
                    (window.location.href = "/consult_a_counselor")
                  }
                >
                  TALK TO EXPERTS
                </Button>
              </div>

              <div className="applynow text-center">
                {result === "Yes" ? (
                  <span>You have already applied for this course.</span>
                ) : (
                  <Button className="btn" onClick={() => handleApply()}>
                    APPLY NOW
                  </Button>
                )}
              </div>
              <p>
                <span className="bg-error">{error}</span>
                <span className="bg-success">{success}</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="listing-details-sec">
        <div className="container">
          <div className="flex-box listing-details-wrapper">
            <div className="listing-details-left-box">
              <ul className="listing-details-leftbar sticky">
                <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="/">Admission Requirements</a>
                </li>

                <li id="ielts-pte-score-link" data-link="ielts-pte-score">
                  <a href="/">IELTS & PTE Score</a>
                </li>

                <li id="course-outcome-link" data-link="course-outcome">
                  <a href="/">Course Outcome</a>
                </li>

                <li id="how-to-apply-link" data-link="how-to-apply">
                  <a href="/">How To Apply</a>
                </li>

                <li id="about-college-link" data-link="about-college">
                  <a href="/">About College</a>
                </li>
              </ul>
            </div>

            <div className="listing-details-info">
              <div
                className="listing-details-info-box"
                id="admission-requirements"
              >
                <div className="list-details-heading">
                  <h2>
                    <strong>Admission</strong> Requirements
                  </h2>
                </div>

                <span
                  dangerouslySetInnerHTML={{ __html: data.Entry_Requirement }}
                ></span>
              </div>

              <div className="listing-details-info-box" id="ielts-pte-score">
                <div className="list-details-heading">
                  <h2>
                    <strong>Scores </strong> Required
                  </h2>
                </div>
                <table className="table table-responsive">
                  <tr>
                    <td>
                      <p>IELTS</p>
                    </td>
                    <td>
                      <p>{data.IELTS_Required && data.IELTS_Required}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>PTE</p>
                    </td>
                    <td>
                      <p>{data.PTE_Required && data.PTE_Required}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>TOEFL</p>
                    </td>
                    <td>
                      <p>{data.TOEFL_Required && data.TOEFL_Required}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>GRE</p>
                    </td>
                    <td>
                      <p>{data.GRE_Required && data.GRE_Required}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>GMAT</p>
                    </td>
                    <td>
                      <p>{data.GMAT_Required && data.GMAT_Required}</p>
                    </td>
                  </tr>
                </table>
                <a href="/ielts_training" className="btn">
                  Know More About Ielts Training
                </a>
                &nbsp;&nbsp;
                <a href="/pte_training" className="btn">
                  Know More About Pte Training
                </a>
              </div>

              {/* <div className="listing-details-info-box">
                <div className="list-details-heading">
                  <h2 id="course-outcome">
                    <strong>Career</strong> Opportunities
                  </h2>
                </div>

                <p>
                  The course provides suitable training for those who wish to
                  take up specialist positions in the accounting and financial
                  services sector of the economy or those who wish to
                  subsequently pursue a research or an academic career via a PhD
                  in Accounting and Finance. Many students go on to work in the
                  financial sector either in the City or across other sectors.
                </p>

                <div className="detail-info-list-wrapper">
                  <ul className="detail-info-list">
                    <li>Chartered accountant</li>

                    <li>Chartered certified accountant</li>

                    <li>Chartered management accountant</li>

                    <li>Chartered public finance accountant</li>

                    <li>Company secretary</li>

                    <li>Arbitrator</li>

                    <li>Business development manager</li>

                    <li>Data analyst</li>

                    <li>Economist</li>

                    <li>Licensed conveyancer</li>
                  </ul>
                </div>
              </div> */}

              <div className="listing-details-info-box">
                <div className="list-details-heading">
                  <h2 id="how-to-apply">
                    <strong>How To</strong> Apply
                  </h2>
                </div>

                <p>
                  Applying to {data.University_Name} is easy. Follow the below
                  steps and get in touch with one of our counsellor to assist
                  you in processing your application in {data.University_Name}.
                </p>

                <div className="details-applybox-wrapper flex-box">
                  <div className="details-applybox text-center">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/blank-sheet.png"
                        alt="student-image"
                      />
                    </div>

                    <h5>
                      <strong>Compare & Shortlist</strong> Your Desired Course
                    </h5>
                  </div>

                  <div className="details-applybox text-center">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/document-profile.png"
                        alt="student-image"
                      />
                    </div>

                    <h5>
                      <strong>Create Profile,</strong> Upload Documents & Apply
                    </h5>
                  </div>

                  <div className="details-applybox text-center">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/apply-message.png"
                        alt="student-image"
                      />
                    </div>

                    <h5>
                      <strong>Track</strong> Your Application
                      <strong>Status</strong> Easily
                    </h5>
                  </div>
                </div>

                <div className="text-center details-apply-cta">
                  {result === "Yes" ? (
                    <span>You have already applied for this course.</span>
                  ) : (
                    <Button className="btn" onClick={handleApply}>
                      Apply Now
                    </Button>
                  )}
                </div>
                <p>
                  <span className="bg-error">{error}</span>
                  <span className="bg-success">{success}</span>
                </p>
              </div>

              <div className="listing-details-info-box">
                {/* <div className="list-details-heading">
                  <h2 id="about-college">
                    <strong>About</strong> College
                  </h2>
                </div>

                <p>
                  Study At Australia’s #1 University And Graduate With An
                  Internationally Recognised Degree. At ANU You Will Live, Learn
                  & Make Lifelong Friends Within A Community Of Talented People.
                  Over 140 Degrees. Guaranteed Accomodation. 150+ Scholarships.
                </p>

                <a href="/" className="btn">
                  Know More About
                </a> */}

                <div className="university-locaton">
                  <h5>Check out the Course Link:</h5>

                  <a href={data.Course_Url} target="_blank">
                    {data.Course_Url}
                  </a>
                </div>

                <div className="college-location">
                  <a href="/">
                    <span className="gps-icon">
                      <LazyLoadingImages
                        src="/img/location-gps.png"
                        alt="student-image"
                      />
                    </span>
                    International tuition {data.Country_Symbol}{" "}
                    {data.Yearly_Tuition_Fees}
                  </a>

                  {data.Banner1903x550 && (
                    <LazyLoadingImages
                      src={
                        data.Banner1903x550
                          ? `${process.env.REACT_APP_SED_DOC_URL}/university/Banner1903x550/${data.Banner1903x550}`
                          : "/img/Banner1903x550.jpg"
                      }
                      alt={data.University_Name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Requirementsinfo;
