import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listallCountry } from "../actions/countryActions";
import { useEffect } from "react";
import { listInterestedLevelOfStudy } from "../actions/interestedLevelOfStudyActions";

export default function InterestedLevelOfStudy() {
  const dispatch = useDispatch();
  const { loading, error, interestedLevels } = useSelector(
    (s) => s.interestedLevelOfStudyList
  );
  const getInterestedLevels = () => {
    dispatch(listInterestedLevelOfStudy());
  };
  useEffect(()=>{
    if(!interestedLevels){
      getInterestedLevels();
    }
  },[dispatch,interestedLevels])
  return interestedLevels
    ? interestedLevels.length > 0
      ? interestedLevels.map((alcd, index) => (
          <option value={alcd.Id} key={alcd.Id}>
            {alcd.Interested_Level_Of_Study}
          </option>
        ))
      : ""
    : "";
}
