import {
  INTERESTED_FIELD_OF_STUDY_LIST_REQUEST,
  INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS,
  INTERESTED_FIELD_OF_STUDY_LIST_FAIL,
} from "../constants/interestedFieldOfStudyConstants";

export const interestedFieldOfStudyListReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case INTERESTED_FIELD_OF_STUDY_LIST_REQUEST:
      return { loading: true };
    case INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS:
      return { loading: false, interestedFields: payload };
    case INTERESTED_FIELD_OF_STUDY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
