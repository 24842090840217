import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Efficientsignup = React.memo(function Efficientsignup() {
  return (
    <section
      className="efficient-signup bg-style"
      style={{
        backgroundImage: `url('/img/portrait-business-executive-using.png')`,
        backgroundColor: `#25253F`,
      }}
    >
      <div className="container">
        <div className="efficient-signup-wrapper">
          <div className="headingbox text-white">
            <h2>
              <span className="themecolor">
                Sign Up Now!
                <br />
              </span>
              Get Study Abroad Guidance
            </h2>
          </div>
          <div className="efficient-signup-featured flex-box space-between">
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="student-image"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>Browse</h5>
              </div>
            </div>
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="student-image"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>Select</h5>
              </div>
            </div>
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="student-image"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>Apply</h5>
              </div>
            </div>
            <div className="efficient-signup-featured-list">
              <div className="efficient-signup-featured-icon">
                <LazyLoadingImages
                  src="/img/light-green-check-icon.png"
                  alt="student-image"
                />
              </div>
              <div className="efficient-signup-featured-content text-white">
                <h5>Track</h5>
              </div>
            </div>
          </div>
          {/* <div className="efficient-signup-featured-button">
            <Link to="/" className="btn">
              Create Account
            </Link>
          </div> */}
        </div>
      </div>
    </section>
  );
});

export default Efficientsignup;
