import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";

const MainBanner = React.memo(function ({ cntry }) {
  //const { cntry } = useParams();
  //const { country } = useSelector((state) => state.getLocation);
  const Navigate = useNavigate();
  const options = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  const IndiaBannerJson = [
    // {
    //   img:
    //     window.innerWidth <= 480
    //       ? "/img/img_landing_mobile.jpg"
    //       : "/img/img_landing.jpg",
    //   bannerText: `<h1 style="text-shadow: 3px 3px black;"> <span style="color:#f36525" ></span></h1>
    //   <p>
   
    //   </p>`,
    //   link: "/global-education-fair-delhi-2025",
    // },

    {
      img: "/img/img_3.jpg",
      bannerText: `<h1 style="text-shadow: 3px 3px black;">Expand your horizons with <span style="color:#f36525" >Global Education</span></h1>
      <p>
      <a class="btn" href="/">Explore</a>
      </p>`,
    },
    {
      img: "/img/img_2.jpg",
      bannerText: `<h1><span style="color:#f36525">Special IELTS Offer </span><br/>Flat 50% Off</h1>
      <p>
      <a class="btn" href="/ielts_training">Book Now</a>
      </p>`,
    },
    {
      img: "/img/img_7.jpg",
      bannerText: `<div style="padding-left:28px"><h1>Study In UK</h1>
      <h4 class="banner-h4"><span>1 Year Master's Degree, PSW VISA Guranteed</span></h4><p>
      <a class="btn" href="/study_in_uk">Explore</a>
      </p></div>`,
    },
    {
      img: "/img/img_24.jpg",
      bannerText: `<h1>Study In CANADA</h1>
      <h4 class="banner-h4">Easy Immigration Quality Education,  Upto 3 years work permit</h4><p>
      <a class="btn" href="/study_in_canada">Explore</a>
      </p>`,
    },
    {
      img: "/img/img_25.jpg",
      bannerText: `<h1>Study In Australia</h1>
      <h4 class="banner-h4">Upto 100% Scholarship, Upto 6 years PSW VISA</h4><p>
      <a class="btn" href="/study_in_australia">Explore</a>
      </p>`,
    },
    {
      img: "/img/img_28.jpg",
      bannerText: `<h1 >IELTS Reality check kiya kya?</h1>
      <h4>Review your IELTS score before taking<br/>
      the actual exam in an exam-like environment</h4><p>
      <a class="btn" href="/ielts_training">Know More</a>
      </p>`,
    },
  ];

  const NepalBannerJson = [
    {
      img: "/img/img_2.jpg",
      bannerText: `<h1><span style="color:#f36525">KICK START</span> <br/>YOUR EDUCATION<br/>JOURNEY ABROAD</h1><hr style="color:#f36525;width:15%;text-align:left;height:8px;margin-left:0;background-color:#f36525;border-radius:10px" /><p><h4>Get hands-on world-class 
      <br/>Education With Search Education</h4></p><p>
      <a class="btn" href="/contactus">Explore</a>
      </p>`,
    },
    {
      img: "/img/img_3n.jpg",
      bannerText: `<h2 style="color:#f36525">"BE EXCEPTIONAL"</h2><br/>
      <div class="fw-600">
      <span class="f-75 cl-white">STUDY <span style="color:#f36525">ABROAD</span></span><br/>
      <span class="f-45 cl-white">WITH SEARCH EDUCATION<span>
      <p><br/>
      <h4 class="fw-600 cl-white lspc-3">
      THE RIGHT COURSE AT LEADING UNIVERSITIES
      </h4></p>
      </div><p>
      <a class="btn" href="/contactus">Explore</a>
      </p>`,
    },
    {
      img: "/img/img_7.jpg",
      bannerText: `<div style="padding-left:28px"><h1>Study In UK</h1>
      <h4 class="banner-h4"><span>1 Year Master's Degree, PSW VISA Guranteed</span></h4><p>
      <a class="btn login-process-modal" href="/study_in_uk">Enquire Now</a>
      </p></div>`,
    },
    {
      img: "/img/img_24.jpg",
      bannerText: `<h1>Study In CANADA</h1>
      <h4 class="banner-h4">Easy Immigration Quality Education,  Upto 3 years work permit</h4><p>
      <a class="btn login-process-modal" href="/study_in_canada" >Enquire Now</a>
      </p>`,
    },
    {
      img: "/img/img_25.jpg",
      bannerText: `<h1>Study In Australia</h1>
      <h4 class="banner-h4">Upto 100% Scholarship, Upto 6 years PSW VISA</h4>
      <p>
      <a class="btn login-process-modal" href="/study_in_australia" >Enquire Now</a>
      </p>`,
    },
  ];

  const AustraliaBannerJson = [
    {
      img: "/img/img_Aus1.jpg",
      bannerText: `<div class="fw-650 tx-uppercase"> 
      <span class="f-40 cl-f57338">Study </span><br/>
      <span class="cl-white f-70" style="font-weight: 500;
      letter-spacing: -7px;
      font-family: monospace;
      color: white;">Kitchen management</span><br/>
      <span class="f-40cl-white">Course in <span class="cl-f57338">australia</span></span>
      <hr class="hr-tg" />
      <span class="f-30 cl-white">Take your cooking skills to 
      <br/>the next level</span></div><p>
      <a class="btn login-process-modal" href="/contactus" >Enquire Now</a>
      </p>`,
    },
    {
      img: "/img/img_Aus2.jpg",
      bannerText: `<div class="fw-650 tx-uppercase cl-blk"> <span class="f-40" >Study </span><br/>
      <span class="f-70 fw-500 cl-f57338" style="letter-spacing: -7px;
      font-family: monospace; word-spacing:-15px;">nursing & Health Care</span><br/>
      <span class="f-40">Course in <span >australia</span></span>
      <hr class="hr-tg" />
      <span style="font-size:25px">make a difference with your specialized<br/> skills in the field of Healthcare</span></div><p>
      <a class="btn login-process-modal" href="/contactus" >Enquire Now</a>
      </p>`,
    },
    {
      img: "/img/img_Aus3.jpg",
      bannerText: `<div class="f-40 fw-650 cl-white tx-uppercase">
      Planning to migrate to <br/>
      <span class="f-80 cl-f36525">australia?</span>
      <hr class="hr-tg" />
      <span class="f-30">Let us help make the visa process <br/>smooth & stress free.<span>
      </div><p>
      <a class="btn login-process-modal" href="/contactus" >Enquire Now</a>
      </p>`,
    },
    {
      img: "/img/img_Aus4.jpg",
      bannerText: `<div class="img-Aus4">
      <div style="">
      <span class="f-35">
      Study <br/>
      <span class="cl-f36525 f-55 cl-sm-blk">
      Social Work<br/>
      <div class="img-jkc">
      <img src="/img/exe_logo1.png" class="img-sml" />
      </div>
      </span></span>
      <div class="f-30">
      Unlock the power of positive</br>
      change and make a meaningful</br>
      impact in your community.</div></div>
      </div><p>
      <a class="btn login-process-modal" href="/contactus" >Enquire Now</a>
      </p>`,
    },
  ];

  return (
    <section className="mainbanner">
      {/* {country ? (
        country.country_name === "Australia" || cntry == "australia" ? (
          <Slider className="bannermainsider" {...options}>
            {AustraliaBannerJson.map((bnj, index) => (
              <div className="bannerslide">
                <div className="bannerslideinnr">
                  <div
                    className="bannerslideimg"
                    style={{ backgroundImage: `url('${bnj.img}')` }}
                  ></div>
                  <div className="container">
                    <div className="banner-slidecntnt">
                      <span
                        dangerouslySetInnerHTML={{ __html: bnj.bannerText }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : country.country_name === "Nepal" || cntry == "nepal" ? (
          <Slider className="bannermainsider" {...options}>
            {NepalBannerJson.map((bnj, index) => (
              <div className="bannerslide">
                <div className="bannerslideinnr">
                  <div
                    className="bannerslideimg"
                    style={{ backgroundImage: `url('${bnj.img}')` }}
                  ></div>
                  <div className="container">
                    <div className="banner-slidecntnt">
                      <span
                        dangerouslySetInnerHTML={{ __html: bnj.bannerText }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (*/}
      {cntry && cntry == "nepal" ? (
        <Slider className="bannermainsider" {...options}>
          {NepalBannerJson.map((bnj, index) => (
            <div className="bannerslide">
              <div className="bannerslideinnr">
                <div
                  className="bannerslideimg"
                  style={{ backgroundImage: `url('${bnj.img}')` }}
                ></div>
                <div className="container">
                  <div className="banner-slidecntnt">
                    <span
                      dangerouslySetInnerHTML={{ __html: bnj.bannerText }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <Slider className="bannermainsider" {...options}>
          {IndiaBannerJson.map((bnj, index) => (
            <div
              className="bannerslide"
              onClick={() => {
                if (bnj?.link) {
                  Navigate(bnj?.link);
                }
              }}
            >
              <div className="bannerslideinnr">
                <div
                  className="bannerslideimg"
                  style={{ backgroundImage: `url('${bnj.img}')` }}
                ></div>
                <div className="container">
                  <div className="banner-slidecntnt">
                    <span
                      dangerouslySetInnerHTML={{ __html: bnj.bannerText }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
});

export default MainBanner;
