import React from 'react'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import SearchCountrySec from '../Home/SearchCountrySec'
import TestimonialSec from '../Counselling/TestimonialSec'
import Ptecounsellingsec from './pte-counselling-sec'
import PteTrainingEducation from './PteTrainingEducation'

import Takepte from './take-pte'
import TypePteExam from './TypePteExam'
import Meta from "../../components/Meta";

export default function index() {
    const metaData={
        title:"PTE Coaching Classes – Search Education",
        mtitle:"PTE Coaching Classes – Search Education",
        mdescription:"Unlock your PTE success with Best Coaching Classes at Search Education. Achieve Your Dream PTE Exam Score. Join our specialized classes today!",
        ogtype:"website",
        ogurl:"",
        ogtitle:"PTE Coaching Classes – Search Education",
        ogdescription:"Unlock your PTE success with Best Coaching Classes at Search Education. Achieve Your Dream PTE Exam Score. Join our specialized classes today!",
        ogimage:"https://www.searcheducation.com/img/making-notes.png",
        twitterCard:"summary_large_image",
        twitterUrl:"",
        twitterTitle:"PTE Coaching Classes – Search Education",
        twitterDescription:"Unlock your PTE success with Best Coaching Classes at Search Education. Achieve Your Dream PTE Exam Score. Join our specialized classes today!",
        twitterImage:"https://www.searcheducation.com/img/making-notes.png",
      }
    return (
        <div className="template-pte-training">
            <Header />
            <Meta metaData={metaData} />
            <Takepte />
            <TypePteExam />
            <PteTrainingEducation />
            <TestimonialSec />
            <Ptecounsellingsec />
            <SearchCountrySec/>
            <Footer />
        </div>
    )
}
