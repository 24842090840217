import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

import Applicationprocess from "./application-process";

import Studyincountry from "./study-in-country";
import Trendingcourse from "./trendingcourse";
import Faqs from "../StudyInAdelaide/Faqs";
import SearchCity from "../Home/SearchCity";

export default function index() {
  return (
    <>
      <Header />
      <Studyincountry />
      <Trendingcourse />
      <Applicationprocess />
      <section className="searchcountrysec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Most Popular</span>
              <span className="themecolor">Search</span> City
            </h2>
          </div>
          <div className="searchcountrysliderpro">
            <SearchCity removeCity="Melbourne" />
          </div>
        </div>
      </section>
      <Faqs city={"Melbourne"} />
      <Footer />
    </>
  );
}
