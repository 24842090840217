import {
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST,
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS,
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL,
  ENGLISH_PROFICIENCY_TEST_MODULE_GET_RESET,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAIL,
  VERIFY_TOKEN_RESET,
  IPCHECKER_CREATE_REQUEST,
  IPCHECKER_CREATE_SUCCESS,
  IPCHECKER_CREATE_FAIL,
} from "../constants/websiteConstants";

export const verifyTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_TOKEN_REQUEST:
      return { loading: true };
    case VERIFY_TOKEN_SUCCESS:
      return { loading: false, valid: action.payload };
    case VERIFY_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const englishGetModuleReducer = (state = {}, action) => {
  switch (action.type) {
    case ENGLISH_PROFICIENCY_TEST_MODULE_GET_REQUEST:
      return { loading: true };
    case ENGLISH_PROFICIENCY_TEST_MODULE_GET_SUCCESS:
      return { loading: false, englishModule: action.payload };
    case ENGLISH_PROFICIENCY_TEST_MODULE_GET_FAIL:
      return { loading: false, error: action.payload };
    case ENGLISH_PROFICIENCY_TEST_MODULE_GET_RESET:
      return { loading: false, englishModule: "" };
    default:
      return state;
  }
};

export const IPInsertReducer=(state={},action)=>{
  switch(action.type){
    case IPCHECKER_CREATE_REQUEST:
      return {loading: true};
    case IPCHECKER_CREATE_SUCCESS:
      return {loading:false,success:action.payload};
    case IPCHECKER_CREATE_FAIL:
      return {loading:false,error:action.payload};
    default:
      return state;
  }
}