import {
  EDUCATION_STATUS_LIST_REQUEST,
  EDUCATION_STATUS_LIST_SUCCESS,
  EDUCATION_STATUS_LIST_FAIL,
} from "../constants/educationStatusConstants";

export const educationalStatusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EDUCATION_STATUS_LIST_REQUEST:
      return { loading: true };
    case EDUCATION_STATUS_LIST_SUCCESS:
      return { loading: false, educationSts: payload };
    case EDUCATION_STATUS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
