import React from "react";
import { useSelector } from "react-redux";
import CounsellorSection from "./CounsellorSection";

export default function CounsellorSec() {
  const { userDt } = useSelector((state) => state.userLogin);
  return (
    <div className="sec-md-col-20 profile-details-info-wrapper sticky">
      <div className="profile-details-info">
        <a href="/panel/notifications">
          <div
            className="notification-bell"
            style={{
              backgroundImage: `url('/img/bell.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            {/* Notification circle */}
            <span className="notification">
              <img
                src="/img/notification-circle.png"
                alt="notification circle"
              />
            </span>
          </div>
        </a>
        <div className="dashboard-profile text-center">
          <div className="profile-image">
            <img src="/img/student-icon.jpg" alt={userDt.Contact_Name} />
          </div>
          <div className="profile-details">
            <h3>{userDt.Contact_Name}</h3>
            {/* <h4>Bachelor in Technology</h4> */}
            <a href="/panel/myprofile" className="view-profile">
              View Profile
              <span className="view-profile-arrow">
                <img src="/img/view-profile-arrow.png" alt="view profile" />
              </span>
            </a>
            {/* <label for="file">Your Profile is 75% get complete!</label>
                    <div
                      className="progress-wrap progress"
                      data-progress-percent="75"
                    >
                      <div className="progress-bar progress"></div>
                    </div> */}
          </div>
        </div>

        {/* Counsellor Name */}
        <CounsellorSection />

        <div
          className="expert-advice bg-style"
          style={{ backgroundImage: `url('/img/expert-image.png')` }}
        >
          <div className="expert-advice-content text-center">
            <h3>Looking For Expert Advice ?</h3>
            <h5>Education Consultants</h5>
            <a href="/consult_a_counselor" className="btn">
              Get In Touch
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
