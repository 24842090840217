import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/sydney-banner.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Sydney</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">
                Sydney is very popular for the
                <br /> opportunities it{" "}
              </span>
              offers to students
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/group-four-multiracial-students.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Education</h2>
              <p>
                Some of the universities in Sydney rank among the best in the
                world for academic research. The city is home to five of the
                largest universities in the world. Sydney, being the largest in
                Australia, is described as a melting pot of nationalities and
                cultures, with more than <strong>35,000 students</strong> from
                all over the world. The city gives students the right exposure
                before they embark on their career. The courses offered and
                qualifications awarded by the universities in Sydney are
                globally recognised.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/medium-shot-graduate-student.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Financial help and scholarships</h2>
              <p>
                There are scholarships available for international students
                studying in Sydney. Financial assistance for international
                students is{" "}
                <strong>available from the universities and banks.</strong>{" "}
                There are conditions that are stipulated for both scholarships
                and financial assistance, the most important of them being a
                very good academic record in terms of grades. International
                students can seek help from charitable institutions, the
                government of <strong>New South Wales</strong> and{" "}
                <strong>NGOs</strong> for help in paying for studies, for sports
                and work. The universities do not interfere in such
                interventions{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding lightgrey">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages
                src="/img/happy-businesswoman-reading-great-online.jpg"
                alt="student"
              />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Work opportunities</h2>
              <p>
                As an international student, there are many opportunities for
                work in Sydney. And the working conditions are attractive with
                flexible working hours and good salaries. Options and job
                opportunities after education are also many.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/rijan-hamidovic.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Weather conditions and attractions</h2>
              <p>
                Sydney is known for its sunny weather, with over 300 days of
                sunshine. This makes it a fantastic place to discover and enjoy
                the outdoors, whether on a bike, at the beach, or just by taking
                a leisurely stroll. There are many opportunities to meet new
                people and establish new acquaintances, and the mild temperature
                is perfect for outdoor activities. Surfing, hiking, paddle
                boarding, and taking advantage of the city's green spaces are
                just a few of the activities available in Sydney. Sydney's
                distinctive lifestyle is ideal for anyone who enjoys being
                outside, from the beaches to the bush, there is always something
                new to discover and explore.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
