import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Journeyservicesec = React.memo(function () {
  return (
    <section className="othersericesec journey-servicesec compadding">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Why University Living By</span> Search
            Edu
          </h2>

          {/* <p>
            The Customs Law of the country prevents an individual from bringing
            in drugs, steroids, firearms, and weapons into the country.
          </p> */}
        </div>

        <div className="sec-row flex-box just-center">
          {/* <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/right-place.png"
                  alt="Getting Visa Guidence"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Quick Search</p>
              </div>
            </div>
          </div> */}

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/icons_Verified_listings.png"
                  alt="Verified Listings"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Verified Listings</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/icons_Price_match_guarantee.png"
                  alt="Price match guarantee"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Price match guarantee</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/icons_Hassle-free_paperwork.png"
                  alt="Hassle-free paperwork"
                />
              </div>

              <div className="otherservicecntnt">
                <p>Hassle-free paperwork</p>
              </div>
            </div>
          </div>

          <div className="sec-md-col-25">
            <div className="otherservicebox text-center">
              <div className="otherserviceicon">
                <LazyLoadingImages
                  src="/img/icon_end_to_end_support.png"
                  alt="End-to-End Support"
                />
              </div>

              <div className="otherservicecntnt">
                <p>End-to-End Support</p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-exploremore text-center">
          <a href="/contactus" className="btn">
            Explore More
          </a>
        </div>
      </div>
    </section>
  );
});

export default Journeyservicesec;
