import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listallCountry } from "../../actions/countryActions";

const CountryListSelect = React.memo(function CountryListSelect() {
  const dispatch = useDispatch();

  const { loading, error, allCountryData } = useSelector(
    (state) => state.allCountryList
  );

  const getCountryList = () => {
    dispatch(listallCountry());
  };

  useEffect(() => {
    if (!allCountryData) {
      getCountryList();
    }
  }, [dispatch, allCountryData]);

  return (
    <>
      {allCountryData ? (
        allCountryData.map((lis) => (
          <option key={lis.Id} value={lis.Id}>
            {lis.Country}
          </option>
        ))
      ) : (
        <option>Loading...</option>
      )}
    </>
  );
});

export default CountryListSelect;
