import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Faqs from "./Faqs";

const Eligibilitystuvisa = React.memo(function () {
  return (
    <>
      <section className="eligibility-stu-visa-sec compadding">
        <div className="container">
          <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Eligibility</span> for Australian
              Student Visa
            </h2>
          </div>

          <div className="flex-box eligibility-stu-visa-wrapper">
            <div className="eligibility-stu-visa-box">
              <div className="eligibility-stu-image">
                <LazyLoadingImages
                  src="/img/eligibility-health.jpg"
                  alt="student"
                />
              </div>

              <div className="eligibility-stu-content">
                <h4>Health</h4>

                <p>
                  To gain a student visa for Australia it is essential that the
                  applicant presents a documented proof of his physical good
                  health. The applicant can present a certificate in this accord
                  issued by any of the impanelled doctors.
                </p>
              </div>
            </div>

            <div className="eligibility-stu-visa-box">
              <div className="eligibility-stu-image">
                <LazyLoadingImages
                  src="/img/character-certificate.jpg"
                  alt="student"
                />
              </div>

              <div className="eligibility-stu-content">
                <h4>Character Certificate</h4>

                <p>
                  This certificate gives proof to the fact that your intention
                  of entering into the country is for educational purposes only.
                  The certificate is a documented proof of your good character
                  which shows that the applicant has no criminal charges against
                  him/her.
                </p>
              </div>
            </div>

            <div className="eligibility-stu-visa-box">
              <div className="eligibility-stu-image">
                <LazyLoadingImages src="/img/learn-group.jpg" alt="student" />
              </div>

              <div className="eligibility-stu-content">
                <h4>English</h4>

                <p>
                  Since Australia is an English speaking nation it is necessary
                  for the applicant to have a firm command on their English
                  reading writing skills. There are tests that the application
                  must pass to get their certificate in this respect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faqs />
    </>
  );
});

export default Eligibilitystuvisa;
