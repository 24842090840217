import React from "react";

const HealthInsurance = React.memo(function () {
  return (
    <section
      className="health-insurance bg-style"
      style={{
        backgroundImage: `url('/img/book-library-with-open-textbook.png')`,
      }}
    >
      <div className="container">
        <div className="text-center headingbox mb-zero counter-content letter-normal">
          <h6>
            <span className="themecolor">/ </span>
          </h6>

          <h2>
            Financial Aid & Health Insurance (For
            <br /> Australia: OSHC, OVHC)
          </h2>

          <p>
            Whether one is going to foreign destination as a student or working
            professional or tourist, most of the time health insurance is a
            must. As part of international travel, this is one mandatory
            documentation one should have for most of the countries. Many
            institutions even list out the health certificates as part of the
            admission formalities for students. In some cases, financial aid may
            also be required.
          </p>

          <p>
            If you want to opt for the same, Search Education will assist you in
            finding suitable opportunities until you find the one. We will help
            you with all the support for documentation of both i.e. Financial
            Aid and Health Insurance. Countries like Australia has mandatory
            requirements for health insurance under two categories OSHC -
            Overseas Student Health Cover & OVHC - Overseas Visa Health Cover
            for both students and people who travel for work or visit. Our
            alliance with BUPA with all possible options is available for you,
            we understand you need more focus on other more important things.
          </p>

          <a href="/consult_a_counselor" className="btn moreless-button">
            Explore
          </a>
        </div>
      </div>
    </section>
  );
});

export default HealthInsurance;
