import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
const Applyingstuvisa = React.memo(function () {
  return (
    <>
      <section className="applying-stu-visa-sec compadding">
        <div className="container">
          <div className="headingbox applying-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Process of</span> Application
            </h2>

            <p>The entire process of availing this visa is described below</p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/choose-study-abroad.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Check Eligibility for subclass - 491</h4>

                <p>
                  To begin with, go through the eligibility criteria above and
                  check if you are qualified enough to get into the Skilled
                  Migration Program by undergoing the Points Test.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/enroll-courses.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Submit Expression of Interest</h4>

                <p>
                  Next, you need to submit your Expression of Interest (EOI)
                  document. This visa is granted on a points-based test. The EOI
                  document will hold your points for various things such as age,
                  educational qualification, proficiency in the English
                  language, etc. The applicant must score a minimum 65 points.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/apply-student-visa.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Wait your visa invitation</h4>

                <p>
                  However, it must be noted that just by merely achieving the 65
                  points does not guarantee that the applicant will be invited
                  to apply for the visa. The process works on a points-based
                  system, the higher the points an applicant gets, the better
                  are the chances of them getting their invitation to apply for
                  the visa.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/immigration-process-visa.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Apply for VISA subclass - 189</h4>

                <p>
                  After the applicant has submitted the application for
                  nomination the applicant will receive the invitation to apply
                  for their visa. The immigration department issues fresh
                  invites every month in varied proportions for aspirants to
                  apply for the Skilled Independent Visa.{" "}
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/ready-chekout.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Get ready to fly to Australia</h4>

                <p>
                  Next, the applicant can initiate the visa application process
                  via the immigration portal.
                </p>
              </div>
            </div>

            <div className="headingbox letter-normal process-application-description mb-0">
              <p>
                The applicant has a period of 60 days to apply for their visa
                after receiving the invitation. At the time of applying for the
                visa, the applicant must submit all the required documents such
                as work experience, education, etc.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion">
                Who can apply for the Skilled Independent Visa – Subclass 189?
                <span className="faq-icon"></span>
              </button>

              <div className="panel">
                <p>
                  Yes, the applicant must be nominated by any of their relatives
                  residing in a regional area of the country. In absence of due
                  nomination, the visa will not be granted.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion">
                How can an applicant apply for the Skilled Independent Visa –
                Subclass 189?
                <span className="faq-icon"></span>
              </button>

              <div className="panel">
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion">
                Is this a permanent visa type?
                <span className="faq-icon"></span>
              </button>

              <div className="panel">
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion">
                How can a person get extra points for obtaining their Skilled
                Independent Visa – Subclass 189?
                <span className="faq-icon"></span>
              </button>

              <div className="panel">
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Applyingstuvisa;
