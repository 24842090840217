import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../actions/contactActions";
import { contactJson } from "../../models/userModel";
import { createSubscribe } from "../../actions/otherActions";

const Banner = React.memo(function Banner() {
  const dispatch = useDispatch();
  const [contactRec, setContactRec] = useState(contactJson);
  const [ierror, setIerror] = useState("");

  const [subscribe, setSubscribe] = useState(false);

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, success, error } = contactCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const handleRec = (e) => {
    setContactRec({
      ...contactRec,
      [e.target.name]: e.target.value,
      Contact_Platform: "wb",
      Link: window.location.href,
      Comments: `Country: ${country?.country_name}`,
    });
  };

  const handleSubmit = () => {
    var errorm = "";
    if (contactRec.full_name === "" || contactRec.full_name === undefined)
      errorm = "Full name cannot be Blank!";
    if (contactRec.Phone_No === "" || contactRec.Phone_No === undefined)
      errorm = "Phone Number cannot be Blank!";
    if (contactRec.Email_Address === "" || contactRec.full_name === undefined)
      errorm = "Email Address cannot be Blank!";

    if (errorm === "") {
      setIerror("");
      if (subscribe) {
        dispatch(createContact(contactRec));
        dispatch(createSubscribe(contactRec.Email_Address));
      } else {
        dispatch(createContact(contactRec));
      }
    } else {
      setIerror(errorm);
    }
  };

  const checkHandler = () => {
    setSubscribe(!subscribe);
  };

  useEffect(() => {}, [dispatch, success]);
  return (
    <section
      className="efficient-courses-banner innrbanner innr-student-banner-sec"
      style={{
        backgroundImage: `url('/img/medium-shot-people.png')`,
        backgroundColor: `#09091E`,
      }}
    >
      <div className="container">
        <div className="innrbannercntnt">
          <div className="headingbox letter-normal text-white">
            <h4 className="themecolor">Counselling</h4>

            <h1>Consult A Counselor </h1>

            <p>
              Home to the ‘English’ language and to the world's oldest
              universities!
            </p>
          </div>

          <div className="efficient-courses-forms forex-applynow-form">
            <form className="flex-box align-center" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  name="full_name"
                  value={contactRec.full_name}
                  onChange={(e) => handleRec(e)}
                  placeholder="Enter Name"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="phnumber"
                  name="Phone_No"
                  value={contactRec.Phone_No}
                  onChange={(e) => handleRec(e)}
                  placeholder="Enter Number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="Email_Address"
                  value={contactRec.Email_Address}
                  onChange={(e) => handleRec(e)}
                  placeholder="Enter Email"
                />
              </div>

              <div className="form-group checkbox-box">
                <input
                  type="checkbox"
                  id="education-news"
                  className="single-checkbox"
                  name="education-news"
                  checked={subscribe}
                  onChange={checkHandler}
                />

                <label for="education-news">
                  <span>
                    I want to receive overseas education news & updates
                  </span>
                </label>
              </div>

              <div className="form-group apply-now">
                {!loading ? (
                  <button type="button" className="btn" onClick={handleSubmit}>
                    Send Enquire
                  </button>
                ) : (
                  <button type="button" className="btn">
                    Loading ...
                  </button>
                )}
                <p style={{ color: "white", backgroundColor: "green" }}>
                  {success}
                </p>
                <p style={{ color: "white", backgroundColor: "red" }}>
                  {error}
                  {ierror}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Banner;
