import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Internationalvisa = React.memo(function Internationalvisa() {
  return (
    <section className="lightgrey inernational-visa compadding">
      <div className="container">
        <div className="inernational-visa-headingbox headingbox text-center com-headingbox letter-normal">
          <h2>
            <span className="themecolor">Types Of International</span> Visa
          </h2>

          <p>
            There are a set of documents that are required for each type of
            visa. Follow on below to know in detail about every visa type
          </p>
        </div>

        <div className="inernational-visa-box-wrapper flex-box">
          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages src="/img/stud-visa.png" alt="student-image" />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>Student Visa</h3>

              <p>
                Australia is one of the most sought after foreign nation for
                higher education. The Australian course curriculum attracts
                millions of students every year from around the globe.
              </p>
            </div>
          </div>

          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages
                src="/img/general-visa.png"
                alt="student-image"
              />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>General Skilled Migration</h3>

              <p>
                A lot of Australian companies provide sponsorship to nominated
                participants if they are unable to get Australian citizenship or
                permanent resident stature. The two most common types of
                employer sponsored visas are as follows.
              </p>
            </div>
          </div>

          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages
                src="/img/employer-visa.png"
                alt="student-image"
              />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>Employer Sponsored Visa</h3>

              <p>
                General skilled migration-These Migration Programmes are for
                those foreign nationals who are qualified and experienced enough
                to migrate to Australia. General Skilled Migration is by far the
                most popular type of migration plan to Australia.
              </p>
            </div>
          </div>

          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages
                src="/img/graduated-visa.png"
                alt="student-image"
              />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>Temporary Graduate Visa</h3>

              <p>
                This type of visa is specially for those graduates who have
                recently completed their university or vocational degree and are
                pursuing to stay in Australia and work here post their studies.
              </p>
            </div>
          </div>

          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages
                src="/img/partner-visa.png"
                alt="student-image"
              />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>Partner Visa</h3>

              <p>
                This visa lets the partner to live, work, and study in
                Australia. Apart from this the visa holder is also entitled to
                several other perks such as receiving access to Medicare.
              </p>
            </div>
          </div>

          <div className="inernational-visa-box">
            <div className="inernational-visa-image">
              <LazyLoadingImages
                src="/img/tourist-visa.png"
                alt="student-image"
              />
            </div>

            <div className="inernational-visa-content letter-normal">
              <h3>Tourist Visa</h3>

              <p>
                The smallest continent of the planet is home to several exciting
                activities, adventurous sports activities and delightful weather
                throughout the year. A person can enjoy all of this using their
                Australian tourist visa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Internationalvisa;
