import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useState } from "react";

const Applyingstuvisasec = React.memo(function Applyingstuvisasec() {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };

  return (
    <>
      <section className="applying-stu-visa-sec compadding">
        <div className="container">
          <div className="headingbox applying-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Process of</span> Application
            </h2>

            <p>The entire process of availing this visa is described below</p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/choose-study-abroad.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Check Eligibility for subclass - 491</h4>

                <p>
                  To begin with, check whether the applicant is eligible to
                  apply for this visa. For this, the applicant must have an
                  eligible family member who is living in the regional area of
                  the country. Secure at least the minimum passing marks in the
                  skill assessment test in any of the occupations mentioned in
                  the MLTSSL.{" "}
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/enroll-courses.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Submit Expression of Interest</h4>

                <p>
                  Next, you need to submit your Expression of Interest (EOI)
                  document. This visa is granted on a points-based test. The EOI
                  document will give you points on various parameters. The
                  applicant must score a minimum of 90-95 points.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/apply-student-visa.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Apply for State Nomination</h4>

                <p>
                  Apart from these requirements, the applicant must be nominated
                  by a relative residing in the regional area of the country.{" "}
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/immigration-process-visa.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Apply for VISA subclass - 491</h4>

                <p>
                  After doing this process the applicant will receive their
                  invitation to apply for the visa.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages
                  src="/img/ready-chekout.png"
                  alt="student-image"
                />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Get ready to fly to Australia</h4>

                <p>
                  Now, the applicant can initiate the visa application process
                  via the immigration portal.
                </p>
              </div>
            </div>

            <div className="headingbox letter-normal process-application-description mb-0">
              <p>
                At the time of applying for the visa, the applicant must submit
                all the required documents such as work experience, education,
                etc.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                Does a person must have an invitation while applying for the
                subclass 491 Visa?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  Yes, the applicant must be nominated by any of their relatives
                  residing in a regional area of the country. In absence of due
                  nomination, the visa will not be granted.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                If the applicant is older than 45 years will they be eligible to
                apply for the 491 Visa?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                How many points are required to avail the 491 Family Sponsored
                Visa?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Yes, you are free to leave and re-enter Australia as many
                  times as you want throughout the validity period of your visa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Applyingstuvisasec;
