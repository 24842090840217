import React from "react";
import EnquiryForm from "../IeltsTrainingCoaching/EnquiryForm";

const Takepte = React.memo(function () {
  return (
    <>
      <section
        className="efficient-courses-banner innrbanner innr-student-banner-sec"
        style={{
          backgroundImage: `url('/img/smiling-students-talking-during-lesson.png')`,
          backgroundColor: "#09091E",
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox letter-normal text-white">
              <h1>PTE Training & Coaching</h1>

              <p>Best PTE Preparation Platform Designed So Far.</p>
            </div>

            <div className="efficient-courses-forms forex-applynow-form">
              <EnquiryForm />
            </div>
          </div>
        </div>
      </section>
      <section className="take-pte-sec compadding lightgrey">
        <div className="container">
          <div className="comm-content-wrapper-width">
            <div className="headingbox letter-normal">
              <h2>
                Why <span className="themecolor">Take PTE?</span>
              </h2>
              <p>
                The PTE Preparation courses in this specialization are intended
                for those seeking to boost their scores on the PTE exam. Whether
                you have taken the test before, these courses will help you to
                become more familiar with the various types of questions on the
                test.
              </p>
            </div>
            <div className="take-pte-list">
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    PTE is recognised by more than 11,000 education and training
                    providers worldwide.
                  </h5>
                </div>
              </div>
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    Take PTE to migrate to Australia, Canada, New Zealand and
                    the UK.
                  </h5>
                </div>
              </div>
              <div className="take-pte-list-item flex-box align-center">
                <div className="take-pte-list-circle"></div>
                <div className="take-pte-list-content">
                  <h5>
                    Organisations around the world rely on PTE to help them
                    select the right people.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Takepte;
