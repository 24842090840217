import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const LazyLoadingImages = ({ alt, src }) => (
  <LazyLoadImage
    alt={alt}
    // height={props.height}
    src={src} // use normal <img> attributes as props
    // width={props.width}
    // <span>{props.caption}</span>
  />
);
