export const UNIVERSITY_LIST_REQUEST='UNIVERSITY_LIST_REQUEST'
export const UNIVERSITY_LIST_SUCCESS='UNIVERSITY_LIST_SUCCESS'
export const UNIVERSITY_LIST_FAIL='UNIVERSITY_LIST_FAIL'

export const UNIVERSITY_POPULAR_LIST_REQUEST='UNIVERSITY_POPULAR_LIST_REQUEST'
export const UNIVERSITY_POPULAR_LIST_SUCCESS='UNIVERSITY_POPULAR_LIST_SUCCESS'
export const UNIVERSITY_POPULAR_LIST_FAIL='UNIVERSITY_POPULAR_LIST_FAIL'

export const UNIVERSITY_WEB_DETAILS_REQUEST='UNIVERSITY_WEB_DETAILS_REQUEST'
export const UNIVERSITY_WEB_DETAILS_SUCCESS='UNIVERSITY_WEB_DETAILS_SUCCESS'
export const UNIVERSITY_WEB_DETAILS_FAIL='UNIVERSITY_WEB_DETAILS_FAIL'