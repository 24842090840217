import {
  EDUCATION_STATUS_LIST_REQUEST,
  EDUCATION_STATUS_LIST_SUCCESS,
  EDUCATION_STATUS_LIST_FAIL,
} from "../constants/educationStatusConstants";
import api2 from "../core/api2";

export const listEducationalStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_STATUS_LIST_REQUEST,
    });
    const { data } = await api2.get(`/educationalStatus`);
    const res = data.Result;
    if (typeof res === "string") {
      dispatch({
        type: EDUCATION_STATUS_LIST_FAIL,
        payload: res,
      });
    } else {
      dispatch({
        type: EDUCATION_STATUS_LIST_SUCCESS,
        payload: res,
      });
    }
  } catch (ex) {
    dispatch({
      type: EDUCATION_STATUS_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
