import React, { useEffect } from "react";
import LeftPanel from "../Common/leftPanel";
import OngoingApplications from "./OngoingApplications";
import FeaturedColleges from "./FeaturedColleges";
import ShortlistedCourse from "./ShortlistedCourse";
import Events from "./Events";
import TrendingCourse from "./TrendingCourse";
// import BachelorSlide from "./BachelorSlide";
import RecommendedCourse from "./RecommendedCourse";
import CounsellorSection from "./CounsellorSection";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CounsellorSec from "./CounsellorSec";
import SearchSection from "../Common/SearchSection";

function Index(props) {
  // const progressAnimate = () => {
  //   var getPercent = $(".progress-wrap").data("progress-percent") / 100;
  //   var getProgressWrapWidth = $(".progress-wrap").width();
  //   var progressTotal = getPercent * getProgressWrapWidth;
  //   var animationLength = 2500;
  //   $(".progress-bar").stop().animate(
  //     {
  //       left: progressTotal,
  //     },
  //     animationLength
  //   );
  // };

  useEffect(() => {
    // progressAnimate();
  }, []);
  return (
    <LeftPanel>
      <div className="sec-lg-col-60 dashboard-rightbox common-dashboard-search-box">
        <SearchSection />
        <OngoingApplications />
        <div className="featured-colleges featuredbg-style">
          <div className="headingbox">
            <h2>Featured Colleges</h2>
          </div>
          <div className="featured-colleges-main">
            <FeaturedColleges />
            <div className="featuredview-all text-center">
              {/* <a href="/" className="btn text-center">
                      View All
                    </a> */}
            </div>
          </div>
        </div>
        <ShortlistedCourse />

        <div className="trained-experts featuredbg-style">
          <div className="headingbox">
            <h2>Get Trained By Experts</h2>
          </div>
          <div className="sec-row">
            <div className="sec-md-col-33 trainedexpertscol">
              <div className="trainedexpertsbox">
                <div className="trainedexpertsicon">
                  <img src="/img/ielitsicon.png" alt="ieltsicon" />
                </div>
                <div className="trainedexpertsiconcontent">
                  <h5>
                    IELTS
                    <a href="/ielts_training">
                      <img src="/img/expert-arrow.png" alt="expert arrow" />
                    </a>
                  </h5>
                  <p>Get trained and score high on IELTS proficiency test.</p>
                </div>
              </div>
            </div>
            <div className="sec-md-col-33 trainedexpertscol">
              <div className="trainedexpertsbox">
                <div className="trainedexpertsicon">
                  <img src="/img/pteicon.png" alt="pteicon" />
                </div>
                <div className="trainedexpertsiconcontent">
                  <h5>
                    PTE
                    <a href="/pte_training">
                      <img src="/img/expert-arrow.png" alt="expert arrow" />
                    </a>
                  </h5>
                  <p>In house professionals to give best of teaching</p>
                </div>
              </div>
            </div>
            <div className="sec-md-col-33 trainedexpertscol">
              <div className="trainedexpertsbox">
                <div className="trainedexpertsicon">
                  <img src="/img/NAATIcon.png" alt="NAATIcon" />
                </div>
                <div className="trainedexpertsiconcontent">
                  <h5>
                    NAATI CCL
                    <a href="/consult_a_counsellor">
                      <img src="/img/expert-arrow.png" alt="Expert Arrow" />
                    </a>
                  </h5>
                  <p>Faculty expert in increasing Australian PR points</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="trending-courses featuredbg-style">
          <div className="headingbox mb-tzero">
            <h2>Trending Courses</h2>
          </div>
          <div className="shortlistedcours-main trending-courses-main">
            <TrendingCourse />
            <div className="featuredview-all text-center">
              <a href="/courses" className="btn text-center">
                View All
              </a>
            </div>
          </div>
        </div>
        {/* <BachelorSlide /> */}
        <div className="other-services featuredbg-style">
          <div className="headingbox">
            <h2>Other Services</h2>
          </div>
          <div className="sec-row">
            <div className="sec-md-col-33 otherservicescol">
              <a href="/get_visa" style={{ textDecoration: "none" }}>
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/visa-guidence.png" alt="Visa Guidance" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Visa Guidence</h5>
                  </div>
                  <a href="/get_visa" className="view-details" tabindex="0">
                    <img src="/img/arrow-white.png" alt="Arrow White" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a
                href="/consult_a_counsellor"
                style={{ textDecoration: "none" }}
              >
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/counselling.png" alt="Counselling" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Counselling By Experts</h5>
                  </div>
                  <a
                    href="/consult_a_counsellor"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="Arrow White" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a href="/consult_a_counselor" style={{ textDecoration: "none" }}>
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img
                      src="/img/health-insurance.png"
                      alt="Health Insurance"
                    />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Health Insurance</h5>
                  </div>
                  <a
                    href="/consult_a_counselor"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="Arrow White" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a
                href="/consult_a_counsellor"
                style={{ textDecoration: "none" }}
              >
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/scholarship.png" alt="Scholarship" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Scholarship Assistance</h5>
                  </div>
                  <a
                    href="/consult_a_counsellor"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="arrow white" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a href="/housing_partner" style={{ textDecoration: "none" }}>
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/arrangement.png" alt="Arrangement" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Stay Arrangement</h5>
                  </div>
                  <a
                    href="/housing_partner"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="arrow white" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a
                href="/hdfc_credila_edu_loan"
                style={{ textDecoration: "none" }}
              >
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/student-loans.png" alt="Student Loans" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Student Loans</h5>
                  </div>
                  <a
                    href="/hdfc_credila_edu_loan"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="arrow white" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a href="/consult_a_counselor" style={{ textDecoration: "none" }}>
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img
                      src="/img/insurance-service.png"
                      alt="Insurance Service"
                    />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>Insurance Service</h5>
                  </div>
                  <a
                    href="/consult_a_counselor"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="arrow-white" />
                  </a>
                </div>
              </a>
            </div>
            <div className="sec-md-col-33 otherservicescol">
              <a href="/consult_a_counselor" style={{ textDecoration: "none" }}>
                <div className="trainedexpertsbox">
                  <div className="trainedexpertsicon">
                    <img src="/img/sop-lor.png" alt="sop lor" />
                  </div>
                  <div className="trainedexpertsbox-content">
                    <h5>SOP & LOR Writing</h5>
                  </div>
                  <a
                    href="/consult_a_counselor"
                    className="view-details"
                    tabindex="0"
                  >
                    <img src="/img/arrow-white.png" alt="arrow white" />
                  </a>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="recommended-courses featuredbg-style">
          <div className="headingbox mb-tzero">
            <h2>Recommended Courses For You</h2>
          </div>
          <div className="recommended-courses-main">
            <RecommendedCourse />
            <div className="featuredview-all text-center">
              <a href="/" className="btn text-center">
                View All
              </a>
            </div>
          </div>
        </div>
        <div className="featuredabout featuredbg-style">
          <div className="headingbox">
            <h2>About Us</h2>
            <p>
              Search Education engages with students through a one on one
              approach both offline and online - 365 days a year during the
              entire 'Student Recruitment Cycle'. Established seven years ago,
              we believe in making every student's dream to 'Study Abroad' a
              reality.
            </p>
          </div>
          <div className="sec-row featuredabout-main">
            <div className="sec-md-col-25 featured-aboutcol">
              <div className="featured-about-box">
                <h3>2,000 +</h3>
                <h6>
                  <strong>Helped </strong>Students
                </h6>
              </div>
            </div>
            <div className="sec-md-col-25 featured-aboutcol">
              <div className="featured-about-box">
                <h3>19,000 +</h3>
                <h6>
                  <strong>Success </strong>Stories
                </h6>
              </div>
            </div>
            <div className="sec-md-col-25 featured-aboutcol">
              <div className="featured-about-box">
                <h3>8,000 +</h3>
                <h6>
                  <strong>Educated </strong>Teacher
                </h6>
              </div>
            </div>
            <div className="sec-md-col-25 featured-aboutcol">
              <div className="featured-about-box">
                <h3>99.99 %</h3>
                <h6>
                  <strong>Acceptance </strong>rate
                </h6>
              </div>
            </div>
          </div>
          <div className="featuredview-all text-center">
            <a href="/about_us" className="btn text-center">
              View
            </a>
          </div>
        </div>
      </div>
      <CounsellorSec />
    </LeftPanel>
  );
}

export default Index;
