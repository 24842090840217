import React from "react";
import Slider from "react-slick";

const SearchCountry = React.memo(function () {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return (
    <>
      <section className="searchcountrysec compadding lightgrey">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Explore</span> Other Cities
            </h2>
          </div>
          <div className="searchcountrysliderpro">
            <Slider className="searchcountryslider" {...options}>
              <div className="searchcountryslide">
                <a href="/study-in-australia">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country.jpg')` }}
                    ></div>
                    <p>
                      Study in <strong>Australia</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div className="searchcountryslide">
                <a href="/student-in-canada">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country2.jpg')` }}
                    ></div>
                    <p>
                      Study in <strong>Canada</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div className="searchcountryslide">
                <a href="/study-in-uk">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country3.jpg')` }}
                    ></div>
                    <p>
                      Study in <strong>United Kingdom</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div className="searchcountryslide">
                <a href="/study-in-ireland">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country4.jpg')` }}
                    ></div>
                    <p>
                      Study in <strong>Ireland</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div className="searchcountryslide">
                <a href="/study-in-newzealand">
                  <div className="countrylistbox">
                    <div
                      className="countryimage"
                      style={{ backgroundImage: `url('/img/country5.jpg')` }}
                    ></div>
                    <p>
                      Study in <strong>New Zealand</strong>
                    </p>
                  </div>
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
});

export default SearchCountry;
