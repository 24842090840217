import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listStudyLevel } from "../../actions/studyLevelActions";

const StudyLevelSelect = React.memo(function StudyLevelSelect(props) {
  const dispatch = useDispatch();

  const studyLevelList = useSelector((state) => state.studyLevelList);
  const { loading, error, studyLevelData } = studyLevelList;

  const getStudyLevelList = () => {
    dispatch(listStudyLevel());
  };

  useEffect(() => {
    if (!studyLevelData) {
      getStudyLevelList();
    }
  }, [dispatch, studyLevelData]);

  return (
    <>
      {studyLevelData ? (
        studyLevelData.map((lis) => (
          <option key={lis.Id} value={lis.Id}>
            {lis.Study_Level}
          </option>
          // <li className="form-group" key={lis.Id}>
          //   <input type="checkbox" id={lis.Id} />

          //   <label for="studylevel1">{lis.Study_Level}</label>
          // </li>
        ))
      ) : (
        <option>Loading...</option>
      )}
    </>
  );
});

export default StudyLevelSelect;
