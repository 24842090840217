import React from 'react'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import Abouthdfccredila from './AboutHdfcCredila'
import Educationhigherstudies from './EducationHigherStudies'
import Educationloan from './education-loan.js'
import Eligibilityeduloan from './EligibilityEduLoan'
import Hdfcloanfeatures from './HdfcLoanFeatures'
import Otherservices from './Otherservices'


export default function index() {
  return (
    <>
      <Header />
      <Educationloan />
      <Abouthdfccredila />
      <Hdfcloanfeatures />
      <Eligibilityeduloan />
      <Otherservices />
      <Educationhigherstudies />
      <Footer />
    </>
  )
}
