import React from 'react'

export default function Dashboardallcourses() {
  return (
    <div>
        <section className="dashboard dashboard-all-courses">
        <div className="container">
          <div className="sec-row">
            <div className="toggle-sidebar-btn-wrapper">
              <div className="sidebar-btn">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            
            <div className="sec-lg-col-75 dashboard-rightbox dashboard-all-courses-rightbox common-dashboard-search-box sec-col-83">
              <div className="messages-search-box-wrapper common-searchbox-style flex-box align-center">
                <div className="coursesearch mb-tzero">
                  <form action="" className="searchofcourses">
                    <input type="text" className="form-control" placeholder="Search for Courses...." />
                    <button type="submit" name="coursesarchsubmit" className="btn">
                      <img src="/img/searchicon.png" alt="Search Icon" />
                    </button>
                  </form>
                </div>
                <div className="notification-bellbox">
                  <div className="notification-bell" style={{ backgroundImage: `url('/img/bell.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
                    <span className="notification"><img src="/img/notification-circle.png" alt="dashboard" /></span>
                  </div>
                </div>
              </div>
              <div className="featuredbg-style dashboard-allcourses">
                <div className="top-cntnr flex-box align-center">
                  <div className="title-grp">
                    <h5>
                      Showing
                      <strong><span className="availblecourse">10</span> out of
                        <span className="totalcourse">200</span></strong>
                      Courses Available
                    </h5>
                  </div>
                  <div className="filters-sortby-grp">
                    <button className="filters-itm">
                      <img src="/img/filter-white.png" alt="Filter Icon" />
                      Filter
                    </button>
                    <div className="sortby-itm">
                      <span className="filer-select-arrow"><img src="/img/select-bottom-arrow.png" alt="dashboard" /></span>
                      <span className="lbl">Sort By:</span>
                      <select className="sortbybtn-select">
                        <option value="Most Popular">Most Popular</option>
                        <option>Price - High to Low</option>
                        <option>Price - Low to High</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo2.png" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo3.png" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo4.jpg" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo2.png" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo3.png" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo4.jpg" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo3.png" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-courses-box-wrapper">
                  <div className="all-courses-box-inner flex-box">
                    <div className="all-coursesimage">
                      <img src="/img/mstpopularuniversity.jpg" alt="dashboard" />
                    </div>
                    <div className="all-coursescontent">
                      <div className="all-courseslogoimage">
                        <img src="/img/universitylogo4.jpg" alt="dashboard" />
                      </div>
                      <div className="all-coursescontentinner flex-box">
                        <div className="all-coursescontenleft-box">
                          <p className="location">
                            <img src="/img/locationicon.png" alt="Map" /><strong>Ca' Foscari
                              University of Venice,</strong> Italy
                          </p>
                          <h5>Marine and Lacustrine Science and Management - MSc</h5>
                        </div>
                        <div className="all-coursescontenright-box flex-box">
                          <a href="/" className="course-cta ad-compare">Add to compare</a>
                          <a href="/" className="course-cta view-course">View Course</a>
                          <a href="/" className="heart">
                            <img src="/img/trase.png" alt="dashboard" />
                          </a>
                        </div>
                        <div className="durationfeesem flex-box">
                          <div className="colhalf text-center">
                            <p><span>Program type</span>Bachelor's</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Course fee Per Sem</span>1,374 USD</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Enrollment Last date </span>22-Dec-2022</p>
                          </div>
                          <div className="colhalf text-center">
                            <p><span>Duration</span>3 years</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination common-pagination-style">
                <a href="/" className="prev"><img src="/img/grey-arrow.png" alt="dashboard" /></a>
                <a href="/" className="active">1</a>
                <span>of</span>
                <a href="/">24</a>
                <a href="/" className="next"><img src="/img/orange-arrow.png" alt="dashboard" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
