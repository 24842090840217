import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";

const Studyincountry = React.memo(function () {
  return (
    <>
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/hobart.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Study In Hobart</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>
            </div>
            <BannerForm />
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding study-in-newzeland">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/jewel-alexandra.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Hobart</h2>
              <p>
                Hobart is Australia’s second oldest capital city after Sydney
                and is in the heart of Tasmania state. This is a sparsely
                populated city due to the tough life and wilderness. It was
                earlier occupied for around 3500 years by the Mouhaneener tribe.
                It has a mild and temperate climate though which is a welcome
                relief. The people who are residents of this place call
                themselves the Tasmanians. Though an old colony, Hobart is as
                small as Darwin due to the tough life conditions. And hence
                Hobart has grown moderately as compared to the bigger cities of
                Australia. It’s tough life conditions made it a perfect place as
                a penal colony.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/boats-anchor-1.jpg" alt="student" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>Tourist Hub</h2>
              <p>
                The European settlers and Britishers used it to house their
                offenders earlier. This portrays Hobart's tough characteristic
                gene pool. With an ideal deep port and river Derwent, the
                residents take it to whaling and fishing in a major way which is
                still the mainstay of Hobart city. The penal colony residents
                were not as greedy as the settlers. And were always
                accommodating towards the local tribesmen. But, diseases brought
                by them wiped out most of the aboriginal population. Visitors
                who like to be tested when the going gets tough will find Hobart
                like Darwin city an amazing challenge to do.
              </p>

              <p>
                Hobart is a major tourist hub on the sea lanes. Because being in
                a river’s estuary, Hobart’s harbor gets port calls of around
                forty cruise and ocean liners a day from all parts of the world
                making it . It’s well preserved Georgian and Victorian
                architecture, it’s aboriginal past are major tourist
                attractions. Hobart's economy, lifestyle, and financial markets
                are all sea-faring and robust. These factors give the city and
                Tasmania self-sustenance and stable markets. Unlike the big
                cities of Australia Hobart like Darwin are at the bottom of the
                size list and therefore are easier on expenses and livelier on
                tougher experiences. These distinct factors make Hobart
                prominent for international students to study here.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Studyincountry;
