import {
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAIL,
  All_COUNTRY_LIST_REQUEST,
  All_COUNTRY_LIST_SUCCESS,
  All_COUNTRY_LIST_FAIL,
} from "../constants/countryConstants";

export const countryListReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_LIST_REQUEST:
      return { loading: true };
    case COUNTRY_LIST_SUCCESS:
      return { loading: false, countryData: action.payload };
    case COUNTRY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allCountryListReducer = (state = {}, action) => {
  switch (action.type) {
    case All_COUNTRY_LIST_REQUEST:
      return { loading: true };
    case All_COUNTRY_LIST_SUCCESS:
      return { loading: false, allCountryData: action.payload };
    case All_COUNTRY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
