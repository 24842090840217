import {
  EVENT_DETAIL_FAIL,
  EVENT_DETAIL_REQUEST,
  EVENT_DETAIL_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
} from "../constants/eventConstants";
import api2 from "../core/api2";

export const getEventList = () => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_LIST_REQUEST,
    });
    const { data } = await api2.get(`/event/all`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: EVENT_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: EVENT_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: EVENT_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getEventDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_DETAIL_REQUEST,
    });
    const { data } = await api2.get(`/event/${id}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: EVENT_DETAIL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: EVENT_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: EVENT_DETAIL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
