import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Supportedcur = React.memo(function () {
  return (
    <section className="supported-currency compadding lightgrey">
      <div className="container">
        <div className="headingbox text-center com-headingbox letter-normal full-width-925">
          <h2>
            <span className="themecolor">Supported Currency By</span> Forex
            Cards
          </h2>

          <p>The application steps to avail a 482- TSS Visa are as follows</p>
        </div>

        <div className="currencies-content-wrapper flex-box full-width-925">
          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/usd.png" alt="usd-image" />
            </div>

            <h5>usd</h5>
          </div>

          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/gbp.png" alt="gbp-image" />
            </div>

            <h5>GBP</h5>
          </div>

          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/usd.png" alt="usd" />
            </div>

            <h5>CAD</h5>
          </div>

          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/eur.png" alt="eur" />
            </div>

            <h5>EUR</h5>
          </div>

          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/usd.png" alt="usd" />
            </div>

            <h5>AUD</h5>
          </div>

          <div className="currencies-box text-center">
            <div className="currencies-icon">
              <LazyLoadingImages src="/img/usd.png" alt="usd" />
            </div>

            <h5>SGD</h5>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Supportedcur;
