import React, { useEffect, useState } from "react";
import { contactJson } from "../../models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_CREATE_RESET } from "../../constants/contactConstants";
import { createContact } from "../../actions/contactActions";
import { createSubscribe } from "../../actions/otherActions";

const Bookappointment = React.memo(function Bookappointment() {
  const dispatch = useDispatch();
  const [contactRec, setContactRec] = useState(contactJson);
  const [subscribe, setSubscribe] = useState(false);

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, success, error } = contactCreate;

  const subscribeCreate = useSelector((state) => state.subscribeCreate);
  const {
    loading: loadingsubscribe,
    success: successsubscribe,
    error: errorsubscribe,
  } = subscribeCreate;

  const getLocation = useSelector((state) => state.getLocation);
  const { country } = getLocation;

  const checkHandler = () => {
    setSubscribe(!subscribe);
  };

  const handleData = (e) => {
    setContactRec({
      ...contactRec,
      [e.target.name]: e.target.value,
      Link: window.location.href,
      Comments: `Country - ${country?.country_name}, Link: ${window.location.href}`,
    });
  };

  const handleSubmit = () => {
    var errorm = "";
    if (
      contactRec.Email_Address === "" ||
      contactRec.Email_Address === undefined
    )
      errorm += "Incorrect Email Address!";
    if (contactRec.Phone_No === "" || contactRec.Phone_No === undefined)
      errorm += "\nIncorrect Phone Number!";
    if (contactRec.full_name === "" || contactRec.full_name === undefined)
      errorm += "\nIncorrect Name!";

    if (errorm === "") {
      if (subscribe) {
        dispatch(createContact(contactRec));
        dispatch(createSubscribe(contactRec.Email_Address));
      } else {
        dispatch(createContact(contactRec));
      }
    } else {
      alert(errorm);
    }
  };

  useEffect(() => {
    if (success) {
      setContactRec(contactJson);
      setSubscribe(false);
      setTimeout(() => {
        dispatch({
          type: CONTACT_CREATE_RESET,
        });
      }, 7000);
    }
  }, [dispatch, success]);

  return (
    <section className="book-appointment">
      <div className="container">
        <div className="headingbox com-headinsgbox text-center letter-normal">
          <h2>
            <span className="themecolor">Book an</span> appointment
          </h2>

          {/* <p>
            The Customs Law of the country prevents an individual from bringing
            in drugs, steroids, firearms, and weapons into the country.
          </p> */}
        </div>

        <div className="appointment-form">
          <form className="flex-box">
            <div className="form-group">
              <label for="name">Name*</label>
              <input
                type="text"
                id="name"
                className="form-control"
                name="full_name"
                placeholder="Name"
                value={contactRec.full_name}
                onChange={(e) => handleData(e)}
              />
            </div>

            <div className="form-group">
              <label for="email">Email*</label>
              <input
                type="text"
                id="email"
                className="form-control"
                name="Email_Address"
                placeholder="Email"
                value={contactRec.Email_Address}
                onChange={(e) => handleData(e)}
              />
            </div>

            <div className="form-group">
              <label for="number">Number*</label>
              <input
                type="text"
                id="number"
                className="form-control"
                name="Phone_No"
                placeholder="Number"
                value={contactRec.Phone_No}
                onChange={(e) => handleData(e)}
              />
            </div>

            <div className="form-group checkbox-box">
              <input
                type="checkbox"
                id="education-update"
                className="single-checkbox"
                name="education-update"
                checked={subscribe}
                onChange={checkHandler}
              />
              <label for="education-update">
                <span>I want to receive overseas education news & updates</span>
              </label>
            </div>

            <div className="appoint-submit text-center">
              {loading ? (
                <button type="button" className="btn">
                  Loading...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
          <div>
            <p>
              <span className="bg-success">{success}</span>
              <span className="bg-danger">{error}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Bookappointment;
