import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import TrendingCourseSec from "../Home/TrendingCourseSec";

import Coursecomparedetails from "./CourseCompareDetails";
import MetaTags from "../../core/MetaTags";

export default function Index() {
  return (
    <>
      <MetaTags title="Compare Courses" description="" />

      <div className="header-white">
        <Header />
      </div>
      <Coursecomparedetails />
      <TrendingCourseSec />
      <Footer />
    </>
  );
}
