import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import BannerForm from "../Common/BannerForm";
const Aboutemployers = React.memo(function Aboutemployers() {
  return (
    <>
      <section
        className="career-banner innrbanner forex-banner"
        style={{
          backgroundImage: `url('/img/people-working-while-respecting-social-distancing.png')`,
          backgroundColor: `#0B0B28`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Visa</h4>

              <h1>General Skilled Migration</h1>
            </div>
            <BannerForm clw="Yes" />
          </div>
        </div>
      </section>

      <section className="about-employers-sec about-family-sponsored-491 compadding">
        <div className="container">
          <div className="flex-box about-employers-visa-wrapper align-center just-center">
            <div className="about-employers-content headingbox mb-zero full-width-925">
              <h2>
                <span className="themecolor">
                  Family Sponsored Visa
                  <br />
                </span>{" "}
                Subclass 491
              </h2>

              <p>
                Out of all the family-sponsored visas available for Australia,
                subclass 491 is the most widely applied form of visa. The visa
                is issued for a full fledged 5-year term to work in Australia
                and can be later on converted into Permanent Residency.
              </p>

              <p>
                However, since these are issued in a very low number every year
                by the authorities, they are not so easily obtained.
              </p>
            </div>

            <div className="about-employers-image full-width-925 hide-mobile image-auto-height">
              <LazyLoadingImages
                src="/img/woman-with-suitcase.jpg"
                alt="student-image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Aboutemployers;
