import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Amberstudent from "./AmberStudent";
import Bookappointment from "./BookAppointment";
import Freeworld from "./FreeWorld";
import Journeyservicesec from "./JourneyServiceSec";

export default function index() {
  return (
    <>
      <Header />
      <Amberstudent />
      <Journeyservicesec />
      <Freeworld />
      <Bookappointment />
      <Footer />
    </>
  );
}
