import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Termsection from "./Termsection";

export default function index() {
  return (
    <>
      <Header />
      <Termsection />
      <Footer />
    </>
  );
}
