import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Docurequired = React.memo(function () {
  return (
    <section className="docu-required-stu-visa-sec lightgrey compadding">
      <div className="container">
        <div className="headingbox validity-stu-visa-heading common-stu-visa-heading text-center">
          <h2>
            <span className="themecolor">Documents Required</span> Australian
            Student Visa
          </h2>

          <p>
            Here we have provided a general list of essential documents that are
            required to gain Australian student visa.
          </p>
        </div>

        <div className="flex-box required-docu-wrapper">
          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>A Valid Passport</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Non-immigrant Visa Application</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Application fee payment receipt</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Confirmation of Enrolment Form (COE)</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Overseas health insurance receipt (OSHC)</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Recent Digital Photographs of the Applicant</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Transcripts, diplomas, degrees, certificates</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>English Language Test Scores</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5>Statement of Purpose (SOP)</h5>
          </div>

          <div className="required-documents-box">
            <span className="attach-required-documents">
              <LazyLoadingImages src="/img/attach-docs.png" alt="student" />
            </span>

            <h5> Evidence of Financial Capacity</h5>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Docurequired;
