import React from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import TestimonialSec from "../Home/TestimonialSec";
import $ from 'jquery'
import ServicesCountList from "./ServicesCountList";
import HealthInsurance from "./HealthInsurance";
import Faqs from "../Forex/Faqs";

function index() {
  //Faq
  $(".accordion").click(function () {
    if ($(".panel").is(":visible")) {
      $(".panel").slideUp(300);
      $(".faq-icon").removeClass("intro");
    }
    if ($(this).next(".panel").is(":visible")) {
      $(this).next(".panel").slideUp(300);
      $(this).children(".faq-icon").removeClass("intro");
    } else {
      $(this).next(".panel").slideDown(300);
      $(this).children(".faq-icon").addClass("intro");
    }
  });
  return (
    <>
      <Header />
      <section
        className="innrbanner services-banner"
        style={{
          backgroundImage: `url('/img/services-banner.png')`,
        }}
      >
        <div className="container">
          <div className="innrbannercntnt">
            <div className="headingbox">
              <h4 className="themecolor">Foreign Education</h4>

              <h1>Start Your Abroad Journey Today</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-get-visa foreign-education-tour compadding">
        <div className="container">
          <div className="about-get-visa-wrapper foreign-education-wrapper flex-box align-center">
            <div className="about-get-visa-imagebox full-width-925">
              <div className="about-get-image-leftbox">
                <div
                  className="get-visa-image-left bg-style"
                  style={{
                    backgroundImage: `url('/img/welcom-college.jpg')`,
                  }}
                ></div>

                <div
                  className="get-visa-image-bottomleft bg-style"
                  style={{
                    backgroundImage: `url('/img/travel-flag.png')`,
                  }}
                ></div>
              </div>

              <div className="about-get-image-righttbox">
                <div
                  className="get-visa-image-right bg-style"
                  style={{
                    backgroundImage: `url('/img/aero.png')`,
                  }}
                ></div>

                <div
                  className="get-visa-image-bottomright bg-style"
                  style={{
                    backgroundImage: `url('/img/businessman-standing-with-boarding-pass.jpg')`,
                  }}
                ></div>
              </div>
            </div>

            <div className="about-get-visa-details-info headingbox mb-zero full-width-925 letter-normal">
              <h2>
                <span className="themecolor">Turn your Dreams of</span> Foreign
                Education into a Reality
              </h2>

              <p>
                We at Search Education ensures that every student coming to us
                receives a full-fledged end to end service. Our team of experts
                are always available to give the best services whenever needed.
                These services can be subdivided into five broad categories.
                These are Awareness, Interest, Decision, Application and
                Enrollment, and Visa Support.
              </p>

              <p>
                Remember, involvement of parents in every stage of the child's
                decision in 'Study Abroad' education is imperative. We always
                recommend you as parents to be open to your child's aspirations,
                intentions and choices to go abroad for further studies.
                Identify the real purpose of going abroad for education. Whether
                its entirely career oriented or it has some other value
                additions that your child is perceiving. Acknowledge his / her
                decision, & foresee your child's education journey as complete &
                fulfilling with our experts support. This patient call will
                allow him/her to lead a successful career and life ahead.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="services compadding lightgrey">
        <div className="container">
          <div className="headingbox com-headingbox letter-normal text-center mb-zero">
            <h2>
              <span className="smh">SERVICES</span>
              <span className="themecolor">Steps for a Promising</span> Future
              Overseas
            </h2>

            <p>
              Getting selected starts when one has to understand own skill set
              and the type that this student would be best suited for.
            </p>
          </div>

          <div className="services-list-wrapper flex-box">
            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/career-consult.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Career Counselling</h3>

                <p>
                  Getting selected starts when one has to understand own skill
                  set and the type that this student would be best suited for.
                </p>

                <p className="moretext">
                  {" "}
                  Hence, we assist students building their profiles based on
                  their capabilities and interests. And we find an university
                  and a course corresponding to the profile. The entire process
                  begins with reviewing the student’s academic performance. This
                  helps us navigate the possible career types that offers the
                  best chances of admission. We give special emphasis on
                  interacting with the students to understand their interests.
                  This insight is then used to narrow down the choices that may
                  be suitable for her / him. In case the student has some
                  predetermined choices, we will include at the initial
                  profiling stage. Yet, we will share the final decision for the
                  most suitable career option only after careful evaluation and
                  consideration. This would be done over the call or in-person
                  meetings.
                </p>

                <a href="/consult_a_counselor" className="btn moreless-button">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/college-colleagues-studying-together.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>IELTS / PTE Preparations</h3>

                <p>
                  If you are someone who is applying to a foreign university,
                  you would be knowing the basics of the language in question...
                </p>

                <p className="moretext">
                  {" "}
                  Hence, we assist students building their profiles based on
                  their capabilities and interests. And we find an university
                  and a course corresponding to the profile. The entire process
                  begins with reviewing the student’s academic performance. This
                  helps us navigate the possible career types that offers the
                  best chances of admission. We give special emphasis on
                  interacting with the students to understand their interests.
                  This insight is then used to narrow down the choices that may
                  be suitable for her / him. In case the student has some
                  predetermined choices, we will include at the initial
                  profiling stage. Yet, we will share the final decision for the
                  most suitable career option only after careful evaluation and
                  consideration. This would be done over the call or in-person
                  meetings.
                </p>

                <a href="/ielts_training" className="btn moreless-button">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/fidel-fernando.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Country, Program and University Selection</h3>

                <p>
                  The initial questions that we ask any student starts with -
                  which Program, then any university preference followed by
                  country.
                </p>

                <p className="moretext">
                  {" "}
                  Hence, we assist students building their profiles based on
                  their capabilities and interests. And we find an university
                  and a course corresponding to the profile. The entire process
                  begins with reviewing the student’s academic performance. This
                  helps us navigate the possible career types that offers the
                  best chances of admission. We give special emphasis on
                  interacting with the students to understand their interests.
                  This insight is then used to narrow down the choices that may
                  be suitable for her / him. In case the student has some
                  predetermined choices, we will include at the initial
                  profiling stage. Yet, we will share the final decision for the
                  most suitable career option only after careful evaluation and
                  consideration.
                </p>

                <a href="/courses" className="btn moreless-button">
                  Explore
                </a>
              </div>
            </div>

            <div className="services-imagebox full-width-925">
              <div
                className="services-image bg-style"
                style={{
                  backgroundImage: `url('/img/business-job-interview-concept.jpg')`,
                }}
              ></div>

              <div className="services-details headingbox mb-zero letter-normal counter-content">
                <h6>
                  <span className="themecolor">/ </span>
                </h6>

                <h3>Scholarship Application Assistance</h3>

                <p>
                  Scholarships are blessings for those with excellent academic
                  performance in the past.
                </p>

                <p className="moretext">
                  {" "}
                  Hence, we assist students building their profiles based on
                  their capabilities and interests. And we find an university
                  and a course corresponding to the profile. The entire process
                  begins with reviewing the student’s academic performance. This
                  helps us navigate the possible career types that offers the
                  best chances of admission. We give special emphasis on
                  interacting with the students to understand their interests.
                  This insight is then used to narrow down the choices that may
                  be suitable for her / him.
                </p>

                <a href="/consult_a_counselor" className="btn moreless-button">
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HealthInsurance/>

      <ServicesCountList/>

      {/* Testimonial Section */}
      <TestimonialSec />

     <Faqs />
      <Footer />
    </>
  );
}

export default index;
