import React from "react";
import Slider from "react-slick";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { Link } from "react-router-dom";
import { listTrendingCourse } from "../../actions/courseActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { docUrl } from "../../core/urls";

const TrendingCourseSec = React.memo(function ({ cntry }) {
  var dispatch = useDispatch();

  const trendingCourseList = useSelector((state) => state.trendingCourseList);
  const { loading, error, trendingcourses } = trendingCourseList;

  // const { country } = useSelector((state) => state.getLocation);

  const getTrendingCourse = () => {
    if (cntry && cntry == "nepal") {
      dispatch(listTrendingCourse("Nepal"));
    } else {
      dispatch(listTrendingCourse("India"));
    }
  };

  useEffect(() => {
    if (!trendingcourses) {
      getTrendingCourse();
    }
  }, [dispatch, trendingcourses]);

  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  return (
    <section className="trendingcoursesec compadding lightgrey">
      <div className="container">
        <div className="headingbox">
          <h2>
            <span className="smh">Explore Our</span>Trending Courses
          </h2>
        </div>
        <div className="popularuniversitysliderpro">
          <Slider className="popularuniversityslider" {...options}>
            {trendingcourses
              ? trendingcourses.map((trendVal, index) => {
                  return (
                    <div className="popularuniversityslide" key={index + 1}>
                      <div className="popularuniversityinnr equal-height">
                        <div className="popularuniversityimg">
                          <LazyLoadingImages
                            src={`${docUrl}/University/Banner402x300/${trendVal.Banner402x300}`}
                            alt={trendVal.University_Name}
                          />
                        </div>
                        <div className="popularuniversitycntnt">
                          <h5>{trendVal.Course_Name}</h5>
                          <p>
                            {trendVal.Duration} months
                            <span className="sperater">|</span>
                            {trendVal.Study_Level}
                          </p>
                          <a
                            href={`/course/${trendVal.Course_Id}`}
                            className="btn"
                            target="_blank"
                          >
                            Know More
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </Slider>
        </div>
      </div>
    </section>
  );
});

export default TrendingCourseSec;
