import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";

const Exchangecompadding = React.memo(function Exchangecompadding() {
  return (
    <section className="exchange-assistance compadding">
      <div className="container">
        <div className="exchange-assistance-wrapper flex-box align-center">
          <div className="exchange-assistance-image full-width-925 hide-mobile">
            <LazyLoadingImages src="/img/exchange.jpg" alt=" exchane-image" />
          </div>

          <div className="exchange-assistance-content headingbox mb-zero letter-normal full-width-925">
            <h2>
              <span className="themecolor">Foreign Exchange</span> Assistance
            </h2>

            <p>
              Getting a good education is an expensive affair. Even more so when
              you are in a foreign land and transacting in terms of a foreign
              currency (usually a strong one). And in most cases, these
              transactions aren't a one time affair.
            </p>

            <p>
              You have to buy groceries, books, sometimes you have to rent
              something, and other times, it is just the university or college
              fee. In such a case, a Forex card comes really handy. At Study
              Abroad, we don’t just help you fulfill your dreams of studying
              abroad, but we also ensure that we make that dream a reality for
              you by easing out the process in general.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Exchangecompadding;
