import React, { useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import BannerForm from "../Common/BannerForm";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import Meta from "../../components/Meta";

const Index = React.memo(function () {
  const [active, setActive] = useState("");
  const handleActive = (vl) => {
    if (active === vl) setActive("22");
    else setActive(vl);
  };
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  const metaData = {
    title: "Study in Ireland | Free Counselling – Search Education",
    mtitle: "Study in Ireland | Free Counselling – Search Education",
    mdescription:
      "Planning to Study Abroad? Discover the possibilities to Study in Ireland with Expert Guidance to unlock a gateway to world-class Education! ",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Study in Ireland | Free Counselling – Search Education",
    ogdescription:
      "Planning to Study Abroad? Discover the possibilities to Study in Ireland with Expert Guidance to unlock a gateway to world-class Education! ",
    ogimage: "https://www.searcheducation.com/img/stundets-with-shake-hand.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle: "Study in Ireland | Free Counselling – Search Education",
    twitterDescription:
      "Planning to Study Abroad? Discover the possibilities to Study in Ireland with Expert Guidance to unlock a gateway to world-class Education! ",
    twitterImage:
      "https://www.searcheducation.com/img/stundets-with-shake-hand.jpg",
  };
  return (
    <>
      <Header />
      <Meta metaData={metaData} />
      <section
        className="innrbanner student-country-banner innr-student-banner-sec"
        style={{ backgroundImage: `url('/img/mountain-with-sea.png')` }}
      >
        <div className="container">
          <div className="innrbannercntnt letter-normal">
            <div className="internal-banner-content-wrapper">
              <div className="headingbox">
                <h4 className="themecolor">STUDY. WORK. LIVE.</h4>

                <h1>Ireland</h1>
                <p>
                  Home to the ‘English’ language and to the world's oldest
                  universities!
                </p>
              </div>

              <div className="canada-university-info-list flex-box">
                <div className="canada-university-info-list-item">
                  <h4>32,000+</h4>
                  <p>International Students</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>12,000£</h4>
                  <p>Avg Living costs/pa</p>
                </div>
                <div className="canada-university-info-list-item">
                  <h4>22+</h4>
                  <p>Universities</p>
                </div>
              </div>
            </div>
            <div className="forex-applynow-form">
              <form action="" className="flex-box align-center">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    placeholder="Enter Name"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="phnumber"
                    placeholder="Enter Number"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email"
                  />
                </div>

                <div className="form-group checkbox-box">
                  <input
                    type="checkbox"
                    id="education-news"
                    className="single-checkbox"
                    name="education-news"
                  />

                  <label for="education-news">
                    <span>
                      I want to receive overseas education news & updates
                    </span>
                  </label>
                </div>

                <div className="form-group apply-now">
                  <button type="submit" className="btn">
                    Send Enquire
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="study-in-counntry compadding">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/stundets-with-shake-hand.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Study In</span> Ireland
              </h2>
              <p>
                Ireland, a largely English-speaking European country, has the
                world's sixth most competitive economy and is quickly
                establishing itself as a popular study abroad option.
                International students from all over the world rush to Ireland
                to be a part of the world's 20th finest education system, which
                offers over 5,000 courses.
              </p>
            </div>
          </div>
          <div className="flex-box canada-study-courses-wrapper just-center row-reverse">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/sea-side-road.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <p>
                Along with Canada, the United Kingdom, the United States, and
                Germany, the country has earned a place among the top countries
                for studying abroad. India is the second-largest sender of
                international students to Ireland, after China.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="visa-of-country compadding education-in-country education-ireland lightgrey">
        <div className="container">
          <div className="headingbox letter-normal com-headingbox text-center">
            <h2>
              <span className="themecolor">Why Study in the </span>Ireland
            </h2>
            <p>
              Ireland is a growing economy that attracts students from over 160
              nations for higher education and has been ranked as the 16th
              happiest country in the world. The country's significant appeal
              stems from its status as a majority English-speaking European
              nation with internationally recognized Irish institutes and
              curricula. If you are an overseas student interested in studying
              in the country, there are a few things you should keep in mind:
            </p>
          </div>
          <div className="flex-box visa-of-country-wrapper just-center com-reverse-row">
            <div className="visa-of-country-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/ireland-flag.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <h2>Education In Ireland</h2>
              <p>
                HDFC Credila processing fee amounts up to 1 % to 1.25% of the
                loan amount which includes all the legal fees and registration
                charges.
              </p>

              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Only 1% of universities worldwide are recognized for their
                    research: Ireland leads the world in Nanotechnology,
                    Chemistry, Material Sciences, and Mathematics research.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Although Ireland is somewhat expensive in comparison to the
                    rest of Europe, brilliant students with a shown interest in
                    research can apply for a number of government and private
                    scholarships, which can be used to study in Ireland for up
                    to four years.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-box visa-of-country-wrapper just-center com-reverse-row">
            <div className="visa-of-country-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/ireland-country.jpg" />
            </div>
            <div className="visa-of-country-content headingbox mb-0 letter-normal full-width-925">
              <ul className="search-edu-visa-service-list">
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    International postgraduate students might choose to work in
                    the country for up to 24 months. Aspirants can use this time
                    to prepare for their future careers while also looking for
                    dream jobs.
                  </span>
                </li>
                <li className="search-edu-visa-service-list-item flex-box">
                  <LazyLoadingImages src="/img/check-circle-image.png" />
                  <span>
                    Despite the fact that student visas in many countries have
                    stringent restrictions, student visas in Ireland allow
                    international candidates to work up to 40 hours per week
                    during their vacations.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="trendingcoursesec compadding ireland-courses">
        <div className="container">
          <div className="headingbox text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Courses </span>To Study In Ireland
            </h2>
            <p>
              The country provides over 5,000 courses to international students,
              spanning from STEM to humanities and arts. Master's programs in
              Ireland attract numerous overseas students compared to bachelor's
              programs at Irish universities. The following are some of the most
              popular courses offered by Irish universities:
            </p>
          </div>

          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>

              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>

                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>

                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>

                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="top-intrnational-universities lightgrey compadding">
        <div className="container">
          <div className="flex-box canada-study-courses-wrapper just-center">
            <div className="canada-study-courses-image full-width-925 image-auto-height">
              <LazyLoadingImages src="/img/international-university.jpg" />
            </div>
            <div className="canada-study-courses-content headingbox letter-normal mb-0 full-width-925">
              <h2>
                <span className="themecolor">Top universities</span> For
                <br /> International Students To Study In Ireland
              </h2>
              <p>
                When considering studying abroad, it's vital to discover the
                proper university and course match because Ireland is home to
                some of the world's most prestigious institutions, offering a
                diverse selection of programs at practically every educational
                level. We provide you with a list of the country's best
                universities.
              </p>
            </div>
          </div>
          <div className="study-courses-tags-list-wrapper headingbox letter-normal mb-0">
            <ul className="flex-box study-courses-tags-list">
              <li className="study-courses-tags-list-item">
                Trinity College Dublin
              </li>
              <li className="study-courses-tags-list-item">
                RCSI University of Medicine and Health Sciences
              </li>
              <li className="study-courses-tags-list-item">
                University College Dublin
              </li>
              <li className="study-courses-tags-list-item">
                National University of Ireland, Galway
              </li>
              <li className="study-courses-tags-list-item">
                University College Cork
              </li>
              <li className="study-courses-tags-list-item">
                Maynooth University
              </li>
              <li className="study-courses-tags-list-item">
                Dublin City University
              </li>
              <li className="study-courses-tags-list-item">
                MasterUniversity of Limericks in Statistics
              </li>
              <li className="study-courses-tags-list-item">
                Technological University Dublin
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="applying-stu-visa-sec study-application-ireland compadding">
        <div className="container">
          <div className="headingbox applying-stu-visa-heading com-headingbox letter-normal text-center">
            <h2>
              <span className="themecolor">How To Study In</span> Ireland?
            </h2>

            <p>
              If you have wanted to apply to an institute in Ireland, it is
              strongly recommended that you prepare ahead of time and walk
              through the process step by step to ensure that no details are
              overlooked. An application form for the same has been compiled
              below for ease of planning.
            </p>
          </div>

          <div className="flex-box applying-stu-visa-wrapper">
            <div className="applying-stu-courses-box flex-box align-center ">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/choose-study-abroad.png" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Universities Offer</h4>

                <p>
                  The country is home to hundreds of prestigious colleges that
                  offer over 5,000 courses, selecting the right option for your
                  interests and budget is crucial.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/enroll-courses.png" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>100% Of Scholarships</h4>

                <p>
                  {" "}
                  Ireland is a European country with expensive tuition and other
                  expenditures, qualifying students can receive up to 100% of
                  their tuition and other fees paid for by the government,
                  making it a worthwhile endeavor.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/apply-student-visa.png" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Application Process, Fees, & Deadlines</h4>

                <p>
                  The admission standards and course offerings of different
                  institutes differ, it's critical to pay attention to their
                  application procedure, fees, and deadlines. Graduate students
                  should send an email to the professors with whom they want to
                  work before applying.
                </p>
              </div>
            </div>

            <div className="applying-stu-courses-box flex-box align-center">
              <div className="applying-stu-courses-image">
                <LazyLoadingImages src="/img/immigration-process-visa.png" />
              </div>

              <div className="applying-stu-courses-content letter-normal">
                <h4>Processing & visa application</h4>

                <p>
                  Applicants can apply for a visa at their nearest Irish embassy
                  three months before their travel date.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trendingcoursesec compadding lightgrey">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="themecolor">Top</span> Courses In Ireland
            </h2>
          </div>
          <div className="popularuniversitysliderpro">
            <Slider className="popularuniversityslider" {...options}>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse.jpg"
                      alt="Image"
                    />
                  </div>
                  <div className="popularuniversitycntnt">
                    <h5>Marketing & Advertising</h5>
                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>
                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>
                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>
                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>
                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse3.jpg"
                      alt="Image"
                    />
                  </div>
                  <div className="popularuniversitycntnt">
                    <h5>Mechanical Engineering</h5>
                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>
                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse4.jpg"
                      alt="Image"
                    />
                  </div>
                  <div className="popularuniversitycntnt">
                    <h5>Master of Business Administration</h5>
                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>
                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
              <div className="popularuniversityslide">
                <div className="popularuniversityinnr equal-height">
                  <div className="popularuniversityimg">
                    <LazyLoadingImages
                      src="/img/trendingcourse2.jpg"
                      alt="Image"
                    />
                  </div>
                  <div className="popularuniversitycntnt">
                    <h5>Computer Science Engineering</h5>
                    <p>
                      3 years<span className="sperater">|</span>Post Graduated
                    </p>
                    <a href="" className="btn">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="application-process compadding">
        <div className="container">
          <div className="headingbox letter-normal text-center letter-normal com-headingbox">
            <h2>
              <span className="themecolor">Application</span> Process
            </h2>
          </div>
          <div className="flex-box appli-process-list just-center">
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/career-counseling.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Free Career
                  <br /> Counseling{" "}
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/best-college.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Find Best-fit
                  <br /> Course & College
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/prepare.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Prepare
                  <br /> Application
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/offer-letter.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Accept
                  <br />
                  Offer Letter
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/approverd-visa.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Get Visa
                  <br /> Approval
                </h5>
              </div>
            </div>
            <div className="appli-process-list-item text-center">
              <div className="appli-process-list-icon">
                <LazyLoadingImages src="/img/take-off.png" />
              </div>
              <div className="appli-process-list-conten headingbox mb-0">
                <h5>
                  Pre Departure
                  <br /> Briefing & Take Off
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqs">
        <div className="container">
          <div className="text-box text-center faq-heading headingbox">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-wrapper">
            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("01")}>
                How much does it cost to study in Ireland?
                <span
                  className={active === "01" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "01" ? "panel blk" : "panel"}>
                <p>
                  The expenses that must be met to live in a foreign land are
                  the main steps that require major preparation and sorting when
                  seeking to study abroad. Although the estimated cost of living
                  in Ireland is between 11,500 and 25,000 EUR per semester,
                  exact costs can vary depending on your programme, university,
                  and city of residence.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("02")}>
                How to study in Ireland with a scholarship?
                <span
                  className={active === "02" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "02" ? "panel blk" : "panel"}>
                <p>
                  International students may apply for a variety of awards and
                  scholarships to help finance their education in Ireland. The
                  awards are given to candidates from over 160 countries at all
                  education levels, including graduation, post-graduation,
                  master's, and research programmes. Candidates can also apply
                  for various university and course-specific studentships,
                  including International Scholarships at the National College
                  of Ireland and MSc merit-based Scholarships, TCD Global
                  Excellence Postgraduate Scholarships, and TCD Global
                  Excellence Postgraduate Scholarships.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("03")}>
                What jobs are available after studying in Ireland?
                <span
                  className={active === "03" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "03" ? "panel blk" : "panel"}>
                <p>
                  Ireland as a nation provides an abundance of work prospects
                  for its graduates, as shown by its low unemployment rate.
                  Though COVID-19 has had a significant effect on the Irish
                  economy, raising the unemployment rate to nearly 26%, the
                  country is taking the necessary steps to address the problem
                  offers hope to students of all disciplines.
                </p>
              </div>
            </div>

            <div className="accordion-group accordion-ques">
              <button className="accordion" onClick={() => handleActive("05")}>
                {" "}
                How much can a student earn in Ireland?
                <span
                  className={active === "05" ? "faq-icon intro" : "faq-icon"}
                ></span>
              </button>

              <div className={active === "05" ? "panel blk" : "panel"}>
                <p>
                  International students have a lot of opportunities after they
                  finish their studies in Ireland. The following are some of the
                  top work positions available to a foreign graduate in Ireland,
                  along with the compensation packages available:
                </p>

                <ul>
                  <li>Finance Manager 77,500 euro per year</li>
                  <li>Aerospace Engineer 50,900euro per year</li>
                  <li>Architect 47,700euro per year</li>
                  <li>B.D Manager 65,900euro per year</li>
                  <li>Business Analyst 48,500euro per year</li>
                  <li>Construction Project Manager 66,400euro per year</li>
                </ul>
                <br />
                <p>
                  As a predominantly English-speaking European economy, Ireland
                  is a package offer for international students looking to study
                  abroad. The country has an endless list of merits to its
                  credit, ranging from 22 internationally renowned colleges,
                  5,000 courses, 2-year stay back, PR in just two years, the
                  hassle-free application process, scenic beauty, warm
                  environment, and the welcoming nature of Irish citizens.
                  Increased job prospects and the attraction of large factories
                  to Ireland serve as icing on the cake for Ireland, attracting
                  many foreign students from India and other South Asian
                  countries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default Index;
