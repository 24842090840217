import React from "react";
import Slider from "react-slick";
import Meta from "../../components/Meta";
import HeaderAustralia from "./HeaderAustralia";
import EnquiryForm2 from "./SidebarForm";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import { useNavigate } from "react-router-dom";
import RequestAQuote from "./RequestQuote";
import { useState } from "react";
import FooterAustralia from "./FooterAustralia";
import EducationPartnerAustralia from "./EducationPartner";
import "./AustraliaPage.css";

const Australia = React.memo(function Australia() {
  const metaData = {
    title: "Search Education – Study Abroad Consultant | Free Counselling",
    mtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    mdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogtype: "website",
    ogurl: "",
    ogtitle: "Search Education – Study Abroad Consultant | Free Counselling",
    ogdescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    ogimage: "https://www.searcheducation.com/img/img_3.jpg",
    twitterCard: "summary_large_image",
    twitterUrl: "",
    twitterTitle:
      "Search Education – Study Abroad Consultant | Free Counselling",
    twitterDescription:
      "Your trusted Overseas Education Consultancy, empowering students for global success. Consult our specialists for guidance on your ideal study-abroad destination",
    twitterImage: "https://www.searcheducation.com/img/img_3.jpg",
  };
  const [createSec, setcreateSec] = useState(false);

  const countryData = [
    {
      imageUrl: "/img/country.jpg",
      countryName: "Australia",
      redirectLink: "/study_in_australia",
    },
    {
      imageUrl: "/img/country2.jpg",
      countryName: "Canada",
      redirectLink: "/study_in_canada",
    },
    {
      imageUrl: "/img/country3.jpg",
      countryName: "United Kingdom",
      redirectLink: "/study_in_uk",
    },
    {
      imageUrl: "/img/country4.jpg",
      countryName: "Ireland",
      redirectLink: "/study_in_ireland",
    },
    {
      imageUrl: "/img/country5.jpg",
      countryName: "New Zealand",
      redirectLink: "/study_in_newzealand",
    },
  ];

  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
  };
  const navigate = useNavigate();

  const optionsWhatWeDo = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow:
      window.innerWidth <= 480 ? 1 : window.innerWidth <= 830 ? 2 : 3,
    rows: 1,
    centerPadding: "20px",
    slidesToScroll: 1,
  };

  const StudentOptions = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow:
      window.innerWidth <= 480 ? 1 : window.innerWidth <= 830 ? 2 : 4,
    rows: 1,
    centerPadding: "20px",
    slidesToScroll: window.innerWidth <= 480 ? 1 : 2,
  };

  const AustraliaBannerJson = [
    {
      img: "/img/AustraliaPage/Banner/Australia-Banner-img_3.png",
      // bannerText: `<div class="fw-650 tx-uppercase">
      // <span class="f-40 cl-f57338">Study </span><br/>
      // <span class="cl-white f-70" style="font-weight: 500;
      // letter-spacing: -7px;
      // font-family: monospace;
      // color: white;">Kitchen management</span><br/>
      // <span class="f-40cl-white">Course in <span class="cl-f57338">australia</span></span>
      // <hr class="hr-tg" />
      // <span class="f-30 cl-white">Take your cooking skills to
      // <br/>the next level</span></div><p>
      // <a class="btn login-process-modal" href="/contactus" >Enquire Now</a>
      // </p>`,
    },
  ];

  const Austrlia_Student_Json = [
    {
      Name: "Akash Kumar Pandey",
      image: "/img/AustraliaPage/successStory/akash-kumar-pandey.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Archana Panjiyar",
      image: "/img/AustraliaPage/successStory/Archana-panjiyar.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Bikalpa Karki",
      image: "/img/AustraliaPage/successStory/bikalpa-karki.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Dedipya Dhakal",
      image: "/img/AustraliaPage/successStory/Dedipya-Dhakal.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Dipesh Adhikari",
      image: "/img/AustraliaPage/successStory/dipesh-adhikari.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Karuna Pandey",
      image: "/img/AustraliaPage/successStory/Karuna-Pandey.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Marielyn",
      image: "/img/AustraliaPage/successStory/marielyn.png",
      Remarks: "student visa with successful s57 case",
    },
    {
      Name: "Ms. Michelle Indira",
      image: "/img/AustraliaPage/successStory/michelle-indira.png",
      Remarks: "student visa (onshore) Granted For Australia",
    },
    {
      Name: "RODOLFO JR & SHANDY",
      image: "/img/AustraliaPage/successStory/RODOLFO-JR-&-SHANDY.png",
      Remarks: "Tourist to Student visa",
    },
    {
      Name: "Mr. Ryan",
      image: "/img/AustraliaPage/successStory/ryan.png",
      Remarks: "student visa (offshore) Granted For Australia",
    },
    {
      Name: "Sandhya Giri",
      image: "/img/AustraliaPage/successStory/sandhya-giri.png",
      Remarks: "Student Visa",
    },
    {
      Name: "Supreksha Karki",
      image: "/img/AustraliaPage/successStory/Supreksha-Karki.png",
      Remarks: "Student Visa",
    },
    {
      Name: " Mr. Emmanuel",
      image: "/img/AustraliaPage/successStory/UnEmmanuel-PH-to- AUS.png",
      Remarks: "Subsequent Student Dependent visa granted For Australia",
    },
  ];

  const Banneroptions = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  const OpenStudentVisitor = () => {
    setcreateSec(true);
  };

  return (
    <>
      <HeaderAustralia />
      <Meta metaData={metaData} />
      <section className="mainbanner">
        <div style={{ width: "100%", overflow: "hidden" }}>
          <Slider className="bannermainsider" {...Banneroptions}>
            {AustraliaBannerJson.map((bnj, index) => (
              <div className="bannerslide" key={index + 146}>
                <div className="bannerslideinnr">
                  <div
                    className="bannerslideimg"
                    style={{
                      backgroundImage: `url('${bnj.img}')`,
                      backgroundColor: "#25253F",
                    }}
                  ></div>
                  <div className="container">
                    <div className="banner-slidecntnt">
                      {/* <span
                        dangerouslySetInnerHTML={{ __html: bnj.bannerText }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="bannerslide">
              <div className="bannerslideinnr">
                <div
                  className="bannerslideimg"
                  style={{ backgroundImage: `url('/img/banner.jpg')` }}
                ></div>
                <div className="container">
                  <div className="banner-slidecntnt">
                    <h4>
                      <span>
                         <LazyLoadingImages src="/img/locationicon.png" alt="Map" />
                      </span>
                      Geelong, Australia
                    </h4>
                    <h1>Get Study In Deakin University</h1>
                    <a href="/" className="btn">
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </Slider>
        </div>
      </section>
      <EnquiryForm2 BtnName={"Speak with our Team"} />
      <RequestAQuote createSec={createSec} setcreateSec={setcreateSec} />
      {/* What we can do start */}
      <section className="eligibility-stu-visa-sec searchcountrysec compadding">
        <div className="container">
          <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">What </span> we do
            </h2>
          </div>

          <div className="searchcountrysliderpro Australia_page_what_we ">
            <Slider
              className="searchcountryslider"
              {...optionsWhatWeDo}
              style={{ height: "auto" }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/australia/Education-and-Student-Visa-Assistance")
                }
                className="eligibility-stu-visa-box "
              >
                <div className="eligibility-stu-image">
                  <LazyLoadingImages
                    src="/img/AustraliaPage/WhatWeDo/Education-and-Career-Counselling.png"
                    alt="student"
                  />
                </div>

                <div
                  className="eligibility-stu-content"
                  style={{ height: "200px" }}
                >
                  <h4>Education and Career Counselling</h4>

                  {/* <p>
                    To gain a student visa for Australia it is essential that
                    the applicant presents a documented proof of his physical
                    good health. The applicant can present a certificate in this
                    accord issued by any of the impanelled doctors.
                  </p> */}
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/australia/migration-services")}
                className="eligibility-stu-visa-box "
              >
                <div className="eligibility-stu-image">
                  <LazyLoadingImages
                    src="/img/AustraliaPage/WhatWeDo/Visas-and-Skilled-Migration-Assistance.png"
                    alt="student"
                  />
                </div>

                <div
                  className="eligibility-stu-content"
                  style={{ height: "200px" }}
                >
                  <h4>Visas and Skilled Migration Assistance</h4>

                  {/* <p>
                    To gain a student visa for Australia it is essential that
                    the applicant presents a documented proof of his physical
                    good health. The applicant can present a certificate in this
                    accord issued by any of the impanelled doctors.
                  </p> */}
                </div>
              </div>

              <div
                style={{ cursor: "pointer" }}
                onClick={() => OpenStudentVisitor()}
                className="eligibility-stu-visa-box"
              >
                <div className="eligibility-stu-image">
                  <LazyLoadingImages
                    src="/img/AustraliaPage/WhatWeDo/Student-and-Visitors-Health-Covers.png"
                    alt="student"
                  />
                </div>

                <div
                  className="eligibility-stu-content"
                  style={{ height: "200px" }}
                >
                  <h4>Student and Visitors Health Covers</h4>

                  {/* <p>
                    To gain a student visa for Australia it is essential that
                    the applicant presents a documented proof of his physical
                    good health. The applicant can present a certificate in this
                    accord issued by any of the impanelled doctors.
                  </p> */}
                </div>
              </div>

              <div
                // style={{ cursor: "pointer" }}
                // onClick={() => navigate("/australia/migration-services")}
                className="eligibility-stu-visa-box "
              >
                <div className="eligibility-stu-image">
                  <LazyLoadingImages
                    src="/img/AustraliaPage/WhatWeDo/Visas-and-Skilled-Migration-Assistance.png"
                    alt="student"
                  />
                </div>

                <div
                  className="eligibility-stu-content"
                  style={{ height: "200px" }}
                >
                  <h4>Document Attestation </h4>
                  <p>Send us your documents to verify their authenticity.</p>
                </div>
              </div>

              <div
                // style={{ cursor: "pointer" }}
                // onClick={() => navigate("/australia/migration-services")}
                className="eligibility-stu-visa-box "
              >
                <div className="eligibility-stu-image">
                  <LazyLoadingImages
                    src="/img/AustraliaPage/WhatWeDo/Education-and-Career-Counselling.png"
                    alt="student"
                  />
                </div>

                <div
                  className="eligibility-stu-content"
                  style={{ height: "200px" }}
                >
                  <h4>Free GS Statement Support</h4>
                  <p>
                    Our team will help you draft a personalised Genuine Student
                    Statement to get your story through.{" "}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* what we can do end */}

      <section className="eligibility-stu-visa-sec compadding searchcountrysec">
        <div className="container">
          <div className="headingbox eligibility-stu-visa-heading common-stu-visa-heading text-center">
            <h2>
              <span className="themecolor">Our Success </span> Stories
            </h2>
          </div>

          <div className="Australia_page_what_we  searchcountrysliderpro">
            <Slider
              className="searchcountryslider"
              {...StudentOptions}
              style={{ height: "auto" }}
            >
              {Austrlia_Student_Json?.map((el, i) => (
                <div
                  key={i + 133}
                  className="eligibility-stu-visa-box Student_Story"
                >
                  <div className="eligibility-stu-image">
                    <LazyLoadingImages src={el.image} alt="student" />
                  </div>
                  <div
                    className="eligibility-stu-content"
                    style={{ height: "180px" }}
                  >
                    <h4 style={{ textAlign: "center" }}>{el?.Name}</h4>
                    <p style={{ textAlign: "center" }}>{el?.Remarks}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* Message from Director */}
      <section className="about-founder lightgrey compadding">
        <div className="container">
          <div className="about-founder-wrapper flex-box">
            <div className="founder-image-box">
              <span className="founder-text">Founder</span>

              <div
                className="about-founder-image bg-style"
                style={{
                  backgroundImage: `url('/img/founder-image.png')`,
                }}
              ></div>
            </div>
            <div className="about-founder-details full-width-925 headingbox mb-0">
              {/* <img src="/img/quotes.png" alt="quotes" /> */}
              <h2 style={{ marginBottom: "20px" }}>
                Message from the Director
              </h2>
              <LazyLoadImage src="/img/quotes.png" alt="quotes" />
              <p>
                {/* Ensuring all aspiring students have{" "}
                <strong>access to quality education</strong> from universities
                and colleges across the globe, we spectacularly pioneered AI
                based algorithms and automation in different processes, which
                has granted us a technology driven edge over others. */}
                My team at Search Education works exceptionally hard each day to
                extend <strong>access to high-quality</strong> global
                educational opportunities to all aspiring students, irrespective
                of their financial backgrounds, to empower them to reach their
                full potential. We constantly upgrade our skillset and leverage
                cutting-edge technology to deliver excellent, personalized
                solutions in this rapidly evolving international educational
                sector.
              </p>

              <p>
                {/* I am proud of where we have reached and I am sure that the path
                ahead will bring abundant prosperity and success for all our
                stakeholders! */}
                The accomplishments of our students on a global stage stand as a
                testament to the collaborative efforts of our brilliant team and
                we feel immense joy in witnessing them thrive. I am filled with
                pride for our achievements thus far and am confident that the
                journey ahead will bring prosperity and success for all our
                stakeholders.
              </p>

              <h5>- Bhupendra Kunwar</h5>
            </div>
          </div>
        </div>
      </section>
      {/* Countries */}
      <section className="searchcountrysec compadding">
        <div className="container">
          <div className="headingbox">
            <h2>
              <span className="smh">Our</span>
              <span className="themecolor">Destinations</span>
            </h2>
          </div>
          <div className="searchcountrysliderpro">
            <Slider className=" searchcountryslider" {...options}>
              {countryData.map((cntry, index) => {
                return (
                  <div className="searchcountryslide" key={index + 24}>
                    <a>
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('${cntry.imageUrl}')`,
                          }}
                        ></div>
                        <p>
                          Study in <strong>{cntry.countryName}</strong>
                        </p>
                      </div>
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      {/* Countries */}
      <EducationPartnerAustralia />
      <FooterAustralia />
    </>
  );
});

export default Australia;
