import React from "react";
import BannerForm from "../Common/BannerForm";

const Banner = React.memo(function Banner() {
  return (
    <section
      className="career-banner innrbanner forex-banner"
      style={{ backgroundImage: `url('/img/forex-banner.png')` }}
    >
      <div className="container">
        <div className="innrbannercntnt">
          <div className="headingbox">
            <h4 className="themecolor">Foreign exchange assistance</h4>

            <h1>Transfer Money Not Stress!</h1>
          </div>

          <BannerForm clw="Yes" />
        </div>
      </div>
    </section>
  );
});

export default Banner;
