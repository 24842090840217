import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getShortlistedCourse } from "../../../actions/courseActions";

export default function ShortlistedCourse() {
  const options = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const { loading, error, courseShortlists } = useSelector(
    (state) => state.courseShortlisted
  );

  const getshortlistedcourses = () => {
    dispatch(getShortlistedCourse());
  };

  useEffect(() => {
    if (!courseShortlists) {
      getshortlistedcourses();
    }
  }, [dispatch, courseShortlists]);

  return loading ? (
    <div className="shortlistedcourses featuredbg-style">
      <div className="headingbox mb-tzero">
        <h2>My Shortlisted Courses </h2>
      </div>
      <div className="shortlistedcours-main">Loading...</div>
    </div>
  ) : courseShortlists ? (
    <div className="shortlistedcourses featuredbg-style">
      <div className="headingbox mb-tzero">
        <h2>My Shortlisted Courses </h2>
      </div>
      <div className="shortlistedcours-main">
        <Slider className="shortlistedcoursesslider" {...options}>
          {courseShortlists.map((crsSh) => (
            <div className="shortlistedcoursesslide">
              <div className="shortlistedcoursesinner equal-height">
                <div className="shortlistedcoursescntnt">
                  <h6>{crsSh.University_Name}</h6>
                  <h3>{crsSh.Course_Name}</h3>
                  <div className="degree-duration flex-box">
                    <h4>4 Years</h4>
                    <h5>Bachelors </h5>
                  </div>
                  <a
                    href={`/course/${crsSh.Course_Id}`}
                    className="view-details"
                  >
                    <img src="/img/arrow-white.png" alt="Arrow White" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="featuredview-all text-center">
          <a href="/panel/wishlist" className="btn text-center">
            View All
          </a>
        </div>
      </div>
    </div>
  ) : (
    <>{error}</>
  );
}
