import {
  STUDY_LEVEL_LIST_REQUEST,
  STUDY_LEVEL_LIST_SUCCESS,
  STUDY_LEVEL_LIST_FAIL,
} from "../constants/studyLevelConstants";
import api2 from "../core/api2";

export const listStudyLevel = () => async (dispatch) => {
  try {
    dispatch({
      type: STUDY_LEVEL_LIST_REQUEST,
    });
    const { data } = await api2.get(`/studylevel`);
    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: STUDY_LEVEL_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: STUDY_LEVEL_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: STUDY_LEVEL_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
