import {
  MESSAGE_ADD_FAIL,
  MESSAGE_ADD_REQUEST,
  MESSAGE_ADD_SUCCESS,
  MESSAGE_ADD_NEW_FAIL,
  MESSAGE_ADD_NEW_REQUEST,
  MESSAGE_ADD_NEW_SUCCESS,
  MESSAGE_INTERNAL_DETAIL_FAIL,
  MESSAGE_INTERNAL_DETAIL_REQUEST,
  MESSAGE_INTERNAL_DETAIL_SUCCESS,
  MESSAGE_LIST_FAIL,
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
} from "../constants/messageConstants";
import api2 from "../core/api2";

export const getMessageList = () => async (dispatch) => {
  try {
    dispatch({
      type: MESSAGE_LIST_REQUEST,
    });
    const { data } = await api2.get(`/student/message`);
    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: MESSAGE_LIST_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: MESSAGE_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: MESSAGE_LIST_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getMessageListInternal = (messageId) => async (dispatch) => {
  try {
    dispatch({
      type: MESSAGE_INTERNAL_DETAIL_REQUEST,
    });
    const { data } = await api2.get(`/student/message/${messageId}`);

    const response = data.Result;
    if (typeof response == "string") {
      dispatch({
        type: MESSAGE_INTERNAL_DETAIL_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: MESSAGE_INTERNAL_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: MESSAGE_INTERNAL_DETAIL_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const addInternalMessage = (messageJson) => async (dispatch) => {
  try {
    dispatch({
      type: MESSAGE_ADD_REQUEST,
    });
    const { data } = await api2.post(
      `/student/message/messageInner`,
      messageJson
    );
    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: MESSAGE_ADD_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: MESSAGE_ADD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: MESSAGE_ADD_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const addMessage = (messageJson) => async (dispatch) => {
  try {
    dispatch({
      type: MESSAGE_ADD_NEW_REQUEST,
    });
    const { data } = await api2.post(`/student/message`, messageJson);
    const response = data.Result;
    if (response.includes("Error")) {
      dispatch({
        type: MESSAGE_ADD_NEW_FAIL,
        payload: response,
      });
    } else {
      dispatch({
        type: MESSAGE_ADD_NEW_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: MESSAGE_ADD_NEW_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
