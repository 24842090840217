import React, { useEffect } from "react";

export default function Protected(props) {
  const checkLogin = () => {
    const userInfo = localStorage.getItem("accinf");
    if (userInfo == undefined || userInfo == "") {
        document.location.href = '/';
    }
  };

  useEffect(()=>{
    checkLogin();
  },[])
  return <>{props.children}</>;
}
