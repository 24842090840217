export const studentJson = {
  Email: "",
  Password: "",
  Comments: "",
  Links: "",
  medium: "",
  campaignId: "",
  Phone_No: "",
  full_name: "",
  OTP: "",
};

export const contactJson = {
  Contact_Id: "0",
  First_Name: " ",
  Last_Name: "",
  Phone_No: "",
  Email_Address: "",
  City: "",
  Country_Id: "0",
  Create_By: "0",
  Create_Date: "",
  Last_Activity_By: "0",
  Last_Activity_Date: "",
  Active_Flag: "1",
  full_name: "",
  pageId: "",
  createdtime: "",
  leadgen_id: "",
  ad_id: "",
  adset_id: "",
  campaign_id: "",
  form_id: "",
  Assigned_User_Id: "3",
  Qualifies: "0",
  Educational_Status: "0",
  Year_Of_Completion: "0",
  Category_Of_Study: "0",
  Interested_Country: "0",
  Interested_Field_Of_Study: "0",
  Interested_Level_Of_Study: "0",
  English_Test_Attempted: "0",
  English_Test_Score: "",
  Team_Id: "0",
  Contact_Platform: "0",
  Reason_not_Qualify: "0",
  Preferred_Intake: "0",
  Profile_Status: "0",
  Month_Id: "0",
  Academic_Percentage: "0",
  Intake_Year: "0",
  Email_Address1: "",
  Email_Address2: "",
  Phone_No1: "",
  Phone_No2: "",
  Activity: "",
  Activity_Des: "",
  Hubspot_Contact_Id: "",
  Comments: "",
  Contact_priority: "0",
  Contact_Owner: "0",
  Lead_Source: "0",
  visa_expiry_date: "",
  referral_type: "",
  Referral_User_Id: "0",
  Referral_Contact_Id: "0",
  Referral_SubAgent_Id: "0",
  Gender: "0",
  Date_Of_Birth: "",
  Marital_Status: "0",
  Contact_Rating: "0",
  How_you_heard_About_Us: "0",
  Branch: "0",
  Enquiry_For: "0",
  Interested_Country2: "0",
  Financial_Source: "0",
  Counsellor_Meeting: "0",
  Meeting_Date: "",
  Ielts_Paid: "0",
  State: "",
  Contact_Owner_II: "0",
  notificationCode: "",
  Grading_System: "0",
  Passport_number: "",
  EmRelation: "0",
  EmName: "",
  EmPhone_Number: "",
  EmEmail_Address: "",
  OSHC_Type: "0",
  Previous_Visa_Refusal: "0",
  Visa_Refusal_Reason: "",
  Vaccination_Status: "0",
  Subscribed_Leads: "0",
  Links: "",
};

export const contactRegisterJson = {
  Email: "",
  Phone_No: "",
  full_name: "",
  Password: "",
  Comments: "",
  Links: "",
  medium: "",
  campaignId: "",
};

export const bookingJson = {
  Mode_Of_Contact:'',
  StudyDestination:'',
  AppointmentDate:'',
  AppointmentTime:'',
  OfficeLocation:'',
  Name:'',
  Email:'',
  Number:'',
}


// contact response
// {
//   "ContactName": "Testing2 tushant",
//   "Contact_Id": 31,
//   "First_Name": "Testing2",
//   "Last_Name": "tushant",
//   "Phone_No": "799029955",
//   "Email_Address": "trajpal2@yopmail.com",
//   "Country_Id": 2,
//   "Create_By": 1,
//   "Create_Date": "2021-06-01T04:09:22.280Z",
//   "Last_Activity_By": 1,
//   "Last_Activity_Date": "2022-03-11T09:15:03.813Z",
//   "Active_Flag": 1,
//   "full_name": "",
//   "pageId": "",
//   "createdtime": "",
//   "leadgen_id": "",
//   "ad_id": "",
//   "adset_id": "",
//   "campaign_id": "",
//   "form_id": "",
//   "Assigned_User_Id": 1,
//   "Qualifies": 1,
//   "Educational_Status": 1,
//   "Year_Of_Completion": 1,
//   "Category_Of_Study": 1,
//   "Interested_Country": 5,
//   "Interested_Field_Of_Study": 1,
//   "Interested_Level_Of_Study": 8,
//   "English_Test_Attempted": 1,
//   "English_Test_Score": "6.0 overall",
//   "Team_Id": 1,
//   "Contact_Platform": "ol",
//   "Reason_not_Qualify": 1,
//   "Preferred_Intake": 2,
//   "Profile_Status": 1,
//   "Month_Id": 1,
//   "Academic_Percentage": 80,
//   "Intake_Year": "2021",
//   "Email_Address1": " ",
//   "Email_Address2": " ",
//   "Phone_No1": " ",
//   "Phone_No2": " ",
//   "City": "Bhopal",
//   "Hubspot_Contact_Id": "0",
//   "Comments": "New Comments: Page: Received Lead from Website Home Page. --- Comment: Hello checking the details on contact dashboard, New Comments: Page: Received Lead from Website Home Page. --- Comment: testing 101, New Comments: Page: Received Lead from Website Home Page. --- Comment: dl;skf;l, New Comments: Page: Received Lead from Website Home Page. --- Comment: hjhkh, New Comments: Page: Received Lead from Website Home Page. --- Comment: fjksldjf, New Comments: Page: Received Lead from Website Home Page. --- Comment: fjksldjf, New Comments: Page: Received Lead from Website Home Page. --- Comment: fhkjsdhfksd",
//   "Contact_priority": 1,
//   "Lead_Source": 5,
//   "visa_expiry_date": "2021-09-06T00:00:00.000Z",
//   "referral_type": 3,
//   "Referral_User_Id": 0,
//   "Referral_SubAgent_Id": 0,
//   "Referral_Contact_Id": 6,
//   "Gender": 1,
//   "Date_Of_Birth": "1990-12-17T00:00:00.000Z",
//   "Marital_Status": 3,
//   "Contact_Rating": 1,
//   "How_you_heard_About_Us": 1,
//   "Branch": 1,
//   "Enquiry_For": 1,
//   "Interested_Country2": 0,
//   "Financial_Source": 4,
//   "Counsellor_Meeting": 0,
//   "Meeting_Date": null,
//   "Ielts_Paid": 0,
//   "Contact_Owner_II": 0,
//   "State": "Madhya Pradesh",
//   "Passport_number": "J1421621",
//   "Grading_System": 1,
//   "EmRelation": 1,
//   "EmName": "null",
//   "EmPhone_Number": "null",
//   "EmEmail_Address": "null",
//   "OSHC_Type": 1,
//   "Previous_Visa_Refusal": 2,
//   "Visa_Refusal_Reason": "null",
//   "Vaccination_Status": 1,
//   "Old_New": 0,
//   "Subscribed_Leads": null,
//   "GenderCategory": "Male",
//   "MaritalStatus": "Married",
//   "Country": "INDIA",
//   "HowYouHeardAboutUs": "Recommendation of former student",
//   "E_Status": "--",
//   "Field_Of_Study": "Agriculture",
//   "I_Country": "IRELAND",
//   "EnglishTestAttempted": "--",
//   "InterestedLevelOfStudy": "Masters in Research",
//   "GradingSystem": "Percentage",
//   "FinancialSource": "Parents",
//   "VaccinatedStatus": "Fully Vaccinated",
//   "EmRelationn": "Parent"
// }