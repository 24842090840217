import React from "react";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import Slider from "react-slick";
import AvailableCourses from "./AvailableCourses";

const RequirementsInfo = React.memo(function RequirementsInfo({ det }) {
  return (
    <>
      <section
        className="innrbanner listing-detail-banner"
        style={{
          backgroundImage: `url('${
            det?.Banner1903x550
              ? process.env.REACT_APP_SED_DOC_URL +
                "/university/Banner1903x550/" +
                det.Banner1903x550
              : "/img/college-banner.png"
          }')`,
        }}
      >
        <div className="container newcontainer">
          <div className="innrbannercntnt listingdetailbanner-content">
            <div className="listingdetail-banner-heading">
              <div className="university-tags-box flex-box">
                <LazyLoadingImages
                  src={
                    det?.Logo134x64
                      ? process.env.REACT_APP_SED_DOC_URL +
                        "/university/Logo134x64/" +
                        det?.Logo134x64
                      : "/img/university-tag-2.png"
                  }
                  alt={det?.University_Name}
                />
              </div>
              <h1>{det?.University_Name}</h1>
              <div className="listingdetail-banner-tags">
                {/* <a href="">
                  <LazyLoadingImages
                    src="/img/online-tag.png"
                    alt="online-tag-image"
                  />
                </a>
                <a href="">
                  <LazyLoadingImages
                    src="/img/sydney-australia.png"
                    alt="sydney-image"
                  />
                </a> */}
              </div>
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: det?.universityDetails }}
                ></div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="requirements-info-sec college-requirements-sec">
        <div className="container newcontainer">
          <div className="flex-box">
            <div className="requirements-info flex-box">
              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/dollar-fee.png"
                  alt="dollar-fee-image"
                />
                <div className="requirment-content">
                  <h5>Admission Fee</h5>
                  <h3>
                    {det?.commonApplicationFee !== 0
                      ? det?.Country_Symbol + " " + det?.commonApplicationFee
                      : "N/A"}
                  </h3>
                </div>
              </div>
              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/dollar-fee.png"
                  alt="dollar-fee-image"
                />
                <div className="requirment-content">
                  <h5>Tuition Fee</h5>
                  <h3>{det?.commonTuitionFees}</h3>
                </div>
              </div>
              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/educationdegree.png"
                  alt="educationdegree-image"
                />
                <div className="requirment-content">
                  <h5>Degree Type</h5>
                  <h3 className="degree-off">{det?.degreeOffered}</h3>
                </div>
              </div>
              <div className="requirment-box">
                <LazyLoadingImages
                  src="/img/education-duration.png"
                  alt="education-duration-image"
                />
                <div className="requirment-content">
                  <h5>Duration</h5>
                  <h3>4 Years ( 8 Sem )</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="listing-details-sec">
        <div className="container newcontainer">
          <div className="flex-box listing-details-wrapper">
            <div className="listing-details-left-box">
              <ul className="listing-details-leftbar sticky">
                <li id="admission-requirements-link" data-link="#about-section">
                  <a href="">About The College</a>
                </li>
                <li
                  id="admission-requirements-link"
                  data-Link="#available-course"
                >
                  <a href="">Available Courses</a>
                </li>
                {/* <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">College Neighborhoods</a>
                </li> */}
                <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">Why {det?.University_Name}</a>
                </li>
                {/* <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">College Gallery</a>
                </li> */}
                <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">About Scholarship</a>
                </li>
                {/* <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">Related Colleges</a>
                </li> */}
                <li
                  id="admission-requirements-link"
                  data-link="admission-requirements"
                >
                  <a href="">Change Course</a>
                </li>
              </ul>
            </div>
            <div className="listing-details-info college-listing-details-info">
              <div className="listing-details-info-box" id="about-section">
                <div className="list-details-heading">
                  <h2>
                    <strong>About</strong> The College
                  </h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: det?.aboutUniversity }}
                ></div>
                <p>
                  <b>Degree Offered:</b> {det?.degreeOffered}
                </p>
                <div dangerouslySetInnerHTML={{ __html: det?.extraDetails }} />
              </div>

              <AvailableCourses id={det?.University_Id} />
              <div className="neighborhoods-sec">
                <div className="headingbox list-details-heading">
                  <h2>
                    <strong>College</strong> Neighborhoods
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: det?.neighborhood }}
                  />
                </div>
                <div className="img-box">
                  <iframe
                    src={det?.googleMapUrl}
                    width="928"
                    height="370"
                    style={{ border: 0, borderRadius: 20 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              {/* <div className="listing-details-info-box" id="ielts-pte-score">
                <div className="list-details-heading">
                  <h3>Near By Places</h3>
                </div>
                <div className="searchcountrysec">
                  <Slider
                    className="searchcountrysliderpro visa-countries-wrapper top-university-sliderpro"
                    {...options}
                  >
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-oxford.jpg')`,
                          }}
                        ></div>
                        <p>
                          University Of <strong>Oxford</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-cambridge.jpg')`,
                          }}
                        ></div>
                        <p>
                          University Of <strong>Cambridge</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london.jpg')`,
                          }}
                        ></div>
                        <p>
                          Imperial College <strong>London</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london-ucl.jpg')`,
                          }}
                        ></div>
                        <p>
                          University College <strong>London (UCL)</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/universityecono.jpg')`,
                          }}
                        ></div>
                        <p>
                          London School Of <strong>Econo & Pol-Sci</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london-ucl.jpg')`,
                          }}
                        ></div>
                        <p>
                          University College <strong>London (UCL)</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london-ucl.jpg')`,
                          }}
                        ></div>
                        <p>
                          University College <strong>London (UCL)</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london-ucl.jpg')`,
                          }}
                        ></div>
                        <p>
                          University College <strong>London (UCL)</strong>
                        </p>
                      </div>
                    </div>
                    <div className="searchcountryslide visa-countries-box">
                      <div className="countrylistbox">
                        <div
                          className="countryimage"
                          style={{
                            backgroundImage: `url('/img/university-london-ucl.jpg')`,
                          }}
                        ></div>
                        <p>
                          University College <strong>London (UCL)</strong>
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div> */}
              <div className="listing-details-info-box university-section">
                <div className="list-details-heading">
                  <h2 id="how-to-apply">
                    <strong>Why</strong> {det?.University_Name}
                  </h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: det?.whyText }} />
                <div className="details-applybox-wrapper">
                  <div className="details-applybox flex-box">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/blank-sheet.png"
                        alt="blanksheet-image"
                      />
                    </div>
                    <h5>
                      <strong>Compare & Shortlist</strong> Your Desired Course
                    </h5>
                  </div>
                  <div className="details-applybox flex-box">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/document-profile.png"
                        alt="document-images"
                      />
                    </div>
                    <h5>
                      <strong>Create Profile,</strong> Upload Documents & Apply
                    </h5>
                  </div>
                  <div className="details-applybox flex-box">
                    <div className="applynow-icon">
                      <LazyLoadingImages
                        src="/img/apply-message.png"
                        alt="apply-message"
                      />
                    </div>
                    <h5>
                      <strong>Track</strong> Your Application{" "}
                      <strong>Status</strong> Easily
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default RequirementsInfo;
