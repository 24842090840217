export default function Alert({ varient, msg }) {
  return (
    <>
      <div
        style={{
          padding: "6px",
          borderRadius: "4px",
          margin: "6px 4px",
          backgroundColor: varient ? "#d1e7dd" : "#eeb8bd",
        }}
      >
        <p style={{ color: varient ? "#0a3622" : "red" }}>{msg}</p>
      </div>
    </>
  );
}
