import React from "react";

const Termsection = React.memo(function () {
  return (
    <div className="terms-section">
      <div className="container letter-normal">
        <h2>Privacy Policy</h2>
        <p>
          Search Education Pty Limited (ACN 39 606 519 420) (“SearchEdu”)
          recognises the importance of protecting any personal information that
          we collect about users of our websites at www.searchedu.com.au and
          searchedu.global (“Website”).
        </p>

        <p>
          We are committed to compliance with the Australian Privacy Principles
          for handling personal information contained in the Privacy Act 1988
          (Clth.)
        </p>

        <h3>Personal Information</h3>

        <p>
          We need to obtain and retain personal information about our users when
          and in order to provide our services. Generally we will tell you why
          we are collecting information and how we plan to use it. Often these
          things will be obvious when we collect the information.
        </p>

        <p>
          The information we usually collect about you will usually include
          information such as your name, address, telephone number, date of
          birth, passport and in some instances, your educational history.
        </p>

        <p>
          We usually collect personal information directly from you although
          sometimes we may verify your information via third parties.
        </p>

        <h3>HOW DO WE COLLECT THE INFORMATION ?</h3>

        <p>
          Search Education. will collect personally identifiable information
          about you only as part of a voluntary registration process, on-line
          survey or any combination thereof. The Site may contain links to other
          Web sites. Search Education.is not responsible for the privacy
          practices of such Web sites which it does not own, manage or control.
          The Site and third-party vendors, including Google, use first-party
          cookies (such as the Google Analytics cookie) and third-party cookies
          (such as the DoubleClick cookie) together to inform, optimize, and
          serve ads based on someone's past visits to the Site.
        </p>

        <h3>Use of Information</h3>

        <p>
          {" "}
          Search Education. will use your personal information to provide
          personalized features to you on the Site and to provide for
          promotional offers to you through the Site and other channels. Search
          Education. will also provide this information to its business
          associates and partners to get in touch with you when necessary to
          provide the services requested by you. Search Education. will use this
          information to preserve transaction history as governed by existing
          law or policy. Search Education. may also use contact information
          internally to direct its efforts for product improvement, to contact
          you as a survey respondent, to notify you if you win any contest; and
          to send you promotional materials from its contest sponsors or
          advertisers. Search Education. will also use this information to serve
          various promotional and advertising materials to you via display
          advertisements through the Google Ad network on third party websites.
          You can opt out of Google Analytics for Display Advertising and
          customize Google Display network ads using the Ads Preferences
          Manager.
        </p>

        <h3>WITH WHOM YOUR INFORMATION WILL BE SHARED?</h3>

        <p>
          We use personal information collected for the purpose of providing our
          services to you, including administrative functions associated with
          these activities. We may also use the information for the purpose of
          informing you of our services. However you may request that we not use
          personal information about you for this purpose by contacting our
          privacy officer (identified at the end of this statement).
        </p>

        <h3>Use by Third Parties</h3>

        <p>
          SearchEdu may disclose your information to education providers with
          whom you have indicated a desire to inquire or apply regarding their
          educational courses. We may also disclose your information to
          Education Providers, but only with your prior consent. If we do this
          we require these parties to protect your information in the same way
          that we do but we will not be responsible to you for any unauthorised
          disclosures or breaches of privacy by the education providers.
        </p>

        <h3>Use of Aggregate Data</h3>

        <p>
          We may use personal information in aggregate form (where the identify
          of individuals is not disclosed) to improve our services and make them
          more responsive to the needs of our users. We may use this aggregated
          information for statistical compilation and analysis.
          <br />
          Our Website may collect non-personal information from your computer
          (e.g. the identity of your Internet browser, the type of operating
          system you use, your IP address and the domain name of your Internet
          service provider). We may collect information regarding the pages
          within our network which you visit and what you click on.
        </p>

        <h3>Data Quality</h3>

        <p>
          We take all reasonable steps to ensure that the personal information
          that we hold is accurate, complete and up to date. If you believe that
          information that we are holding is not accurate, complete or up to
          date you have a right to notify us and we will correct any
          inaccuracies.
        </p>

        <h3>Security</h3>

        <p>
          SearchEdu strives to ensure the security, integrity and privacy of all
          information held about our users. We use a variety of physical and
          electronic security measures, including restricting physical access to
          our offices and the use of firewalls and secure databases on our
          systems, to keep personal information secure from misuse, loss or
          unauthorised use or disclosure. Unfortunately, no data transmission
          over the Internet can currently be guaranteed to be totally secure.
        </p>

        <h3>Access to Personal Information</h3>

        <p>
          You have a right to access personal information we hold about you,
          except in certain specific circumstances. If we deny you access for
          any reason we will tell you why. We may charge you for our costs in
          providing access to information such as costs of photocopying and
          accessing information stored off-site.
        </p>

        <p>
          To request access, please contact our privacy officer as identified at
          the end of this statement.
        </p>

        <h3>Overseas data flows</h3>

        <p>
          We will not transfer your personal information outside Australia
          unless we have obtained your prior consent or where we need to do so
          in order to communicate with you.
        </p>

        <h3>Complaints</h3>

        <p>
          If you have a complaint regarding our handling of your personal
          information including how access is provided to you, you may contact
          our privacy officer as identified out at the end of this statement.
        </p>

        <h3>Cookies</h3>

        <p>
          Cookies are data that a website transfers to an individual's hard
          drive for record-keeping purposes. Cookies can facilitate a user's
          ongoing access to and use of a site. We may utilise Cookies from time
          to time for that purpose. They allow us to track usage patterns and to
          compile data that can help us improve our content and target
          advertising. If you do not want information collected through the use
          of Cookies, there is a simple procedure in most browsers that allows
          you to deny the Cookie feature. But you should note that Cookies might
          be necessary to provide you with features of our Website.
        </p>

        <h3>Online Links to Third Party and Co-Branded Sites</h3>

        <p>
          From time to time our Website may contain links to other Websites.
          SearchEdu is not responsible for the privacy practices or the content
          of those Websites. These sites may collect personal information from
          you that may be shared with us. This Privacy Policy Statement will
          apply to any personal information we obtain in this manner.
        </p>

        <h3>For further information</h3>

        <p>
          Please send an email to our privacy officer via the email address
          below to ask for access to your information or if you have a complaint
          concerning your information privacy or if you would like more
          information about our approach to privacy.
        </p>

        <p>The Privacy Officer at info@SearchEdu.com.au</p>
      </div>
    </div>
  );
});

export default Termsection;
