import React from "react";
import LeftPanel from "../Common/leftPanel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getEventList } from "../../../actions/eventActions";
import moment from "moment/moment";

export default function Index() {
  const dispatch = useDispatch();
  const { loading, error, eventlists } = useSelector(
    (state) => state.listEvent
  );

  const getEvents = () => {
    dispatch(getEventList());
  };

  useEffect(() => {
    if (!eventlists) {
      getEvents();
    }
  }, [dispatch, eventlists]);

  return (
    <LeftPanel>
      <div className="dashboard-profile-main common-dashboard-search-box">
        <div className="dashboard-profile-search-box common-searchbox-style flex-box align-center">
          <div className="coursesearch mb-tzero">
            <form action="" className="searchofcourses">
              <input
                type="text"
                className="form-control"
                placeholder="Search for Courses...."
              />
              <button type="submit" name="coursesarchsubmit" className="btn">
                <img src="/img/searchicon.png" alt="Search Icon" />
              </button>
            </form>
          </div>
          <div className="notification-bellbox">
            <div
              className="notification-bell"
              style={{
                backgroundImage: `url('/img/bell.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <span className="notification">
                <img src="/img/notification-circle.png" alt="dashboard" />
              </span>
            </div>
          </div>
        </div>
        <div className="sec-lg-col-60 dashboard-rightbox dashboard-events dashboard-profile-box-wrapper">
          <div className="featuredbg-style">
            <div className="dash-common-heading-style">
              <h1>Events</h1>
            </div>
            <div className="dashboard-events-wrapper flex-box">
              {eventlists ? (
                eventlists.map((evntl, index) => (
                  <div className="dashboard-events-box">
                    <a href="/" className="dashboard-events-box-image">
                      <div className="dashboard-events-image">
                        <img
                          src={`${process.env.REACT_APP_SED_DOC_URL}/events/mobile/${evntl.MobileImageOfEvent}`}
                          alt={evntl.EventName}
                        />
                      </div>
                    </a>
                    <div className="dashboard-events-content text-center">
                      <p className="location">
                        <img src="/img/locationicon.png" alt="Map" />
                        {evntl.Country}
                      </p>
                      <a href="/">
                        <h5>{evntl.EventName}</h5>
                      </a>
                      <p className="dashboard-event-date">
                        {moment(evntl.StartDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Events As of Now to show!</div>
              )}
            </div>
          </div>
        </div>
        <div className="sec-md-col-20 sticky profile-details-info-wrapper profile-others-details-info-wrapper repeating-events-wrapper">
          <div className="profile-others-details-info">
            <div className="profile-others-details-heading">
              <h2>Repeating Event</h2>
            </div>
            <div className="repeating-events">
              {eventlists ? (
                eventlists.map((evlt, index) => (
                  <div
                    className="repeating-event-box flex-box align-center"
                    key={index + 1}
                  >
                    <div className="repeating-event-date-box">
                      <p className="repeating-event-date">
                        <strong>{moment(evlt.StartDate).format("DD")}</strong>
                        <span className="repeating-month">
                          {moment(evlt.StartDate).format("MMM")}
                        </span>{" "}
                        {moment(evlt.StartDate).format("YYYY")}
                      </p>
                    </div>
                    <div className="repeating-event-content">
                      <p className="location">
                        <img src="/img/locationicon.png" alt="Map" />
                        {evlt.Country}
                      </p>
                      <h5>{evlt.EventName}</h5>
                      <p></p>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </LeftPanel>
  );
}
