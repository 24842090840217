import React from "react";
import { Link } from "react-router-dom";

const ConsultNow = React.memo(function ConsultNow() {
  return (
    <div className="talk-experts text-center">
      <div
        className="talk-experts-top-box bg-style"
        style={{
          backgroundImage: `url('/img/dark-blue-half-circle.png')`,
        }}
      >
        <h6>Talk To Experts</h6>

        <h3>Are You Looking For An Experts</h3>
      </div>

      <div className="talk-experts-bottom-box">
        <a href="/consult_a_counselor" className="btn text-center">
          Consult Now
        </a>
      </div>
    </div>
  );
});

export default ConsultNow;
