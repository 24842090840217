import React, { useEffect, useState } from "react";
import $ from "jquery";
import { LazyLoadingImages } from "../../components/LazyLoadingImages";
import LoginPopup from "../Common/LoginPopup";
import SignupPopup from "../Common/SignupPopup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions/userActions";

import { insertIP } from "../../actions/websiteActions";

const HeaderAustralia = React.memo(function HeaderAustralia({ Height }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setshow] = useState(false);

  const [loginShow, setloginShow] = useState(false);
  const handleLoginShow = () => setloginShow(true);
  const handleLoginClose = () => setloginShow(false);

  const [signupshow, setsignupshow] = useState(false);
  const handleSignupShow = () => setsignupshow(true);
  const handleSignupClose = () => setsignupshow(false);

  const [ActiveClass, setActiveClass] = useState("Home");

  const ClassActiveSet = () => {
    const url = window.location.pathname;
    const suburl = url.split("/")[2];
    setActiveClass(suburl || "");
  };

  // Fixed Header

  if (Height) {
    $(".siteheader").addClass("fixHeader");
  }
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 100 || Height) {
      $(".siteheader").addClass("fixHeader");
    } else {
      $(".siteheader").removeClass("fixHeader");
    }
  });

  // Searchbar fixed on menu
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     if (scroll >= 600) {
  //       $(".siteheader .navfixcoursesearchform").addClass("show");
  //       $(".searchdreamcoursesec").addClass("hide");
  //     } else {
  //       $(".siteheader .navfixcoursesearchform").removeClass("show");
  //       $(".searchdreamcoursesec").removeClass("hide");
  //     }
  //   });

  const userLogin = useSelector((state) => state.userLogin);
  const { userDt } = userLogin;

  //   const { error, success, loading } = useSelector((state) => state.IPInsert);

  const getGeoInfo = async () => {
    const countryname = localStorage.getItem("country");
    const dateold = localStorage.getItem("dtgb");
    const dt = new Date().getTime();
    const dtdiff = parseInt(dt) - parseInt(dateold ? dateold : 0);

    if (!countryname || dtdiff > 86400000) {
      axios
        .get("https://geolocation-db.com/json/")
        .then((respon) => {
          let response = respon.data;

          let ipjson = {
            ipaddress: response?.IPv4,
            countryname: response?.country_name,
            countrycode: response?.country_code,
            OtherDet: JSON.stringify(response),
          };
          dispatch(insertIP(ipjson));

          // data Record:
          /* 
        {
    "ip": "183.83.211.239",
    "network": "183.83.211.224/27",
    "version": "IPv4",
    "city": "Delhi",
    "region": "National Capital Territory of Delhi",
    "region_code": "DL",
    "country": "IN",
    "country_name": "India",
    "country_code": "IN",
    "country_code_iso3": "IND",
    "country_capital": "New Delhi",
    "country_tld": ".in",
    "continent_code": "AS",
    "in_eu": false,
    "postal": "110003",
    "latitude": 28.6542,
    "longitude": 77.2373,
    "timezone": "Asia/Kolkata",
    "utc_offset": "+0530",
    "country_calling_code": "+91",
    "currency": "INR",
    "currency_name": "Rupee",
    "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    "country_area": 3287590,
    "country_population": 1352617328,
    "asn": "AS18209",
    "org": "Atria Convergence Technologies pvt ltd"
}
*/
          dispatch({
            type: "LOCATION",
            payload: response,
          });
          const datett = new Date().getTime();
          localStorage.setItem("country", response?.country_name);
          localStorage.setItem("dtgb", datett);
        })
        .catch((error) => {
          console.warn(error);
        });
    } else {
      const resp = { country_name: localStorage.getItem("country") };
      dispatch({
        type: "LOCATION",
        payload: resp,
      });
    }

    //  const { data } = await axios.get(
    //   "https://ipjson.searcheducation.com/getip"
    // );

    //     if (!countryname || countryname == "undefined" || dtdiff > 86400000) {
    //       await axios
    //         .get("https://ipjson.searcheducation.com/getip")
    //         .then(async (respon) => {
    //           let IpResponse = respon.data.ip;

    //           const { data } = await axios.get(
    //             `https://ipapi.co/${IpResponse}/json`
    //           );
    //           let response = data;

    //           let ipjson = {
    //             ipaddress: response.version,
    //             countryname: response.country_name,
    //             countrycode: response.country_code,
    //             OtherDet: JSON.stringify(response),
    //           };
    //           dispatch(insertIP(ipjson));

    //           // data Record:
    //           /*
    //         {
    //     "ip": "183.83.211.239",
    //     "network": "183.83.211.224/27",
    //     "version": "IPv4",
    //     "city": "Delhi",
    //     "region": "National Capital Territory of Delhi",
    //     "region_code": "DL",
    //     "country": "IN",
    //     "country_name": "India",
    //     "country_code": "IN",
    //     "country_code_iso3": "IND",
    //     "country_capital": "New Delhi",
    //     "country_tld": ".in",
    //     "continent_code": "AS",
    //     "in_eu": false,
    //     "postal": "110003",
    //     "latitude": 28.6542,
    //     "longitude": 77.2373,
    //     "timezone": "Asia/Kolkata",
    //     "utc_offset": "+0530",
    //     "country_calling_code": "+91",
    //     "currency": "INR",
    //     "currency_name": "Rupee",
    //     "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    //     "country_area": 3287590,
    //     "country_population": 1352617328,
    //     "asn": "AS18209",
    //     "org": "Atria Convergence Technologies pvt ltd"
    // }
    // */
    //           dispatch({
    //             type: "LOCATION",
    //             payload: response,
    //           });
    //           const datett = new Date().getTime();
    //           localStorage.setItem("country", response.country_name);
    //           localStorage.setItem("dtgb", datett);
    //         })
    //         .catch((error) => {
    //           console.warn(error);
    //         });
    //     } else {
    //       const resp = { country_name: localStorage.getItem("country") };
    //       dispatch({
    //         type: "LOCATION",
    //         payload: resp,
    //       });
    //     }
  };

  const openLogin = (e) => {
    e.preventDefault();
    setsignupshow(false);
    setloginShow(true);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getGeoInfo();
    ClassActiveSet();
  }, [dispatch]);

  return (
    <>
      <LoginPopup
        show={loginShow}
        handleClose={handleLoginClose}
        handleOpen={handleLoginShow}
      />
      <SignupPopup
        show={signupshow}
        handleClose={handleSignupClose}
        handleOpen={handleSignupShow}
        handleSignIn={openLogin}
      />

      <header className="siteheader">
        <div className="container">
          <div className="sec-row">
            <div className="sec-md-col-10 logoclmn">
              <div className="logo-grp">
                <a href="/australia" className="transparent">
                  {/* <LazyLoadingImages
                    src="/img/SE-White-Lodgo_christmas.png"
                    alt="Logo"
                  /> */}
                  <LazyLoadingImages src="/img/logo.png" alt="Logo" />
                </a>
                <a href="/australia" className="notransparent">
                  {/* <LazyLoadingImages
                    src="/img/SE_Christmas_logo.gif"
                    alt="Logo"
                  /> */}
                  <LazyLoadingImages src="/img/colorlogo.png" alt="Logo" />
                </a>
              </div>
            </div>
            <div className="sec-md-col-85 navclmn">
              <div className="navapplynow">
                <div
                  className="togglenav mnavtriggr"
                  style={{ display: "none" }}
                >
                  <button className="toggle" onClick={(e) => setshow(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <nav
                  className={show ? "navigation-grp show" : "navigation-grp "}
                >
                  <div
                    className="navclstrgr d-none"
                    onClick={(e) => setshow(false)}
                  ></div>
                  <div className="nav-primary">
                    <div className="navformmobile">
                      <div className="navfixcoursesearchform">
                        <div className="courseselectform hide-500 d-none">
                          {/* <form
                            className="flexbox"
                            onSubmit={() =>
                              navigate(`/australia/migration-services`)
                            }
                          >
                            <div className="courseselect" bis_skin_checked="1">
                              Migration Services
                              <span className="courseselect-arrow">
                                <img src="/img/course-arrow-down.png" />
                              </span>
                              <select name="selectcourse" id="selectcourse" style={{maxWidth:'100%', float:"left"}}>
                                <option value="">Country</option>
                                <CountrySelect />
                              </select>
                            </div>
                            {/* <div
                              className="searchcourseform"
                              bis_skin_checked="1"
                            >
                              <div
                                className="coursesearch"
                                bis_skin_checked="1"
                              >
                                <input
                                  type="text"
                                  id="searchcourse"
                                  value={searchcourse}
                                  onChange={(e) =>
                                    setsearchcourse(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                      navigate(
                                        `/courses/search/${searchcourse}`
                                      );
                                  }}
                                  placeholder="Search for Courses...."
                                />
                                <button
                                  type="submit"
                                  name="coursesarchsubmit"
                                  className="btn"
                                >
                                  <img
                                    src="/img/searchicon.png"
                                    alt="Search Icon"
                                  />
                                </button>
                              </div>
                            </div> 
                          </form> */}
                        </div>
                        <ul>
                          <li
                            className={
                              ActiveClass === "" ? "active" : "inactive"
                            }
                          >
                            <a href="/australia">Home</a>
                          </li>
                          <li
                            className={
                              ActiveClass === "migration-services"
                                ? "active"
                                : "inactive"
                            }
                          >
                            <a href="/australia/migration-services">
                              Migration Services
                            </a>
                          </li>
                          <li
                            className={
                              ActiveClass ===
                              "Education-and-Student-Visa-Assistance"
                                ? "active"
                                : "inactive"
                            }
                          >
                            <a href="/australia/Education-and-Student-Visa-Assistance">
                              Education and Student Visa Assistance
                            </a>
                          </li>
                          {/* <li
                            className={
                              ActiveClass === "FAQs" ? "active" : "inactive"
                            }
                          >
                            <a href="/australia/FAQs">FAQs</a>
                          </li> */}
                          <li
                            className={
                              ActiveClass === "about_us" ? "active" : "inactive"
                            }
                          >
                            <a href="/australia/about_us">About Us</a>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="navcoursesearchform show-500">
                        <h6>Search Your Dream Course</h6>
                        <div className="courseselectform">
                          <form action="" className="flexbox">
                            <div className="courseselect">
                              <select name="selectcourse" id="selectcourse">
                                <option value="Courses">Courses</option>
                              </select>
                            </div>
                            <div className="searchcourseform">
                              <div className="coursesearch">
                                <input
                                  type="text"
                                  id="searchcourse"
                                  placeholder="Search for Courses...."
                                  value={searchtxt}
                                  onChange={(e) => setsearchtxt(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      navigate(`/courses/search/${searchtxt}`);
                                    }
                                  }}
                                />
                                <button
                                  type="submit"
                                  name="coursesarchsubmit"
                                  className="btn"
                                  onClick={(e) => {
                                    navigate(`/courses/search/${searchtxt}`);
                                  }}
                                >
                                  <LazyLoadingImages
                                    src="/img/searchicon.png"
                                    alt="Search Icon"
                                  />
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>

                    <div className="applynow show-900">
                      {!userDt ? (
                        <ul>
                          <li>
                            {/* <button className="btn login-process-modal-toggle">Login In</button> */}
                            <a
                              onClick={handleLoginShow}
                              className="btn login-process-modal-toggle"
                            >
                              Log In
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={handleSignupShow}
                              className="btn signup-process-modal-toggle"
                            >
                              Sign Up
                            </a>
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li className="userlogin">
                            <a href="/panel/dashboard">
                              <img src="/img/usericon.png" alt="user" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="btn login-process-modal"
                              onClick={handleLogout}
                            >
                              LogOut
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </nav>
                <div className="applynow hide-900">
                  {!userDt ? (
                    <ul>
                      <li>
                        <a
                          onClick={handleLoginShow}
                          className="btn login-process-modal-toggle"
                        >
                          Log In
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={handleSignupShow}
                          className="btn signup-process-modal-toggle"
                        >
                          Sign Up
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li className="userlogin">
                        <a href="/panel/dashboard" target="_blank">
                          <img src="/img/usericon.png" alt="user" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn login-process-modal"
                          onClick={handleLogout}
                        >
                          LogOut
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});

export default HeaderAustralia;
